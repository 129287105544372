@use 'styles/colors' as *;

.delivery-order-details .delivery-order-summary-wrapper {
    padding: 48px 30px;
    border-width: 0 1px;
    border-style: solid;
    border-color: #eaefef;
    background-color: #ffffff;
    position: relative;

    .tabs {
      width: 50%;
    }
  }

  .delivery-order-details .delivery-order-summary-wrapper::before {
    position: absolute;
    height: 18px;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    content: '';
    background-image: url(./sawtooth-pattern.svg);
    background-repeat: repeat-x;
    background-position: top left 15px;
  }

  .delivery-order-details .delivery-order-summary-wrapper::after {
    position: absolute;
    height: 18px;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    content: '';
    background-image: url(./sawtooth-pattern.svg);
    background-repeat: repeat-x;
    background-position: bottom left;
  }

  .delivery-order-details .user-details {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }

  .delivery-order-details .collapse-container-order, .delivery-order-details .collapse-container-metadata,.delivery-order-details .collapse-container-payment, .delivery-order-details .collapse-container-refund{
    border-top: 1px solid #eaefef;
    /* padding-top: 20px; */
    position: relative;
    display: flex;
    align-items: center;
    margin: auto -30px;
    padding: 0px 30px;
  }
  .delivery-order-details .collapse-container-order {
    margin-top: 20px;
  }

  .delivery-order-details {
    .no-packlist-text {
      display: flex;
      justify-content: center;
      margin: 2rem auto;
      color: $fp-grey;
    }
  }

  @media screen and (max-width: 550px) {
    .delivery-order-details .delivery-order-summary-wrapper {
        margin: 0 -30px;
        padding-top: 20px;
        border-top: 1px solid #eaefef;
      }
  }

  @media screen and (max-width: 480px) {
    .delivery-order-details .delivery-order-summary-wrapper::before, .delivery-order-details .order-placement-info-item, .delivery-order-details .delivery-order-summary-wrapper::after {
        background-image: none;
        padding: 12px 0 0px;
      }
  }
