.editPopup.lineitem-box{
  max-width: 50rem;
} 
.lineitem-wrapper .lineitem-inner {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.75rem;
  line-height: 1.8;
  padding: 0.625rem;
}

.lineitem-wrapper .lineitem-inner .lineitem-item:not(.description) {
  width: 50%;
  margin-bottom: 0.125rem;
}

.lineitem-wrapper .lineitem-inner .lineitem-item.description {
  width: 100%;
  margin-bottom: 0.125rem;
}

.lineitem-wrapper .lineitem-inner.setupdetails .lineitem-item{
  margin-bottom: 0.125rem;
}

.lineitem-wrapper .lineitem-inner .black{
  color: #000;
}
.lineitem-wrapper .lineitem-inner .black.comma-end{
  margin: 0 0.125rem;
}
.lineitem-wrapper .lineitem-inner .black.comma-end:not(:last-child):after{
  content: ',';
}

.lineitem-wrapper .lineitem-inner .title{
  font-size: 0.875rem;
}

.lineitem-wrapper .advertiser-table{
  max-height: 12.75rem;
  overflow-y: scroll;
}
.lineitem-wrapper .advertiser-row{
  display: flex;
  border-top: 0.0625rem solid #ccc;
  border-left: 0.0625rem solid #ccc;
  border-right: 0.0625rem solid #ccc;
  color: #000;
}
.lineitem-wrapper .advertiser-row:last-child{
  border-bottom: 0.0625rem solid #ccc;
}
.lineitem-wrapper .advertiser-row .id{
  width: 10%;
  padding-left: 0.625rem;
}
.lineitem-wrapper .advertiser-row .name{
  width: 70%;
  padding-left: 0.625rem;
}
.lineitem-wrapper .advertiser-row .budget{
  box-sizing: border-box;
  width: 30%;
}
.lineitem-wrapper .advertiser-row .content{
  box-sizing: border-box;
  width: 90%;
}
