.Imageslideshow {
  position: relative;
  overflow: hidden;
}
.Imageslideshow h3 {
  margin: 0;
  margin-bottom: 10px;
}
.Imageslideshow .slides {
  white-space: nowrap;
  transform: translate3d(0, 0, 0);
  transition: transform 500ms cubic-bezier(0.77, 0, 0.175, 1);
}
.Imageslideshow .slide {
  display: inline-block;
}
.Imageslideshow img {
  max-width: 100%;
}
.Imageslideshow .control {
  text-align: center;
}
.Imageslideshow .bulconst {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 0 5px;
  cursor: pointer;
}
.Imageslideshow .active.bulconst {
  background-color: #ccc;
}

button:disabled {
  opacity: 0.4;
}
