.CustomerEmailWidget {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.CustomerEmailWidget .mail-icon {
  background-image: url('./icon.svg');
  height: 18px;
  width: 18px;
  background-size: fit;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.CustomerEmailWidgetPopup .header {
  height: auto;
  background-color: #2b3238;
  color: #ffffff;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 100%;
  position: relative;
  word-break: break-all;
  display: flex;
}
.CustomerEmailWidgetPopup .header h1{
 line-height: 22px;

}

.CustomerEmailWidgetPopup .header .close{
 margin-top: 11px;
}