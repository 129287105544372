:root {
  --border-color: #dadee0;
  --primary-text-color: #2b3238;
  --secondary-text-color: #80959d;
  --primary-color: #1557bf;
  --secondary-action-color: #f3f5f6;
  --separator-color: #eaefef;
  --bg-color: #fbfcfc;
  --disabled-color: #f4f7f9;
  --red: #eb8181;
  --fp-white: #fbfcfc;
  --fp-white-0: #ffff;
  --fp-white-2: #eaefef;
  --fp-white-3: #f0f4f4;
  --fp-blue: #1557bf;
  --fp-blue-1: #1454b8;
  --fp-blue-2: #44bffe;
  --fp-red: #ff0000;
  --fp-red-1: #fb424b;
  --fp-red-2: #eb8181;
  --fp-red-3: #c10b3a;
  --fp-red-4: #f89393;
  --fp-red-5: #f8d7da;
  --fp-green: #008000;
  --fp-green-2: #3fc078;
  --fp-grey: #80959d;
  --fp-grey-1: #f4f7f9;
  --fp-grey-2: #8b9fa6;
  --fp-grey-3: #696969;
  --fp-grey-4: #dadee0;
  --fp-grey-5: #999999;
  --fp-teal-1: #588292;
  --fp-black: #2b3238;
  --fp-black-0: #0000;
  --fp-black-0-40: rgba(var(--fp-black-0), 0.04);
}
html {
  font-size: 16px;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: #2b3238;
  margin: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: inherit;
}

input,
textarea,
select,
button {
  font-family: inherit;
}

:focus {
  outline: none;
}

/* Page Container */
#app {
  display: flex;
  min-height: 100vh;
  max-width: 100vw;
  overflow-x: scroll;
}
#app main {
  min-width: 320px;
  box-shadow: 2px 15px 9px 0 rgba(0, 0, 0, 0.15);
  padding: 30px 40px;
  z-index: 1;
}
#app main > div {
  position: relative;
}

#app main {
  flex: 0 0 100%;
}

@media (min-width: 1024px) {
  #app main {
    flex: 1 1;
  }
}

.fullWidthPage #main {
  background: url('./bg-footer.svg') repeat-x -42px bottom;
  min-height: calc(100% - 50px);
  background-position: bottom;
  padding: 15px 25px;
}
h1 {
  margin: 0 0 38px;
  font-size: 1.85rem;
  font-weight: bold;
  line-height: 0.93;
  letter-spacing: -0.7px;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

h1.title {
  margin-bottom: 40px;
  line-height: 1;
}

strong {
  font-weight: bold;
}

a {
  color: #1557bf;
  cursor: pointer;
  text-decoration: none;
}

img:not([src]) {
  display: none;
}

.flex {
  display: flex;
}

.flex-around {
  display: flex;
  justify-content: space-between;
  flex: 1; /* This is given so that elements with this class can be nested */
}

.flex-vertical {
  flex-direction: column;
}

.flex-item {
  flex: 1;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.plus-icon {
  height: 10px;
  width: 10px;
  background-image: url('./plus.svg');
  display: inline-block;
  margin-right: 8px;
}

small {
  font-size: 0.75rem;
}

.text-muted {
  color: #80959d;
}

.text-nowrap {
  white-space: nowrap;
}

.hidden {
  display: none;
}

.red {
  color: #eb8181;
}

.green {
  color: #20c05c;
}

.zero-font {
  font-size: 0;
}

.grid {
  display: grid;
}

.bold {
  font-weight: 600;
}

.capitalize {
  text-transform: capitalize;
}

@media screen and (min-width: 768px) {
  /* Media Object */
  .media {
    display: flex;
  }
  .media-body {
    flex: 1;
  }

  .imgHeader {
    margin-top: 5rem;
    text-align: center;
  }

  .imgHeader img {
    width: 40vw;
  }

  .loaderWrapper {
    margin-top: 10vh;
  }
}

@media screen and (max-width: 768px) {
  .imgHeader {
    text-align: center;
  }

  .imgHeader img {
    width: 60%;
  }

  .loaderWrapper {
    margin-top: 10vh;
  }
}
@media screen and (max-width: 480px) {
  #app main {
    padding: 25px;
  }
  .xs-hidden {
    display: none;
  }
  h1.title {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 0 0 1rem;
  }
  h1 {
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 0 0 1rem;
  }

  .imgHeader {
    text-align: center;
  }

  .imgHeader img {
    width: 80vw;
  }
  .loaderWrapper {
    margin-top: 5vh;
  }
}

@media screen and (max-width: 360px) {
  #app main {
    padding: 20px;
  }
}

#preorderOffline {
  font-family: Roboto;
}

[data-name='fixedButton'] {
  padding: 0 20px 0 110px;
}

@media screen and (max-width: 360px) {
  #app main {
    padding: 20px;
  }
}

@media screen and (max-width: 1024px) {
  [data-name='fixedButton'] {
    padding: 0 20px;
  }
}

.lockScroll {
  overflow-y: hidden;
}
.dotted__line {
  width: 100%;
  border-top: 2px dotted black;
}

.confirmPreorderPopup .details {
  padding: 16px;
}

.esignature {
  aspect-ratio: 2.5;
}
