.product-linking-form .product-row {
  display: flex;
  justify-content: space-between;
  background-size: 1em;
  background-position: left 12px center;
  background-repeat: no-repeat;
  border-top: 1px solid #f5f5f5;
  padding: 0 12px 0 32px;
}

.product-linking-form .product-row .product-name,
.product-linking-form .product-row .action-text {
  line-height: 38px;
  transition: color 0.2s ease-in-out;
}

.product-linking-form .product-row.status-adding .product-name,
.product-linking-form .product-row.status-removing .product-name {
  color: #80959d;
}

.product-linking-form .product-row.status-failed .product-name {
  color: #eb8181;
}

.product-linking-form .product-row.status-added {
  background-image: url(./done_icon.svg);
}

.product-linking-form .product-row.status-failed {
  background-image: url(./error_icon.svg);
}

.product-linking-form .product-row.status-added,
.product-linking-form .product-row.status-failed {
  cursor: pointer;
}

.product-linking-form .product-row.status-added .action-text,
.product-linking-form .product-row.status-failed .action-text {
  font-weight: bold;
  font-size: 0.75rem;
  color: #80959d;
}