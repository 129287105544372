.evoucher-summary-wrapper .evoucher-placement-details {
  width: 21.875rem;
}

.evoucher-summary-wrapper .evoucher-placement-details .section-pre-evoucher {
  display: flex;
  justify-content: space-between;
}

.evoucher-summary-wrapper .evoucher-placement-details .pre-evoucher {
  margin-top: 0.313rem;
}

.evoucher-summary-wrapper .evoucher-placement-details .pre-evoucherz img {
  margin-right: 0.5rem;
  margin-top: -0.313rem;
}

.user-details .flex {
  display: flex;
  align-items: center;
}

.user-details .flex .button-container {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.user-details .error {
  font-size: 11px;
  color: red;
}

.no-margin-top {
  margin-top: 0;
}
