.pickup-pending-form .pickup-pending {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.pickup-pending-form .return-qty{
  margin-left: 20px;
  padding: 10px 0;
  font-size: 0.75rem;
}

.pickup-pending-form .form-actions {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .pickup-pending-form .pickup-pending {
    display: inherit;
  }
}