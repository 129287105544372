.notifications form {
  max-width: 100%;
}
.notifications .form-error {
  text-align: left;
}
.notifications .notifications-group .Toggle:not(:last-child) {
  border-bottom: 1px solid #f5f5f5;
}

.notifications .notifications-form .field .react-toggle {
  flex: 0 0 50px;
}
.notifications .notifications-group {
  display: flex;
  justify-content: flex-start;
  flex: 1 1 100%;
  border: solid 1px #eaefef;
}

.notifications .form-fields {
  flex: 1 1;
  margin: 20px 30px;
}
.notifications form .explanation {
  display: none; /* TODO: Get proper text from API*/
  flex: 0 1 280px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
  background-color: #fbfcfc;
  font-size: 0.75rem;
  color: #80959d;
  letter-spacing: -0.1px;
  border-left: solid 1px #eaefef;
}
.notifications .Radio {
  padding-top: 10px;
}
.notifications .checkbox-group .checkbox-label {
  flex: 1 0;
}
.notifications .Send {
  margin: 10px 0;
}
.notifications .DropDown {
  max-width: 155px;
  margin-top: -10px;
}
.notifications .DropDown .dropdown-menu {
  width: 100%;
  font-weight: 600;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #eaefef;
  margin-top: -23px;
}
.notifications .DropDown .name-part {
  display: flex;
}
.notifications .DropDown .name-part .icon {
  padding-right: 10px;
  width: 30px;
}
.notifications .DropDown .heading {
  text-align: center;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaefef;
  height: 40px;
  line-height: 40px;
  font-size: 0.75rem;
  font-weight: 600;
  color: #1557bf;
}
.notifications .DropDown .heading img {
  margin-left: -10px;
  margin-right: 10px;
}
.notifications .Toggle {
  margin: 20px 0;
}
.notifications .notifications-group .Toggle {
  padding-bottom: 20px;
  border-bottom: 1px solid #efefef;
}
.notifications .notifications-group .Toggle:last-of-type {
  border-bottom: none;
}
.notifications .notifications-group .Toggle:first-child {
  margin-top: 10px;
}
.notifications .form-buttons-container {
  margin-top: 20px;
}
.notifications .notifications-group .label {
  font-size: 0.875rem;
  font-weight: 600;
}

.editPopup .addEmailForm .form-buttons-container,
.editPopup .AddPhoneNumberForm .form-buttons-container {
  display: flex;
  justify-content: flex-end;
}

.editPopup .addEmailForm .form-buttons-container .primary,
.editPopup .AddPhoneNumberForm .form-buttons-container .primary {
  margin-left: 10px;
}

@media screen and (max-width: 767px) {
  .notifications .notifications-group {
    display: block;
  }
  .notifications form .explanation {
    display: none; /* TODO: Get proper text from API*/
    padding-bottom: 40px;
    border-left: none;
    border-top: solid 1px #eaefef;
  }
  .notifications .Send .name-part {
    min-width: 40px;
  }
  .notifications .Send .name {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .notifications .Send .value-part {
    word-break: break-all;
  }
  .notifications-form .form-buttons-container {
    display: flex;
  }
  .notifications-form .form-buttons-container .primary,
  .notifications-form .form-buttons-container .button {
    flex-grow: 1;
  }
}
