.customer-tags .table .table-row .Toggle label {
    display: none;
}

.customer-tags .table .table-row .Toggle {
    display: none;
}
.customer-tags .customer-service-tag-name span:hover {
    cursor: pointer;
    color: #1557bf;
}

@media only screen and (max-width: 768px) {

    .customer-tags .table .table-row .DISABLED span:first-child, 
    .customer-tags .table .table-row .ENABLED span:first-child {
        display: none;
    }
    
    .customer-tags .table .table-row .Toggle {
        display: inline-block;
    }
    .customer-tags .table .table-row {
        display: grid;
        border: 1px solid #eaefef;
        background: #ffffff;
        position: relative;
        margin-bottom: 20px;
    }

    .customer-tags .table .table-row:last-child {
        margin-bottom: unset;
    }

    .customer-tags .table-header {
        display: none;
    }

    .customer-tags .table .table-row .table-cell {
        border:none;
        padding: 0;
        height: unset;
    }

    .customer-tags .table .table-row .customer-service-tag-name {
        padding:10px 8px;
        letter-spacing: -0.2px;
        color: #2b3238;
        font-weight: 600;
        min-height: 45px;
    }
    .customer-tags .table .table-row .customer-service-tag-description {
        padding:10px 8px;
        color: #9e9e9e;
        padding-right: 60px;
        background: #fbfcfc;
    }

    .customer-tags .table .table-row .customer-service-tag-actions {
        position: absolute;
        right: 8px;
        top:10px;
    }
    .customer-tags .table .table-row .customer-service-tag-status {
        position: absolute;
        right: 8px;
        bottom:10px;
    }

    .customer-tags .table .table-cell.customer-service-tag-description::before {
        content: 'Description: ';
        position: relative;
        top: -2px;
        line-height: 1.5;
        letter-spacing: -0.1px;
        color: #80959d;
        font-weight: 600;
    }
    .customer-tags .table .table-row .table-cell.system-generated-tag-name {
        padding: 12px 10px;
    }
  }
@media only screen and (max-width: 480px) {
    .customer-tags .header-container  {
        flex-direction: column;
    }
    .customer-tags .header-container .header-actions-wrapper {
        margin-left: -44px;
    }
    .customer-tags .header-container .header-actions-wrapper .search-button-wrapper {
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .customer-tags .header-actions-wrapper .button span.text {
        display: inline;
    }
    .customer-tags .header-actions-wrapper .import-tags-button,.customer-tags .header-actions-wrapper .primary {
        flex: 1;
        font-size: 14px;
        width: 100%;
    }
    .customer-tags .filters-wrapper .form-fields {
        display: flex;
    }

    .customer-tags .filters-wrapper .button,.customer-tags .filters-wrapper .primary {
        width: 48%;
    }
    .customer-tags .table-row .table-cell.customer-service-tag-description {
        border-top: 1px solid #eaefef;
        font-size: 13px;
        color: #2b3238;
    }
    .customer-tags .table-row .table-cell.customer-service-tag-description::before {
        font-size: 11px;
        font-weight: normal;
    }

    .customer-tags .table .table-row  {
        grid-template-columns: 1fr 60px;
    }

    .customer-tags .table .table-row .table-cell {
        padding: 10px 15px;
    }
   
    .customer-tags .table .table-row .customer-service-tag-status, .customer-tags .table .table-row .customer-service-tag-actions {
        position: initial;
    }

    .customer-tags .table .table-row .table-cell.customer-service-tag-status {
        grid-row-start: 2;
        grid-column-start: 2;
        border-top: 1px solid #eaefef;
        background: #fbfcfc;
        padding-left: 0px;
    }

    .customer-tags .table .table-row .customer-service-tag-actions {
        grid-row-start: 1;
        grid-column-start: 2;
    }
    .customer-tags-form .form-actions .primary, .customer-tags-form .form-actions .button {
        flex: 1;
    }
    .customer-tags .table-container {
        margin: auto 0px;
    }
}
