.SellerForm form {
  max-width: 90%;
}
.SellerForm .table .table-cell:last-child {
  text-align: left;
}
.SellerForm .Searchable .tag-value-icon {
  z-index: 2;
}
.SellerForm .tooltip {
  color: gray;
  background-color: white;
  visibility: visible;
  top: 10px;
}
.SellerForm .tag-value:hover .tooltip {
  opacity: 1;
}
.SellerForm .calendar-container {
  margin-top: 10px;
  margin-left: -17px;
}
.SellerForm .seller-store-box {
  border: 1px solid gray;
  border-style: dashed;
  padding: 20px;
}
.SellerForm .seller-store-box .store-config {
  margin-bottom: 20px;
}
.SellerForm .seller-store-box .stock-distribution {
  margin-right: 20px;
}
.SellerForm .seller-store-box button.button {
  margin-left: 40px;
}
.SellerForm .handling-time-container {
  display: grid;
  grid-template-columns: 75% 25%;
  align-items: center;
}
.SellerForm .handling-time-container.delete {
  display: grid;
  grid-template-columns: 70% 2% 28%;
  align-items: center;
  grid-column-gap: 5px;
}
.SellerForm .flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.SellerForm .handling-time-box {
  flex: 0.95;
  align-items: flex-start;
}
.SellerForm .handling-time-box.split-report {
  flex: 1;
  margin-right: 16px;
}
.SellerForm .handling-time-store {
  flex: 0.5;
}
.SellerForm .Toggle {
  display: initial;
  margin-right: 5px;
  flex: 0;
}
.SellerForm .store-config-box {
  margin: 10px 0;
}
.SellerForm .store-box {
  flex: 0.5;
}
.SellerForm .add-store {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.SellerForm .delete-store {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: auto;
}
.SellerForm .delete-cutoff {
  cursor: pointer;
}
.SellerForm .delete-store span {
  color: #ea353a;
  font-size: 13px;
  margin-left: 10px;
}
.SellerForm .hide.button {
  visibility: hidden;
}
.SellerForm img.hide {
  visibility: hidden;
}
.SellerForm .form-actions.edit {
  justify-content: flex-end;
  margin-top: 30px;
}
.SellerForm .error {
  font-size: 11px;
  color: red;
  display: flex;
  justify-content: flex-end;
}
.SellerForm .config-options-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 20px;
}
.SellerForm .config-options-container.full {
  width: 100%;
}
.SellerForm .config-options-container .picklist-type {
}
.SellerForm .transporter-container {
  align-items: flex-start;
}
.SellerForm .transporter-container .search-transporter {
  flex: 0.6;
  margin-right: 20px;
}
.SellerForm .transporter-container .transporter-off-days {
  flex: 1;
}
.SellerForm .transporter-container {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  column-gap: 20px;
}
.SellerForm .multi-select .select-field-dropdown {
  position: absolute;
  display: block;
  width: 100%;
}

.SellerForm .additional-packlist-generated .Checkbox label {
  font-weight: normal;
  font-size: 14px;
}
.SellerForm .download-container {
  display: flex;
  justify-content: flex-end;
}
.SellerForm .field.input-error select {
  border: 1px solid #ea353a;
  background-color: #fbf5f5;
}
