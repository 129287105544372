#config {
  max-height: 31.25rem;
  height: 31.25rem;
  overflow-y: scroll;
}

.results {
  padding-top: 1.25rem;
}

.domain-select {
  width: 16%;
  float: left;
  text-align: center;
}

.config-select {
  display: inline-block;
  width: 100%;
}

.add-button {
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #f3f5f6;
  color: #2b3238;
  margin-left: 0.625rem;
  letter-spacing: -0.0187rem;
  border: 0.0625rem solid transparent;
  display: inline-block;
  cursor: pointer;
}

.description {
  width: 100%;
  display: inline-block;
}

#addattr-button {
  margin-left: 1.25rem;
  float: right;
}

.recommender-config table {
  border: 0.063rem solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.recommender-config  table caption {
  font-size: 1.5rem;
  margin: 0.5rem 0 0.75rem;
}

.recommender-config  table tr {
  background-color: #f8f8f8;
  border: 0.063rem solid #ddd;
  padding: 0.35rem;
}

.recommender-config table th,
.recommender-config table td {
  padding: 0.625rem;
  text-align: center;
  word-wrap: break-word;
}

.recommender-config  table th {
  font-size: 0.85rem;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
}

@media screen and (max-width: 37.5rem) {
  .recommender-config table {
    border: 0;
  }

  .recommender-config table caption {
    font-size: 1.3rem;
  }

  .recommender-config table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 0.063rem;
    margin: -0.063rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 0.063rem;
  }

  .recommender-config table tr {
    border-bottom: 3 solid #ddd;
    display: block;
    margin-bottom: 0.625rem;
  }

  .recommender-config table td {
    border-bottom: 0.063rem solid #ddd;
    display: block;
    font-size: 0.8rem;
    text-align: right;
  }

  .recommender-config table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .recommender-config table td:last-child {
    border-bottom: 0;
  }
}
.recommender-config input {
  margin: 10 rem;
}
.recommender-config button {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.recommender-config .config-save {
  margin: 1.25rem 0;
  display: flex;
  justify-content: left;
}

.recommender-config .config-save {
  margin: 1.25rem 0;
  display: flex;
  justify-content: left;
}

.recommender-config .success {
  color: #000e24;
  background-color: #669cf3;
  border-color: #000b1d;
  font-size: 0.75rem;
  border: 1px solid transparent;
  margin: 0.9375rem 0 0.5rem;
  padding: 0.5rem 0;
  text-align: center;
}