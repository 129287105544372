.header-container {
  display: flex;
  flex-direction: row;
}
.header-container > a {
  margin-right: 0.625rem;
  border: 1px solid #eaefef;
  background-color: #f3f5f6;
  padding: 0.375rem 0.5rem 0.625rem 0.5rem;
  border-left: none;
}
.header-container .dropdown-menu {
  width: 12rem;
  text-align: left;
}
.header-container .dropdown-menu span {
  white-space: nowrap;
}
.header-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.rewards-page .header-container .header-actions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.table .table-cell {
  min-width: 8rem;
}
.listing-page .search-button-wrapper:not(:only-child) {
  margin-left: auto;
}
.rewards-page .header-container .header-actions-wrapper {
  margin-left: -3.25rem;
}
.table-row .rewards-page-actions a {
  color: #2b3238;
}
.table-row .rewards-page-actions a:hover {
  color: #1557bf;
}
.rewards-page .table.table-dynamic .table-row .table-cell.column-name {
  width: 55%;
}
.rewards-page .table-row .table-cell {
  height: 4.375rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.rewards-page .table .table-cell {
  vertical-align: middle;
}
.table .table-cell:last-child {
  width: 8rem;
}
h1.title {
  min-width: 9.875rem;
}
.table .table-cell:last-child {
  width: 8rem;
}
h1.title {
  min-width: 9.875rem;
}
.table-header .table-cell.ascending::after {
  content: ' \25B2';
  display: inline-block;
  margin-left: 1em;
}

.table-header .table-cell.descending::after {
  content: ' \25BC';
  display: inline-block;
  margin-left: 1em;
}
.resend-popup {
  max-width: 100% !important;
}
@media (max-width: 580px) {
  .rewards-page .table.table-dynamic .table-row {
    margin: 0.625rem 1.25rem !important;
    width: auto;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .rewards-page .table.table-dynamic .table-cell {
    width: 50%;
  }
  .rewards-page .table-container {
    margin: 0.625rem -0.938rem 0 -0.938rem;
  }
  .rewards-page .header-container .header-actions-wrapper {
    margin-top: -0.25rem;
    margin-right: -0.313rem;
  }
}

@media only screen and (max-width: 650px) {
  .rewards-page .table .table-row .table-cell small,
  .rewards-page .table .table-row .table-cell span,
  .rewards-page .table .table-row .table-cell div {
    font-size: 0.75rem;
  }
  .header-container {
    flex-direction: column;
  }
  .listing-page .search-button-wrapper:not(:only-child) {
    margin-top: -2.625rem;
  }
}

@media screen and (max-width: 840px) {
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-name::before {
    content: 'Campaign Name';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-start-date::before {
    content: 'Start Date';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-end-date::before {
    content: 'End Date';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-campaign-status {
    bottom: 0;
    position: relative;
    width: 50%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 0.75rem 1.2rem;
  }
  .rewards-page .table.table-dynamic .table-row .table-cell.column-actions {
    bottom: 0;
    right: 0;
    position: absolute;
    width: 50%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 0.75rem 0.9375rem;
  }
}

@media screen and (min-width: 1024px) {
  .header-container {
    margin-left: auto;
    justify-content: space-between;
  }
  .rewards-page .header-container .header-actions-wrapper {
    flex-direction: row;
  }
  .resend-popup {
    max-width: 50% !important;
  }
}
