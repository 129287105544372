.pickers-today .pickers-table {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0px;
}

.pickers-today .pickers-flex {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0px;
}

.pickers-today .pickers-idle {
    border: solid 1px #eaefef;
    background-color: #fbfcfc;
    padding: 0px 0px 10px 0px;
    margin-right: 20px;
    margin-bottom: 20px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.pickers-today .pickers-table-row {
    background-color: white;
    margin-right: 20px;
    margin-bottom: 20px;
    flex: 1;
    max-width: 602px;
}

.pickers-today .pickers-table-cell {
    background-color: white;
    border: solid 1px #eaefef;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    padding: 0px;
}

.pickers-today .active-picker-section {
    display: flex;
}
 
.pickers-today .pickers-employee-details-section {
    border-radius: 2px;
    display: flex;
    
}

.pickers-table .pickers-order-details {
    padding: 22px 15px;
    width: 100%;
}

.pickers-table .curve-box{
    background: #8B9FA6;
    color: #FFFF;
    width: 150px;
    height: 20px;
    text-align: center;
    border-radius: 10px;
    display: inline-block;
    font-size: 0.8rem;
  }

.pickers-today .pickers-active-employee-details-section {
    border-bottom: solid 1px #eaefef;
    border-radius: 2px;
    background-color: #fbfcfc;
    padding-bottom: 10px;
    border-right: 1px solid #eaefef;
}
.pickers-today .pickers-emp-name-image {
    display: flex;
}

.pickers-today .pickers-employee-image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 21px 12px 21px 20px;
    object-fit: cover;
}

.pickers-today .pickers-employee-image-empty {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    margin: 21px 12px 21px 20px;
    object-fit: cover;
}

.pickers-today .setting-image {
    margin: -1px 0px 4px 0px;
}

.pickers-today .late-time {
    float: right;
    background-color: #ffeeee;
    padding: 0px 8px;
    border-radius: 2px;
    color: #777777;
}

.pickers-today .pickers-idle-picker-header, .pickers-today .pickers-active-picker-header {
    font-size: 18px;
    font-weight: 600;
    color: #2b3238;
}

.pickers-today .pickers-employee-name {
    font-weight: 600;
    letter-spacing: -0.3px;
    text-align: left;
    color: #2b3238;
    margin-top: 22px;
    margin-bottom: 22px;
    width: 210px;
}

.pickers-today .pickers-employee-phone {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: left;
    color: #80959d;
    font-weight: 400;
}

.pickers-today .pickers-idle-pickers {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: left;
    color: #80959d;
    margin: auto;
}

.pickers-today .pickers-picking-item {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0.1px;
    text-align: left;
    color: #80959d;
}


.pickers-today .pickers-slot-time-1, .pickers-today .pickers-slot-time-2, .pickers-today .pickers-slot-time-3 {
    border-right: solid 1px #eaefef;
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #2b3238;
    padding: 14px 0px;
    text-align: center;
}

.pickers-today .pickers-shift-time-1{
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #2b3238;
    padding: 7px 0px;
    margin: 0 20px;
}

.pickers-today .active-picker-section .pickers-active-employee-details-section .pickers-shift-time-1.slot    {
    border-bottom: 1px solid #ececec;
}

.pickers-today .slot {
    border-bottom: 1px solid #ececec;
}

.pickers-today .pickers-time-slot-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.pickers-today .pickers-slot-time-3 {
    border: 0px;
}

.pickers-today .pickers-pagination-count {
    display: none;
}

.pickers-today .pickers-grid-cell {
    display: grid;
    grid-gap: 2em;
}

.pickers-today .pickers-idle-picker {
    padding: 30px 0px;
}

.pickers-today .pickers-nav-btn {
    float: right;
}

.pickers-today .pickers-picker-header {
    font-size: 18px;
    font-weight: 600;
    color: #2b3238;
    margin-bottom: 24px;
    margin-left: 40px;
}

.pickers-today .pickers-slot-time {
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: -0.1px;
    color: #2b3238;
    margin: 14px auto;
    padding: 0px;
    text-align: center;
}

.pickers-today .pickers-phone-icon {
    margin-right: 3px;
    margin-top: -2px;
}

.pickers-today .pickers-empty-state {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.pickers-today .pickers-title {
    margin: 40px;
    margin: 0 0 38px;
    font-size: 1.85rem;
    font-weight: bold;
    line-height: 0.93;
    letter-spacing: -0.7px;
}

.pickers-today .pickers-side-bar-container {
    width: 250px;
    height: 339px;
    border-radius: 2px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    border: solid 1px #eaefef;
    padding: 12px;
    text-align: center;
    float: right;
}

.pickers-today .pickers-total-pickers-container {
    height: 226px;
    border-radius: 2px;
    background-color: #e5f6ff;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: #2b3238;
    padding-top: 16px;
}

.pickers-today .order-slot {
    float: right;
}

.pickers-today .pickers-total-pickers-container span {
    font-size: 30px;
    font-weight: bold;
    line-height: 0.93;
    letter-spacing: -0.7px;
    line-height: 1.4;
}

.pickers-today .pickers-active-pickers-container, .pickers-today .pickers-idle-pickers-container {
    width: 110px;
    height: 82px;
    border-radius: 2px;
    background-color: #e9fcf1;
    margin: 8px 0px;
    padding: 16px;
}

.pickers-today .pickers-active-pickers-container span, .pickers-today .pickers-idle-pickers-container span {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    letter-spacing: -0.3px;
}

.pickers-today .pickers-idle-pickers-container {
    background-color: #ffeeee;
    margin-left: 8px;
}

.pickers-today .pickers-active-idle {
    display: inline-flex;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    color: #2b3238;
}

.pickers-today .pickers-icon {
    margin-top: 20px;
}

.pickers-today .btnUnassign{
    margin-top:20px;
}

@media (max-width: 1024px) {
    .pickers-today .pickers-table {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .pickers-today .pickers-picking-item {
        margin: auto;
    }
  
}

@media (max-width: 830px) {
    .pickers-today .pickers-idle {
        margin-right: 16px;
    }
    .pickers-today .pickers-flex {
        grid-gap: 0em;
    }
}

@media (max-width: 768px) {
    .pickers-today .pickers-table-row {
        max-width: 100%;
    }

    .pickers-today .pickers-active-employee-details-section {
        width: 100%;
    }
    .pickers-today .pickers-flex {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
    }
    .pickers-today .pickers-table {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .pickers-today .pickers-side-bar-container {
        float: none;
        margin: auto;
        margin-bottom: 24px;
    }
    .pickers-today .pickers-table-row, .pickers-today .pickers-idle {
        margin: 0px;
    }
}

@media screen and (max-width: 580px) {

    .pickers-today .active-picker-section {
        flex-direction: column
    }

    .pickers-today .primary.button {
        overflow: hidden;
    }
    .pickers-today .pickers-title {
       font-size: 1.125rem;
       margin-top: 5px;
       display: flex;
       justify-content: space-between;
    }
}

@media (max-width: 480px) {
    .pickers-today .pickers-slot-time-1, .pickers-today .pickers-slot-time-2, .pickers-today .pickers-slot-time-3 {
        padding: 14px 14px;
    }
    .pickers-today .pickers-flex {
        grid-template-columns: 1fr;
        grid-gap: 20px;
    }
    .pickers-today .pickers-nav-btn .button {
        font-size: 14px;
        margin-top: -10px;
        padding: 0px 10px;
    }
    .pickers-today .pickers-table-row, .pickers-today .pickers-idle {
        margin: 0px;
    }
    .pickers-today .pickers-table {
        margin: 20px 0px;
    }
    .pickers-today .pickers-side-bar-container {
        width: 100%;
        height: auto;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-gap: 10px;
    }
    .pickers-today .pickers-active-idle {
        flex-direction: column;
    }
    .pickers-today .pickers-active-pickers-container, .pickers-today .pickers-idle-pickers-container {
        margin: 0px;
        width: auto;
        flex: 1;
        padding-top: 30px;
    }
    .pickers-today .pickers-active-pickers-container {
        margin-bottom: 10px;
    }
}

@media (max-width: 360px) {
    .pickers-today .pickers-table-row, .pickers-today .pickers-idle {
        margin: 0px;
    }

    .pickers-today .pickers-table-row{
        margin-left: -8px;
    }
    .pickers-today .pickers-title .pickers-nav-btn img {
        display: none;
    }
    .pickers-today .pickers-nav-btn .button {
        padding: 0px 5px;
    }
}

@media (max-width: 320px) {
    .pickers-today .pickers-employee-name {
        margin-right: 0px; 
    }
    .pickers-today .pickers-table-row {
        margin-left: -20px;
    }
    .pickers-today .pickers-idle {
        margin-left: -8px;
    }
}