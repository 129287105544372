.zc-banner-with-multiple-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.zc-banner-with-multiple-buttons:before {
  content: '';
  display: block;
  padding-top: 29.41176%;
}

.zc-banner-with-multiple-buttons .zc-banner-content-div {
  text-align: center;
}
.zc-banner-with-multiple-buttons h2 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1;
  color: #333;
  margin: 0px 0px 0.5rem 0px;
}

.zc-banner-with-multiple-buttons p {
  margin: 0px 0px 0.5rem 0px;
}

.zc-banner-with-multiple-buttons h3 {
  font-size: 0.75rem;
  font-weight: normal;
}
.zc-banner-with-multiple-buttons .buttons-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.zc-banner-with-multiple-buttons .buttons-div .z-button {
  margin: 10px;
}

@media only screen and (max-width: 800px) {
  .zc-banner-with-multiple-buttons p {
    font-size: 1rem !important;
  }
  .zc-banner-with-multiple-buttons p,
  .zc-banner-with-multiple-buttons h3,
  .zc-banner-with-multiple-buttons h2 {
    margin: 0px 0px 0.35rem 0px;
  }

  .buttons-div > button,
  .buttons-div > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    padding: 10px;
    margin: 5px 10px 5px 0px !important;
  }
}

@media only screen and (max-width: 400px) {
  .buttons-div > button,
  .buttons-div > a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    padding: 8px;
    margin: 3px 10px 5px 0px !important;
  }
  .zc-banner-with-multiple-buttons h2 {
    font-size: 1rem !important;
  }

  .zc-banner-with-multiple-buttons p {
    font-size: 0.8rem !important;
  }
}
