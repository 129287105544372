.my-leaves .filters-wrapper .status-field .status-filter {
  width: 100%;
}
.my-leaves .editPopup .form-actions {
  display: flex;
  justify-content: space-between;
}

.my-leaves .editPopup .form-actions input[type="submit"] {
  width: auto;
}

.my-leaves .editPopup {
  max-width: 720px;
}

.my-leaves .editPopup form {
  max-width: 100%;
}

.my-leaves .editPopup .form-fields {
  display: flex;
}

.my-leaves .editPopup .form-fields .field {
  flex: 1;
}

.my-leaves .editPopup .form-fields .field:not(:last-child) {
  margin-right: 20px;
}

.my-leaves-form .form-actions {
  display: flex;
  justify-content: space-between;
}

.my-leaves-form input[type='submit'] {
  max-width: 110px;
}

.my-leaves-form .form-fields .DateRangePicker {
  display: block;
}


.my-leaves-form .form-actions button, .my-leaves-form .form-actions input[type='submit']{
  min-width: 48%;
}
@media screen and (max-width: 768px) {
  .my-leaves .table {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
    width: auto;
  }

  .my-leaves .spacer {
    padding: 10px;
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .my-leaves .editPopup .form-fields {
    display: inherit;
  }

  .my-leaves .editPopup .form-fields .field:not(:last-child) {
    margin-right: 0;
  }

  .my-leaves .table {
    display: block;
    padding: 0 30px;
  }

  .my-leaves .spacer {
    padding: 0 0 20px 0;
    width: 100%;
  }
  .my-leaves .header-actions-wrapper .button {
    font-size: 14px;
  }
  .my-leaves-form .form-actions {
    margin-top: 20px;
  }
}
