.offers-page .offer-filters input[type='text']:read-only {
  background-color: #fff;
}

.container-pin-wallet {
  width: 50%;
}

.search-success-pin-wallet {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border: 1px solid transparent;
  margin: 1rem 0 !important;
  padding: 0.5rem 0;
  text-align: center;
}

.text-area-pin-wallet textarea {
  max-height: 500px !important;
}

.offers-form .form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

form.offers-form {
  max-width: 100%;
  padding: 30px;
  border: 1px solid #efefef;
  margin-top: -1px;
}

.offers-page .table-cell a {
  color: #2b3238;
}

.offers-page .table-cell a span:hover {
  color: #1557bf;
}
.offers-page .mobile-only {
  display: none;
}
.offers-page .desktop-only {
  display: block;
}
.offer-table-time {
  width: 6.5rem;
}

.offers-page .add-action {
  margin-right: 10px;
}

@media screen and (max-width: 580px) {
  .offers-page .mobile-only {
    display: block;
  }
  .offers-page .desktop-only {
    display: none;
  }
  .offers-page .table-container {
    border-top: 1px solid #eaefef;
    margin: 0px -25px 0 -25px;
  }
  .offers-page .table-container .table-header {
    display: none;
  }
  .offers-page .table-container .table-row {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    background-color: white;
    border: 1px solid #eaefef;
    margin: 20px;
  }
  .offers-page .table-container .table-cell {
    border: none;
  }
  .offer-table-time {
    width: 6.5 rem;
  }
  .offers-page .table .table-row .table-cell div,
  .offers-page .table .table-row .table-cell small,
  .offers-page .table .table-row .table-cell span {
    font-size: 0.75rem;
  }
  .offers-page .table .offer-table-image {
    grid-column-start: 1;
    grid-row-start: 1;
  }
  .offers-page .table .offer-table-type,
  .offers-page .table .offer-table-status {
    border-top: 1px solid #eaefef;
    background-color: #fcfcfc;
  }
  .offers-page .table .offer-table-type {
    grid-column-start: 1;
    grid-column-end: 3;
    padding-left: 15px;
  }
  .offers-page .table,
  .offer-table-name,
  .offers-page .table .offer-table-actions .offer-table-time {
    grid-row-start: 1;
  }
  .offers-page .table .offer-table-status .Toggle {
    margin-right: 10px;
  }
  .offers-page
    .table
    .offer-table-description
    .name
    .offer-table-name
    .offer-table-time {
    font-weight: 600;
  }
  .offers-page
    .table
    .table-row
    .DropDown
    .dropdown-menu
    .dropdown-items
    .dropdown-item,
  .offers-page .table-cell.offer-table-type span:last-child {
    font-size: 0.8125rem;
  }
  .offers-form .table .table-cell.offer-table-type .text-muted {
    font-size: 11px;
  }
  .offers-page .table .table-cell.offer-table-image .image.image-sm {
    border: 1px solid #eaefef;
  }
  .offers-page .table .table-cell:first-child {
    padding-left: 15px;
  }
  .offers-page .offer-filters .button,
  .offers-page .offer-filters .primary {
    width: 48%;
  }
}
