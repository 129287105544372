.show-hide-popup .product-upload {
  width: 440px;
  margin: 0 auto;
}
.product-upload input {
  height: 90px;
  opacity: 0;
  cursor: pointer;
}

.product-upload .download-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 0.75rem;
}

.product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.product-upload .border-text {
  display: inline-block;
  position: absolute;
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  padding: 6px;
  background: white;
  transform: translate(-50%, -50%);
  top: 0px;
  left: 50%;
}

.product-upload .add-product-link {
  margin-bottom: 48px;
}

.show-hide-popup .product-upload input.rc-time-picker-input {
  height: 45px;
}
.editPopup.show-hide-popup {
  max-width: 570px;
}
.show-hide-popup .product-upload .dateTimeSelect {
  display: flex;
  justify-content: space-between;
}
.show-hide-popup .download-sample-text,
.show-hide-popup .schedule-checkbox {
  margin-top: 20px;
  margin-bottom: 10px;
}

.show-hide-popup .product-upload .dateTimeSelect {
  display: flex;
  justify-content: space-between;
}

.product-upload .schedule-checkbox {
  margin-bottom: 10px;
}
.product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.show-hide-popup .product-upload input {
  height: 44px;
  opacity: 1;
  cursor: pointer;
}

.show-hide-popup .product-upload input[type='file'] {
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
}
.show-hide-popup .upload-button-container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 520px) {
  .show-hide-popup .product-upload {
    width: 300px;
    margin: 0 auto;
  }
  .editPopup.show-hide-popup {
    max-width: 500px;
  }
  .show-hide-popup .product-upload .dateTimeSelect {
    flex-direction: column;
  }
  .show-hide-popup .date-picker {
    max-width: 252px;
  }
}
