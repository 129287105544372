.appsplash-form .w-100 {
  width: 100%;
}

.appsplash-form .mb-1 {
  margin-bottom: 1rem;
}

.appsplash-form .mb-2 {
  margin-bottom: 2rem;
}

.appsplash-form .errorButton {
  border: 1px solid #ea353a;
  background-color: #fbf5f5;
}

.appsplash-form .splashscreen {
  border: 1px solid black;
  width: 10.12rem;
  height: 21.163rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.appsplash-form .splashscreen .topAnimation {
  position: absolute;
  top: 0;
}

.appsplash-form .splashscreen .topAnimation img {
  width: 10.12rem;
}

.appsplash-form .splashscreen .bottomAnimation {
  position: absolute;
  bottom: 0;
}

.appsplash-form .splashscreen .bottomAnimation img {
  width: 10.12rem;
}

.appsplash-form .splashscreen .background img {
  width: 10rem;
  height: 21.063rem;
}

.appsplash-form .splashscreen .logo {
  position: absolute;
  top: 9.65rem;
}

.appsplash-form .splashscreen .logo img {
  width: 8rem;
  height: 2rem;
}

.appsplash-form .splashscreen .logo #lottie {
  width: 8rem !important;
  height: 2rem;
}

.appsplash-form .rc-time-picker {
  display: flex;
}

.appsplash-form .appFormButtons .primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

@media screen and (max-width: 683px) {
  .appsplash-form .appFormButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .appsplash-form .campaignName {
    width: 100%;
  }
}

@media screen and (min-width: 683px) {
  .appsplash-form .name-status-section {
    display: flex;
    gap: 1rem;
  }

  .appsplash-form .campaignName {
    flex: 1;
  }

  .appsplash-form .campaignStatus {
    flex: 1;
  }

  .appsplash-form .validFrom {
    display: flex;
    gap: 1rem;
  }

  .appsplash-form .validTo {
    display: flex;
    gap: 1rem;
  }

  .appsplash-form .appFormButtons {
    display: flex;
    justify-content: flex-end;
  }

  .appsplash-form .appFormButtons .primary {
    margin-left: var(--s3);
  }
}
