#proof-image {
  border-radius: 0.5rem;
}
.input-key {
  width: 14rem;
}
.user-details-button {
  float: right;
  margin-top: 1rem;
}
.pending-image {
  img {
    border: 0.063rem solid #cccccc;
    border-radius: 0.25rem;
    max-width: 15rem;
    max-height: 15rem;
  }
}
.margin-right-8 {
  margin-right: 0.5rem;
}
.margin-top-16 {
  margin-top: 1rem;
}
.reject-popup {
  max-width: 40rem;
}
.info {
  color: red;
}
.small-font {
  font-size: 0.75rem;
  margin-top: 0;
  padding-top: 0;
}
.flex-end {
  display: flex;
  justify-content: flex-end;
}
.error {
  background-color: #ffeeee;
  margin-top: 0;
  padding: 1rem !important;
  width: fit-content;
}
.spacing-top {
  margin-top: 5rem;
}
.pointer {
  cursor: pointer;
}
