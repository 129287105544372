.category-sequence form {
  max-width: 100%;
}

.category-sequence .no-items {
  text-align: center;
  padding: 1rem 0;
}

.category-sequence .sortable-tree .category-tree .rst__toolbarButton:first-child {
  position: unset;
}
.category-sequence .sortable-tree .category-tree .rst__toolbarButton:first-child img {
  height: 18px;
  width: 18px;
}

.category-sequence .DropDown .dropdown-item:hover  {
  padding: 0 17.5px;
}
.category-sequence .navNote {
  color: #045a7c;
  padding: 1.3rem;
  background: #e5f8ff;
  border-radius: .4rem;
  font-size: .9rem;
  max-width: fit-content;
}