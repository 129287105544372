
.sortable-item-label {
  position: absolute;
  left: 10px;
}

.sortable-item-title {
  display: flex;
  align-items: center;
  position: relative;
}

.sortable-collapsed-item {
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 1rem;
  cursor: grab;
}

.sortable-collapsed-item .item-info {
  line-height: 1.75rem;
  font-weight: 400;
  margin-left: 2.4375rem;
}

.sortable-collapsed-item .item-title {
  font-size: 1.1rem;
}

.sortable-collapsed-item .item-subtitle {
  font-size: 1rem;
}

[data-testid='collapsed-list-container'] h3 {
  margin-bottom: 0.5rem;
}

[data-testid='collapsed-list-container'] h3 label {
  color: #999999;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.swimlane-buttons {
  position: absolute;
  top: 0.5rem;
  right: 30%;
  display: none;
  column-gap: 0.5rem;
}
