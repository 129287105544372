@media screen and (max-width: 840px) {
  .rewards-page.devices
      .table.table-dynamic
      .table-row
      .table-cell.serial-number::before {
      content: 'Serial Number';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .rewards-page.devices
      .table.table-dynamic
      .table-row
      .table-cell.cls-operator-id::before {
      content: 'CLS OperatorId ID';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .rewards-page.devices
    .table.table-dynamic
    .table-row
    .table-cell.cls-merchant-id::before {
      content: 'CLS MerchantId ID';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }

    .rewards-page.devices
    .table.table-dynamic
    .table-row
    .table-cell.cls-branch-no::before {
      content: 'CLS Branch No';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }

    .rewards-page.devices
    .table.table-dynamic
    .table-row
    .table-cell.cls-terminal-id::before {
      content: 'CLS Terminal ID';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .rewards-page.devices
    .table.table-dynamic
    .table-row
    .table-cell.store-name::before {
    content: 'Store Name';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
}
