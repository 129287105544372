.TagsCSV .form-actions.add,
.TagsCSV .form-actions.edit {
  display: flex;
  margin-top: 25px;
}

.TagsCSV .heading {
  line-height: 0;
  padding: 10px 0 30px;
}

.TagsCSV .form-actions {
  justify-content: flex-end;
}

.TagsCSV .form-actions button {
  margin-right: 10px;
}

.TagsCSV .downloadBtn {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}
