.pickers-page .column-user-name {
  display: flex;
}

.pickers-page .column-user-name .user-details {
  margin-left: 20px;
  margin-top: 5px;
}

.pickers-page .table-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pickers-page .user-name {
  cursor: pointer;
}

.pickers-page .user-name:hover {
  color: #1557bf;
}

.pickers-page .table .table-cell:first-child {
  min-width: 250px;
}

.pickers-page .table .table-cell:last-child {
  width: 1%;
}

.picker-form {
  max-width: 768px;
}

.picker-form form {
  max-width: 100%;
}

.picker-form .form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.picker-form .checkbox-group.default-skin .checkbox-label {
  flex: 1;
}

.attendance-button {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: -0.1px;
}

.pickers-page .user-attendance .text-muted {
  font-size: 0.75rem;
  display: none;
}

.pickers-page .check-inTime {
  margin-top:5px;
  position: relative;
}

.pickers-page .weekly-off-days, .pickers-page .timings {
  position: relative;
}

.pickers-page .check-inTime::before, .pickers-page .weekly-off-days::before, .pickers-page .timings::before {
  content: '';
  position: absolute;
  left: -22px;
  height: 16px;
  width: 16px;
  top: 2px;
}

.pickers-page .check-inTime::before {
  background-image: url('../../hr/UserShifts/check-in.svg');
}

.pickers-page .weekly-off-days::before {
  background-image: url('../../hr/UserShifts/weekly-off.svg');
}

.pickers-page .timings::before {
  background-image: url('../../hr/UserShifts/clock.svg');
}

.pickers-page .setting-image {
  margin: -1px 0px 4px 0px;
}

.pickers-page .table .user-off-days {
  padding-left: 2rem;
  min-width: 115px;
}

.pickers-page .table .user-timings {
  padding-left: 2rem;
  min-width: 190px;
}

@media screen and (max-width: 768px) {
  .picker-form .form-fields {
    grid-template-columns: 1fr;
  }
  .pickers-page .table-container {
    overflow-x: auto;
  }
  .picker-form .checkbox-group {
    overflow-x: auto;
 }
}

@media screen and (max-width: 640px) {

  .pickers-page .table-container {
    overflow: hidden;
    margin: 0 -30px;
    padding: 0 15px;
    border-top:1px solid #efefef;
  }

  .pickers-page .table {
    display: grid;
  }

  .pickers-page .table .table-header {
    display: none;
  }

  .pickers-page .table .table-cell:last-child {
    position: absolute;
    right:0;
    top:10px;
  }

  .pickers-page .table .table-row {
    display: unset;
    border:1px solid #eaefef;
    margin:15px 0;
    position: relative;
  }
  
  .pickers-page .table .table-row .table-cell {
    border:none;
  }

  .pickers-page .check-inTime::before, .pickers-page .weekly-off-days::before, .pickers-page .timings::before {
    top:0px;
  }

  .pickers-page .table .user-off-days {
    height: 90px;
  }

  .pickers-page .table .weekly-off-days {
    margin-bottom:40px;
    margin-left: 15px;
  }

  .pickers-page .table .timings {
    position: absolute;
    bottom: 25px;
    left: 45px;
  }

  .pickers-page .table .user-attendance {
    position: absolute;
    bottom: 5px;
    right: 0px;
  }

  .pickers-page .table .check-inTime {
    position: absolute;
    bottom: 60px;
    right:20px;
  }
}

@media (max-width: 480px){
  .pickers-page .table .table-row .table-cell .user-details .user-name{
    font-size: 14px;
    font-weight: 600;
  }
  .pickers-page .table .table-row .table-cell .image.image-sm {
    border: 1px solid #eaefef;
  }
  .pickers-page .table .table-row:nth-child(2n){
    background-color: #fff;
  }
  
  .pickers-page .table .table-row .table-cell div {
    font-size: 0.8125rem;
  }
  .pickers-page .table .user-off-days {
    height: 90px;
    width: 100%;
    background:#fbfcfc;
  }
  .pickers-page .table .user-timings {
    width: 100%;
    background:#fbfcfc;
  }
  .pickers-page .table .table-row {
    margin:15px 10px 0px;
  }
  .pickers-page .table .user-attendance {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .pickers-page .search-button{
    margin-right:-8px;
  }
  .pickers-page .header-actions-wrapper .button span.text {
    display: contents;
    font-size: 14px;
  }
  .pickers-page .table .table-row .table-cell:first-child {
    padding-left: 15px;
  }
  .pickers-page .header-actions-wrapper .button {
    font-size: 14px;
    padding: 0px 10px;
  }
  .pickers-page .table .table-cell.user-off-days, .pickers-page .table .table-cell.user-timings{
    border-top: 1px solid #eaefef;
  }
  .pickers-page .table .table-cell div.dropdown-item  {
    font-size: 13px;
  }
  .pickers-page .table .table-cell.user-timings .text-muted.check-inTime {
    color: #2b3238;
  }
  .pickers-page .table .user-off-days {
    padding-left: 24px;
  }
  .pickers-page .table .timings {
    left: 38px;
  }
  .pickers-page .filters-wrapper .primary, .pickers-page .filters-wrapper .button {
    width: 48%;
  }
  .pickers-page .table .check-inTime {
    bottom: 58px;
  }
  .picker-form .form-actions .primary, .picker-form .form-actions .button {
    flex: 1;
  }
  .picker-form .form-fields {
    grid-gap: 0px;
  }
  .pickers-page .header-actions-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: -5px;
    margin-right: -3px;
  }
}

@media screen and (max-width: 360px) {
  .pickers-page .header-actions-wrapper .button span.text {
    display: none;
  }
  .pickers-page .header-actions-wrapper .button {
    padding: 12px;
  }
}
