#divToPrint {
  width: 100%;
  position: absolute;
  left: -99999rem;
}
#preorderInvoice {
  max-width: 95mm;
  padding-left: 4mm;
  width: 100%;
  position: absolute;
  left: -99999rem;
}

.evoucher-details h1.heading {
  font-weight: 300;
  color: #80959d;
}

.evoucher-details h1.heading .reference-number {
  color: #2b3238;
}

.evoucher-details .evoucher-items-view .flex-around.section-title h3,
.evoucher-details .evoucher-metadata-details .flex-around.section-title h3,
.evoucher-details .payment-details .section-title h3 {
  margin-left: 1.875rem;
  font-size: 0.875rem;
}

.evoucher-details .evoucher-actions .evoucher-actions-mobile-view {
  display: none;
}

.evoucher-details .evoucher-actions-dropdown {
  margin-left: 1rem;
}

.evoucher-details .evoucher-actions-dropdown .dropdown-items {
  width: 10rem;
}

.evoucher-details .align-center {
  text-align: center;
  font-weight: normal;
}

.evoucher-details .cursor {
  cursor: pointer;
}

.evoucher-details .section-title {
  text-transform: capitalize;
}

.evoucher-details .invoice-button {
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  background-image: url('./invoice-icon.svg');
  background-position: left 0.625rem center;
  padding: 0 0 0 2.5rem;
  background-repeat: no-repeat;
  background-size: 1.125rem;
  height: 1.75rem;
  border-width: 0;
  cursor: pointer;
}

.evoucher-details .invoice-button a {
  color: #80959d;
}

.evoucher-details .batch-icon {
  background-image: url('./batch.png');
  margin-right: 0.625rem;
}
.evoucher-details .print-icon {
  background-image: url('./print.png');
  margin-right: 0.625rem;
}
.evoucher-details .retry-message {
  text-align: center;
  cursor: pointer;
}

.evoucher-details .evoucher-summary-wrapper {
  padding: 3rem 1.875rem;
  border-width: 0 0.063rem;
  border-style: solid;
  border-color: #eaefef;
  background-color: #ffffff;
  position: relative;
  overflow: scroll;
}

.evoucher-details .evoucher-summary-wrapper::before {
  position: absolute;
  height: 1.125rem;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  content: '';
  background-image: url(./sawtooth-pattern.svg);
  background-repeat: repeat-x;
  background-position: top left 0.938rem;
}

.evoucher-details .evoucher-summary-wrapper::after {
  position: absolute;
  height: 1.125rem;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  content: '';
  background-image: url(./sawtooth-pattern.svg);
  background-repeat: repeat-x;
  background-position: bottom left;
}

.evoucher-details .evoucher-status-pending {
  color: #f88c8c;
}

.evoucher-details .evoucher-status-completed {
  color: #1557bf;
}

.evoucher-details .evoucher-placement-info-item {
  min-width: 17.5rem;
  padding: 0.75rem 0;
  background-image: linear-gradient(
    to right,
    #eaefef 80%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 0.625rem 0.063rem;
  background-repeat: repeat-x;
}

.evoucher-details .margin-top {
  margin-top: 3rem !important;
}

.evoucher-details .text-muted {
  padding: 0.5rem 0;
}

.evoucher-details .evoucher-placement-info-icon {
  margin-right: 0.625rem;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
}

.evoucher-details .creation-time,
.evoucher-details .completion-time,
.evoucher-details .device-name,
.evoucher-details .batch-info,
.evoucher-details .verification-status {
  color: #2b3238;
  font-weight: 600;
}

.evoucher-details .icon-shield {
  background-image: url('./shield-icon.svg');
}

.evoucher-details .icon-clock {
  background-image: url('./clock-icon.svg');
}

.evoucher-details .icon-mobile {
  background-image: url('./mobile-icon.svg');
}

.evoucher-details .evoucher-actions .evoucher-actions-web-view {
  display: inline;
}

.evoucher-details .evoucher-actions .evoucher-actions-mobile-view {
  display: none;
}

@media screen and (max-width: 550px) {
  .evoucher-details .evoucher-placement-details {
    margin-bottom: 3rem;
  }
  .evoucher-details .evoucher-placement-details,
  .evoucher-details .address-details {
    width: 100%;
  }
  .evoucher-details .evoucher-summary-wrapper {
    margin: 0 -1.875rem;
    padding-top: 1.25rem;
    border-top: 0.063rem solid #eaefef;
  }
  .evoucher-details .evoucher-actions .evoucher-actions-mobile-view {
    display: inline;
  }
  .evoucher-details .evoucher-actions .evoucher-actions-web-view {
    display: none;
  }
  .evoucher-details .evoucher-metadata-details .form-action,
  .evoucher-details .delivery-option-buttons {
    display: flex;
  }
  .evoucher-details .evoucher-metadata-details .form-action .primary,
  .evoucher-details .evoucher-metadata-details .form-action .button,
  .evoucher-details .delivery-option-buttons .button {
    flex: 1;
  }
}

@media screen and (max-width: 480px) {
  .evoucher-details .print-icon {
    margin-right: 0;
  }
  .evoucher-details .evoucher-actions-dropdown {
    margin-left: 14px;
  }
  .evoucher-actions {
    text-align: right;
    margin-bottom: 0.625rem;
  }
  .evoucher-details .evoucher-summary-wrapper::before,
  .evoucher-details .evoucher-placement-info-item,
  .evoucher-details .evoucher-summary-wrapper::after {
    background-image: none;
    padding: 0.75rem 0 0rem;
  }

  .evoucher-details .evoucher-placement-details,
  .evoucher-details .address-details {
    border: solid 0.063rem #eaefef;
    background-color: #fbfcfc;
    padding: 0.5rem 0.938rem 0.75rem;
  }
  .evoucher-details .evoucher-placement-details .section-pre-evoucher h3 {
    font-size: 14px;
    letter-spacing: -0.26px;
    margin-bottom: 0.625rem;
  }
  .evoucher-summary-wrapper .evoucher-placement-details .section-pre-evoucher {
    border-bottom: solid 0.063rem #eaefef;
  }
  .evoucher-details .evoucher-placement-details {
    margin-bottom: 1.25rem;
  }
  .evoucher-details .address-details .customer-heading,
  .evoucher-details .address-details .flex-around.section-title.title {
    font-size: 14px;
    letter-spacing: -0.26px;
    margin: 0.625rem 0rem;
  }
  .evoucher-details .address-details .flex-around.section-title.title {
    margin-top: 0rem;
    padding-bottom: 0.625rem;
    border-bottom: solid 0.063rem #eaefef;
  }
  .evoucher-details .address-details .slot-details {
    margin-top: 0.938rem;
  }
  .evoucher-details .address-details .slot-details .section-title h5 {
    margin-top: 0.5rem;
  }
  .evoucher-details .customer-addresses {
    margin-top: 0rem;
  }
  .evoucher-details .section-title {
    margin-bottom: 0.625rem;
  }
  .evoucher-details .location-options .radio-label-text {
    font-size: 14px;
  }
  .evoucher-details .collapse-container-evoucher,
  .evoucher-details .collapse-container-metadata,
  .evoucher-details .collapse-container-payment,
  .evoucher-details .collapse-container-refund {
    border-top: 0.063rem solid #eaefef;
    /* padding-top: 1.25rem; */
    position: relative;
    display: flex;
    align-items: center;
    margin: auto -1.875rem;
    padding: 0rem 1.875rem;
  }
  .evoucher-details .collapse-container-evoucher {
    margin-top: 1.25rem;
  }

  .evoucher-details .evoucher-items-view .flex-around.section-title h3,
  .evoucher-details .evoucher-metadata-details .flex-around.section-title h3,
  .evoucher-details .payment-details .section-title h3 {
    margin-left: 1.875rem;
    font-size: 0.875rem;
  }
  .evoucher-details .collapse-container-evoucher button,
  .evoucher-details .collapse-container-metadata button,
  .evoucher-details .collapse-container-payment button,
  .evoucher-details .collapse-container-refund button {
    position: absolute;
    font-size: 1.125rem;
    font-weight: 600;
    color: #80959d;
  }
  .evoucher-details .collapse-container-evoucher.false button {
    top: 1.25rem;
    z-index: 10;
  }
  .evoucher-details .collapse-container-metadata.false button,
  .evoucher-details .collapse-container-payment.false button,
  .evoucher-details .collapse-container-refund.false button {
    top: 0.938rem;
    z-index: 10;
  }
  .evoucher-details .collapse-container-evoucher h3,
  .evoucher-details .collapse-container-metadata h3,
  .evoucher-details .collapse-container-payment h3,
  .evoucher-details .collapse-container-refund h3 {
    margin-left: 1.875rem;
    font-size: 0.875rem;
  }
  .evoucher-details .evoucher-items-view .table {
    margin-top: 0.938rem;
  }
  .evoucher-details .section-title {
    margin-top: 0.938rem;
    margin-bottom: 0.938rem;
  }
  .orders-page .table .table-cell .evoucher-type-icon-delivery,
  .orders-page .table .table-cell .evoucher-type-icon-pickup {
    margin-right: 0.625rem;
  }
  .label-text {
    font-size: 1rem !important;
    font-weight: 700 !important;
  }
}
