.store-form {
  max-width: unset;
}

.store-form .map-element {
  height: 300px;
}

.store-form .map-element {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  transition: border 200ms ease-in-out;
}

.store-form .location-search-box {
  width: 240px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  font-weight: normal;
  height: 36px;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.store-form .coordinates-input input[name='coordinates'] {
  display: none;
}

.store-form-address-name-wrapper {
  display: flex;
  align-items: center;
}

.store-form-address-name-wrapper .field.store-name::after {
  content: '45 Characters Max.';
  display: block;
  text-align: right;
  color: #80959d;
  font-size: 0.75rem;
}

.store-form-address-name-wrapper > div {
  flex: 1;
  margin: 1rem;
}

.store-form .metadata-section .metadata-section-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.store-form .metadata-section .metadata-section-wrapper .text,
.store-form .metadata-section .metadata-section-wrapper .string {
  grid-column: span 3;
}

.store-form .metadata-section .metadata-section-wrapper .field-checkbox {
  margin-bottom: 40px;
}
.store-form .metadata-section .metadata-section-wrapper span.Checkbox {
  position: relative;
  top: 30px;
}

.store-form .metadata-section h2 {
  margin-bottom: 30px;
}

.store-form .grid {
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.store-form .client-id {
  width: 49%;
}

.store-form .delivey-hub,
.stores-filter .delivey-hub,
.store-form .status {
  display: flex;
}
.store-form .delivey-hub span,
.stores-filter .delivey-hub span,
.store-form .status span {
  order: 1;
}

.stores-filter .flex .delivey-hub:first-child {
  margin-left: -10px;
}
.stores-filter .flex .delivey-hub:nth-child(2) {
  margin-left: 10px;
}
.store-form .businessTime {
  margin-bottom: 30px;
}
.store-form .businessTime h2 {
  padding-bottom: 20px;
}
.store-form .business-time-row .table-cell:nth-child(1) {
  min-width: 200px;
}
.store-form .business-time-row .table-cell:nth-child(2) {
  min-width: 180px;
}
.store-form .Toggle {
  position: absolute;
  top: -65px;
  right: 0px;
}
@media only screen and (max-width: 650px) {
  .store-form .store-form-address-name-wrapper {
    display: grid;
    grid-template-columns: 1fr;
  }

  .store-form .metadata-section .metadata-section-wrapper > div {
    grid-column: span 3;
  }
}

@media only screen and (max-width: 480px) {
  .store-form .store-form-address-name-wrapper > div {
    margin: 0px;
  }
  .store-form .grid {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }
  .store-form .grid .delivey-hub {
    margin-bottom: 0px;
  }
  .store-form .client-id {
    width: 100%;
  }
  .store-form .form-buttons {
    display: flex;
  }
  .store-form .form-buttons .primary,
  .store-form .form-buttons .button {
    flex: 1;
  }
  .store-form .businessTime {
    overflow-x: scroll;
  }
  .store-form .businessTime .business-time-row .input-wrapper {
    min-width: 100px;
  }
  .store-form .Toggle {
    position: absolute;
    top: -57px;
    right: 0px;
  }
}

.search-by-coords-btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.search-by-coords-btn img {
  margin-left: 0.5rem;
  scale: 0.8;
}
.coord-search-input {
  position: relative;
}
.coord-search-input input {
  padding-right: 2.5rem;
}
.coord-search-input .search-icon {
  position: absolute;
  top: 55%;
  right: 0.5rem;
}

.result-link {
  color: #1557bf;
}
.result-link:hover {
  text-decoration: underline;
}

.error-message {
  color: red;
}

.store-form .label-tooltip, .with-tooltip .label-tooltip {
  font-size: 0.75rem;
  margin-left: 1em;
  position: relative;
}

.store-form .label-tooltip .label-tooltiptext, .with-tooltip .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 18.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: -3.155rem;
  text-align: left;
}
.store-form .label-tooltip .label-tooltiptext::after, .with-tooltip .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.store-form .label-tooltip:hover .label-tooltiptext, .with-tooltip .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}
.store-form img[alt="note"] {
  margin-left: 0.25rem;
}
.preview__popup {
  max-width: 1000px;
  max-height: 70vh;
}
.changes__wrapper {
  max-height: 500px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.changes__wrapper * {
  text-align: center;
  padding: 0.5rem;
}
.changes__header {
  font-weight: bold;
  text-align: center;
}

.changes__field {
  border-bottom: 1px solid lightgray;
}

.line-through {
  text-decoration: line-through;
}
.block {
  display: block;
}
