.segments-form .subheading {
    font-weight: 700;
    letter-spacing: -.3px;
    margin-bottom: 15px;
    font-size: 1.125rem;
}

.segments-form .coupon-segment {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 10px;
}

.segments-form .segment-container {
    width: 100%;
    border: 1px solid #f3f5f6;
    padding: 20px;
    margin-bottom: 20px;

}

.segments-form .segment-type {
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}

.segments-form .segment-type button {
    font-size: 0;
}

.segments-form .segment-container .DateInput {
    width: 150px;
}

.segments-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.customer-segment-selection {
    width: 100%;
}

@media screen and (max-width: 580px) {
.segments-form .coupon-segment.grid {
    grid-template-columns: 1fr;
    }
}
