.final-calculation {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 0;
}
.summary-calculation {
  justify-content: space-between;
  width: 100%;
  padding-top: 0.625rem;
  padding-right: 1.25rem;
  padding-left: 0.625rem;
  border-bottom: 1px solid #eaefef;
}
.summary-calculation.no-bottom-border {
  border-bottom-width: 0;
}
.summary-calculation.bg-grey {
  background-color: #eaefef;
}

.summary-calculation,
.summary-calculation label {
  display: flex;
  font-size: 1rem;
  font-weight: 700;
  align-items: baseline;
}
.summary-calculation.small-text,
.summary-calculation.small-text label {
  font-size: 0.875rem;
}
.summary-calculation label img {
  margin-left: 0.5rem;
}
.summary-calculation label.with-arrow {
  cursor: pointer;
}
.summary-calculation.not-bold,
.summary-calculation.not-bold label {
  font-weight: 500;
}
.other-section {
  padding-bottom: 1rem;
}
.summary-calculation.sub-item {
  padding-top: 0;
}
.summary-calculation.sub-item:not(.last-item),
.summary-calculation.cont-fee {
  border-bottom-width: 0;
}

.summary-calculation.sub-item,
.summary-calculation.sub-item label {
  font-weight: 400;
}

.summary-calculation > div {
  width: 8.125rem;
  text-align: end;
}

.summary-calculation .container-fee-checkbox-container {
  display: flex;
  white-space: nowrap;
}

.final-calculation .custom-tooltip-container {
  display: flex;
  flex-direction: column;
  width: 14.375rem;
}
.final-calculation .custom-tooltop-desc {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}

@media screen and (max-width: 840px) {
  .final-calculation {
    border: 1px solid #eaefef;
    min-height: 8.4375rem;
    margin: 0.625rem;
    align-items: flex-start;
    padding: 1rem;
    width: calc(50% - 1.25rem);
    margin-bottom: 0.625rem;
  }
  .summary-calculation {
    flex-direction: column;
    margin-bottom: 0.625rem;
  }
  .summary-calculation > div {
    width: 100%;
    text-align: left;
  }
  .final-summary-calculation.summary-calculation,
  .final-summary-calculation.summary-calculation label {
    font-size: 0.875rem;
  }
}
@media screen and (max-width: 580px) {
  .final-calculation {
    width: 100%;
    margin: 0;
  }
}
