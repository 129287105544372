.scratch-card-form {
  .w-100 {
    width: 100%;
  }

  .rc-time-picker {
    display: flex;
  }

  .screen-wrapper {
    position: relative;

    h5 {
      text-wrap: nowrap;
      position: absolute;
      top: -25px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .form-buttons-section {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3rem;
    width: 40%;
    gap: 1rem;

    .primary {
      height: 2.5rem;
      padding: 0 1.25rem;
      font-weight: bold;
      font-size: 0.875rem;
      text-transform: capitalize;
      border: 0.0625rem solid transparent;
      border-radius: 0.125rem;
      letter-spacing: -0.0187rem;
      cursor: pointer;
      text-decoration: none;
      line-height: 2.5rem;
      display: inline-block;
      text-align: center;
    }
  }
}

@media screen and (max-width: 683px) {
  .scratch-card-form {
    .screen {
      min-width: 10.12rem;
    }

    .form-buttons-section {
      flex-direction: column;
      width: 100%;
    }
  }
}

@media screen and (min-width: 683px) {
  .scratch-card-form {
    .validFrom {
      display: flex;
      gap: 1rem;
    }

    .validTo {
      display: flex;
      gap: 1rem;
    }
  }
}
