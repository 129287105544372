.CategoryCollection .no-label p,
.BrandCollection .no-label p,
.SubCategoryCollection .no-label p {
  margin-top: 0;
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.2px;
}

.CategoryCollection .no-label.no-margin {
  margin: 0;
  width: 100%;
  padding: 32px 0;
}

.CategoryCollection .no-label .field,
.BrandCollection .no-label .field {
  margin-bottom: 0px;
}
.category-collection {
  padding-bottom: 20px;
}

.CategoryCollection .no-label .labelWrap label,
.BrandCollection .no-label .labelWrap label {
  font-size: 0;
  margin: 0;
}
.form-sections .image_bannerbutton {
  height: 205px;
}

.CategoryCollection .form-sections.category-tag-search {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
  margin: 0px;
}

.CategoryCollection
  .form-sections.category-tag-search
  .category-search
  .tag-values {
  display: none;
}

.CategoryCollection
  .form-sections.category-tag-search
  .category-search.category-container
  .tag-values {
  margin-right: -99%;
  display: block;
}
.CategoryCollection .form-sections.category-tag-search .tag-search .tag-values {
  margin-left: -107%;
}
.CategoryCollection
  .form-sections.category-tag-search.tag-page
  .category-search
  .tag-values {
  display: block;
  margin-left: 0px;
}
.CategoryCollection
  .form-sections.category-tag-search.category-page
  .tag-search
  .tag-values {
  display: block;
  margin-left: 0px;
}

.CategoryCollection
  .form-sections.category-tag-search
  .tag-search
  .tag-values
  ul {
  padding: 0px;
}
.CategoryCollection
  .form-sections.category-tag-search
  .category-search
  .tag-values
  ul {
  padding: 0px;
}

.CategoryCollection .form-sections .form-section.config,
.SubCategoryCollection .form-sections .form-section.config {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.CategoryCollection .form-sections .form-section.config p,
.SubCategoryCollection .form-sections .form-section.config p {
  grid-column-start: 1;
  grid-column-end: 3;
}
.SubCategoryCollection .form-sections .form-section.config {
  margin-bottom: 1rem;
}

.SubCategoryCollection .form-sections .input-wrapper label {
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: -0.2px;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease-in-out;
}

.user-segment {
  background: #e0f9f9;
}

.user-segment-heading-layout {
  width: 100%;
  display: flex;
  background: #c6e6e6;
  padding: 1.25rem;
  font-weight: 600;
}

.user-segment .label-tooltiptext {
  visibility: hidden;
  width: 25.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 140%;
  left: 0;
  text-align: left;
}

.user-segment .label-tooltip img {
  max-width: 100%;
  max-height: 100%;
}

.user-segment .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}

.user-segment label.blank_label {
  display: flex;
  margin-bottom: 1.25rem;
}

@media screen and (max-width: 767px) {
  .CategoryCollection .form-sections.category-tag-search {
    grid-gap: 10px;
  }
}
