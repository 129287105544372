.mobile-apps form {
  max-width: 980px;
}

.mobile-apps .labelWrap {
  justify-content: space-between;
}

.mobile-apps .image-upload-div {
  display: block;
  height: auto;
  position: relative;
  background-image: none;
  border: 1px solid #eaefef;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  padding: 10px;
}

.mobile-apps .image-upload-div::after {
  padding-top: 100%;  /* To maintain 1:1 aspect ratio */
  display: block;
  content: '';
  width: 100px;
}

.mobile-apps .image-upload-div img {
  max-height: initial;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile-apps .Upload .file-label {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  height: initial;
  width: initial;
  background-color: #fbfcfc;
}

.mobile-apps textarea {
  height: 100px;
  resize: none;
}

.mobile-apps .scroller {
  white-space: nowrap;
  overflow: auto;
  margin-bottom: 40px;
}

.mobile-apps .theme-option {
  display: inline-block;
  margin-bottom: 20px;
}

.mobile-apps .theme-option:not(:last-child) {
  margin-right: 30px;
}

.mobile-apps .theme-option label {
  cursor: pointer;
}

.mobile-apps .theme-name {
  font-weight: 600;
  margin-top: 10px;
  font-size: 0.875rem;
}

.mobile-apps .theme-preview-container {
  border-radius: 2px;
  border: 1px solid #eaefef;
  padding: 10px;
  position: relative;
}

.mobile-apps .theme-option.selected .theme-preview-container {
  border-color: #1557bf;
}

.mobile-apps .theme-preview-container::before {
  position: absolute;
  content: '';
  display: block;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
}

.mobile-apps .theme-option.selected .theme-preview-container::before {
  background-color: rgba(0, 0, 0, 0.5);
}

.mobile-apps .theme-option.selected .theme-preview-container::after {
  height: 40px;
  width: 40px;
  content: '';
  display: block;
  background-color: #1557bf;
  border-radius: 50%;
  background-image: url(./tick.svg);
  background-position: center;
  background-size: 24px;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.mobile-apps .theme-preview-container img {
  height: 380px;
}

.mobile-apps .invisible {
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: block;
}

.mobile-apps .centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mobile-apps .loader-message {
  font-size: 0.75rem;
  margin-bottom: 20px;
}

.mobile-apps .Tooltip {
  position: relative;
  display: inline-block;
  top: -6px;
}

.mobile-apps .Tooltip .tooltip-text {
  display: none;
  position: absolute;
  z-index: 1;
  width: 220px;
  background-color: #2b3238;
  color: #fff;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  font-size: 0.75rem;
  top: 100%;
  right: -4px;
  margin-top: 4px;
}

.mobile-apps .Tooltip .tooltip-text::before {
  content: ' ';
  position: absolute;
  bottom: 100%;
  right: 8px;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #2b3238 transparent;
}

.mobile-apps .Tooltip:hover .tooltip-text {
  display: block;
}

@media screen and (min-width:551px) {
  .mobile-apps .form-fields {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -15px;
  }
  
  .mobile-apps .field {
    width: 50%;
    padding: 0 15px;
  }
  
  .mobile-apps .app-name {
    margin-right: 50%;
  }
}