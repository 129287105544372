.website-page-form {
  max-width: initial;
}

.website-page-form .title-input .input {
  flex-flow: row wrap;
}

.website-page-form .title-input .input-wrapper {
  flex-basis: 100%;
}

.website-page-form .title-input input {
  z-index: 1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.website-page-form .title-input .RichEditor {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.website-page-form .RichEditor {
  flex-basis: 100%;
}

.website-page-form input[type='url'] {
  max-width: 140px;
}

.editor-conatiner .editor-actions {
  display: none;
}

.website-page-form .layout-box {
  margin: 20px 0;
}

.website-form.layout-container {
  border: 1px solid #eaefef;
  padding: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  cursor: -webkit-grab;
  position: relative;
}
.website-form.layout-container::after {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 15px;
  background-color: rgba(43, 50, 46, 0.8);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.website-form.layout-container:hover::after {
  opacity: 1;
}

.website-page-form .layout-editable {
  cursor: -webkit-grab;
}

.website-page-form .layout-box {
  margin: 80px 0;
}

.website-page-form .add-layout {
  margin: 20px 0;
  display: flex;
  justify-content: flex-start;
}

.website-page-form .editor-conatiner {
  margin: 80px 0;
}

.website-page-form .editor-buttons {
  position: relative;
  bottom: -5px;
  right: 0;
  float: right;
}

.website-page-edit .editPopup .add-layout {
  min-height: 100px;
}

.website-page-edit .editPopup .add-layout .button.primary {
  position: absolute;
  left: 36%;
  top: 40%;
}

.website-page-edit .editor-buttons button {
  background-image: url('./delete.svg');
  background-position: center;
  background-color: transparent;
  padding: 0;
  min-width: 20px;
  height: 20px;
}

.layout-preview img {
  width: 100%;
}

.website-page-edit .layout-editable img {
  width: 100%;
}

.website-page-form button.add {
  background: url('./plus.svg') no-repeat center;
  height: 40px;
  margin-right: 20px;
  border: 1px solid #efefef;
  border-radius: 20px;
  width: 40px;
  border: 1px solid #1557bf;
}
.website-page-form button.add.expanded {
  background: url('./plus.svg') no-repeat center;
  transform: rotate(45deg);
}

.website-page-form .add-image-container {
  display: inline-block;
}
.add-image-label {
  position: absolute;
  font-size: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  bottom: 8px;
  right: 42px;
  border: 2px solid #fff;
  border-radius: 50%;
  background: url('./close.svg') no-repeat center;
  cursor: pointer;
}
.add-image-label + .input-wrapper {
  height: 0;
  width: 0;
  overflow: hidden;
}
.website-page-form .add-actions {
  display: none;
  position: relative;
  margin-left: 30px;
  line-height: 40px;
}
.website-page-form .add-actions.expanded {
  display: contents;
}

.website-page-form .add-layout .add.expanded + .add-actions {
  display: none;
  opacity: 1;
}

.website-page-form .add-layout .add.expanded + .add-actions.expanded {
  display: contents;
}

.website-page-form .layout-actions {
  float: right;
  position: relative;
}
.add-image-label.layout-edit {
  background: url('./edit.svg') center no-repeat;
  right: 30px;
}
.layout-box .layout-delete {
  background: url('./delete.svg') center no-repeat;
  min-width: 20px;
  padding-right: 0;
  height: 40px;
}
.add-actions label {
  left: 54px;
  bottom: 78px;
  font-size: 14px;
  line-height: 40px;
  height: 44px;
  background-color: #87d5fa;
  border-radius: 2px;
  color: #ffffff;
  font-weight: 700;
  width: fit-content;
}
.website-page-form .add-actions .add-content-block {
  margin-left: 120px;
  background-color: #87d5fa;
  color: #fff;
}
@keyframes animated {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

@media screen and (max-width: 480px) {
  .website-page-form .input-addon:first-child {
    word-break: break-all;
  }

  .website-page-form .input-addon + input {
    min-width: 88px;
  }
  .website-page-form .form-buttons {
    display: flex;
  }
  .website-page-form .form-buttons .primary,
  .website-page-form .form-buttons .button {
    flex: 1;
  }
  .website-page-form .url-input .input-wrapper .input {
    flex-wrap: wrap;
  }
  .website-page-form .url-input .input-wrapper .input span {
    border-right: 1px solid var(--border-color);
  }
}
