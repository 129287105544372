@use 'styles/colors' as *;

.address-details .address-details-error {
  color: #f88c8c;
  font-size: 0.85rem;
}

.customer-contact-details {
  font-size: 0.875rem;
  color: $fp-grey;
  line-height: 0.5rem;
}
