.zc-banner-with-text {
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.zc-banner-with-text:before {
  content: '';
  display: block;
  padding-top: 29.41176%;
}

.zc-banner-with-text .zc-banner-content-div {
  position: absolute;
  width: 75%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zc-banner-with-text h2 {
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1;
  color: #333;
  margin: 0px 0px 0.5rem 0px;
}

.zc-banner-with-text p {
  margin: 0px 0px 0.5rem 0px;
}
.zc-banner-with-text h3 {
  font-size: 0.75rem;
  font-weight: normal;
}
.alignLeft {
  text-align: left;
}
.alignRight {
  text-align: right;
}

.banner-buttons-container > button,
.banner-buttons-container > a {
  margin: 10px 20px 10px 0px;
}

@media only screen and (max-width: 800px) {
  .zc-banner-with-text h2,
  .zc-banner-with-text h3,
  .zc-banner-with-text p,
  .banner-buttons-container > .z-button {
    margin: 2px;
    padding: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .banner-buttons-container > .z-button {
    font-size: 0.75rem;
    padding: 10px;
    margin: 5px 10px 5px 0px;
  }

  .zc-banner-with-text h2 {
    font-size: 1rem !important;
  }
  .zc-banner-with-text h3 {
    font-size: 1rem !important;
  }
  .zc-banner-with-text p {
    font-size: 0.5rem !important;
  }
}

@media only screen and (max-width: 400px) {
  .banner-buttons-container > .z-button {
    padding: 8px;
  }
}
