.payment-review {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  font-size: 12px;
  width: 7rem;
  height: 1.25rem;
  left: 0px;
  background: #c10b3a;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  color: #ffffff;
}

.other-payment-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  font-size: 12px;
  width: 9rem;
  height: 1.25rem;
  left: 0px;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 10px;
  color: #333333;
}

.sub-order-name ul {
  padding-inline-start: 0;
}

.search-fields {
  display: flex;
  align-items: center;
}

.qc-orders-page .table .table-row:nth-child(2n) {
  background-color: #fff;
}

.qc-orders-page .table .table-row .table-cell .no-row {
  border: none !important;
}
.no-row {
  border: none;
}
.fulfilment-text {
  color: #333333;
  font-weight: 500;
  margin-right: 0.5rem;
}
.column-order-master-status {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
}

.qc-orders-page .table .table-cell:last-child {
  text-align: left;
  padding-right: 1.25rem;
}

.qc-orders-page .table .table-cell {
  min-width: 5rem;
}
