.reward-type {
  flex-grow: 0.05;
}

.task-section {
  margin-bottom: 2rem;
}

.task-section .col-6 {
  margin: inherit 0;
}

.rewards-box .col-6 {
  margin: 0 0.5rem;
}

.task-popup {
  border: 0.063rem solid #dbdbdb;
}

.task-details {
  padding: 1.25rem;
}

.task-details textarea {
  height: 1.875rem;
  padding: 0.875rem;
  border-radius: 0.125rem;
  margin-bottom: 0;
}

.task-header {
  height: 3rem;
  background-color: #2b3238;
  color: #ffffff;
  padding-left: 1.563rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.omnichallenge .reward-header {
  height: 2rem;
  background-color: #66777e;
}

.omnichallenge .task-section .offer-select-value {
  border-radius: 0.125rem;
  background-color: #80959d;
  display: inline-block;
  color: #ffffff;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  margin-top: -0.5rem;
}

.task-detail-sec2 {
  display: flex;
  flex-flow: row wrap;
}

.task-detail-sec3 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.task-useraction label.thin {
  height: 1.2rem;
}

.task-type {
  flex-grow: 0.85;
}

.task-useraction {
  flex-grow: 0.15;
}

.task-channel {
  flex-grow: 0.7;
}

.task-reward {
  display: flex;
  flex-flow: row wrap;
}

@media screen and (max-width: 495px) {
  .task-details textarea {
    height: 5.25rem;
  }
}

@media screen and (max-width: 768px) {
  .mobile-768 {
    width: 100% !important;
    margin-right: 0 !important;
  }
  .task-popup .row {
    flex-direction: column;
  }
  .task-popup .row .col-6 {
    width: 100%;
    padding: 0 0.5rem;
    margin: 0;
  }
}
