.appsplash-listingpage .mr-1 {
    margin-right: 1rem;
}

.appsplash-listingpage .table .table-row .table-cell {
    min-width: 9rem;
}

.appsplash-listingpage .table-row a {
    color: #2b3238;
}

.appsplash-listingpage .table-row a:hover {
    color: #1557bf;
}

.appsplash-listingpage .pagination-button {
    font-size: .875rem;
    color: #80959d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 1rem 1rem;
    gap: 0.5rem;
}

.appsplash-listingpage .pageNo {
    padding: 0.1rem 0.5rem 0.1rem 0.5rem
}

.appsplash-listingpage .pagination-button img {
    padding-top: 0.1rem;
}

.appsplash-listingpage .splashscreen {
    border: 1px solid black;
    width: 10.12rem;
    height: 21.163rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appsplash-listingpage .splashscreen .topAnimation {
    position: absolute;
    top: 0;
}

.appsplash-listingpage .splashscreen .topAnimation img {
    width: 10.12rem;
}

.appsplash-listingpage .splashscreen .bottomAnimation {
    position: absolute;
    bottom: 0;
}

.appsplash-listingpage .splashscreen .bottomAnimation img {
    width: 10.12rem;
}

.appsplash-listingpage .splashscreen .background img {
    width: 10rem;
    height: 21.063rem;
}

.appsplash-listingpage .splashscreen .logo {
    position: absolute;
    top: 9.65rem;
}

.appsplash-listingpage .splashscreen .logo img {
    width: 8rem;
    height: 2rem;
}

.appsplash-listingpage .splashscreen .logo #lottie {
    width: 8rem !important;
    height: 2rem;
}

.appsplash-listingpage .column-Expired,.column-Unpublished {
    color: red;
    font-weight: bold;
}

.appsplash-listingpage .column-Scheduled,.column-Live {
    color: green;
    font-weight: bold;
}

@media screen and (min-width: 840px) {
    .appsplash-listingpage .table .table-cell:last-child {
        text-align: center;
    }
}

@media screen and (max-width: 840px) {
    .appsplash-listingpage .table.table-dynamic .table-row {
        width: 100%;
        overflow-x: auto;;
    }
    .appsplash-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-campaign::before {
        content: 'Campaign Name';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appsplash-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-start-date::before {
        content: 'Start Date';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appsplash-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-end-date::before {
        content: 'End Date';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appsplash-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-actions {
        bottom: 0;
        right: 0;
        position: absolute;
    }
}
