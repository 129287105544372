@use 'styles/colors' as *;

.scan-campaign-form-page {
  .left-heading-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    button {
      margin-left: 1rem;
    }

    .copy-campaign-btn {
      border: 1px solid $fp-grey-2;
    }
  }
  .offer-campaign-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
    font-weight: 600;
    font-size: 0.75rem;
    height: 2rem;

    span {
      margin-right: 0.25rem;
      margin-top: 0.25rem;
    }

    .status-text {
      color: $fp-white;
      border-radius: 5px;
      padding: 0 0.5rem;
      &.enabled {
        background-color: $fp-green;
      }

      &.disabled {
        background-color: $fp-red-1;
      }

      &.expired {
        background-color: $fp-grey;
        opacity: 0.6;
      }
    }
  }

  .campaign-uid {
    font-size: 0.875rem;
    color: $fp-grey;
    margin-bottom: 1rem;
  }

  .inputs-row-container {
    display: flex;
    gap: 1rem;
    .campaign-name-input {
      width: 40%;
    }
    .store-ids-input {
      width: 60%;
    }
  }

  .datetime-picker-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;

    .Date-time .input-wrapper:nth-child(2) {
      width: 7rem;
    }
  }

  .scan-campaign-rule-container {
    padding-bottom: 0.5rem;

    .scan-campaign-rule-title {
      margin-top: 0;
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .single-rule-container {
      box-shadow:
        rgba(9, 30, 66, 0.25) 0px 1px 1px,
        rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      margin-bottom: 1rem;

      .scan-campaign-reward-title {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .single-rule-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.25rem;
        background-color: $fp-grey-4;
        color: $fp-black;
        padding: 0 0.5rem;
        height: 2.5rem;

        .left-header-container {
          display: flex;
          flex-direction: row;

          .move-operations-container {
            button {
              margin-left: 0.25rem;
            }
          }

          button {
            margin-bottom: 0.5rem;
          }
        }

        .right-header-container,
        .odds-display-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          @mixin odds-display-box($color) {
            border: 1px solid $color;
            color: $color;
            border-radius: 1rem;
            padding: 0.25rem 0.5rem;
            font-size: 0.75rem;
            margin-right: 0.5rem;
          }
          .winning-odds {
            @include odds-display-box($fp-green);
          }

          .nothing-odds {
            @include odds-display-box($fp-red);
          }
        }

        .close-icon {
          margin-bottom: 0.35rem;
        }

        .Toggle {
          margin-right: 0.5rem;
        }
      }

      .single-rule-inputs-container {
        padding: 1rem;

        .segment-type {
          width: 50%;
        }
      }

      .row-container {
        display: flex;
        flex-direction: row;
        gap: 1rem;

        .rule-type-select {
          width: 20%;
        }
        .sequence-input {
          width: 80%;
        }
      }

      .Toggle {
        > label {
          flex: none;
        }
      }

      .single-reward-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        > .field {
          width: 12rem;
        }
        .rule-reward-percentage, .rule-reward-x-days {
          width: 8rem;
        }
        .delete-icon {
          width: 2rem;
        }
      }

      .add-reward-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        svg {
          margin-right: 0.5rem;
        }
      }
    }
  }
  .add-rule-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    svg {
      margin-right: 0.5rem;
    }
  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }
}

.duplicate-confirmation-btn-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
}
