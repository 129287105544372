.user-activities .column-timestamp span {
  font-size: 12px;
  color: #80959d;
}

.user-activities.error-box {
  width: '100%';
  height: 200px;
  display: flex;
  flex-direction: row;
  padding-top: 0px;
}
.user-activities.error-box p {
  padding: 0;
  line-height: 0;
}

.user-activities .wrap-cell {
  max-width: 110px;
  white-space: pre-wrap;

  text-overflow: wrap;
  word-wrap: break-word;
}

.action-trail-popup {
  max-width: 93.75rem !important;
  min-width: 31.25rem !important;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  padding: 0px;
  transform: translate3d(0, -50%, 0);
  max-height: 100vh;
  background: purple;
}

