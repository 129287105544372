.video-card-carousel {
  max-width: 1100px;
  margin: 0 auto;
}
.video-card-carousel .alice-carousel {
  padding: 0px 60px;
}
.video-card-carousel .alice-carousel .video-card .video-description {
  text-align: center;
}
