.category-tree .sortable-tree {
  height: 90%;
}

.category-tree .heading {
  display: flex;
  justify-content: space-between;
}

.category-tree .node-div {
  margin: 4px 10px;
  font-size: 0.65rem;
}

.category-tree button {
  font-size: 0.875rem;
}

.category-tree .heading-links>a {
  border: 1px solid #eaefef;
  background-color: #f3f5f6;
  padding: 6px 8px 10px 8px;
}
.category-tree .heading-links>img {
  margin-right: 10px;
  border: 1px solid #eaefef;
  border-left: none;
  padding: 7px 8px;
}

.category-tree .DropDown {
  margin: 0 10px;
}

.category-tree .rst__nodeContent {
  width: 75%;
}

.category-tree .rst__rowLabel {
  max-width: 300px;
}

.category-tree ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.category-tree .rst__rowTitle {
  font-weight: 700;
  font-size: 0.8rem;
}

.category-tree .rst__rowSubtitle {
  color: #80959d;
}
.category-tree .categoryNote {
  color: #045a7c;
  padding: 1.3rem;
  background: #e5f8ff;
  border-radius: .4rem;
  font-size: .9rem;
  max-width: fit-content;
}
/* 
.category-tree .rst__collapseButton:focus, .rst__expandButton:focus {
  box-shadow: 0 0 0 1px #000, 0 0 1px 1px #1557bf;
} */

@media screen and (max-width: 580px) {
  .category-tree .heading-links .button span.text {
      display: none;
  }
  .category-tree .heading-links .button {
    min-width: 0;
    font-size: 20px;
    padding: 0 15px;
  }
}

@media screen and (max-width: 480px) {
  .category-tree .heading h1 {
    font-size: 1.125rem;
    line-height: 26px;
  }
}