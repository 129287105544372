.customer-support-home {
  padding: 0 0 2.5rem 0;
}
.customer-support-home .form-fields {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
}
.search-by-associate-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1.25rem;
}
.customer-support-home .primary {
  width: 90px;
}

form.customer-support-home {
  max-width: 100%;
}

main .table-container .orders-page .header-container {
  display: none;
}

main .table-container .orders-page .table-container .pagination-container {
  display: none;
}

@media (max-width: 480px) {
  .customer-support-home .form-fields {
    width: 100%;
    display: block;
  }
}

.search-by-association-button {
  background-color: #1557bf;
  color: #fff;
  border-radius: 0.125rem;
  padding: 1rem;
  font-size: 0.875rem;
  font-weight: bold;
}
