/* Draftjs overrides */
.RichEditor .public-DraftEditorPlaceholder-root {
  color: #aeb9bd;
}

.RichEditor .public-DraftEditor-content,
.RichEditor .public-DraftEditorPlaceholder-root {
  cursor: text;
  padding: 15px;
  font-size: 0.875rem;
}

.RichEditor .public-DraftEditor-content {
  min-height: 280px;
}
/* End of overrides */

.RichEditor {
  border: solid 1px var(--border-color);
}

.RichEditor .editor-actions {
  height: 70px;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: serif;
  color: #777777;
}

.RichEditor .editor-action {
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-width: 0;
  margin: 0 5px;
  border-radius: 2px;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}

.RichEditor .editor-action:hover {
  /*background-color: #eee;*/
  color: #ff6e6d;
}

.tox-tinymce{
  box-sizing: content-box !important;
  border: 0.0625rem solid #dadee0 !important;
  margin: 0.625rem 0 !important;
  border-radius: 0.125rem !important;
  font-size: 0.875rem !important;
}
.tox-tinymce:focus-within {
  border: 0.0625rem solid #1557bf!important;
}
.tox-tinymce{
  min-height: 12.5rem !important;
  transition: all 200ms ease-in-out !important;
  vertical-align: middle !important;
}
.tox .tox-notification--warning{
  display: none !important;
}