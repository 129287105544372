.card-content {
  width: 131px;
  height: 211px;
  border: 1px solid #eaeaea;
  padding: 0.5rem;

  .card-image-wrapper {
    margin-bottom: 0.5rem;
    border: 1px solid #eaeaea;
    height: 115px;
    width: 115px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    margin: 0 auto;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    font-style: normal;
    overflow: hidden;
  }
}

.appcard-listingpage {
  .column-Scheduled,
  .column-Live {
    color: green;
    font-weight: bold;
  }
  .column-Expired,
  .column-Unpublished {
    color: red;
    font-weight: bold;
  }
  .column-name span:hover {
    cursor: pointer;
    color: hsl(0, 0%, 60%);
  }
}
