.categories-page .category-name {
  color: #2b3238;
  cursor: pointer;
}
.categories-page .category-id {
  color: #2b3238;
}

.categories-page .DropDown {
  display: inline;
}

.categories-page .DropDown {
  display: inline;
}

.categories-page .category-name:hover {
  color: #1557bf;
}

.categories-page .table-header .table-cell {
  padding-bottom: 10px;
}

.categories-page .table .DropDown {
  text-align: right;
}

.categories-page .dropdown-menu {
  width: 154px;
}

.categories-page .category-header .dropdown-menu {
  width: 180px;
}

.CategoriesForm textarea {
  height: 200px;
  max-width: 400px;
}

.CategoriesForm .actions.add, .CategoriesForm .actions.edit{
  display: flex;
  justify-content: flex-end;
  margin-top : 25px;
  position: relative;
}

.CategoriesForm input[type='submit'] {
  margin-top: 0px;
  min-width: 110px;
  width: auto;
  margin-right: 0px;
}
.CategoriesForm button {
  min-width: 110px;
  margin-right: 10px;
}
.CategoriesForm .delete-button img{
  margin-right: 5px;
  color: #eb8181;
}

.CategoriesForm .delete-button span{
  margin-top: 2px;
}

.CategoriesForm .field {
  margin-bottom : 20px;
}

.categories-page .dropdown-item:hover {
  font-weight: 600;
}

.categories-page .table-header .table-cell:nth-last-child(2) {
  width: 11%;
}

.categories-page .table-header .table-cell:nth-child(2) {
  width: 16%;
}

.categories-page .table-header .table-cell:nth-child(5) {
  text-align: right;
  width: 18%;
  padding-right: 50px;
}

.categories-page .table-header .table-cell:last-child {
  width: 5%;
}
.categories-page .table-cell:last-child {
  text-align: center;
}

.categories-page .table-row .product-count {
  padding-right: 50px;
}

.categories-page .category-header>a {
  margin-right: 10px;
  border: 1px solid #eaefef;
  background-color: #f3f5f6;
  padding: 6px 8px 10px 8px;
  border-left: none;
}
.categories-page .category-header>img {
  border: 1px solid #eaefef;
  padding: 8px;
}
.categories-page .category-header-mobileview{
  display: none;
}
.categories-page .category-productcount-mobileview{
  display: none;
}
.CategoriesForm .Upload .image-upload-div {
  max-height: initial;
}

@media screen and (max-width: 650px) {
  .categories-page .filters-wrapper .field {
    width: 100%;
  }
}

@media (max-width:495px){
  .categories-page .header-actions-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .categories-page .header-actions-wrapper .button {
    min-width: 150px;
  }
  .CategoriesForm input[type='submit'] {
    max-width: 110px;
  }
}

@media screen and (max-width: 580px) {
  .categories-page .header-actions-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .categories-page .header-actions-wrapper .button{
    min-width: 0;
    font-size: 20px;
    padding: 0 15px;
  }
  .categories-page .header-actions-wrapper .button span.text{
    display: none;
  }
  .categories-page .table-container {
    overflow-x: auto;
  }
}


@media (max-width: 480px){
  .categories-page .table .table-cell:first-child {
    padding: 0px;
  }
  .categories-page .table-container{
    border-top: 1px solid #eaefef;
    margin: 0px -25px;
    padding: 15px 20px;
  }
  .categories-page .table .table-header{
    display:none;
  }
  .categories-page .table .table-row{
    display: block;
    margin-bottom: 15px;
    padding: 15px;
    border: 1px solid#eaefef;
    min-height: 125px;
    position: relative;
  }
  .categories-page .table .table-row .table-cell div {
    font-size: 0.75rem;
 }
  .categories-page .table-row .table-cell{
    height:auto;
    border:none;
  }
  .categories-page .table-row .table-cell.category-name{
    color: #2b3238;
    font-weight: 600;
    padding-right: 24px;
    word-break: break-all;
    font-size:14px;
    font-weight: 600;
  }
  .categories-page .table .table-row:nth-child(2n){
    background-color: #fff;
  }
  .categories-page .table .table-row .table{
    border:none;
  }
  .categories-page .table-row .product-count{
    font-size:11px;
    position: absolute;
    left: 143px;
    border:none;
    margin-top: 6px;
  }
  .categories-page .table-row .product-count .text-product-count{
    display: block;
    text-align: left;
    color:#000;
    font-weight:600;
  }
  .categories-page .table-row .parent-category{
    font-size:11px;
    position: absolute;
    border: none;
    left:0px;
    width:100%;
    background:#fbfcfc;
    height: 48px;
    margin-top: 10px;
    padding-top:10px;
    padding-left: 15px;
    border-top: 1px solid#eaefef;
  }
  .categories-page .table-row .parent-category .text-category-name{
    display: block;
    color:#000;
    font-size:12px;
  }
  .categories-page .table-row .category-status .text-muted{
    position: absolute;
    right:15px;
    margin-top: 6px;
  }
  .categories-page .table-row .category-actions{
    position:absolute;
    right: -3px;
    top: 0px;
  }
  .categories-page .category-header-mobileview{
    display: block;
  }
  .categories-page .category-productcount-mobileview{
    display: block;
  }
  .categories-page .table-row .category-actions .DropDown .dropdown-menu .dropdown-items .dropdown-item {
    font-size:13px;
  }
  .CategoriesForm .actions.edit button{
    min-width: 38vw;
  }
  .CategoriesForm .actions.edit input[type='submit']{
    min-width: 48%;
  }
  .CategoriesForm .actions.add button{
    min-width: 48%;
  }
  .CategoriesForm .actions.add input[type='submit']{
    min-width: 48%;
  }
  .CategoriesForm .actions.edit{
    height:82px;
  }
  .CategoriesForm .actions.edit .delete-button{
    align-self: flex-end;
    left:36%;
  }
  .categories-page .category-name{
    font-weight:600;
  }
  .categories-page .filters-wrapper .primary, .categories-page .filters-wrapper .button {
    width: 48%;
  }
}
@media (max-width: 320px){
  .categories-page .table-row .product-count{
     left:112px;
  }
  .CategoriesForm .actions.edit button{
    min-width: 36vw;
  }
}
