.omni-journey-voucher-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.omni-journey-voucher-form .rc-time-picker {
  width: 100%;
}

.omni-journey-voucher-form .csv-upload-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--border-color);
  padding: 20px;
  grid-column: 1/3;
  position: relative;
  margin-top: 0.5rem;
}

.omni-journey-voucher-form .csv-upload-section input[type='file'] {
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0;
  opacity: 0;
  left: 0;
  top: 0;
}

.omni-journey-voucher-form .status-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
