.cx-phone-card .customer-phone, .cx-email-card .customer-email {
    display: inline-block;
    padding: 15px 0;
}

.cx-email-card .customer-email {
    word-break: break-all;
}
.cx-phone-card, .cx-email-card {
    font-size: 0.875rem;
    color: #2b3238;
    letter-spacing: -0.3px;
    display: flex;
}
.cx-phone-email-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eaefef;
    margin: 0px 20px;
}

.cx-phone-email-container .DropDown .dropdown-item {
    white-space: nowrap;
}

.phone .cx-phone-card:nth-child(2):nth-last-child(2) {
    border-bottom: 1px solid #eaefef;
}

.email .cx-email-card:nth-child(2):nth-last-child(2) {
    border-bottom: 1px solid #eaefef;
}

.cx-phone-card .delete-icon, .cx-email-card .delete-icon {
    margin-top: 14px;
    margin-left: auto;
}
.cx-phone-card .phone-icon {
    margin-top: 14px;
    padding-right: 10px;
}
.cx-email-card .email-icon {
    margin-top: 15px;
    padding-right: 10px;
}

.cx-phone-email-card img:hover {
    cursor: pointer;
}

.cx-phone-email-card .image {
    line-height: 40px;
}

.cx-phone-email-container .cx-phone-email-actions .default{
    margin: 0px 10px;
    background-color: #fbfcfc;
    color: #80959d;
    border: 1px solid #f4f7f9;
    padding: 2px 8px;
    font-size: 0.75rem;
    border-radius: 36px;
}

.cx-phone-email-container .cx-phone-email-actions .default-disabled{
    cursor: not-allowed;
    height: 20px;
}

.phone div:last-of-type, .email div:last-of-type {
    border-bottom: 0px;
}

.phone .add-button, .email .add-button {
    background: #fbfcfc;
}
