.tags-page .table-row .table-cell {
  height: 70px;
  padding-top: 0;
  padding-bottom: 0;
}

.tags-page .tag-name {
  color: #2b3238;
  cursor: pointer;
}

.tags-page .tag-name:hover {
  color: #1557bf;
}

.tags-page .dropdown-menu {
  width: 154px;
}

.tags-page .tag-header-mobileview{
  display: none;
}
.tags-page .editPopup,
.tags-page .editPopup h1 {
  text-align: left;
}

@media screen and (min-width: 550px) {
  .tags-page .editPopup .form-actions {
    text-align: right;
  }

  .tags-page .editPopup input {
    width: initial;
  }
}
@media (max-width: 480px) {
  .tags-page .table-row .table-cell {
    height: 37px;
    padding-top: 15px;
  }
  .tags-page .table-container{
    border-top: 1px solid #eaefef;
    margin: 0px -25px;
    padding: 15px 20px;
  }
  .tags-page .table .table-header{
    display: none;
  }
  .tags-page .table .table-row{
    display: block;
    margin-bottom: 15px;
    padding-left: 15px;
    border: 1px solid #eaefef;
    min-height: 121px;
    position: relative;
  }
  .tags-page .table .table-row .table-cell div{
    font-size: 0.75rem;
  }
  .tags-page .table .table-row:nth-child(2n){
    background-color: #fff;
  }
  .tags-page .table .table-cell:first-child {
    padding-left: 0px;
    padding-right: 5px;
  }
  .tags-page .table .table-row .table-cell{
    border:none;
  }
  .tags-page .table-row .table-cell.tag-name{
    color: #2b3238;
    font-weight: 600;
    padding-right: 24px;
    word-break: break-all;
    font-weight: 14px;
  }
  .tags-page .table-row .product-count,
  .tags-page .table-row .tag-actions{
    position: absolute;
  }
  .tags-page .table-row .tag-actions{
    right:-5px;
    top:7px;
  }
  .tags-page .table-row .product-count{
    font-size:11px;
    left:0px;
    width: 100%;
    background: #fbfcfc;
    margin-top: 10px;
    padding-left: 15px;
    padding-bottom: 28px
  }
  .tags-page .table-row .table-cell.product-count {
    border-top: 1px solid #eaefef; 
  }
  .tags-page .table-row .table-cell .tag-name {
    font-weight: 600;
  }
  .tags-page .table-row .tag-product-count{
     color:#000;
     font-weight: 600;
  }
  .tags-page .search-button-wrapper{
    margin-right: 0;
    position: absolute;
    right: 0px;
    top:0px
  }
  .tags-page .header-actions-wrapper {
    display: flex;
    margin-top: 47px;
    margin-left: -81px;
    justify-content: flex-end;
  }
  .tags-page .header-actions-wrapper .button span.text {
      display: inline;
   }
   .tags-page .header-actions-wrapper .button {
     font-size: 12px;
   }
   .tags-page .import-tags-button{
      margin-right: 15px;
   }
  .tags-page .filters-wrapper  .primary, .tags-page .filters-wrapper .button {
    width: 48%;
  }
  .tags-page .tag-header-mobileview{
    display: inline;
  }
}