@use 'styles/colors' as *;

.order-list-items-container {
  position: relative;
  margin-top: 1rem;

  .item-quantity {
    width: 5.625rem;
  }

  .item-amount {
    font-weight: 600;
    color: $fp-grey;
    font-size: 0.75rem;
  }

  .table-footer {
    margin-top: 1.25rem;
  }

  .product-name {
    color: $fp-teal-1;
    .brand-name {
      color: $fp-black;
    }
  }

  .sub-heading-table {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $fp-white-3;
    padding: 1rem;
    width: 100%;
    position: absolute;
    box-shadow: none;


    a {
      z-index: 10;
      text-decoration: underline;
      font-size: 0.8rem;
    }

    .store-logo {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
    }

    .status-text {
      color: $fp-grey;
      margin-left: auto;
      font-size: 0.9rem;
      font-weight: 600;

      .pending {
        color: $fp-red-4;
      }
      .processing {
        color: $fp-red-4;
      }
      .packed {
        color: $fp-grey-3;
      }
      .dspatched {
        color: $fp-grey-3;
      }
      .completed {
        color: $fp-green-2;
      }
      .cancelled {
        color: $fp-red-3;
      }
    }

    & >  * {
      margin-right: 0.5rem;
    }

    .delivery-slot {
      font-size: 0.8rem;
      font-weight: 500;
      margin-left: 2rem;
      span {
        color: $fp-grey-2;
      }
    }
  }

  .table {
    .row-without-styling {
      background-color: none !important;
    }
    .table-row {
      &.sub-item-gray-out {
        opacity: 0.5;
      }

      .substitution-icon-img {
        right: 0.5625rem;
        bottom: 0.3rem;
      }
      &.black-bold-text{
        small {
          color: $fp-black;
          font-weight: bold;
        }
      }
      &.without-border {
        .table-cell {
          border-top: none;
        }
      }
    }

    .table-cell {
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;

      &.item-image {
        position: relative;
        .sub-icon-img {
          position: absolute;
          right: 0.5625rem;
          bottom: 0.3rem;
        }
      }

      .item-link {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }


    .offset-top {
      .table-cell {
        padding-top: 5.125rem;
      }
    }
  }


  .invoice-summary-wrapper {
    display: flex;
    justify-content: flex-end;

    .invoice-summary {
      .pending-amount {
        color: $fp-red-1;
      }
      & > div {
        margin-top: 0.6rem;
      }
      .small-size {
        font-size: 0.8rem;
      }
    }
  }
}

.sale-order-grouped-discounts {
  .discount-amount {
    font-size: 0.75rem;
    line-height: 1.625rem;
    text-overflow: ellipsis;
  }
  .code {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2rem;
    margin-left: 1rem;
  }

  .discount-amount-nowrap {
    white-space: nowrap;
  }
}
