.media-adset-detail .timestamp{
  font-size: 0.75rem;
  text-align: right;
}
.media-adset-detail .section-row:after { 
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  font-size:0;
}
.media-adset-detail .section-row-input{
  width: 50%;
  float: left;
  padding-right: 1.25rem;
}
.media-adset-detail .section-row-input-full{
  width: 100%;
  padding-right: 1.25rem;
}
.media-adset-detail .section-row-input-auto{
  width: auto;
  float: left;
  padding-right: 1.25rem;
}
.media-adset-detail .section-row-input-half{
  width: 20%;
  float: left;
  padding-right: 1.25rem;
}
.media-adset-detail .timeLine{
  float: left;
}

.media-adset-detail .section-row.addable-inputs {
  display: flex;
  align-items: center;
}
.media-adset-detail .section-row.addable-inputs .addable-inputs-btn{
  width: 1.125rem;
  height: 1.125rem;
  cursor: pointer;
  margin-top: 0.375rem;
}
.media-adset-detail .section-row.addable-inputs .addable-inputs-btn.add{
  background: url(../images/add-icon.svg) no-repeat;
  background-size: 100%;
}
.media-adset-detail .section-row.addable-inputs .addable-inputs-btn.delete{
  margin-right: 1rem;
  background: url(../images/delete-icon.svg) no-repeat;
  background-size: 100%;
}


.media-adset-detail .section-row.addable-inputs .section-row-input{
  width: 85%;
}



.media-adset-detail .assets-summary{
  background-color: #f0f5f8;
  padding: 0.625rem;
  margin-bottom: 1.25rem;
}
.media-adset-detail .assets-summary .title{
  margin: 0 0 0.625rem;
}
.media-adset-detail .assets-summary .assets-summary-inner{
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #2b3238;
  line-height: 2;
}
.media-adset-detail .assets-summary .assets-summary-inner .assets-summary-col-btm{
  font-size: 1rem;
}


.media-adset-detail .package-table-wrap{
  margin-bottom: 2.5rem;
}
.media-adset-detail .package-table-wrap .cell-action {
  font-weight: bold;
  color: #80959D;
  cursor: pointer;
}
.media-adset-detail .package-table-wrap .cell-action.remove {
  font-size: 0.75rem;
  font-weight: bold;
  color: #eb8181;
}

.media-adset-detail .advertisers_table{
  margin-bottom: 2.5rem;
}
.media-adset-detail .advertisers_table h3{
  margin-bottom: 0;
}
.media-adset-detail .advertisers_table .name{
  width: 40%;
}
.media-adset-detail .advertisers_table .budget-input{
  width: 30%;
}

.media-adset-detail .w-secondary-label .labelWrap{
  margin-bottom: 1.2rem;
}
.media-adset-detail .secondary-label{
  position: absolute;
  left: 0;
  top: 1.4rem;
  font-size: 0.75rem;
}
.media-adset-detail .small-label{
  font-size: 0.75rem;
  line-height: 0.875rem;
  margin-left: 0.125rem;
  color: #ff0000;
}

.media-adset-detail .label-tooltip {
  font-size: 0.75rem;
  margin-left: 0.5em;
  position: relative;
}

.media-adset-detail .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 25.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: -3.155rem;
  text-align: left;
}

.media-adset-detail .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.media-adset-detail .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}


@media (max-width: 36rem) {
  .media-adset-detail  .section-row-input{
      width: 100%;
      padding-right: 0;
  }
  .media-adset-detail  .section-row-input-half{
      width: 50%;
  }
}

.media-adset-detail .advertisers_table .totalBudget{
    padding: 0.5rem 1.25rem;
    text-align: right;
    color: #80959d;
}

.media-adset-detail .assetsBox,
.media-adset-detail .advertisers_table{
  border: 0.125rem solid var(--border-color);
  margin-bottom: 1.25rem;
}

.media-adset-detail .assetsBox .title,
.media-adset-detail .advertisers_table .title{
  background-color: var(--border-color);
  font-size: 0.75rem;
  padding: 0.3rem 0.625rem;
}
.media-adset-detail .assetsBox_filter{
  padding: 1rem 0 0 0.625rem;
}

.media-adset-detail .assetsBox .assetsBox_list.scroll{
  min-height: 31rem;
  max-height: 34rem;
  overflow-y: scroll;
}


.media-adset-detail .assetsBox .assetsBox_list .table-row.hide{
  display: none;
}

.media-adset-detail .assetsBox .assetsBox_list .table-cell.name{
  width: 50%;
}
.media-adset-detail .assetsBox .assetsBox_list .table-cell.time{
  text-align: left;
}
.media-adset-detail .assetsBox .assetsBox_list .table-cell.time .tip{
  font-weight: normal;
}

.media-adset-detail .assetsBox .multi-dateRangePicker-wrap{
  margin-bottom: 0rem;
}
