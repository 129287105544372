.entity-meta-data-page .tab-details {
  padding: 40px 0;
}

.entity-meta-data-page .delete-button {
  margin-top: -35px;
  float: right;
  font-size: 0;
  transition: none;
}

.entity-meta-data-page .delete-button {
  background-image: url('./delete.svg');
  height: 40px;
  background-position: center;
  margin-top: 30px;
  margin-left: 5px;
  padding: 10px;
}

.entity-meta-data-page .form-actions {
  justify-content: flex-start;
  margin-top: 40px;
}

.entity-meta-data-page .key-container {
  display: flex;
  justify-content: flex-start;
  margin-right: 0;
}

.entity-meta-data-page
  .key-container.product-key-container
  button.delete-button {
  margin-top: 3.25rem;
}

.entity-meta-data-page .key-container.product-key-container .field .labelWrap {
  display: flex;
  align-items: center;
  height: 3.25rem;
  max-width: 8rem;
}

.entity-meta-data-page .key-container.product-key-container .field label {
  height: auto;
}

.entity-meta-data-page .key-container .field.checkbox {
  margin: 0 30px;
}

.entity-meta-data-page .key-container .field.checkbox .input-wrapper {
  margin-top: 0.825rem;
}

.entity-meta-data-page .key-container .field:first-child {
  margin-right: 20px;
}

.entity-meta-data-page form {
  max-width: 100%;
}
.entity-meta-data-page .enum-fields {
  margin-left: 20px;
}
.entity-meta-data-page form .form-actions input[type='submit'],
.entity-meta-data-page form .form-actions .button {
  min-width: 100px;
}
.entity-meta-data-page .enum-fields.checkbox .input-wrapper {
  margin-top: 17px;
}

.entity-meta-data-page .product-custom-class > div {
  margin-bottom: 1.875rem;
  padding: 1rem;
  border-radius: 0.313rem;
  box-shadow: rgb(199 199 199 / 20%) 0 0.5rem 1.5rem;
}

.entity-meta-data-page .product-custom-class > div:hover {
  box-shadow: rgba(149, 157, 165, 0.2) 0 0.5rem 1.5rem;
  cursor: pointer;
}

.entity-meta-data-page .product-custom-class > div .key-container {
  justify-content: space-between;
}

.entity-meta-data-page .product-custom-class > div .key-container .enum-fields {
  width: 30%;
}

.entity-meta-data-page
  .product-custom-class
  > div
  .key-container
  .input-wrapper
  select {
  width: 12.5rem;
}

.entity-meta-data-page
  .product-custom-class
  > div
  .key-container
  .input-wrapper
  .multitext-tags {
  display: inline-block;
  width: 100%;
  max-height: 9.375rem;
  overflow-y: auto;
  border: 0.063rem solid #dadee0;
  padding: 0 0.813rem;
  padding-bottom: 0.813rem;
  border-top: 0;
  border-bottom-left-radius: 0.313rem;
  border-bottom-right-radius: 0.313rem;
}

.entity-meta-data-page
  .product-custom-class
  > div
  .key-container
  .field.checkbox
  .input-wrapper {
  margin-top: 0;
}

.entity-meta-data-page
  .product-custom-class
  > div
  .key-container
  button.delete-button {
  margin-top: 2.3rem;
}

.entity-meta-data-page .product-custom-class > div .key-container .thin {
  font-weight: 600;
}
