.BrandForm .field {
  margin-bottom: 20px;
}

.TagForm .form-actions.add, 
.TagForm .form-actions.edit{
  display: flex;
  margin-top : 25px;
}

.TagForm .form-actions.edit{
  justify-content: flex-end;
}

.TagForm .form-actions {
  justify-content: flex-end
}

.TagForm .form-actions button{
  margin-right: 10px;
}

.TagForm .delete-button {
  left: 40px;
}

.TagForm .delete-button img{
  margin-right: 5px;
  color: #eb8181;
}

.TagForm .delete-button span{
  margin-top: 2px;
}


.BrandForm input[type='submit'],
.BrandForm .button {
  min-width: 110px;
  width: auto;
}

.BrandForm .button:not(:last-child) {
  margin-right: 10px;
}
@media (max-width: 480px) {
  .form-actions.edit .primary, .form-actions.add .primary, .form-actions.add .button{
    width: 48%;
  }
}