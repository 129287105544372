.order-id {
  color: #4787eb;
  font-weight: normal;
  margin-left: 0.3125rem;
}

.order-details-qc {
  background-color: #fafafa;
  padding: 0 !important;
}

.order-details-qc .navigationDrawer {
  top: 1.875rem;
  left: 2.8125rem;
}

.order-details-qc h1 {
  background-color: white;
  padding: 1.875rem 5.3125rem;
}

.key-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 0.875rem;
  padding: 1rem;
  background-color: white;
}
.key-info > div,
.key-info > b {
  padding-bottom: 0.5rem;
}
.master-status,
.rider-status {
  border: 1px solid var(--border-color);
  border-radius: 1.25rem;
  font-size: 0.75rem;
  padding: 1px 0.75rem;
  margin-left: 0.3125rem;
}

.edit-status {
  margin-left: 0.5rem;
  cursor: pointer;
  height: 1.25rem;
}

.platform-detail,
.master-status-container,
.rider-status-container {
  display: flex;
}
.master-status-container,
.rider-status-container {
  align-items: center;
}
.platform-detail b {
  margin-left: 0.3125rem;
}
.fairprice-logo {
  width: 5.6875rem;
  height: 1.25rem;
  margin-left: 0.625rem;
}

.custom-select {
  width: 16.25rem;
  margin-left: 0.3125rem;
}

.key-info-buttons {
  display: flex;
  justify-content: flex-end;
}
.key-info-buttons.cancel-delivery {
  justify-content: flex-start;
}
.key-info-buttons .primary {
  margin-right: 0.625rem;
}

.delivery-info {
  display: flex;
  justify-content: space-between;
}
.odp-container {
  padding: 0 2.5rem;
}
.qc-container {
  margin-top: 1.875rem;
  background-color: white;
}
.qc-container:last-child {
  margin-bottom: 1.875rem;
}
.qc-header {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1rem;
}
.qc-header h2 {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.75rem;
}
.qc-header .bulk-actions {
  text-align: right;
  width: 100%;
}
.qc-header .bulk-actions button {
  margin-left: 1rem;
}

.summary-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.summary-input-container.select-all-container {
  justify-content: flex-end;
}
.summary-input-container .input-wrapper label {
  font-weight: 700;
  white-space: nowrap;
}

.odp-container button {
  cursor: pointer;
}
.odp-container button:disabled,
.odp-container button[disabled] {
  cursor: not-allowed;
}

@media screen and (max-width: 840px) {
  .delivery-info {
    flex-direction: column;
  }
  .key-info {
    margin-bottom: 1.875rem;
  }
  .qc-header .bulk-actions {
    align-self: flex-start;
    margin-top: 0.625rem;
    text-align: left;
  }
  .summary-input-container {
    align-items: flex-start;
    flex-direction: column;
  }
  .qc-container {
    padding-bottom: 0.3125rem;
  }
}

@media screen and (max-width: 580px) {
  .qc-header .bulk-actions button {
    flex-direction: column;
    display: flex;
    margin-left: 0;
    margin-top: 0.625rem;
  }
  .key-info.without-button {
    height: auto;
  }
  .key-info.without-button > div {
    flex-direction: column;
    text-align: left;
    display: flex;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .odp-container {
    padding: 0 1rem;
  }
  .order-details-qc h1 {
    padding: 1.875rem 0 1.875rem 3.4375rem;
  }

  .order-details-qc .navigationDrawer {
    left: 1.25rem;
  }
  .qc-container {
    padding-bottom: 0;
  }
}
