.editPopup.assetPreview-box {
    max-width: 50rem;
}

.asset-summary-wrap .asset-summary-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asset-summary-wrap .asset-summary-inner {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    line-height: 1.8;
    padding: 0.625rem;
}

.asset-summary-wrap .asset-summary-inner .asset-summary-item:not(.description) {
    width: 50%;
    margin-bottom: 0.625rem;
}

.asset-summary-wrap .asset-summary-inner .asset-summary-item.description {
    width: 100%;
    margin-bottom: 0.625rem;
}

.asset-summary-wrap .asset-summary-inner .black {
    color: #000;
}

.asset-summary-wrap .asset-summary-inner .asset-summary-item.description .operatingHour {
    display: flex;
    flex-wrap: wrap;
}

.asset-summary-wrap .asset-summary-item .dateBox {
    width: 18%;
    padding: 0.625rem 0;
    margin: 0.625rem 1%;
    text-align: center;
    background-color: #f0f5f8;
    border-radius: 0.375rem;
    line-height: 1.5;
}

.asset-summary-wrap .dateBox .asset-time-title {
    font-weight: bold;
}
.asset-summary-wrap .dateBox .asset-time-status{
    position: relative;
}
.asset-summary-wrap .dateBox .asset-time-status::before{
    content: '';
    display: block;
    position: absolute;
    left: -4rem;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 0.375rem;
    background-color: #7bd49d;
}
.asset-summary-wrap .dateBox .asset-time-status.inactive::before{
    left: -5rem;
    background-color: #eb8181;
}

.asset-summary-wrap .dateBox .asset-time-time {
    font-size: 0.6875rem;
}

@media (max-width: 36rem) {
    .asset-summary-wrap .asset-summary-inner .asset-summary-item:not(.description) {
        width: 100%;
    }

    .asset-summary-wrap .asset-summary-item .dateBox {
        width: 33%;
        padding: 0.375rem 0;
        margin: 0.375rem 0;
        font-size: 0.625rem;
    }
}