.return-file-form form {
  max-width: 100%;
}

.return-file-form .form-action {
  margin-top: 40px;
}

.return-file-form .table .table-cell:last-child {
  text-align: left;
}