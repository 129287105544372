.orders-page .table-row .table-cell {
  height: 70px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.orders-page .dropdown-menu, .egifting-orders-page .dropdown-menu {
  width: 154px;
}

.orders-page .order-number {
  color: #2b3238;
  cursor: pointer;
}

.orders-page .order-number:hover {
  color: #1557bf;
}

.orders-page .dropdown-toggle {
  padding: 12px;
  margin: -12px;
}

.orders-page .pending-amount,
.orders-page .refund-amount {
  color: #80959d;
}

.orders-page .table-row:hover .pending-amount, .orders-page .table-row .fill-rate {
  color: #eb8181;
}

.orders-page .table-row:hover .refund-amount {
  color: #1557bf;
}
.orders-page .column-customer-name .cx-name-text {
  max-width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.orders-page .column-order-fillrate {
  min-width: 87px;
}
.orders-page .column-creation-time {
  min-width: 97px;
}
.orders-page .column-completion-time {
  min-width: 89px;
}
.orders-page .table .table-cell:last-child {
  max-width: 55px;
}
.orders-page .table .table-cell:first-child {
  padding-left: 10px;
}
.orders-page .table .table-cell {
  vertical-align: baseline;
}
.orders-page .customer-actions {
  margin-top: 2px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  max-width: 112px;
}

.orders-page .customer-actions .CustomerAddressWidget {
  grid-column-start: 1;
}
.orders-page .customer-actions .CustomerEmailWidget {
  grid-column-start: 2;
}
.orders-page .customer-actions .CustomerCallWidget {
  grid-column-start: 3;
}

.orders-page .customer-actions .CustomerAddressWidget .address-icon {
  margin-right: 0px;

}

.orders-page .customer-name {
  cursor: pointer;
  color: #2b3238;
  text-transform: capitalize;
}

.orders-page .customer-name:hover {
  color: #1557bf;
}
.orders-page .pre-order {
display: flex;
}
.orders-page .pre-order span{
  order: 1;
}

.orders-page .address-icon {
  display: inline-block;
  width: 14px;
  height: 24px;
  background-position: center;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  margin-right: 30px;
}

.orders-page .CustomerEmailWidget,
.orders-page .CustomerCallWidget {
  margin-right: 4px;
  height: 24px;
  width: 24px;
}

.orders-page .address-icon {
  background-image: url('./icon-address-inactive.svg');
}
.orders-page .mail-icon {
  background-image: url('./icon-mail-inactive.svg');
}
.orders-page .call-icon {
  background-image: url('./icon-call-inactive.svg');
}
.orders-page .table-row:hover .address-icon {
  background-image: url('./icon-address-active.svg');
}
.orders-page .table-row:hover .mail-icon {
  background-image: url('./icon-mail-active.svg');
}
.orders-page .table-row:hover .call-icon {
  background-image: url('./icon-call-active.svg');
}

.orders-page .order-status {
  text-transform: uppercase;
}

.orders-page .DetailsCard {
  max-width: initial;
}
.orders-page .editPopup .address-map {
  height: 300px;
}

.editPopup.order-log {
  max-width: 1000px;
}

.orders-page .prefix {
  margin-right: -4px;
}

.orders-page .container-order-number .order-type-icons .image{
  background: transparent;
}

@media screen and (max-width: 840px){
  .orders-page .table.table-dynamic .table-row .table-cell.column-order-number a{
    color: #1557bf;
    font-weight: 900;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-customer-name, 
  .orders-page .table.table-dynamic .table-row .table-cell.column-order-amount{
    display: none;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-order-status{
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: right;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-order-status > div{
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-order-status::before{
    content:'Order Status';
    display: block;
    color:#80959d;
    font-size: 0.75rem;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-completion-time{
    position: absolute;
    right: 0;
    top: 52px;
    color:#80959d;
    font-size: 0.75rem;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-completion-time div{
    display: inline;
    padding-right: 5px; 
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-creation-time {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 12px 20px;
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-creation-time div{
    display: inline;
    color:#80959d;
    padding-right: 5px; 
  }
  .orders-page .table.table-dynamic .table-row .table-cell.column-order-actions{
    position: absolute;
    bottom: 14px;
    right: 20px;
    padding: 0;
    height: auto;
  }
}

@media only screen and (max-width: 650px) {
  .orders-page .table .table-row .table-cell small, .orders-page .table .table-row .table-cell span, .orders-page .table .table-row .table-cell div{
    font-size: 0.75rem;
  }
  .orders-page .order-type-icon-delivery {
    margin-top: 3px;
}

.orders-page .table .table-row .table-cell .dropdown-item  {
  font-size: 13px;
}
.orders-page .table.table-dynamic .table-row .table-cell.column-creation-time {
  padding: 10px 15px;
}
.orders-page .table .table-row .order-type-icon-pickup, .orders-page .table .table-row .order-type-icon-delivery {
  padding: 10px 15px;
}
.orders-page .filters-wrapper .primary, .orders-page .filters-wrapper .button {
  min-width: 48%;
}
}

@media(max-width:580px){
  .orders-page .table.table-dynamic .table-row{
    margin: 10px 20px!important;
    width: auto;
  }
  .orders-page .table-container {
    margin: 10px -15px 0 -15px;
}
.orders-page .header-container .header-actions-wrapper{
  margin-top: -4px;
  margin-right: -5px;
}
.orders-page .table-container .table .table-row .order-number {
font-size: 14px;
font-weight: 600;
}
.orders-page .filters-wrapper .button{
  margin-top: 0px;
}
.orders-page .table-container .table-cell .order-number span{
  font-size: 14px;
  font-weight: 600;
}
.orders-page .table.table-dynamic .table-row .table-cell.column-creation-time div, .orders-page .table.table-dynamic .table-row .table-cell.column-creation-time small {
  font-size: 13px;
}
.orders-page .table.table-dynamic .table-row .table-cell.column-order-status::before, .orders-page .table.table-dynamic .table-row .table-cell.column-order-status::before {
  font-size: 11px;
}
.orders-page .table.table-dynamic .order-number, .orders-page .table.table-dynamic .store-name {
  margin-left: 0px;
}
.orders-page .dropdown-toggle {
  margin-right: -15px;
}
.orders-page .table .table-cell .order-type-icons {
  margin-left: -8px;
}
.orders-page .table .table-cell .order-type-icons .image{
  margin-right: 5px;
}
}

.orders-page .fullWidth {
  width: 100%;
}