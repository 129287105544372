.banner-filter-main {
    width: 100%;
}

.banner-date-time-section {
    width: 100%;
    display: flex;
}

.banner-date-time-section .date-time-filter {
    width: 50%;
    display: flex;
}

@media (max-width: 996px) { 

    .banner-date-time-section {
        flex-direction: column;
    }

    .banner-date-time-section .date-time-filter {
        width: 100%;
    }
}