.select-menu {
  width: 13.75rem;
}
.select-menu .select-btn {
  display: flex;
  height: 3.438rem;
  background: #404040;
  padding: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 0.5rem;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  box-shadow: 0 0 0.313rem #eaecf0;
  border: 1px solid #eaecf0;
  justify-content: center;
  color: white;
}
.select-menu .options {
  position: absolute;
  width: 13.75rem;
  overflow-y: auto;
  max-height: 18.438rem;
  padding: 0.625rem;
  margin-top: 0.625rem;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 0 0 0.625rem rgba(0, 0, 0, 0.1);
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-duration: 0.35s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.35s;
  -webkit-animation-fill-mode: both;
}
.select-menu .options .option {
  display: flex;
  height: 3.438rem;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 0.5rem;
  align-items: center;
  background: #fff;
}
.select-menu .options .option:hover {
  background: #f2f2f2;
}
.select-menu .options .option i {
  font-size: 1.563rem;
  margin-right: 0.75rem;
}
.select-menu .options .option .option-text {
  font-size: 1rem;
  color: #333;
}

.select-btn i {
  font-size: 1.563rem;
  transition: 0.3s;
}

.select-menu.active .select-btn i {
  transform: rotate(-180deg);
}
.select-menu.active .options {
  display: block;
  opacity: 0;
  z-index: 10;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 1.875rem, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeInDown {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 1.25rem, 0);
    opacity: 0;
  }
}

/* LOADER */
.skeleton {
  --bg: #f7f7f7;
  --glare-bg: linear-gradient(
    90deg,
    transparent,
    hsla(0, 0%, 100%, 0.4),
    transparent
  );
  position: relative;
  background-color: var(--bg);
  overflow: hidden;
}

.skeleton::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--glare-bg);
  transform: translateX(-100%);
  animation: skeleton-glare 1.75s infinite ease-out;
  z-index: 1;
}

@keyframes skeleton-glare {
  to {
    transform: translateX(100%);
  }
}

.card {
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 0.5rem;
  overflow: hidden;
}

.card__img {
  width: 100%;
  height: 4.25rem;
}

.card__body {
  padding: 1rem 0px;
}

.card__text {
  font-size: 1rem;
  height: 1em;
  border-radius: 1.25rem;
}

.card__body > .card__text {
  margin: 0.5em 0;
  width: 40%;
}

.card__body > .card__text:first-child {
  margin-top: 0.25rem;
}

.card__body > .card__text:last-child {
  margin-bottom: 0.25rem;
  width: 30%;
}

.wrapper {
  margin-bottom: 2rem;
}

.ConfigSubmit {
  background: #175cd3;
  color: white;
  padding: 0.125rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  height: 2.188rem;
  font-size: 0.875rem;
}

.ConfigCancel {
  background: #373737;
  color: white;
  padding: 0.125rem 0.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  margin-left: 1rem;
  height: 2.188rem;
  font-size: 0.875rem;
}

.SaveDisabled {
  background-color: #ffe42c;
  cursor: not-allowed;
  color: black;
}

.ConfigBottom {
  display: flex;
  justify-content: flex-end;
}

.ConfigAddNew {
  border: 1px solid #eaecf0;
  border-radius: 0.5rem;
  margin: 2.5rem 0;
}

.AddHeader {
  padding: 1.25rem 0.938rem;
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
  display: flex;
  justify-content: space-between;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
  align-items: center;
}

.AddHeader h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.ConfigAddNew form {
  padding: 1.563rem 0.938rem;
}

.ConfigAddNew form label {
  font-weight: 600;
  color: #334054;
}

.ConfigAddNew form select,
.ConfigAddNew form input,
.ConfigAddNew form div {
  border-color: #eaecf0;
}

.FirstBox {
  display: flex;
  justify-content: space-between;
}

.ConfigAddNew .FirstBox .field {
  width: 47%;
}

.ConfigTime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ConfigStartTime,
.ConfigEndTime {
  display: flex;
  flex-direction: row;
  width: 47%;
  justify-content: space-between;
}

.ConfigStartTime .field,
.ConfigEndTime .field {
  width: 45%;
}

.variant-right div {
  background: #d1fadf;
  padding: 0.438rem 0.625rem;
  border-radius: 1.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: #117f4e;
}

.variant-left .variant-disabled {
  color: #8b0404;
  background: #f6b1b1;
}

.Config-Footer .Delete-btn {
  background: #f65555;
  border-radius: 0.5rem;
  padding: 0.313rem 0.938rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
  margin-left: 1rem;
}

.Config-Footer .Edit-btn {
  background: #4a5d62;
  border-radius: 0.5rem;
  padding: 0.313rem 0.938rem;
  color: white;
  font-size: 0.875rem;
  font-weight: 600;
}

.variant-left div {
  font-size: 0.875rem;
  font-weight: 600;
}

.ConfigDetails .Config-header {
  padding: 1.563rem 0.938rem;
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Config-Content label {
  display: flex;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.Config-Content .ConfigChips {
  background: #fff;
  border: 1px solid #e7e7e7;
  padding: 0.625rem;
  border-radius: 0.25rem;
  font-weight: 500;
  font-size: 1rem;
}

.configWrap {
  margin-bottom: 1.875rem;
}

.ConfigSection {
  margin-bottom: 2.5rem;
}

.ConfigSection:last-child {
  margin-bottom: 1rem;
}

.ConfigSection.ConfigTimeStamp {
  display: flex;
}

.ConfigEndDate {
  margin-left: 2rem;
}

span.ConfigChips {
  background: #ecefff;
  border-radius: 0.438rem;
  margin-right: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  padding: 0.5rem 0.688rem;
}

.variant-left div {
  display: flex;
}

.Config-ID {
  padding: 0.438rem 0.938rem;
  background: #404040;
  color: white;
  border-top-left-radius: 0.5rem;
}

.Config-V {
  padding: 0.438rem 0.938rem;
  background: #ffe42b;
  color: #404040;
  text-transform: capitalize;
}

.Config-Status {
  padding: 0.438rem 0.938rem;
  color: #117f4e;
  background: #d1fadf;
  border-top-right-radius: 0.5rem;
  text-transform: capitalize;
}

.ConfigTimeStamp span {
  font-size: 0.875rem;
  font-weight: 600;
}

.InfoBanner {
  padding: 1.25rem;
  background: #d1fadf;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 1rem;
}

.InfoBanner.InfoError {
  background: #f6b1b1;
  color: #8b0404;
}
