.kml-upload {
  width: 300px;
  margin: 0 auto;
}

.kml-upload input {
  height: 90px;
  opacity: 0;
  cursor: pointer;
}

.kml-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}
