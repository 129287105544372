.apptile-listingpage .mr-1 {
    margin-right: 1rem;
}

.apptile-listingpage .table .table-row .table-cell {
    min-width: 9rem;
}

.apptile-listingpage .table-row a {
    color: #2b3238;
}

.apptile-listingpage .table-row a:hover {
    color: #1557bf;
}

.apptile-listingpage .table .table-row .column-campaign-details {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 23rem;
    justify-content: center;
}

.apptile-listingpage .tile{
    position: relative;
}

.apptile-listingpage .tile :last-child{
    position: absolute;
    left: 0;
    padding: 0.75rem;
    font-size: 0.85rem;
    color: white;
    font-weight: 700;
}

.apptile-listingpage .grid-upper-col {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(9.875rem, 1fr));
    grid-template-rows: 5.875rem;
    gap: 0.7rem;
    width: 21rem;
    margin-bottom: 0.5rem;
}

.apptile-listingpage .grid-lower-col {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(3.375rem, 1fr));
    grid-template-rows: 5.875rem;
    gap: 0.7rem;
    width: 21rem;
    margin-bottom: 0.5rem;
}

.apptile-listingpage .table .table-row .column-img-url img {
    height: 100%;
    width: 100%;
    border-radius: 0.5rem;
}

.apptile-listingpage .column-Expired {
    color: red;
    font-weight: bold;
}

.apptile-listingpage .column-Scheduled,.column-Live {
    color: green;
    font-weight: bold;
}

.apptile-listingpage .pagination-button {
    font-size: .875rem;
    color: #80959d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 1rem 1rem;
    gap: 0.5rem;
}

.apptile-listingpage .pageNo {
    padding: 0.1rem 0.5rem 0.1rem 0.5rem
}

.apptile-listingpage .pagination-button img {
    padding-top: 0.1rem;
}

@media screen and (min-width: 840px) {
    .apptile-listingpage .table .table-cell:last-child {
        text-align: center;
    }
}

@media screen and (max-width: 840px) {
    .apptile-listingpage .table.table-dynamic .table-row {
        width: 100%;
        overflow-x: auto;;
    }
    .apptile-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-img-url::before {
        content: 'Tiles';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .apptile-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-campaign::before {
        content: 'Campaign Name';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .apptile-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-start-date::before {
        content: 'Start Date';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .apptile-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-end-date::before {
        content: 'End Date';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .apptile-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-actions {
        bottom: 0;
        right: 0;
        position: absolute;
    }
}
