.CallForm select {
    margin-bottom: 30px;
    width: 100%;
}
.CallForm button {
    background-color: #1557bf;
    width: 100%;
    color: #ffffff;
}
.CallForm .form-buttons-container:after {
    content: "";
    display: block;
    clear: both;
}