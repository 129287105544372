.package-detail-page .section-row:after {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size: 0;
}

.package-detail-page .section-row-input {
    width: 50%;
    float: left;
    padding-right: 1.25rem;
}

.package-detail-page textarea {
    width: 100%;
    height: 6.25rem;
}

.package-detail-page .label-tooltip {
    font-size: 0.75rem;
    margin-left: 0.5em;
    position: relative;
}

.package-detail-page .label-tooltip .label-tooltiptext {
    visibility: hidden;
    width: 25.75rem;
    background-color: #fff;
    color: #2b3238;
    box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
    padding: 0.625rem 1.25rem 1.25rem 1.25rem;
    position: absolute;
    z-index: 1;
    bottom: 145%;
    left: -3.155rem;
    text-align: left;
}

.package-detail-page .label-tooltip .label-tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 3.375rem;
    margin-left: -0.3125rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

.package-detail-page .label-tooltip:hover .label-tooltiptext {
    visibility: visible;
}



.package-detail-page .assets-summary {
    background-color: #f0f5f8;
    padding: 0.625rem;
    margin-bottom: 1.25rem;
}

.package-detail-page .assets-summary .title {
    margin: 0 0 0.625rem;
}

.package-detail-page .assets-summary .assets-summary-inner {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    color: #2b3238;
    line-height: 2;
}

.package-detail-page .assets-summary .assets-summary-inner .assets-summary-col:first-child {
    width: 50%;
}

.package-detail-page .assets-summary .assets-summary-inner .assets-summary-col-btm {
    font-size: 1rem;
    line-height: 1.5;
}

.package-detail-page .assets-summary .assets-summary-inner .assets-summary-col-btm span {
    display: inline-block;
    background: #80959d;
    color: #fff;
    padding: 0.125rem 0.625rem;
    font-size: 0.875rem;
    margin: 0 0.375rem 0.375rem 0;
}

.package-detail-page .assets-summary .assets-summary-inner .assets-summary-col-btm span.hide {
    display: none;
}

.package-detail-page .assets-summary .loadbtn {
    display: none;
    width: 100%;
    height: 1.25rem;
    cursor: pointer;
}

.package-detail-page .assets-summary .loadbtn.down {
    background: url(./icon/icon_down.svg) center center no-repeat;
    background-size: 1rem;
}

.package-detail-page .assets-summary .loadbtn.up {
    background: url(./icon/icon_up.svg) center center no-repeat;
    background-size: 1rem;
}

.package-detail-page .assets-summary .loadbtn.show {
    display: block;
}

.package-detail-page .assets-summary .assets-summary-inner .assets-summary-col .button {
    margin-top: 0.625rem;
}

.package-detail-page .asset-table-wrap {
    margin-bottom: 2.5rem;
}

.package-detail-page .asset-table-wrap .header-container {
    display: none;
}

.package-detail-page .asset-table-wrap .cell-action {
    font-weight: bold;
    color: #80959D;
    cursor: pointer;
}

.package-detail-page .asset-table-wrap .cell-action.remove {
    font-size: 0.75rem;
    font-weight: bold;
    color: #eb8181;
}

.package-detail-page .assetName {
    cursor: pointer;
    color: #1557bf;
}


@media (max-width: 36rem) {
    .package-detail-page .section-row-input {
        width: 100%;
        padding-right: 0;
    }
}