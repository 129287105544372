.search-campaigns .editPopup {
  max-width: 43.75rem;
}

.search-campaigns .editPopup a {
  color: white;
}

.campaigns-label-popup {
  max-width: 51.25rem;
}

.campaigns-label-popup .dialogPopup .description {
  margin-bottom: 0.625rem;
}

.campaigns-label-popup form {
  max-width: 50rem;
}

.campaigns-label-popup .form-sections {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.25rem;
}

.campaigns-label-popup .form-sections .form-section {
  width: 100%;
}

.campaigns-label-popup .form-sections .form-section .mutistores {
  margin-top: 1.25rem;
}

.Radio .radio-label.selected {
  z-index: 0;
}

.campaigns-label-popup .date-time-section {
  display: flex;
  align-items: center;
}

.campaigns-label-popup .date-time-section .date-section {
  flex: 0.7;
  margin-right: 0.625rem;
}
.campaigns-label-popup .date-time-section .time-section {
  flex: 0.3;
}

.campaigns-label-popup .time-section select {
  color: black;
}

.campaigns-label-popup .form-actions {
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-top: 0.625rem;
  margin: 0rem -1.5625rem;
  border-top: 0.0625rem solid #efefef;
}

.campaigns-label-popup .form-actions .primary {
  margin-left: 0.9375rem;
  margin-right: 1.5625rem;
}

.campaigns-label-popup .Upload .image-upload-div {
  border: none;
}

.campaigns-label-popup .Upload .image-upload-div .file-label {
  background-color: #fbfcfc;
  border: 0.0625rem dashed #dadee0;
}
.campaigns-label-popup .searched-items .labelWrap {
  display: none;
}
.campaigns-label-popup .searched-items {
  padding: 1rem;
  border-bottom-left-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  background-color: #fbfcfc;
  border: 0.0625rem solid #e9eaeb;
  border-top: none;
}
.campaigns-label-popup .seachable-component .Radio .radio-label {
  margin-bottom: 0rem;
}
.campaigns-label-popup .searched-items .Searchable .tag-value,
.campaigns-label-popup .searched-items .multitext-tags .tag-value,
.campaigns-label-popup .multi-select .select-value {
  width: 100%;
  padding: 0rem;
  margin-right: 0rem;
  margin-top: 0.625rem;
  background-color: transparent;
  color: #2b3238;
  display: flex;
  align-items: center;
  height: 1.375rem;
}
.campaigns-label-popup .searched-items .Searchable .tag-value small,
.campaigns-label-popup .searched-items .multi-select .select-value small {
  font-size: 0.875rem;
  font-weight: 400;
}
.campaigns-label-popup .searched-items .Searchable .tag-value::before,
.campaigns-label-popup .searched-items .multitext-tags .tag-value::before {
  content: url(./tag.svg);
  margin-bottom: -0.4375rem;
  margin-right: 0.5rem;
}
.campaigns-label-popup .searched-items .multitext-tags .tag-value::before {
  margin-bottom: -0.625rem;
}
.campaigns-label-popup .searched-items .multitext-tags .tag-value::before {
  margin-bottom: -0.625rem;
}
.campaigns-label-popup .multi-select {
  margin-top: 0.625rem;
}
.campaigns-label-popup .multi-select .select-value::before {
  content: url(./store-ops-inactive.svg);
  margin-bottom: -0.625rem;
  margin-right: 0.5rem;
}

.campaigns-label-popup .searched-items .Searchable .tag-value-icon,
.campaigns-label-popup .searched-items .multitext-tags .tag-value-icon,
.campaigns-label-popup .multi-select .select-value-icon {
  background-image: url(./delete-icon.svg);
  height: 1.125rem;
  width: 1rem;
  position: absolute;
  right: 0rem;
}

.campaigns-label-popup .form-actions .delete-button {
  left: 1rem;
}

.campaigns-label-popup .form-actions .delete-button img {
  margin-right: 0.125rem;
}

.campaigns-label-popup .form-actions .delete-button span {
  margin-top: 0.375rem;
}

.campaigns-label-popup
  .form-section
  .language
  .multi-select
  .select-value::before {
  content: '';
}

.campaigns-label-popup .form-section .language .multi-select {
  margin-top: 0rem;
  width: 107%;
}

.campaigns-label-popup .form-section.language-section .field {
  width: 93%;
}
.cl-logo-upload {
  width: 100% !important
}
.search-campaigns-form .block-section {
  width: 100% !important;
}
.search-campaigns-form fieldset {
  display: block;
  margin-inline-start: 0rem;
  margin-inline-end: 0rem;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 0rem;
  border-style: none;
  border-color: white;
  border-image: none;
}

.search-campaigns-form input[type='file'] {
  opacity: 0;
  cursor: pointer;
}

.search-campaigns-filters .form-fields .form-field {
  width: 50% !important;
}
.search-campaigns-filters .form-fields .form-field .field {
  margin-bottom: 0;
  width: 100%;
}
.search-campaigns-filters .form-fields .searched-items .labelWrap {
  display: none;
}
.search-campaigns-filters .form-fields .searched-items {
  margin: 0 10px 10px 10px;
  padding: 1rem;
  border-bottom-left-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
  background-color: #fbfcfc;
  border: 0.0625rem solid #e9eaeb;
  border-top: none;
}

.campaigns-label-popup .label-tooltip, .with-tooltip .label-tooltip {
  font-size: 0.75rem;
  margin-left: 1em;
  position: relative;
}

.campaigns-label-popup .label-tooltip .label-tooltiptext, .with-tooltip .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 18.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: -3.155rem;
  text-align: left;
}
.campaigns-label-popup .label-tooltip .label-tooltiptext::after, .with-tooltip .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.campaigns-label-popup .label-tooltip:hover .label-tooltiptext, .with-tooltip .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}

@media (max-width: 47.9375rem) {
  .campaigns-label-popup .select-value button {
    min-width: auto;
  }
  .campaigns-label-popup .form-sections .form-section {
    width: 100%;
    margin-bottom: 0.875rem;
  }
  .campaigns-label-popup .form-section:nth-child(odd) {
    margin-right: 0rem;
  }
}

@media (max-width: 30rem) {
  .campaigns-label-popup .DayPicker_transitionContainer__vertical {
    height: 21.875rem !important;
    width: auto;
  }

  .campaigns-label-popup .form-section.date-time-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0rem auto;
  }

  .campaigns-label-popup .date-time-section .date-section {
    width: 100%;
    margin-right: 0rem;
  }

  .campaigns-label-popup .time-section {
    width: 100%;
  }
  .search-campaigns .filters-wrapper .primary,
  .search-campaigns .filters-wrapper .button {
    width: 48%;
  }

  .campaigns-label-popup .search-campaigns-filters .form-actions {
    margin-bottom: 2.8125rem;
    padding-left: 1.25rem;
  }
  .campaigns-label-popup
    .search-campaigns-filters
    .form-actions
    .delete-button {
    left: 40%;
    top: 3.125rem;
  }
  .campaigns-label-popup .form-actions .primary,
  .search-campaigns-popup .form-actions .button {
    flex: 1;
  }
}
