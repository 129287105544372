.appbanner-form .w-100{
    width: 100%;
}

.appbanner-form .rc-time-picker{
    display: flex;
}

.dialogPopup .missingInfoDialog {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    margin-top: -2rem;
}

.dialogPopup .missingInfo {
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
}

.appbanner-form .appFormButtons .primary {
    height: 2.5rem;
    padding: 0 1.25rem;
    font-weight: bold;
    font-size: 0.875rem;
    border: 0.0625rem solid transparent;
    border-radius: 0.125rem;
    letter-spacing: -0.0187rem;
    cursor: pointer;
    text-decoration: none;
    line-height: 2.5rem;
    display: inline-block;
    text-align: center;
}

.appbanner-dialog .primary {
    text-transform: none;
}

@media screen and (max-width: 683px ) {
    .appbanner-form .appFormButtons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (min-width: 683px ) {
    .appbanner-form .grid-2-col {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
        padding: 0 var(--s3);
    }

    .appbanner-form .bannerImage {
        grid-row: 2;
        grid-column: 1 / span 2;
    }

    .appbanner-form .bannerName {
        grid-row: 3;
    }

    .appbanner-form .bannerDeepLink {
        grid-row: 3;
        grid-column: 2/3;
    }

    .appbanner-form .validFrom {
        grid-row: 4;
        grid-column: 1/2;
        display: flex;
        gap: 1rem;
    }

    .appbanner-form .validTo {
        grid-row: 4;
        grid-column: 2/3;
        display: flex;
        gap: 1rem;
    }

    .appbanner-form .bannerPriority {
        grid-row: 5;
    }

    .appbanner-form .appFormButtons {
        grid-row: 6;
        grid-column: 2/3;
        justify-self: flex-end;
    }

    .appbanner-form .appFormButtons .primary {
        margin-left: var(--s3);
    }
}