@media screen and (max-width: 840px) {
    .promotions
      .table.table-dynamic
      .table-row
      .table-cell.column-partner::before {
      content: 'Partner';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      }
}