.apphome-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 10px;
}

.listing-page .apphome-filters .field {
  padding: 0;
  width: 100%;
  margin-bottom: 0;
}
