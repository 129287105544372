.offers-form .store-types {
  margin-bottom: 8px;
}
.offers-form .store-types span {
  border-radius: 5px;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 4px 16px;
  font-size: 14px;
  background-color: #f3f5f6;
  cursor: pointer;
  display: inline-block;
}

.offers-form .selected-items .remove-all {
  color: #1557bf;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.offers-form .product-name {
  margin: 15px 0 0 10px;
}

.offers-form .grid {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.offers-form .form-action {
  margin-top: 40px;
}

.offers-form .qty-cell {
  max-width: 100px;
}

.offers-form .table .table-cell {
  padding-top: 20px;
  padding-block-end: 20px;
}

.offers-form .table .table-cell .input-error-message   {
  position: absolute;
}


.offers-form .delete-button {
  background-image: url('./delete.svg');
  background-color: transparent;
  background-position: right;
  min-width: 0px;
}

.offers-form .offer-fields {
  display: flex;
  flex-wrap: wrap;
  margin-top: 25px;
}

.offers-form .form-fields .customer-level-restrictions, .offers-form .form-fields .field-row
 {
  grid-column-end: span 2
}

.offers-form .form-fields .customer-level-restrictions-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  padding: 1rem 0;
}

.offers-form .form-fields .customer-level-restrictions label.delivery-date {
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.2px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}

.offers-form .form-fields .customer-level-restrictions label.delivery-date + div.grid {
  margin-top: 10px;
}

.offers-form .form-fields .customer-level-restrictions .redemptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.offers-form .offer-fields .shipping-cart input[type='number'] {
  max-width: 100%;
}

.offers-form .offer-fields > div:first-child {
  flex: 1;
  max-width: 500px;
  min-width: 16.5rem;
}

.offers-form .offer-fields > div:not(:last-child) {
  margin-right: 10px;
}
.offers-form.PT-offer .offer-fields > div:not(:last-child) {
  margin-right: 20px;
}

.offers-form .offer-fields input[type='number'] {
  max-width: 150px;
}

.offers-form .product-searchable-max {
  max-width: 500px;
}

.offers-form h3.top-o {
  margin-top: -20px
}

.offers-form textarea {
  height: 161px;
}

.offers-form .table .name {
  text-align: left;
}

.offers-form .delivery-slot-multi-select {
  max-width: 500px;
}

.offers-form .delivery-slot-multi-select .select-field-dropdown-container {
  position: absolute;
  width: 100%;
  z-index: 2;
}

.offers-form .segments-form {
  grid-column-start: 1;
  grid-column-end: 3;
}

.offers-form .segments-form .segment-container .delete-button img {
  display: none;
}

.offers-form .segments-form .coupon-section.coupon-selection {
  width: 100%;
}
.offers-form.PT-offer .offer-fields .field.min-amount {
  width: 48.77%;
}
.offers-form.PT-offer .offer-fields .field.discount-type {
  width: 25%;
  margin-right: 10px;
}
.offers-form.PT-offer .offer-fields > div:first-child {
  max-width: 48.77%;
}
.offers-form.PT-offer .offer-fields .min-amount input[type='number']{
  max-width: 100%;
}
.offers-form.PT-offer .offer-fields {
  flex-wrap: nowrap;
}
.offers-form.PT-offer .offer-fields .field.discount-value {
  width: 22.5%;
}
.offers-form.PT-offer .offer-fields .discount-value input[type='number']{
  max-width: 100%;
}
.offers-form.tag .offer-fields.tag > .input-wrapper > .input-wrapper {
  margin-top: 1.6rem;
}

.offers-form.tag .offer-fields.tag .tag-values ul {
  padding-left:0;
}

.offers-form .offers-form.BMIN .Searchable .tag-value {
  display: none;
}

.offers-form .product-search-BMINXGFG .tag-value {
  display: none;
}

.offers-form .offer-fields div.product-search-BMINXGFG {
  padding: 1rem 0;
  width: 100%;
}

.offers-form .offers-form.BMIN .field.entity-checkbox, .offers-form.BMINXGFG-Offer .field.entity-checkbox.BMINXGFG {
  display: flex;
}
.offers-form .offers-form.BMIN .field.entity-checkbox > span, .offers-form.BMINXGFG-Offer .field.entity-checkbox.BMINXGFG > span {
  order: 1;
}

.offers-form.BMINXGFG-Offer .field.product-searchable {
  max-width: 500px;
}

.offers-form.BMINXGFG-Offer .rule-block {
  width: 100%;
}

.offers-form.BMINXGFG-Offer .rule-block .row > div:not(:last-child) {
  margin-right: 10px;
}

/* .offers-form.BMINXGFG-Offer .rule-block .row > div {
  flex: 1;
} */

.offers-form.BMINXGFG-Offer .rule-block .row input[type='number'] {
  max-width: none !important;
}

.offers-form .delivery-date {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0px;
}

.offers-form.offers-toggle {
  position: absolute;
  top: 0px;
  right: 0px;
}

.offers-form .grid span.download-image img {
  cursor: pointer;
}

.offers-form .metadata-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}
.offers-form .restiction-order-type{
  color: #000;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  margin-bottom: 10px;
  letter-spacing: -0.2px;
}
.offers-form .restiction-order-type .restriction-order-type-warning, .confirmation-modal-warning {
  color: #FF0000;
  text-transform: initial;
}
.offers-form .restriction-payment-type.margin-t{
  margin-top: 1.18rem;
}
.offers-form .field.field-checkbox {
  padding-top: 34px;
}
.offers-form .offers-form.BMIN .offer-fields .Product-search input[type='text']{
  width:200px;
}

.clone-buttons {
  margin-bottom: 1rem;
}

.clone-buttons button {
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0 20px;
  line-height: 2rem;
  border-radius: 2px;
}

.clone-buttons button:not(:last-child) {
  margin-right: 10px;
}

.offers-form .offer-available-redemption {
  font-weight: 600;
}

.offers-form .row {
  display: flex;
  width: 100%;
  position: relative;
}

.offers-form .row .row-element {
  margin-left: 1rem;
}

.offers-form .rule-block {
  border: 1px dashed var(--border-color);
  padding: 1rem 1rem;
  margin-bottom: 0.5rem;
  margin-right: 0 !important;
}

.offers-form .offer-rule-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.offers-form .offer-rule-header .rule-delete-icon {
  margin-left: auto;
  cursor: pointer;
}

.offers-form.BMIN .offer-rule-header, .offers-form.BMINXGFG-Offer .offer-rule-header, .offers-form.BMINXATP-offer .offer-rule-header {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem
}

.offers-form.BMINXGFG-Offer .offer-rule-header {
  top: 0px;
  right: 0px;
}

.offers-form button.add-rule-btn {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.125rem 0.5rem;
  border-radius: 4px;
  float: right;
  margin-left: auto;
}

.offer-form-confirmation-modal {
  max-width: 900px;
}
.offer-form-confirmation-modal ul li span:first-child {
  flex: 0 0 25%;
}

.offer-form-confirmation-modal ul li {
  list-style: none;
  display: flex;
  padding: 5px;
  border-bottom: 1px solid #eee;
}
.offer-form-confirmation-modal ul {
  padding: 0;
  margin: 0;
}

.offer-form-confirmation-modal .segment-list {
  display: block;
  font-size: .75rem;
}
.offer-form-confirmation-modal .summary-list {
  display: block;
  margin-bottom: 0.25rem;
}

.offers-form .form-fields .SingleDatePicker{
  z-index: 2;
}

@media screen and (max-width: 768px) {
  .offers-form .offer-fields div:not(:last-child) {
    margin-right: 0px;
  }

  .offers-form .offer-fields input[type='number'] {
    max-width: 100%;
  }

  .offers-form .metadata-section {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 580px) {
.offers-form.offers-toggle {
  top: -45px;
}
.tabs-offer .tabs .tab{
  padding: 18px 32px ;
}
.offers-form .grid{
  display: block;
}
.offers-form .rc-time-picker-input{
  margin-top: 2px;
}
.offers-form .table{
  width:100%;
  overflow:scroll;
  display: block;

}
}
@media screen and (max-width:480px){
  .offers-form .restriction-payment-type.margin-t{
    margin-top: 70px;
  }
}
@media screen and (max-width:375px){
  .offers-form .restriction-payment-type.margin-t{
    margin-top: 86px;
  }
}
.offers-form input[type="checkbox"] + label {
  font-size: 0.875rem;
  font-weight: 600;
}


