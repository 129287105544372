.app-popup-listingpage .table-row a {
  color: #2b3238;
}

.app-popup-listingpage .table-row a:hover {
  color: #1557bf;
}

.app-popup-listingpage .table .table-row .column-img-url img {
  width: 12.5rem;
  height: 6.25rem;
}

.app-popup-listingpage
  .table.table-dynamic
  .table-row
  .table-cell.column-Expired,
.column-Unpublished {
  color: red;
  font-weight: bold;
}

.app-popup-listingpage
  .table.table-dynamic
  .table-row
  .table-cell.column-Scheduled,
.column-Live {
  color: green;
  font-weight: bold;
}

.app-popup-listingpage .pagination-button {
  font-size: 0.875rem;
  color: #80959d;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin: 1rem 1rem;
  gap: 0.5rem;
}

.app-popup-listingpage .pageNo {
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
}

.app-popup-listingpage .pagination-button img {
  padding-top: 0.1rem;
}

@media screen and (min-width: 840px) {
  .app-popup-listingpage .table .table-cell:last-child {
    min-width: 3rem;
  }

  .app-popup-listingpage .table .table-cell:nth-last-child(2) {
    text-align: center;
    min-width: 3rem;
  }

  .app-popup-listingpage .table .table-cell:nth-last-child(3) {
    text-align: left;
  }
}

@media screen and (max-width: 840px) {
  .app-popup-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-img-url::before {
    content: 'Image';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .app-popup-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-campaign::before {
    content: 'Title';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .app-popup-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-start-date::before {
    content: 'Valid From';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .app-popup-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-end-date::before {
    content: 'Valid To';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .app-popup-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-actions {
    bottom: 0;
    right: 0;
    position: absolute;
  }
}
