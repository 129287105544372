.reasonPopup {
  max-width: 37.5rem;
}

.reasonPopup .header {
  display: none;
}
.reasonPopup h3 {
  margin-top: 0;
}
.reasonPopup .details {
  text-align: left;
  border-radius: 0.3125rem;
}

.reasonPopup.editPopup .details > * {
  padding-bottom: 0;
}
.reasonPopup .details .question {
  font-size: 0.75rem;
  padding-bottom: 0.3125rem;
}
.reasonPopup .details .question.multiple-reason,
.top-gap {
  margin-top: 0.625rem;
}

.reasonPopup.editPopup .button-container {
  display: flex;
  justify-content: flex-end;
}
.reasonPopup.editPopup button.primary {
  margin-right: 1.25rem;
}
.reasonPopup.editPopup button.primary,
.reasonPopup.editPopup button {
  float: none;
  margin-top: 1.25rem;
}
.reasonPopup.editPopup .details {
  padding-bottom: 2.8125rem;
}
.reasonPopup.editPopup .details:last-child {
  padding-bottom: 1.5625rem;
}

.reasonPopup.editPopup .scroll-reasons {
  max-height: 16.875rem;
  overflow: auto;
}
