.customerDetails .tab-container .user-tags .user-tags-form {
    max-width: unset;
    position: relative;
}

.customerDetails .tab-container .user-tags .user-tags-form .input {
    display: inline-block;
    width: 250px;
}

.customerDetails .tab-container .user-tags .Searchable  {
    padding-top: 40px;
    padding-left: 20px;
    padding-right: 0px;
    padding-bottom:10px;
}

.customerDetails .tab-container .user-tags .Searchable .select-field-dropdown {
    width: 250px;
    position: relative;
}