.campaign-list-page .section-row-input{
    width: 50%;
    float: left;
}
.campaign-list-page .checkbox-group.default-skin .checkbox-label {
    min-width: 33%;
}

.campaign-list-page .bulk-btn{
    margin-left: 1rem;
}
