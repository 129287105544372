.delivery-area-content {
    display: flex; 
    background-color: #fbfcfc;
}
.delivery-area-content .map-area {
    position: relative;
}
.delivery-area-content .map-area button.edit-icon{
    position: absolute;
    top: 15px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: #fff;
    right: 10px;
}
.delivery-area-content .map-area button.edit-icon img{
    width: 20px;
    height: 20px;
}
.delivery-area-content .map-element {
    /* border-radius: 2px; */
    overflow: hidden;
    /* transition: border 200ms ease-in-out; */
    height: 615px;
    padding: 5px 0 5px 5px;
}
.delivery-area-content .map-area {
    width: calc(100% - 270px);
}
.delivery-area-content .map-controls {
    padding: 20px;
    width: 270px;
    position: relative;
}

.delivery-area-content .action-buttons {
    position: absolute;
    bottom: 20px;
    width: 230px;
}
.delivery-area-content .action-buttons div {
    display: flex;
    justify-content: space-between;
}
.delivery-area-content .map-controls .delivering-zones.title{
    font-size: 16px;
}
/* .delivery-area-content .form-fields .edit-section {
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #fbfcfc;
} */

.delivery-area-content .zones-subsection {
    padding: 10px 20px 20px;
    background-color: #ffffff;
    border: 1px solid #fbfcfc;
    margin-bottom: 30px;
    position: relative;
}
.delivery-area-content .zones-subsection label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #2b3238;
    margin-bottom: 10px;
}
.delivery-area-content .field {
    margin-bottom: 0;
}
.delivery-area-content .input-wrapper {
    margin: 10px 0 10px 0;
}
.delivery-area-content .zones-subsection .open-close-icon {
    position: absolute;
    top: 10px;
    padding-left: 5px;
    right: 15px;
}
.delivery-area-content .zones-subsection .section-title {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #2b3238;
    margin-bottom: 10px;
}

.delivery-area-content .hidden-section .zones-content {
    display: none;
}
.delivery-area-content .zones-content button.update-group, .delivery-area-content .zones-content button.cancel-update {
    min-width: 80px;
    width: 90px;
}
.delivery-area-content .zones-content .flex {
    justify-content: space-between
}
.delivery-area-content .map-controls .store-select-error {
    color: red;
    padding-left: 5px;
}