.uploadlogo-section {
    display: flex;
    justify-content: space-between;
}

.uploadlogo-section .defaultlogo-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.uploadlogo-section .defaultlogo {
    height: 10.063rem;
    width: 17.399rem;
    border: 1px solid black;
    background: #b4b6b8;
}

.uploadlogo-section .defaultlogo img {
    display: block;
    margin: 3.125rem auto;
    padding: 0.3rem 1rem;
}

.uploadPopup {
    max-width: 62.5rem;
}

.uploadPopup button {
    float: right;
}

.uploadPopup .details:last-child {
    padding-bottom: 3.75rem;
}