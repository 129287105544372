@use 'styles/colors' as *;

.stepper-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem;
  height: 100%;

  .steps-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;

    .single-step-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .step-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        font-size: 1.25rem;
        font-weight: 600;
        margin: 0 0.25rem;
      }

      .clickable:hover {
        cursor: pointer;
      }

      .step-text {
        font-size: 14px;
      }

      &.active {
        .step-number {
          background-color: $fp-blue-1;
          color: $fp-white-0;
        }
        .step-text {
          color: $fp-blue-1;
          font-weight: 600;
        }
      }

      &.inactive {
        .step-number {
          border: 1px solid $fp-grey-2;
          color: $fp-grey-2;
        }
        .step-text {
          color: $fp-grey-2;
          font-weight: 400;
        }
      }

      &.completed {
        .step-number {
          background-color: $fp-grey-2;
        }
      }

      &:after {
        content: '';
        width: 5rem;
        height: 1px;
        margin: 0 0.3rem;
        background-color: $fp-grey-2;
      }

      &:last-child{
        &:after {
          content: none;
        }
      }
    }
  }

  .footer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;

    > * {
      margin: 0 0.5rem;
    }
  }

  button:disabled {
    opacity: 0.4;
  }
}
