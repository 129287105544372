.capacity-planning-form {
  max-width: 990px;
}

.capacity-planning-form form {
  max-width: 100%;
}

.capacity-planning .datepick-wrapper .date-picker button.SingleDatePickerInput_calendarIcon{
  margin: 0 2px 0 1px;
}

.capacity-planning .datepick-wrapper .date-picker .DateInput {
  display: none;
}

.capacity-planning .calender-icon {
  padding: 0 1rem;
  line-height: 2.5rem;
  border: 1px solid #dadee0;
  height: 2.75rem;
  margin: 0 .6rem;

}


.capacity-planning-form .grid {
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.capacity-planning .today-heading {
  line-height: 2.5rem;
  height: 2.75rem;
  padding:  0 1rem;
  text-align: center;
  border: 1px solid #dadee0;
  margin: 0 .6rem;
}

.capacity-planning .table-header .table-cell:nth-child(6) {
  text-align: right;
}

.capacity-planning .table span:hover {
  color: #1557bf;
  cursor: pointer;
}

.capacity-planning-form .form-actions {
  display: flex;
  justify-content: flex-end;
}

.capacity-planning-form .button {
  margin-right: 10px;
}

.capacity-planning-form .delete-button {
  left: 40px;
}
.capacity-planning .mobile-header-slot{
  display: none;
}
.capacity-planning .mobile-header-cutoff{
  display: none;
}
.capacity-planning .mobile-header-capacity{
  display: none;
}

.capacity-planning .header-actions-wrapper .button {
  height: 2.75rem;
}

.capacity-planning .header-actions-wrapper .margin-left {
  margin-left: 0.5rem
}

@media screen and (max-width: 768 px) {
  .capacity-planning-form .grid {
    grid-template-columns: 1fr 1fr;
  }
  .capacity-planning .table-container {
    overflow: auto;
  }
}

@media screen and (max-width: 580px){
  .capacity-planning .datepick-wrapper .date-picker button.SingleDatePickerInput_calendarIcon {
    margin: 0 2px 2px 1px;
}
  .capacity-planning .header-container {
    display: block;
  }

  .capacity-planning .header-actions-wrapper .margin-left {
    margin-left: 0;
  }

  .capacity-planning .header-actions-wrapper {
    display: grid;
    grid-template-columns: auto 1fr 1fr;
    grid-gap: 0.5rem;
    top: 2.8125rem;
    left: -2.5rem;
  }

  .capacity-planning .header-actions-wrapper .button {
    height: 2.75rem;
  }

  .capacity-planning .filters-wrapper {
    margin-top: 20px;
  }
  .capacity-planning .header-actions-wrapper .button span.text {
    display: initial;
  }
  .capacity-planning .header-actions-wrapper .button span.text {
    font-size: 0.875rem;
  }
  .capacity-planning .header-actions-wrapper .search-button-wrapper {
    right: -5%;
    position: absolute;
    top: -5px;
  }
  .capacity-planning .header-actions-wrapper .search-button-wrapper .datepick-wrapper .SingleDatePickerInput_calendarIcon {
   margin: 5px;
  }

.capacity-planning .header-actions-wrapper .datepick-wrapper .SingleDatePickerInput_calendarIcon {
  margin: 0px;
  padding: 8px;
}

  .capacity-planning .header-actions-wrapper .today-heading {
    padding: 0;
    height: 42px;
  }

  .capacity-planning .table-container {
    padding-top: 20px;
    border-top: 1px solid #efefef;
    margin: 20px -30px 0 -30px;
  }
  .capacity-planning .table {
    display: block;
    min-width: 320px;
  }

  .capacity-planning .table .table-row .table-cell {
    border:none;
    padding: 15px;
  }

  .capacity-planning .table .table-row .table-cell div, .capacity-planning .table .table-row .table-cell small {
    font-size: 0.75rem;
  }

  .capacity-planning .table-container .table .table-header {
    display: none;
  } 

  .capacity-planning .table .table-row {
    display: grid;
    grid-template-columns: 2fr 3fr 1fr;
    margin: 0px 30px 15px 30px;
    padding: 10px 0px 0px 0px;
    border: 1px solid#eaefef;
    min-height: 110px; 
    position: relative;
  }

  .capacity-planning .table .table-row:nth-child(2n) {
    background-color: white;
  }

  .capacity-planning .table-row .table-cell{
    height: auto;
    font-weight: 600;
  }

  .capacity-planning .table .table-row .table-cell.capacity-day {
    display: none;
  }

  .capacity-planning .table .table-row .capacity-actions{
    padding-top: 5px;
    padding-bottom: 20px;
    grid-column-start: 3;
    grid-row-start: 1;
  }
  
 .capacity-planning .table .table-row .capacity-zone-name {
  position: absolute;
  top: 12px;
 }
  .capacity-planning .table .table-row .capacity-cutoff-time, .capacity-planning .table .table-row .capacity-slot, .capacity-planning .table .table-row .capacity{
    grid-row-start: 2;
    border-top: 1px solid #eaefef;
    background-color: #fcfcfc;
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .capacity-planning .table .table-row .capacity-cutoff-time p, .capacity-planning .table .table-row .capacity-slot p, .capacity-planning .table .table-row .capacity p{
    margin-top: 2px;
    font-size: 13px;
  }

  .capacity-planning .table-row .capacity-cutoff-time {
    grid-column-start: 1;
  }
  .capacity-planning .table-row .capacity-slot {
    grid-column-start: 2;
  }

  .capacity-planning .table .table-row .table-cell .text-muted {
    font-weight: normal;
    font-size: 11px;
  }
  .capacity-planning-form .grid {
    grid-gap: 0px;
  }
  
  .capacity-planning .table .table-row .capacity-order-type {
    padding-top: 0px;
    grid-column-start: 1;
    grid-column-end: 3;
 }

 .capacity-planning .filters-wrapper {
  margin-bottom: 30px;
}
.capacity-planning .header-actions-wrapper.SingleDatePicker {
  height: 44px;
}
.capacity-planning .header-actions-wrapper .button {
  height: 42px;
}
.capacity-planning .table-cell .capacity-actions .dropdown-item  {
  font-size: 13px;
}
.capacity-planning-form .form-actions .button, .capacity-planning-form .form-actions .primary{
  min-width: 48%;
}
.capacity-planning .filters-wrapper .button, .capacity-planning .filters-wrapper .primary{
  min-width: 48%;
}
}

@media screen and (max-width: 480px) {
  .capacity-planning-form .grid {
    grid-template-columns: 1fr;
  }
  .capacity-planning-form .form-actions {
    position: relative;
    margin-bottom: 35px;
}
.capacity-planning-form .delete-button {
  left: 40%;
  top: 50px;
}
.capacity-planning .mobile-header-slot{
  display: block;
}
.capacity-planning .mobile-header-cutoff{
  display: block;
}
.capacity-planning .mobile-header-capacity{
  display: block;
}
}
