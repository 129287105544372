.stores-page .table-cell {
  height: 70px;
  max-width: 280px;
}

.stores-page .dropdown-menu {
  width: 100px;
}

.stores-page .store-name {
  color: #2b3238;
}

.stores-page .store-name:hover {
  color: #1557bf;
}

.stores-page .table .table-cell .store-mobile-view-header {
  display: none;
}

.stores-page .actions-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stores-page .actions-box button {
  margin-right: 0.6em;
}

@media screen and (max-width: 480px) {
 .stores-page .table-container {
    margin: 0px;
}
  .stores-page .table .table-header {
    display: none;
  }
  .stores-page .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    border:1px solid #eaefef;
    background: #ffffff;
    margin:15px 0;
  }
  .stores-page .table .table-row .table-cell {
    border:none;
    padding: 10px 15px;
    height: auto;
    min-height: 30px;
  }
  .stores-page .table .table-row div.table-cell .store-mobile-view-header {
    display: block;
    font-size: 11px;
    color: #80959d;
    font-weight: normal;
  }
  .stores-page .table .store-has-delivery-hub {
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .stores-page .table .store-actions {
    grid-column-start: 4;
    grid-row-start: 1;
  }
  .stores-page .table .store-name-container {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end:4;    
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .stores-page .table .store-client-id {
    grid-column-start: 3;
    grid-row-start: 2;
  }
  .stores-page .table .store-has-picking {
    grid-column-start: 2;
    grid-row-start: 2;
  }
  .stores-page .table .store-status {
    grid-column-start: 4;
    grid-row-start: 2;
  }
  .stores-page .table .table-cell.store-address {
    grid-column-start:1;
    grid-column-end:4;
    grid-row-start: 1;
    margin-top: 30px;
  }

  .store-form .business-time-row .table-cell:nth-child(2) {
    min-width: 120px;
}
.store-form .business-time-row .table-cell:nth-child(1) {
  min-width: 150px;
}
  .stores-page .table .table-cell.store-has-delivery-hub, .stores-page .table .table-cell.store-client-id,   .stores-page .table .table-cell.store-has-picking, .stores-page .table .table-cell.store-status{
    background-color: #fbfcfc;
    border-top: 1px solid #eaefef;
    font-size: 13px;
  }
  .stores-page .table .store-name-container .store-name {
    font-size: 14px;
    font-weight: 600;
  }
  .stores-page .table .table-cell.store-address {
    font-size: 12px;
    color: #80959d;
    font-weight: normal;
    padding-bottom: 10px; 
    padding-top: 0px; 
  }
  .stores-page .stores-filter .primary, .stores-page .stores-filter .button {
    min-width: 48%;
  }
  .stores-page .table .table-cell.store-has-delivery-hub span, .stores-page .table .table-cell.store-client-id span,   .stores-page .table .table-cell.store-has-picking span,.stores-page .table .table-cell.store-status span{
    font-weight: 600;
  }
} 
