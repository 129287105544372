.returns-popup {
  max-width: 1000px;
}
.return-resolution p {
  font-weight: 600;
  font-size: 0.875rem;
}
.return-resolution form {
  max-width: 100%;
}
.return-resolution .form-action {
 margin-top: 40px;
}
.return-resolution option,
.return-resolution select {
  text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .return-resolution .return-item {
    display: initial;
  }
  .return-resolution p {
    margin-top: 0;
  }
}