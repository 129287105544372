.disputes-popup {
  max-width: 1024px;
}

.disputes-popup form {
  max-width: 100%;
}

.disputes-page .ref-num a {
  color: #2b3238;
}

.disputes-page .ref-num a:hover {
  color: #1557bf;
}

.disputes-popup .form-action {
  margin-top: 40px;
}