.input-error .DateRangePickerInput {
    border: 1px solid #ea353a;
    background-color: #fbf5f5;
    border-radius : 2px;
}

.input-error .DateRangePickerInput_arrow,
.input-error .DateRangePickerInput_calendarIcon {
    background-color: #fbf5f5;
}

.input-error .date-picker .DateInput input {
    border: none;
}
