.multi-dateRangePicker-wrap{
  margin-bottom: 1.25rem;
}

.multi-dateRangePicker{
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.multi-dateRangePicker .field.section-row-input{
  float: none;
  margin-bottom: 0;
}
.multi-dateRangePicker .button{
  margin-left: 0;
  margin-bottom: 0.3rem;
}

.datepick_wrap{
  text-align: left;
}

.datepick_wrap .select-value{
  border-radius: 2px;
  background-color: #80959d;
  display: inline-block;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.01rem;
  text-align: left;
  font-size: 0.75rem;
  margin-right: 5px;
  margin-top: 10px;
  padding: 2px 4px 2px 10px;
}
