.blog-form {
  max-width: initial;
}

.blog-form .title-input .input {
  flex-flow: row wrap;
}

.blog-form .title-input .input-wrapper {
  flex-basis: 100%;
}

.blog-form .title-input input {
  z-index: 1;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.blog-form .title-input .RichEditor {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.blog-form .RichEditor {
  flex-basis: 100%;
}

.blog-form input[type=url] {
  max-width: 140px;
}

.blog-form .editor-actions {
  display: none;
}