.helpCard {
  display: inline-block;
  width: 276px;
  height: 200px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e2e2;
  padding: 25px;
  border-radius: 4px;
  margin: 10px;
  white-space: normal;
  vertical-align: top;
}

.helpCard .card-text {
  color: #9b9b9b;
  font-size: 0.875rem;
}

.helpCard .image-with-title {
  margin-bottom: 25px;
  display: table;
}

.helpCard .image-with-title .title {
  display: table-cell;
  vertical-align: middle;
  font-size: 0.875rem;
  font-weight: 600;
  color: #4a4a4a;
}

.helpCard .image-with-title img {
  display: table-cell;
  width: 40px;
  height: 40px;
  margin-right: 16px;
}

.helpCard .image-with-title::after {
  content: '';
  display: block;
  clear: both;
}

.helpCard .action:hover {
  cursor: pointer;
  color: #1557bf;
}

.helpCard.actions:hover {
  cursor: pointer;
}
