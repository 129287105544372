.extension-popup {
  max-width: 800px;
}
.extension-popup form {
  max-width: 100%;
}
.extension-popup .extension-header {
  display: inline-flex;
  width: 100%;
  justify-content: flex-start;
}
.extension-popup .extension-name {
  font-size: 1.125rem;
  letter-spacing: -0.3px;
  color: #2b3238;
  font-weight: 600;
  flex: 1;
}
.extension-popup .key {
  color: #80959d;
  font-size: 0.75rem;
  font-weight: normal;
  letter-spacing: -0.2px;
}
.extension-popup .payment-amount {
  font-size: 1.125rem;
  font-weight: 600;
  letter-spacing: -0.3px;
  color: #1557bf;
  text-align: left;
}
.extension-popup .payment-details {
  text-align: right;
  margin-top: 10px;
}
.extension-popup img {
  width: 100px;
  height: 100px;
  background-color: #f8f8f8;
}
.extension-popup .extension-details {
  padding-top: 32px;
  display: inline-flex;
  font-size: 0.75rem;
  letter-spacing: -0.1px;
  color: #2b3238;
  margin-bottom: 50px;
}
.extension-popup .details {
  border-radius: 2px;
}
.extension-popup .extension-image {
  padding-right: 20px;
}
.extension-popup .span-header {
  padding: 4px 0;
}
.extension-popup .span-header .value {
  font-weight: 600;
}
.extension-popup .extension-checkbox label {
  color: #2b3238;
  font-weight: normal;
  margin-top: 20px;
}
.extension-popup .form-actions {
  margin-top: 20px;
  justify-content: flex-start;
}
.extension-popup .form-actions .primary {
  float: left;
  min-width: 110px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  width: auto;
  padding: 0 12px;
}
.extension-popup .form-actions button {
  margin-right: 10px;
  font-size: 0.875rem;
  letter-spacing: -0.3px;
  font-weight: bold;
  height: 40px;
  line-height: initial;
}
.extension-dialog {
  display: none;
}
.extension-action .dialogPopup .description {
  padding-bottom: 20px;
}
.extension-action .dialogPopup button {
  line-height: initial;
}
.extension-popup ul {
  padding-left: 15px;
  color: #2b3238;
}
.extension-popup li {
  padding: 10px 6px;
}
.extension-popup .payment-explanation {
  color: #80959d;
  font-size: 0.75rem;
  font-weight: normal;
}
.extension-popup .descheading {
  font-weight: 600;
  color: #2b3238;
  letter-spacing: -0.1px;
  font-size: 0.875rem;
}
.extension-popup .details .extension-details {
  padding: 20px 0 0 0;
  margin-bottom: 0;
  border: 1px solid #eaefef;
  border-width: 1px 0;
  margin-top: 20px;
  width: 100%;
}
.extension-popup .extension-details .description-heading {
  font-size: 0.875rem;
  color: #2b3238;
  letter-spacing: -0.2px;
  padding: 0 0 30px 0;
  line-height: 1.86;
}
.extension-popup .extension-details .optional {
  margin-bottom: 20px;
}
.extension-popup .header {
  background-color: transparent;
  position: absolute;
  width: 35px;
  right: 20px;
}
