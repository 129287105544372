.Product-search {
  display: flex;
}

.Product-search .Searchable {
  flex: 1;
}
.Product-search div:last-of-type {
  flex: 1;
}

.Product-search .searchable-product-details .product-name {
  font-weight: 400;
  font-size: 12px;
  color: #2b3238;
}

.Product-search .searchable-product-details .product-name .bold {
  font-weight: bold;
}

.Product-search .price-holder {
  margin-top: 5px;
}

.Product-search .price-holder .price {
  font-weight: 400;
  color: #645f5f;
}

.Product-search .select-field-dropdown .select-option:hover {
  background-color: #f1f5f2;
  color: inherit;
}

.Product-search .searchable-product-details {
  /* margin: 10px; */
}

.Product-search .select-field-dropdown {
  max-height: 160px;
  height: inherit;
}

.Product-search .searchable-product-details {
  margin-left: 10px;
}

.Product-search .no-inventory .product-name {
  /* padding: 6px 0; */
}

.Product-search select {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Product-search input[type='text'] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.Product-search .Searchable .tag-values {
  margin-bottom: 20px;
}

.Product-search.withInventory .searchable-product-details {
  margin: 0;
  margin-left: 10px;
}