.brands-page .table-row .table-cell {
  height: 70px;
  padding-top: 0;
  padding-bottom: 0;
}

.brands-page .brand-name {
  color: #2b3238;
  cursor: pointer;
}

.brands-page .brand-name:hover {
  color: #1557bf;
}

.brands-page .dropdown-menu {
  width: 140px;
}

.brands-page .brand-header-mobileview{
  display: none;
}
.brands-page .brand-enable-mobileview{
  display: none;
}

@media (max-width: 480px) {
  
  .brands-page .table-container {
    border-top: 1px solid #eaefef;
    margin: 0px -25px;
    padding: 15px 20px;
  }
  .brands-page .table .table-header {
    display: none;
  }
  .brands-page .table .table-row {
    display: block;
    margin-bottom: 15px;
    padding: 10px 0px 0px 0px;
    border: 1px solid#eaefef;
    min-height: 110px; 
    position: relative;
  }

  .brands-page .table .table-row .table-cell div {
    font-size: 0.75rem;
 }

  .brands-page .table-row .table-cell{
    height: auto;
  }

  .brands-page .table-row .table-cell.brand-name {
    color: #2b3238;
    font-weight: 600;
    padding-right: 24px;
    word-break: break-all;
  }

  .brands-page .table .table-row:nth-child(2n) {
    background-color: #fff;
  }
  .brands-page .table .table-row .table-cell{
    border: none;
  }
  /* .brands-page .product-count::before {
    content: 'Product Count: '
  } */

  .brands-page .table-row .brand-status,
  .brands-page .table-row .product-count,
  .brands-page .table-row .brand-actions {
    position: absolute;
  }

  .brands-page .table-row .brand-status {
    right: 6px;
    top: 83px;
  }


  .brands-page .table .table-cell:first-child {
    padding-left: 10px;
    padding-bottom: 57px;
  }

  .brands-page .table-row .product-count{
    bottom: 0px;
  }

  .brands-page .table-row .product-count{
    font-size: 11px;
    width: 100%;
    height: 49px;
    margin-top: -13px;
    background-color: #fbfcfc;
    display: flex;
    align-items: center;
  }
  .brands-page .table-row .table-cell.product-count{
    border-top: 1px solid #eaefef; 
  }
  .brands-page .table-row .product-count span{
    display: flex;
  }

  .brands-page .table-row .product-count .text-muted small{
    color:#2b3238;
    font-weight: bold;
    display: block;
    margin-left: 5px;
  }

  .brands-page .table-row .brand-actions {
    right: -8px;
    top: 14px;
  }
  .brands-page .table-row .brand-name .brand-name-heading{
    position:absolute;
    top:20px;
    left: 70px;
  }
  .brands-page .table-row .brand-actions .DropDown .dropdown-menu
  .dropdown-items .dropdown-item {
    font-size:13px;
  }
  .brands-page .filters-wrapper .primary, .brands-page .filters-wrapper .button{
    width: 48%;
  }
  .brands-page .brand-header-mobileview{
    display: block;
  }
  .brands-page .brand-enable{
    display: none;
  }
  .brands-page .brand-enable-mobileview{
    display: block;
  }
}
