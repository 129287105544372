.instore-config-form .Radio {
    flex-direction: column;
}

.instore-config-form .Radio .radio-label {
    justify-content: left;
    border: none;
    margin-bottom: 0;
    height: 30px;
}

.instore-config-form input[type='submit'] {
    min-width: 110px;
}

.instore-config-form .reset-default-button {
    margin-right: 10px;
}

.instore-settings .header-container {
    display: flex;
    justify-content: space-between;
}

@media (max-width:500px) {
    .instore-settings .header-container {
        flex-direction: column;
    }
    .instore-settings .header-actions-wrapper {
        margin-bottom: 20px;
    }

    .instore-config-form .Radio .radio-label {
        margin-bottom: 0.5rem;
    }
}