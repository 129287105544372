@use 'styles/colors' as *;

.bulk-dispatch-orders-tab {
  padding: 0 1rem;
  height: 20rem;

  .file-upload-wrapper {
    display: flex;
    border: 1px dashed $fp-grey-4;
    background-color: $fp-grey-1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    svg {
      margin-bottom: 2rem;
      fill: none;
    }

    span {
      color: $fp-grey-2;
      font-weight: 600;
    }

    // style changes to common FileUpload component
    .secondary-title {
      margin-bottom: 0.75rem;
    }
    input {
      opacity: 0; // to disable default file input element
      cursor: pointer;
    }
  }

  .dropped-file-and-btn-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .dropped-file-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    border: 1px solid $fp-grey-2;
    border-radius: 2px;

    .file-name {
      font-size: 0.8rem;
    }

    .status {
      color: $fp-white;
      font-size: 0.8rem;
      border-radius: 1rem;
      padding: 0.1rem 0.5rem;

      &.success-status {
        background-color: $fp-green;
      }

      &.error-status {
        background-color: $fp-red-1;
      }
    }

  }
  .invalid-csv-error {
    font-size: 0.8rem;
    color: $fp-red-1;
  }

  .dispatch-button {
    margin-top: 0.5rem;
  }

  .bulk-dispatch-result-card {
    display: flex;
    flex-direction: column;

    h4 {
      text-decoration: underline;
      margin-bottom: 0.5rem;
    }

    .field-display-line {
      display: flex;
      flex-direction: row;

      span:first-child {
        width: 4rem;
        font-weight: bold;
      }
    }

    .styled-table {
      border-collapse: collapse;
      margin: 0.5rem 0;
      font-size: 0.9em;
      min-width: 400px;
      width: 100%;
      height: 100%;

      thead tr {
        background-color: $fp-grey-2;
        color: $fp-white-0;
        th {
          padding: 0.75rem 0;
        }
      }

      thead th, tbody td {
        border: 1px solid $fp-white-2;

        &.align-center {
          text-align: center;
          padding: 0.5rem;
        }
      }

      tbody tr:nth-of-type(even) {
          background-color: $fp-white;
      }
      tbody {
        tr {
          padding: 1rem;
        }
      }
    }
  }
}
