.editPopup.fileUpload-box{
  max-width: 43.75rem;
}
.editPopup.fileUpload-box .tip{
  font-size: 0.75rem;
  margin-bottom: 0.625rem;
}
.Media-SKU-upload{
  width: 100%;
}
.Media-SKU-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 0.25rem;
  padding: 2rem;
  position: relative;
}
.Media-SKU-upload input {
  opacity: 0;
  cursor: pointer;
}

.Media-SKU-upload .download-link {
  display: inline-block;
  margin-top: 0.625rem;
  font-size: 0.75rem;
}
.Media-SKU-upload .FileUpload .upload-button-container{
  display: flex;
  justify-content: center;
}