.manage-banners .banner-image,
.manage-banners .image-size {
  width: 100%;
  align-content: center;
  position: relative;
  margin: 0px auto;
  cursor: grab;
}

.manage-banners-sortable-helper img {
  width: 100%;
  max-height: 240px;
}

.manage-banners-sortable-helper .banner-image-fields,
.manage-banners-sortable-helper .table-action,
.manage-banners-sortable-helper .banner-image small a {
  display: none;
}

.manage-banners .banner-image small a {
  text-decoration: none;
}

.manage-banners .table .table-row:nth-child(2n) {
  background-color: white;
}

.manage-banners .banner-image .banner-image-fields {
  font-size: 0.75rem;
  color: #2b3238;
  padding: 5px;
  margin-top: 6px;
}

.grid-view-tiles .banner-image .banner-image-fields {
  display: none;
}

.grid-banner-info {
  display: none;
  font-size: 12px;
  margin-top: -6px;
  line-height: 1.6;
}

.grid-view-tiles .grid-banner-info {
  display: block;
}

.banner-sequence {
  display: none;
}

.grid-view-tiles .banner-sequence {
  display: block;
  position: absolute;
  font-size: 10px;
  top: 12px;
  left: 12px;
  border-radius: 50%;
  background-color: white;
  font-weight: bold;
  padding: 4px 8px;
  border: 5px solid #eaefef;
}

.manage-banners .banner-image .banner-image-fields span {
  margin-right: 8px;
  color: #80959d;
  line-height: 1.6;
}

.manage-banners .banner-image .banner-image-fields .scope span {
  margin-right: 18px;
}

.grid-view-tiles .table > div:first-of-type {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
}

.grid-view-tiles.row-4 .table > div:first-of-type {
  grid-template-columns: repeat(4, 1fr);
}

.manage-banners .table .table-row {
  display: block;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  border: 1px solid #eaefef;
  margin-bottom: 15px;
}

.manage-banners .table .table-row .table-cell.table-action {
  position: absolute;
  bottom: 12px;
  right: 14px;
  border: none;
}

.manage-banners .table .table-cell {
  border: none;
  display: table;
  padding: 10px;
  margin: 0px;
}

.grid-view-tiles .table .table-cell {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.manage-banners .table .view-on-web {
  position: absolute;
  top: 15px;
  right: 15px;
  border-radius: 2px;
  background-color: #f3f5f6;
  padding: 5px 10px;
}

.manage-banners .table .table-row .DropDown .dropdown-toggle {
  padding: 15px;
  margin: -15px;
}

.manage-banners .table .view-on-web a {
  font-size: 0.75rem;
  font-weight: 600;
  color: #2b3238;
}

.manage-banners.draggable-listing-page .draggable-actions {
  padding-top: 0px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.manage-banners.draggable-listing-page .draggable-actions button {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  text-align: center;
  padding: 0;
}

.grid-view-tiles .table .table-row .table-cell.table-action {
  display: none;
}

.grid-view-tiles .table-cell .banner-image .banner-image-fields{
  display: none;
}

.grid-view-tiles .banner-image .image-size {
  height: 74px;
  object-fit: cover;
}

.grid-view-tiles .table .table-cell .lg-padding-left {
  padding-left: 0;
}
@media (max-width: 480px) {
  .manage-banners .banner-image .banner-image-fields {
    position: inherit;
  }

  .manage-banners .DropDown .dropdown-toggle {
    padding: 0px;
    margin: auto;
  }

  .manage-banners .banner-image {
    position: inherit;
  }

  .manage-banners .primary.button span {
    display: none;
  }

  .manage-banners .banner-image-fields {
    max-width: 95%;
  }
  .manage-banners .table .table-row .table-cell small,
  .manage-banners .table .table-row .table-cell div {
    font-size: 0.75rem;
  }

  .manage-banners .table .table-row .table-cell.table-action {
    right: 8px;
  }
}

.inline-block {
  display: inline-block;
}

.lg-padding-left {
  padding-left: 25px;
}
