.manage-banners .editPopup {
    max-width: 700px;
}

.manage-banners .editPopup a{
    color: white;
}

.manage-banners-popup {
    max-width: 820px;
}

.manage-banners-popup .dialogPopup .description {
    margin-bottom: 10px;
}

.manage-banners-popup form {
    max-width: 800px;
}

.manage-banners-popup .form-sections {
    display: flex;
    flex-wrap: wrap;
}

.manage-banners-popup .form-section:nth-child(odd) {
    margin-right: 26px;
}

.manage-banners-popup .form-sections .form-section {
    width: 48%;
}

.manage-banners-popup .form-sections .form-section .mutistores {
    margin-top: -11px;
}

.manage-banners-popup .Radio .radio-label.selected {
    z-index: 0;
}

.manage-banners-popup .date-time-section {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.manage-banners-popup .date-time-section .date-section{
    flex: .7;
    margin-right: 10px;
}
.manage-banners-popup .date-time-section .time-section{
    flex: .3;
}

.manage-banners-popup .time-section select {
    color: black;
}

.manage-banners-popup .form-actions {
    justify-content: flex-end;
    align-items: center;
    position: relative;
    padding-top: 10px;
    margin: 0px -25px;
    border-top: 1px solid #efefef; 
}

.manage-banners-popup .form-actions .primary{
    margin-left: 15px;
    margin-right: 25px;
}

.manage-banners-popup .Upload .image-upload-div {
    border: none;
}

.manage-banners-popup .Upload .image-upload-div .file-label {
    background-color: #fbfcfc; 
    border: 1px dashed #dadee0;
}
.manage-banners-popup .searched-items .labelWrap {
    display: none;
}
.manage-banners-popup .searched-items {
    padding: 16px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background-color: #fbfcfc;
    border: 1px solid #e9eaeb;
    border-top: none;
}
.manage-banners-popup .seachable-component .field{
    margin-bottom: 0px !important;
}
.manage-banners-popup .seachable-component .Radio .radio-label{
    margin-bottom: 0px;
}
.manage-banners-popup .searched-items .Searchable .tag-value,
.manage-banners-popup .searched-items .multitext-tags .tag-value,
.manage-banners-popup .multi-select .select-value{
    width: 100%;
    padding: 0PX;
    margin-right: 0px;
    margin-top: 10px;
    background-color: transparent;
    color: #2b3238;
    display: flex;
    align-items: center;
    height: 22px;
}
.manage-banners-popup .searched-items .Searchable .tag-value small,
.manage-banners-popup .searched-items .multi-select .select-value small{
    font-size: 0.875rem;
    font-weight: 400;
}
.manage-banners-popup .searched-items .Searchable .tag-value::before,
.manage-banners-popup .searched-items .multitext-tags .tag-value::before {
    content: url(./tag.svg);
    margin-bottom: -7px;
    margin-right: 8px;
}.manage-banners-popup .searched-items .multitext-tags .tag-value::before {
    margin-bottom: -10px;
}
.manage-banners-popup .multi-select{
    margin-top: 10px;
}
.manage-banners-popup .multi-select .select-value::before {
    content: url(./store-ops-inactive.svg);
    margin-bottom: -10px;
    margin-right: 8px;
}

.manage-banners-popup .searched-items .Searchable .tag-value-icon,
.manage-banners-popup .searched-items .multitext-tags .tag-value-icon,
.manage-banners-popup .multi-select .select-value-icon {
    background-image: url(./delete-icon.svg);
    height: 18px;
    width: 16px;
    position: absolute;
    right: 0px;
}

.manage-banners-popup .form-actions .delete-button {
    left: 16px;
}

.manage-banners-popup .form-actions .delete-button img{
    margin-right: 2px;
}

.manage-banners-popup .form-actions .delete-button span{
    margin-top: 6px;
}

.manage-banners-popup .form-section .language .multi-select .select-value::before {
    content: '';
}

.manage-banners-popup .form-section .language .multi-select {
    margin-top: 0px;
    width:107%;
}
.manage-banners-popup .cx-import-tooltip{
    display: block;
    margin-top: 0px;
    margin-left: 69px;
    margin-bottom: -18px;
    z-index: 10;
}
.manage-banners-popup .cx-import-tooltip .tooltip-title {
    font-weight: 600;
}
.manage-banners-popup .cx-import-tooltip .tooltiptext {
    visibility: hidden;
    width: 300px;
    background-color: #fff;
    color: #2b3238;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
    padding: 10px 20px 20px 20px;
    position: absolute;
    z-index: 1;
    bottom: 200%;
    left: -49px;
    text-align: left;
  }
  .manage-banners-popup .cx-import-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 54px;
    margin-left: -5px;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
  }
  .manage-banners-popup .cx-import-tooltip:hover .tooltiptext {
    visibility: visible;
  }

  .manage-banners-popup .form-section.language-section .field{
      width:93%;
  }
@media (max-width: 767px) {
    .manage-banners-popup .select-value button{
        min-width: auto;
    }
    .manage-banners-popup .form-sections .form-section {
        width: 100%;
        margin-bottom: 14px;
    }
    .manage-banners-popup .form-section:nth-child(odd) {
        margin-right: 0px;
    }
}

@media (max-width: 480px) {
    .manage-banners-popup .DayPicker_transitionContainer__vertical {
        height: 350px !important;
        width: auto;
    }
    
    .manage-banners-popup .form-section.date-time-section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        margin: 0px auto;

    }

    .manage-banners-popup .date-time-section .date-section {
        width: 100%;
        margin-right: 0px;
    }

    .manage-banners-popup .time-section {
        width: 100%;
    }
    .manage-banners .filters-wrapper  .primary, .manage-banners .filters-wrapper .button {
        width: 48%;
      }

      .manage-banners-popup .banner-edit-form .form-actions {
          margin-bottom: 45px;
          padding-left: 20px;
      }
      .manage-banners-popup .banner-edit-form .form-actions .delete-button {
            left: 40%;
            top: 50px;
      }
      .manage-banners-popup .form-actions .primary, .manage-banners-popup .form-actions .button{
          flex: 1;
      }
}