.catalogue-dashboard .count-info {
    display: flex;
    justify-content: center;
}

.catalogue-dashboard .nameValueCard {
    max-width: 263px;
}

.catalogue-dashboard .products-info {
    margin-top: 60px;
    display: flex;
}

.catalogue-dashboard .Warning {
    flex-grow : 1;
    font-size: 0.875rem;
}

.catalogue-dashboard .Warning:first-child {
    margin-right: 20px;
}

.catalogue-dashboard .helpWidget h2 {
    margin-bottom: 10px;
    margin-top: 60px;
}

.catalogue-dashboard .products-info:empty {
    display: none;
}

.catalogue-dashboard .pages-link {
    color: #ffffff;
}

@media (max-width: 1024px) {
    .catalogue-dashboard .Warning {
       margin-bottom : 10px;
       margin-right: 20px;
    }

    .catalogue-dashboard .count-info {
        flex-direction: column;
    }

    .catalogue-dashboard .nameValueCard {
        margin : 0 auto;
    }

    .catalogue-dashboard .nameValueCard:not(:first-child) {
        margin-top: 10px;
    }
}

@media (max-width: 560px) {
    .catalogue-dashboard .products-info {
        flex-direction: column;
    }
}