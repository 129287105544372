.searchLinkByVc {
  button {
    color: white;
    background-color: #1557bf;
    font-size: 0.875rem;
    border: 1px solid #eaefef;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  .inputWrappers {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
}

.searchLinkResult {
  .linkAcc,
  .linkAccHeading {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
  }
  .linkAcc:hover {
    cursor: pointer;
  }
  .linkAccHeading {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  .accItem {
    border-top: 1px solid #eaefef;
    border-bottom: 1px solid #eaefef;
    padding: 1rem 0;
    background-color: #fbfcfc;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .accItem:nth-of-type(n + 2) {
    color: black;
  }
}
