.attendace-summary-page .table-header .table-cell:first-child {
    background-color: #fff;
}
.attendace-summary-page .table-header .table-cell {
    border: 0px 0px 0px 1px;
}

.attendace-summary-page .table .table-header {
    display: flex;
    border-bottom: 1px solid #eaefef;
    border-left: 1px solid #eaefef;
    border-right: 1px solid #eaefef;
    height: auto;
}

.attendace-summary-page .table .table-cell:last-child {
    text-align: left;
}

.attendace-summary-page .table .table-cell {
    padding: 15px 20px;
    flex-grow: 1;
}

.attendace-summary-page .table .table-header:first-child {
    border-left: 0px;
    border-right: 0px;
}

.attendace-summary-page .table .table-header:first-child .table-cell{
    border: 0px;
}

.attendace-summary-page .table .table-header:first-child .table-cell:first-child{
    /* width: 100px; */
    background-color: #fff;
    box-shadow: none;
}

.attendace-summary-page .table-header .table-cell:first-child {
    box-shadow:inset -2px 0px 2px 0px rgba(0, 0, 0, 0.04);
    background-color: #fbfcfc;
}

.attendace-summary-page .table .table-header:first-child, .attendace-summary-page .table-header .table-cell:first-child {
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    text-align: left;
    color: #2b3238;
}

.attendace-summary-page .table .previous-button, .attendace-summary-page .table .next-button {
    width: 25px;
    height: 25px;
    opacity: 0.49;
    border-radius: 2px;
    background-color: #f3f5f6;
    margin-left: -30px;
    margin-top: 16px;
    line-height: 0;
}


.attendace-summary-page .table .table-header:first-child .table-cell:nth-child(5){
    padding-left: 15px;
}

@media screen and (max-width: 768px) {
   .attendace-summary-page .table-header .table-cell:first-child {
       max-width: 180px;
   }
   .attendace-summary-page .table .table-cell {
       padding: 15px 15px;
   }
}
@media screen and (max-width: 480px) {
    .attendace-summary-page .table-container {
        overflow-x: auto;
    }
}
