.radial-form .hidden-location input, .radial-form .hidden-location .labelWrap {
    display: none;
}

.radial-form .hidden-location {
    margin-bottom: 0;
}
.radial-form .distance-middle-text {
    align-self: center;
    font-size: 12px;
    padding: 10px;
    color: #80959d;
}
.radial-form .distance-description {
    font-size: 12px;
    color: #2b3238;
}
.radial-form .zones-content .map-description {
    color: red;
}
.radial-form .zones-content .location-search {
    margin-top: 10px;
}
.radial-form .radial-zones-list .list-item {
    font-size: 12px;
    position: relative;
    margin-bottom: 5px;
}
.radial-form .radial-zones-list {
    margin-top: 10px;
}
.radial-form .radial-zones-list .list-item span{
    height: 10px;
    width: 10px;
    margin-right: 9px;
    margin-bottom: -1px;
    display: inline-block;
}
.radial-form .radial-zones-list .list-item .delete-radial {
    position: absolute;
    right: -6px;
    top: -1px;
    display: none;
}
.radial-form .radial-zones-list .list-item:hover .delete-radial {
    display: block;
}