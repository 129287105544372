.editPopup.calendar-box{
  max-width: 50rem;
}
.editPopup.calendar-box .clickable{
  cursor: pointer;
}
.editPopup.calendar-box .table .table-cell:last-child{
  width: 90%;
}

.editPopup.selectedPackage-box{
  max-width: 50rem;
}

.editPopup.selectedPackage-box .tip{
  font-size: 0.75rem;
  margin-top: 0;
}

.editPopup.selectedPackage-box .btns{
  margin-top: 1rem;
}
.editPopup.selectedPackage-box .SelectedPackage-table{
  overflow-y: auto;
  max-height: 30rem;
}
.editPopup.selectedPackage-box .btn.delete{
  display: inline-block;
  width: 1rem;
  height: 1.125rem;
  background: url(../../commonMedia/images/delete-icon.svg) no-repeat;
  background-size: 100%;
  cursor: pointer;
}


.media-package-discovery .button.goback{
  margin-left: 1rem;
}

.media-package-discovery .title .light{
  font-weight: 300;
  color: #80959d;
  font-size: 1.4rem;
}

.media-package-discovery .asset-summary-wrap{
  margin: -0.625rem 0 1.25rem;
}


.media-package-discovery .summary-info {
  display: flex;
  justify-content: center;
}

.media-package-discovery .nameValueCard {
  max-width: 26.4rem;
}

/* slot table */
.media-package-discovery .slotWrap{
  margin-top: 1.25rem;
}
.media-package-discovery .slotWrap .slot-headercolumn{
  display: inline-block;
  width: 12rem;
  vertical-align: top;
}
.media-package-discovery .slotWrap .slot-innerbox{
  display: inline-block;
  width: calc(100% - 12rem);
  white-space: nowrap;
  overflow-x: auto;
  scroll-behavior: smooth;
}
.media-package-discovery .slotWrap .slot-innerbox .table{
  width: 8rem;
  display: inline-block;
}
.media-package-discovery .slotWrap .table .table-cell{
  height: 3.125rem;
  line-height: 1.2;
}
.media-package-discovery .slotWrap .table .table-cell:last-child{
  text-align: center;
}

.media-package-discovery .slotWrap .table .table-cell.green{
  background-color: #e9fbf1;
}
.media-package-discovery .slotWrap .table .table-cell.red{
  background-color: #feeeed;
}
.media-package-discovery .slotWrap .table .table-cell.booked{
  cursor: pointer;
}

.media-package-discovery .slotWrap .table .table-cell.doubleline{
  padding-top: 0.3125rem;
  padding-bottom: 0.375rem;
}
.media-package-discovery .slotWrap .headerColumn .table-cell:last-child{
  width: 4rem;
  min-width: 4rem;
  padding: 0 1rem;
}
.media-package-discovery .slotWrap .headerColumn .table-cell:last-child .name{
  width: 10rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  cursor: pointer;
}
.media-package-discovery .slotWrap .headerColumn .navigation{
  display: flex;
  justify-content: space-between;
}

.media-package-discovery .navigation-button {
  display: inline-block;
  margin: 0 0.125rem;
  height: 1.125rem;
  min-width: 1.5rem;
  padding: 0 0.25rem;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  color: #80959d;
  border: 0 solid transparent;
  transition: background 0.2s ease-in-out;
  vertical-align: bottom;
}

.media-package-discovery .navigation-icon {
  display: inline-block;
  vertical-align: middle;
}


@media (max-width: 36rem) {
  .media-package-discovery .section-wrap {
      flex-wrap: wrap;
  }
  .media-package-discovery .summary-info {
      flex-wrap: wrap;
  }
  .media-package-discovery .nameValueCard {
      margin : 1.25rem auto 0;
  }
}