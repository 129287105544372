@use 'styles/colors' as *;

.deprecated-message-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  background-color: $fp-red-5;
  padding: 0.25rem 0.5rem;
  width: fit-content;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}
