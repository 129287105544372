.store-selector-select-form {
    min-height: 2.5rem;
    position: absolute;
    top: 4.9375rem;
    left: 5.625rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.625rem 1.6875rem;
    width: calc(100% - 5.625rem);
    background-color: rgba(21, 87, 191, 0.7);
    font-weight: bold;
    color: #fff;
    font-size: 0.8rem;
    padding-left: 1.875rem;
    max-width: 15rem;
    z-index: 3;
    border-right: 1px solid #e2e2e2;
}

.store-selector-select-form.search  {
    background: #ffffff;
    border-top: 1px solid #e2e2e2;
}

.store-selector-select-form .default-store {
    position: absolute;
    color: black;
    font-weight: 700;
    font-size: 1;
    text-transform: capitalize;
    padding-right: 2rem;
}

.store-selector-select-form.search:after {
    content: "";
    margin-top: 5px;
    right: -5px;
    background-image: url('../tick-icon.svg');
    position: absolute;
    width: 1.25rem;
    height: 0.625rem;
    margin-right: 1.25rem;
}

.store-selector-select-form .input-wrapper {
    position: relative;
    left: -1.875rem;
    top: 2.2rem;
    z-index: 5;
    background: #ffffff;
}

.store-selector-select-form .Searchable {
    width: 15rem;
    background: #ffffff;
    padding-bottom:.5rem;
    border-right: 1px solid #e2e2e2;
}

.store-selector-select-form .Searchable .input {
    width: 12.25rem;
    margin: 0 auto;
    display: block;
}


.store-selector-select-form .select-field-dropdown {
    box-shadow: -.5rem .5rem 0.8125rem 0 rgba(0, 0, 0, 0.1);
    border-right: none;
}
.store-selector-select-form .select-field-dropdown .select-option  {
    margin-bottom: 0;
    background-color: #fff;
    padding: .9375rem 0.5625rem;
    font-weight: normal;
    font-size: 0.8rem;
    color: #2b3238;
    cursor: pointer;
    letter-spacing: -0.3px;
    margin: 0 1.25rem;
}

.store-selector-select-form .select-field-dropdown .select-option:not(:last-child) {
    border-bottom: 1px solid #eaefef;
}

.store-selector-select-form .select-field-dropdown-container {
    z-index: 10;
}

.store-selector-select-form input#store:focus {
    border-color: #dadee0;
}