.campaignDetails-page {
.preorder-form-align {
  display: flex;
  width: 100%;
}

.preorder-form-align1 {
  width: 100%;
  margin-right: 0.625rem;
}

.storeSelector {
  width: 99%;
}

.preorder-form-date {
  width: 25%;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
}

.preorder-form-align-date {
  display: flex;
}

.divider-container {
  display: flex;
  align-items: center;
  margin-top: 2.8125rem;
  margin-bottom: 2.8125rem;
}

.divider-border {
  border-bottom: 0.063rem solid #eaeaea;
  width: 100%;
}

.preOrder-input-error-message {
  color: #ea353a;
  margin-top: 0.25rem;
  font-size: 0.70rem;

}
.preOrderField{
    margin-bottom: 0 !important;
}}