.product-input__wrapper {
  position: relative;
  border: 1px solid #dadee0;
  padding: 0.25rem 1rem;
  border-radius: 2px;
  height: 2.5rem;
  display: flex;
  align-items: center;
  max-width: 32.5rem;
}

.add-product__label {
  display: block;
  margin-bottom: 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
}

.add-product-btn {
  position: absolute;
  right: 0.5625rem;
  width: 4.125rem;
  height: 2rem;
  background: #f3f5f6;
  font-weight: 700;
  border-radius: 0.25rem;
}

#product-input {
  outline: none;
  border: 0;
  width: 100%;
  padding-right: 4.125rem;
}

.sku-list {
  display: flex;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  margin-top: 0.75rem;
  flex-wrap: wrap;
}

.added-sku {
  background-color: #84949c;
  color: #f4f5f6;
  width: fit-content;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem;
}

.remove-sku {
  margin-left: 0.6875rem;
  font-weight: 600;
  cursor: pointer;
}
