.website form {
  max-width: 100%;
}

.website .title {
  margin: 15px 0 20px;
}
.popup .button-white {
  float: right;
 }

.website h2 {
  margin: 0 0 30px 0;
  font-weight: normal;
  font-size: 1.5rem;
}
.website img {
  max-width: 100%;
}
.website .layout img {
  width: 100%;
}
.website .box {
  width: 410px;
  max-width: 100%;
  margin: 80px auto auto;
  text-align: center;
}
.website .box .primary {
  margin-left: 10px;
}

.website .current-theme {
  background-color: #f0f5f8;
  padding: 28px 24px;
  margin-bottom: 50px;
  text-align: center;
}
.website .current-theme .media-figure {
  background: url('./image-placeholder.svg') no-repeat center;
  height: 180px;
}
.website .box img {
  margin-bottom: 30px;
}

.website .current-theme h2 {
  color: #1557bf;
  margin-bottom: 12px;
}
.website .current-theme p {
  font-size: 0.875rem;
  color: #80959d;
  margin-bottom: 28px;
}
.website .theme h3 {
  font-size: 1.125rem;
  line-height: 1.6;
  font-weight: normal;
}
.website .theme p {
  font-size: 0.875rem;
  color: #80959d;
  height: 3.9375rem; /* 3 * 1.5 * 0.875rem */
  line-height: 1.5;
  text-overflow: ellipsis;
  overflow: hidden;
}
.website .theme-preview {
  padding: 10px;
  border: 1px solid #eaefef;
  border-radius: 4px;
  position: relative;
}
.website .theme-preview .actions {
  position: absolute;
  top: 10px;
  right: 10px;
  bottom: 10px;
  left: 10px;
  background-color: rgba(43, 50, 56, 0.2);
  margin: auto;
  transition: opacity 100ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}
.website .theme-preview:hover .actions {
  opacity: 1;
}
.website .add-layout-section {
  margin-bottom: 30px;
  text-align: center;
  color: #e7e9ea;
}
.website .add-layout-btn {
  font-size: 0.75rem;
  color: #80959d;
}
.website .layout-option {
  text-align: center;
  position: relative;
}
.website .layout-option h3 {
  font-size: 0.75rem;
  text-transform: uppercase;
  margin: 0 auto 6px;
}
.website .layout-option .button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.website .layout-option:after {
  content: '';
  display: table-cell;
  clear: both;
}

/* tabs override */
.website .tab {
  text-transform: capitalize;
}

/* Layouts */
.layout {
  border: 1px solid #eaefef;
  padding: 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: relative;
}
.layout.ImageSlideShow .emptyState {
  background-color: #fee;
  border: 1px solid #f8e6e6;
  height: 100%;
}
.layout .slide{
  width: 100%;
  text-align: center;
}
.layout .slide img{
  display: inline-block;
}
.layout.editable:after {
  content: '';
  position: absolute;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  background-color: rgba(43, 50, 56, 0.8);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.layout.editable:hover:after {
  opacity: 1;
}
.layout .toolbar {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
}
.layout .toolbar .button {
  margin-right: 20px;
}
.layout .toolbar .primary {
  background-color: #f3f5f6;
  color: #2b3238;
}
.layout .toolbar .button:last-child {
  margin-right: none;
}
.layout:hover .toolbar {
  display: block;
}
.layout .emptyLayout {
  background-color: #fff;
  height: 300px;
  text-align: center;
  line-height: 300px;
}
.layout-footer {
  text-align: center;
  margin-top: 20px;
}
.layout-footer .primary {
  margin-left: 10px;
}
.website .editLayout {
  margin: 30px -40px 0;
  padding: 40px;
  padding-bottom: 0;
  background-color: #fbfcfc;
  border: 1px solid #e7e9ea;
  border-width: 1px 0;
}

.website .chosenAnimation {
  animation: beInFocus 400ms ease-in-out, setFocus 200ms ease-in-out 400ms;
  animation-fill-mode: forwards;
}
.website .revealAnimation {
  opacity: 0;
  animation: reveal 300ms ease-in-out 400ms;
  animation-fill-mode: forwards;
}

@keyframes setFocus {
  to {
    border: 1px dashed #1557bf;
  }
}

@keyframes beInFocus {
  to {
    transform: none;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
    transform: translate3d(0, -10%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}
.layoutsContainer {
  background-color: #f4f7f9;
  box-shadow: inset 0 0 10px #e7e9ea;
  margin-bottom: 40px;
}
.layoutItem {
  background-color: #fff;
  padding-top: 30px;
  cursor: move;
  cursor: grab;
}
.layoutItem h3 {
  margin: 0 0 10px;
}
.is-dragging {
  z-index: 1;
  cursor: -webkit-grabbing;
  opacity: 0.8;
}

.is-dragging img {
  width: 100%;
}

.website .field label {
  flex-grow: 1;
}
.website .field .labelWrap img {
  margin-left: 12px;
}
.website input[type="color"] {
  height: 44px;
  width: 88px;
}
.website textarea {
  margin-bottom: 0px;
}
.website .taxonomy-select {
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}
.website .taxonomy-select select {
  cursor: pointer;
}
.website .taxonomy-select span label {
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 600;
  color: #2b3238;
  display: inline-block;
  text-transform: capitalize;
}

.website .themes-customize .themes-customize-heading {
  display: grid;
  grid-template-columns: 11fr 1fr;
}
.website .themes-customize .button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.website .themes-customize button.primary {
  margin-left: 20px;
}
.add-new-layout-popup {
  max-width: 700px;
}
.add-new-layout-popup .details {
  min-height: 550px;
}
.add-new-layout-popup .layout-main-content {
  display: flex;
}
.add-new-layout-popup .layout-main-content ul {
  padding: 10px !important;
  margin: 0px;
  flex: 1;
  list-style: none;
  text-align: left;
  padding: 0px;
  color: black;
}
.add-new-layout-popup .layout-main-content ul li {
  color: #2c3b56;
  font-size: 14px;
  line-height: 25px;
  cursor: pointer;
}
.add-new-layout-popup .layout-main-content .ui-components .layout-option {
  max-height: 200px;
  max-width: 400px;
  margin: 10px;
  cursor: pointer;
  position: relative;
}
.add-new-layout-popup .layout-main-content .ui-components h3 {
  margin: 10px;
} 
.add-new-layout-popup .layout-option .toolbar {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 1;
}
.add-new-layout-popup .layout-option:hover .toolbar {
  display: block;
}
.add-new-layout-popup .layout-option:hover:after {
  opacity: 1;
}
.add-new-layout-popup .layout-option:after {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: rgba(43, 50, 56, 0.8);
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.add-new-layout-popup .layout-main-content ul .active-layouts {
  color: #459eed !important;
}

.category-collection .form-sections .category-tag-searchable {
  display: grid;
  grid-template-columns: .4fr 1fr;
}

.website .themes-customize .layout-scheduler-controller {
  display: flex;
  align-items: flex-start;
  margin-top: 0.5rem;
}

.website .themes-customize .layout-scheduler-controller.column {
  flex-wrap: wrap;
}

.website .themes-customize .layout-scheduler-controller .inline-block {
  display: flex;
  flex: 1;
}

.website .themes-customize .layout-scheduler-controller.column .inline-block {
  width: 100%;
  margin-bottom: 1rem;
}

.website .themes-customize .layout-scheduler-controller .layout-delete-btn {
  display: inline-block;
  align-self: center;
  margin-left: auto;
 }

.website .themes-customize .layout-scheduler-controller .delete-btn img {
  height: 25px;
  margin-top: 10px;
}

.website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown {
  margin: 0 0 0 auto;
  width: 18rem;
}

.website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown label {
  font-size: 14px;
  margin-bottom: 6px;
  font-weight: 600;
  color: #2b3238;
  display: inline-block;
}

.website .themes-customize .layout-scheduler-controller .layout-scheduler-container.bottom {
  order: 2;
  width: 100%;
}

.website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown .dropdown-toggle {
  margin: 0;
  background-color: #fafafa;
  display: flex;
  justify-content: space-between;
  border: 1px solid #cccccc;
  font-size: 12px;
  padding: 11px 10px;
}

.website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown .dropdown-menu {
  width: 100%;
}

.website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown .dropdown-item {
  font-size: 12px;
}

.website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown .dropdown-item .layout-time {
  display: inline-block;
  margin: 0 0.25rem;
  color: #777777;
}

.website .themes-customize .layout-scheduler-container {
  display: inline-flex;
  margin-top: 0.5rem;
}

.website .themes-customize .layout-scheduler-container .timeline-block {
  display: flex;
}

.website .themes-customize .layout-scheduler-container .timeline-block:last-child {
  margin-left: 2rem;
}

.website .themes-customize .layout-scheduler-container .timeline-block .field {
  margin-left: 1rem;
}

.website .themes-customize .layout-scheduler-container .timeline-block .field .input-wrapper select {
  height: 46px;
}
.website .themes-customize .layout-scheduler-container .timeline-date > label {
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 600;
    color: #2b3238;
    display: block;
    line-height: 1.41;
}
.website .themes-customize .filter-checkbox {
  margin-top: 30px;
  margin-right: 30px;
}

@media screen and (min-width: 768px) {
  .website .theme-preview .actions {
    background-color: rgba(43, 50, 56, 0.8);
    opacity: 0;
  }
  .website .current-theme {
    text-align: left;
  }
  .website .themes {
    margin: 0 -1rem;
  }
  .website .theme {
    float: left;
    margin: 0 1rem 3rem 1rem;
    width: calc(50% - 2rem);
  }
  .website .media-figure {
    margin-right: 30px;
  }
  .editLayout .form-sections {
    margin: 0 -15px;
  }
  .ImageSlideShow .form-sections, .VideoCardCarousel .form-sections {
    margin: -1px 0 40px;
    padding-top: 30px;
    background-color: #fff;
    border: 1px solid #e7e9ea;
    border-radius: 0 0 2px 2px;
  }
  .editLayout .form-sections:after {
    content: '';
    display: block;
    clear: both;
  }
  .editLayout .form-section {
    width:  calc(50% - 30px);
    margin: 0 15px;
    float: left;
  }
  .layout-footer {
    text-align: right;
  }
}
@media screen and (max-width:480px){
  .website .themes-customize .layout-scheduler-controller {
    display: block;
  }
  .website .themes-customize .layout-scheduler-controller .inline-block {
    display: block;
  }
  .website .themes-customize .layout-scheduler-controller .layout-timeline-dropdown {
    margin: 10px 0 0 0;
    width: 100%;
  }
  .website .taxonomy-select {
    margin-top: 10px;
  }
  .website .tabs .tab {
    padding: 18px 32px;
  }
}