.apptiles-form .w-100{
    width: 100%;
}

.apptiles-form .mb-1{
    margin-bottom: 1rem;
}

.apptiles-form .mb-2{
    margin-bottom: 2rem;
}

.apptiles-form .add-tile {
    border: dotted var(--border-color) 1px;
    height: 29.688rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1.1rem;
}

.apptiles-form .tile .tile-label {
    display: flex;
    align-items: center;
}

.apptiles-form .tile .tile-label span {
    font-size: 0.875rem;
    text-transform: none;
    font-weight: normal;
    margin-bottom: 0.625rem;
    letter-spacing: -0.0125rem;
    display: inline-block;
    color: #2b3238;
}

.apptiles-form .tile .tile-label button {
    margin-bottom: 0.8rem;
}

.apptiles-form .tile .input-wrapper {
    margin-bottom: 0.8rem;
}

.apptiles-form .tile .Upload .image-upload-div {
    height: 12.813rem;
}

.apptiles-form .tile :nth-child(3) {
    margin-bottom: 1rem;
}

.apptiles-form .rc-time-picker{
    display: flex;
}

.apptiles-form .appFormButtons .primary {
    height: 2.5rem;
    padding: 0 1.25rem;
    font-weight: bold;
    font-size: 0.875rem;
    text-transform: capitalize;
    border: 0.0625rem solid transparent;
    border-radius: 0.125rem;
    letter-spacing: -0.0187rem;
    cursor: pointer;
    text-decoration: none;
    line-height: 2.5rem;
    display: inline-block;
    text-align: center;
}

.apptiles-form .grid-2-col {
    display: grid;
    grid-template-columns: repeat(2, minmax(23.25rem,1fr));
    align-items: center;
    gap: 1rem;
}

.apptiles-form .grid-3-col {
    display: grid;
    grid-template-columns: repeat(3, minmax(15.25rem,1fr));
    align-items: center;
    gap: 1rem;
}

.apptiles-form .tileduration{
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 683px ) {
    .apptiles-form .appFormButtons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .apptiles-form .campaignName{
        width: 100%;
    }
    .apptiles-form .tiles {
        overflow-x: scroll;
    }
}

@media screen and (min-width: 683px ) {
    .apptiles-form .campaignName{
        width: 49.5%;
    }

    .apptiles-form .validFrom {
        grid-column: 1/2;
        display: flex;
        gap: 1rem;
    }

    .apptiles-form .validTo {
        grid-column: 2/3;
        display: flex;
        gap: 1rem;
    }

    .apptiles-form .appFormButtons {
        display: flex;
        justify-content: flex-end;
    }

    .apptiles-form .appFormButtons .primary {
        margin-left: var(--s3);
    }
}

@media screen and (min-width: 895px ) {
    .apptiles-form .tileduration{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 1rem;
    }
}