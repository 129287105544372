@use 'styles/colors' as *;

.bulk-locations-result-card {
  display: flex;
  flex-direction: column;

  h4 {
    text-decoration: underline;
    margin-bottom: 0.5rem;
  }

  .field-display-line {
    display: flex;
    flex-direction: row;

    span:first-child {
      width: 4rem;
      font-weight: bold;
    }
  }

  .result-table {
    border-collapse: collapse;
    margin: 0.5rem 0;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    height: 100%;

    thead tr {
      background-color: $fp-grey-2;
      color: $fp-white-0;
      th {
        padding: 0.75rem 0;
      }
    }

    thead th, tbody td {
      border: 1px solid $fp-white-2;

      p {
        color: $fp-red-1;
      }

      &.center-aligned {
        text-align: center;
        padding: 0.5rem;
      }
    }

    tbody tr:nth-of-type(even) {
        background-color: $fp-white;
    }
    tbody {
      tr {
        padding: 1rem;
      }
    }
  }
}
