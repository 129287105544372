.table {
  display: table;
  width: 100%;
}

.table .table-row {
  display: table-row;
  color: #2b3238;
  font-size: 0.875rem;
  transition: box-shadow 0.2s ease-in-out;
}

.table .table-caption {
  display: table-caption;
}

.table .table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 0.9375rem 0.625rem;
}

.table .table-header .table-cell {
  vertical-align: baseline;
}

.table .table-cell.zone-prefix.align-right {
  margin-top: 10px;
  float: right;
  text-align: right;
}

.table .table-cell.align-right {
  text-align: right;
}

.table .table-cell:last-child {
  text-align: right;
  padding-right: 1.25rem;
}

.table .table-header {
  display: table-row;
  color: #80959d;
  font-size: 0.75rem;
  font-weight: 600;
  height: 2.375rem;
}

.table .table-row:nth-child(2n) {
  background-color: #fbfcfc;
}

.table .table-row .table-cell {
  border-top: 1px solid #eaefef;
}

.table .table-row:last-child .table-cell {
  border-bottom: 1px solid #eaefef;
}

.table .table-row .no-row {
  border: none !important;
  padding: 0 0.625rem !important;
}

.table .actions-link {
  font-size: 1.2rem;
  color: #80959d;
  font-weight: 700;
  cursor: pointer;
}

.table .table-row:hover {
  position: relative;
}

@media (max-width: 840px) {
  .table.table-dynamic {
    display: flex !important;
    width: auto;
    margin: -0.625rem;
    flex-flow: row wrap;
  }

  .summary-listing-page .table.table-dynamic {
    margin: 0;
  }

  .table.table-dynamic .table-header {
    display: none;
  }

  .table.table-dynamic .table-row {
    min-height: 8.4375rem;
    width: calc(50% - 1.25rem);
    display: flex;
    flex-direction: column;
    box-shadow: none !important;
    border: 1px solid #eaefef;
    border-radius: 0.125rem;
    background-color: #ffffff !important;
    margin: 0.625rem !important;
    position: relative;
  }

  .table.table-dynamic .table-row .table-cell {
    padding: 1.25rem;
    border: none !important;
  }

  .summary-listing-page .table .table-cell:last-child {
    text-align: left;
    padding-right: 0;
  }
}

@media (max-width: 580px) {
  .table.table-dynamic {
    flex-flow: column wrap;
  }

  .table.table-dynamic .table-row {
    flex-grow: 1;
    flex-flow: column wrap;
    width: 100%;
    margin: -1px 0 0 0 !important;
    min-height: 7.8125rem;
  }

  .table.table-dynamic .table-row .table-cell {
    padding: 0.9375rem;
  }
}

@media screen and (max-width: 380px) {
  .table .table-cell {
    padding: 0.9375rem 0.5rem;
  }
}
