.actions-section {
  display: flex;
  gap: 1rem;
}

.preview-container {
  display: flex;
  flex-direction: column;
}

.filteredcards-section {
  display: flex;
  gap: 0.5rem;
  margin-bottom: 1rem;
  overflow-x: auto;
}

.previewcard-popup {
  max-width: 650px;
}

.previewcard-popup .buttons-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  margin-top: 1rem;
}

.previewcard-popup .text-section {
  color: #80959d;
  font-size: 0.75rem;
  text-align: center;
}

.previewcard-popup .empty.text-section {
  font-size: revert;
}

.appcard-popup {
  max-width: 40.5rem;
}

.appcard-listingpage .pagination-button {
  font-size: 0.875rem;
  color: #80959d;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin: 1rem 1rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

@media screen and (min-width: 840px) {
  .appcard-listingpage .table .table-cell:last-child {
    text-align: center;
  }
}

@media screen and (max-width: 840px) {
  .appcard-listingpage .table.table-dynamic .table-row {
    width: 100%;
    overflow-x: auto;
  }
  .appcard-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-name::before {
    content: 'Name';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .appcard-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-category::before {
    content: 'Category';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .appcard-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-start-date::before {
    content: 'Start Date';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .appcard-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-end-date::before {
    content: 'End Date';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
    margin-bottom: 0.5rem;
  }
  .appcard-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-actions {
    bottom: 0;
    right: 0;
    position: absolute;
  }
}
