.nameValueCard {
    display: block;
    width: 100%;
    text-align: center;
    min-width: 160px;
}

.nameValueCard:not(:last-child) {
    margin-right: 40px;
}

.nameValueCard .value {
    width: 100%;
    height: 82px;
    border-radius: 4px;
    padding-top: 24px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: -13px;
}

.nameValueCard .name {
    max-width: 100%;
    min-width: 80px;
    height: 26px;
    border-radius: 4px;
    letter-spacing: -0.2px;
    line-height: 25px;
    color: #ffffff;
    font-size: 12px;
    padding: 0 10px;
    display: inline-block;
    font-weight: 600;
}
.nameValueCard .lightgreen {
    background-color: #e9fcf1;
}
.nameValueCard .green {
    background-color: #7bd49d;
}
.nameValueCard .lightgray {
    background-color: #f0f5f8;
}
.nameValueCard .gray {
    background-color: #80959d;
}
.nameValueCard .lightred {
    background-color: #ffeeee;
}
.nameValueCard .red {
    background-color: #eb8181;
}
.nameValueCard .lightblue {
    background-color: #e5f6ff;
}
.nameValueCard .blue {
    background-color: #45c0ff;
}