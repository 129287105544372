.scheduled-jobs-page .pagination-container {
  display: none;
}

.scheduled-jobs-page .table .table-cell.scheduledDate {
  text-align: left;
}
@media screen and (max-width: 1440px) {
  .scheduled-jobs-page .table-row .table-cell.filename {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: zoom-out;
  }
  .scheduled-jobs-page .table-row .table-cell.filename:hover .tooltip {
    visibility: visible;
    opacity: 1;
    max-width: fit-content;
    font-size: 11px;
  }
}
