.product-upload {
  width: 300px;
  margin: 0 auto;
}

.product-upload input {
  height: 90px;
  opacity: 0;
  cursor: pointer;
}

.product-upload .download-link {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 0.75rem;
}

.product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.product-upload .border-text {
  display: inline-block;
  position: absolute;
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  padding: 6px;
  background: white;
  transform: translate(-50%, -50%);
  top: 0px;
  left: 50%;
}

.product-upload .add-product-link {
  margin-bottom: 48px;
}

.product-upload .instruction {
  font-size: 0.75rem;
  margin-top: 10px;
}

.cx-import-tooltip {
  font-size: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.cx-import-tooltip .tooltip-title {
  font-weight: 600;
}
.cx-import-tooltip .tooltiptext {
  visibility: hidden;
  width: 300px;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 20px 20px 20px;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 50px;
  text-align: left;
}

.cx-import-tooltip img {
  padding-right: 5px;
  padding-bottom: 2px;
}

.cx-import-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 54px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.cx-import-tooltip:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 480px) {
.product-upload {
  width: 100%;
}
  .product-upload .bordered-box {
    width:100%;
  }
}