.order-details h1.heading {
  font-weight: 300;
  color: #80959d;
}

.order-details h1.heading .reference-number {
  color: #2b3238;
}

.order-details .invoice-button {
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  background-image: url('./invoice-icon.svg');
  background-position: left 10px center;
  padding: 0 0 0 40px;
  background-repeat: no-repeat;
  background-size: 18px;
  height: 28px;
  border-width: 0;
  cursor: pointer;
}

.order-details .invoice-button a{
  color: #80959d;
}

.order-details .batch-icon {
  background-image: url('./batch.png');
  margin-right: 0.625rem;
}

.order-details .print-icon {
  background-image: url('./print.png');
  margin-right: 10px;
}

.order-details .DropDown {
  display: inline-block;
}

.order-details .dropdown-menu {
  top: 34px;
}

.order-details .order-actions-dropdown {
  padding-left: 0.8rem;
}

.order-details .order-actions-dropdown .dropdown-items {
  width: 160px;
}

.order-details .actions-link {
  color: #80959d;
  font-weight: 700;
}

.order-details .edit-icon {
  cursor: pointer;
}

.order-details .ButtonWithOptions {
  margin-right: 10px;
}

.order-details .order-summary-wrapper {
  padding: 48px 30px;
  border-width: 0 1px;
  border-style: solid;
  border-color: #eaefef;
  background-color: #ffffff;
  position: relative;
}

.order-details .order-summary-wrapper::before {
  position: absolute;
  height: 18px;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  content: '';
  background-image: url(./sawtooth-pattern.svg);
  background-repeat: repeat-x;
  background-position: top left 15px;
}

.order-details .order-summary-wrapper::after {
  position: absolute;
  height: 18px;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  content: '';
  background-image: url(./sawtooth-pattern.svg);
  background-repeat: repeat-x;
  background-position: bottom left;
}

.order-details .section-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0 0 20px 0;
  line-height: 28px;
}
.order-details .slot-title {
  margin-bottom: 0;
  margin-top: 5px;
}
.order-details .section-title h3, .order-details .section-title h5 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.order-details .section-title.title {
  margin: 10px 0;
}
.order-details .section-title h5 {
  font-size: 0.875rem;
  margin-top: 10px;
}

.order-details .order-status-pending {
  color: #f88c8c;
}

.order-details .order-status-completed {
  color: #1557bf;
}

.order-details .user-details {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.order-details .address-details {
  width: 260px;
}

.order-details .customer-name {
  color: #1557bf;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

.order-details .order-placement-info-item {
  min-width: 280px;
  padding: 12px 0;
  background-image: linear-gradient(to right, #eaefef 80%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.order-details .order-placement-info-icon {
  margin-right: 10px;
  display: inline-block;
  height: 24px;
  width: 24px;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.order-details .icon-shield {
  background-image: url('./shield-icon.svg');
}

.order-details .icon-clock {
  background-image: url('./clock-icon.svg');
}

.order-details .icon-mobile {
  background-image: url('./mobile-icon.svg');
}

.order-details .creation-time,
.order-details .completion-time,
.order-details .device-name,
.order-details .batch-info,
.order-details .verification-status {
  color: #2b3238;
  font-weight: 600;
}

.order-details .batch-info-reassigned {
  color: #AEB9BD;
  font-weight: 600;
}

.order-details .customer-actions {
  display: flex;
  margin-top: 10px;
}

.order-details .customer-address,
.order-details .customer-addresses,
.order-details .store-address {
  font-size: 0.875rem;
  color: #80959d;
}

.order-details .customer-address.customer-meta {
  font-weight: 600;
}

.order-details .customer-addresses {
  margin-top: 24px;
  margin-bottom: 20px;
}

.order-details .order-items-view {
  margin-top: 48px;
}

.order-details .retry-message {
  text-align: center;
  cursor: pointer;
}

.order-details .button {
  min-width: 80px;
  padding: 0 16px;
}

.order-details .location-options .radio-label {
  height: 28px;
}

.order-details .location-options .radio-label-text {
  font-size: 1.125rem;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.order-details .customer-addresses .Radio {
  flex-flow: row wrap;
}

.order-details .customer-addresses .radio-label {
  flex-direction: row-reverse;
}

.order-details .customer-addresses .radio-label::before {
  margin: 0 0 0 10px;
}

.order-details .customer-addresses .radio-label-text {
  font-size: 0.875rem;
  color: #80959d;
  font-weight: normal;
}

.order-details .delivery-option-buttons:not(:empty) {
  margin-top: 20px;
}

.order-details .address-details .customer-heading {
  margin-bottom: 10px;
}

.orders-page .order-type-icon-pickup,.orders-page .order-type-icon-delivery{
  border-radius: 50%;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  padding:5px 10px;
}
.orders-page .order-type-icon-pickup{
  background-color: #FFEEEE ;
}
.orders-page .order-type-icon-delivery{
  background-color: #E9FCF1;
}
.orders-page .container-order-number{
  display: flex;
}
.orders-page .order-number,.orders-page .store-name{
  margin-left: 10px;
}
.order-details .payment-details .payment-card {
  margin-right: 10px;
}
.order-details .payment-details .payment-card-number .image-sm img {
  width: 30px;
  height: 30px;
}
.order-details .order-actions .order-actions-web-view {
  display: inline;
}

.order-details .order-actions .order-actions-mobile-view {
  display: none;
}

@media screen and (max-width: 550px) {
  .order-details .order-placement-details {
    margin-bottom: 48px;
  }
  .order-details .order-placement-details,
  .order-details .address-details {
    width: 100%;
  }
  .order-details .order-summary-wrapper {
    margin: 0 -30px;
    padding-top: 20px;
    border-top: 1px solid #eaefef;
  }
  .order-details .order-actions .order-actions-mobile-view {
    display: inline;
  }
  .order-details .order-actions .order-actions-web-view {
    display: none;
  }
  .order-details .order-metadata-details .form-action, .order-details .delivery-option-buttons{
    display: flex;
  }
  .order-details .order-metadata-details .form-action .primary, .order-details .order-metadata-details .form-action .button, .order-details .delivery-option-buttons .button{
    flex: 1;
  }
}

@media screen and (max-width: 480px) {
  .order-details .print-icon {
    margin-right: 0;
  }
  .order-details .order-actions-dropdown {
    padding-left: 0.8rem;
  }
  .order-actions {
    text-align: right;
    margin-bottom: 10px;
  }
  .order-details .order-summary-wrapper::before, .order-details .order-placement-info-item, .order-details .order-summary-wrapper::after {
    background-image: none;
    padding: 12px 0 0px;
  }
  .order-details .order-placement-details, .order-details .address-details {
    border: solid 1px #eaefef;
    background-color: #fbfcfc;
    padding: 8px 15px 12px;
  }
  .order-details .order-placement-details .section-pre-order h3{
    font-size: 14px;
    letter-spacing: -0.26px;
    margin-bottom: 10px;
  }
  .order-summary-wrapper .order-placement-details .section-pre-order {
    border-bottom: solid 1px #eaefef;
  }
  .order-details .order-placement-details {
    margin-bottom: 20px;
}
.order-details .address-details .customer-heading,.order-details .address-details .flex-around.section-title.title {
    font-size: 14px;
    letter-spacing: -0.26px;
    margin: 10px 0px;
}
.order-details .address-details .flex-around.section-title.title {
  margin-top: 0px;
  padding-bottom: 10px;
  border-bottom: solid 1px #eaefef;
}
.order-details .address-details .slot-details {
  margin-top: 15px;
  border-top: 1px dashed #eaefef;
}
.order-details .address-details .slot-details .section-title h5 {
  margin-top: 8px;
}
.order-details .customer-addresses {
  margin-top: 0px;
}
.order-details .section-title {
  margin-bottom: 10px;
}
.order-details .location-options .radio-label-text {
  font-size: 14px;
}
.order-details .collapse-container-order, .order-details .collapse-container-metadata,.order-details .collapse-container-payment, .order-details .collapse-container-refund{
  border-top: 1px solid #eaefef;
  /* padding-top: 20px; */
  position: relative;
  display: flex;
  align-items: center;
  margin: auto -30px;
  padding: 0px 30px;
}
.order-details .collapse-container-order {
  margin-top: 20px;
}

.order-details .order-items-view .flex-around.section-title h3, .order-details .order-metadata-details .flex-around.section-title h3, .order-details .payment-details .section-title h3 {
  margin-left: 30px;
  font-size: 0.875rem;
}
.order-details .collapse-container-order button, .order-details .collapse-container-metadata button, .order-details .collapse-container-payment button, .order-details .collapse-container-refund button{
  position: absolute;
  font-size: 18px;
  font-weight: 600;
  color: #80959d;
}
.order-details .collapse-container-order.false button {
  top: 20px;
  z-index: 10;
}
.order-details .collapse-container-metadata.false button, .order-details .collapse-container-payment.false button, .order-details .collapse-container-refund.false button {
  top: 15px;
  z-index: 10;
}
.order-details .collapse-container-order h3, .order-details .collapse-container-metadata h3,.order-details .collapse-container-payment h3, .order-details .collapse-container-refund h3{
  margin-left: 30px;
  font-size: 0.875rem;
}
.order-details .order-items-view .table{
  margin-top: 15px;
}
.order-details .section-title {
  margin-top: 15px;
  margin-bottom: 15px;
}
.orders-page .table .table-cell .order-type-icon-delivery, .orders-page .table .table-cell .order-type-icon-pickup {
  margin-right: 10px;
}
}
