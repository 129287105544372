.screen {
  border: 1px solid black;
  width: 10.12rem;
  height: 21.163rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .top {
    position: absolute;
    top: 0;
  }

  .center {
    position: absolute;
    top: 25%;
  }

  .animation {
    position: absolute;
    top: 35%;
  }

  .bottom {
    position: absolute;
    bottom: 0;
  }

  img {
    width: 100%;
  }
}
