.delivery-area-page .add-delivery-area .DropDown .dropdown-item:hover {
    font-weight: 600;
}

.delivery-area-page .add-delivery-area .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    min-width: 180px;
}

.delivery-area-page .add-delivery-area .dropdown-item {
    padding-right : inherit;
}

.delivery-area-page .add-delivery-area .button {
    min-width: 180px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}