@use 'styles/colors' as *;

.zone-picking .page-header {
  display: flex;
  justify-content: space-between;
}

.zone-picking .table {
  margin-top: 2.5rem;
}

.zone-picking .sub-heading {
  background: $fp-white-3;
  padding: 0.625rem;
  font-size: 0.8rem;
  width: 100%;
  position: absolute;
  box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.zone-picking .sub-heading span {
  display: inline-block;
  margin-left: 2.5rem;
}

.zone-picking .sub-heading .curve-box{
  background: $fp-grey-2;
  color: #FFFF;
  width: 120px;
  height: 20px;
  text-align: center;
  border-radius: 10px
}

.zone-picking .offset-top .table-cell {
  padding-top: 3.125rem;
}

.zone-picking .table .table-cell:last-child {
  text-align: left;
}

.zone-picking .table .table-row:nth-child(2n) {
  background-color: $fp-white-0;
}

@media screen and (max-width: 580px){
  .zone-picking .page-header {
    display: block;
  }
}
