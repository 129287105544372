.media-adset-list-page .title .light{
    font-weight: 300;
    color: #80959d;
}

.media-adset-list-page .lineitem-list .link-btn{
    cursor: pointer;
}
.media-adset-list-page .lineitem-list .link-btn:hover{
    color: #1557bf;
}

.media-adset-list-page .adset-summary-wrap{
    margin: -1.875rem 0 1.875rem 0;
}
.media-adset-list-page .adset-summary-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.media-adset-list-page .adset-summary-inner {
    display: flex;
    flex-wrap: wrap;
    background-color: #f0f5f8;
    padding: 0.625rem;
}
.media-adset-list-page .adset-summary-inner .adset-summary-item:not(.description){
    width: 50%;
}
.media-adset-list-page .adset-summary-inner .adset-summary-item.description{
    width: 100%;
}
.media-adset-list-page .adset-summary-inner .black{
    color: #000;
}
.media-adset-list-page .adset-summary-inner .advertiser-name{
    background: #fff;
    margin: 0 0.375rem;
    padding: 0.125rem 0.375rem;
    display: inline-block;
    border-radius: 0.375rem;
}
.media-adset-list-page .adset-table-title{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}


.Advertiser-box.editPopup{
    max-width: 43rem;
}
.Advertiser-box.editPopup .table .table-cell{
    width: 50%;
}
.media-adset-list-page .download-button,
.Advertiser-box .download-button{
  display: inline-block;
  margin-left: 1.25rem;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.25rem;
}
.media-adset-list-page .upload-button,
.Advertiser-box .upload-button{
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.25rem;
  background: url(../commonMedia/images/upload_normal.svg) no-repeat center;
  background-size: 100%;
}
.media-adset-list-page .upload-button:hover,
.Advertiser-box .upload-button:hover{
  background: url(../commonMedia/images/upload_hover.svg) no-repeat center;
  background-size: 100%;
}


@media (max-width: 36rem) {
    .media-adset-list-page .adset-summary-inner .adset-summary-item:not(.description){
        width: 100%;
    }
}