.payment-configuration h4 {
  margin: 0;
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.payment-configuration .cancel-button {
  margin: 20px 0;
}

.payment-configuration .gateway-choices {
  display: flex;
  border: 1px solid #eaefef;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border-radius: 2px;
}

.payment-configuration .gateway-choice {
  flex: 1;
  position: relative;
  padding: 30px;
  display: flex;
  flex-flow: column nowrap;
}

.payment-configuration .gateway-choice:not(:first-child) {
  border-left: 1px solid #eaefef;
}

.payment-configuration .gateway-choice .description {
  color: #80959d;
  font-size: 0.75rem;
  margin: 0 0 36px 0;
}

.payment-configuration .gateway-choice .button-container {
  margin-top: auto;
}

.payment-configuration .gateway-choice .border-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  background-color: white;
  font-size: 0.875rem;
  height: 34px;
  width: 34px;
  line-height: 34px;
  text-align: center;
  z-index: 1;
  border-radius: 50%;
  border: 1px solid #eaefef;
}

.payment-configuration .gateway-configurations {
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border-bottom: 0;
  overflow-x: hidden;
}

.payment-configuration .gateway-configuration {
  border-bottom: 1px solid #f5f5f5;
}

.payment-configuration .gateway-configuration .header {
  padding: 12px 24px;
  display: flex;
}

.payment-configuration .gateway-configuration .content-container {
  border-top: 1px solid #f5f5f5;
  max-height: 0;
  height: auto;
  overflow: hidden;
  transition: max-height .2s ease-in-out;
  margin-bottom: -1px;
}

.payment-configuration .gateway-configuration.expanded .content-container {
  max-height: 800px;
  border-bottom: 1px solid #f5f5f5;
  width: 100%;
}

.payment-configuration .gateway-configuration-form {
  max-width: initial;
}

.payment-configuration .gateway-configuration-form .form-fields {
  display: flex;
  flex-flow: row wrap;
  margin-left: -50px;
}

.payment-configuration .gateway-configuration-form .basic-section {
  /* flex-basis: ; */
  width: 320px;
  margin-left: 50px;
  margin-bottom: 40px;
}

.payment-configuration .gateway-configuration-form .portal-selection-section {
  /* flex-basis: ; */
  padding-left: 50px;
}

.payment-configuration .gateway-configuration-form .show-option {
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #80959d;
}

.payment-configuration .gateway-configuration-form .form-fields {
  padding: 24px;
  display: flex;
}

.payment-configuration .gateway-configuration-form .form-actions {
  padding: 24px;
  background-color: #fbfcfc;
  border-top: 1px solid #f5f5f5;
}

.payment-configuration .gateway-configuration-form .checkbox-icon-wrapper,
.payment-configuration .default-payment-configuration .default-payment-gateway-form .checkbox-icon-wrapper {
  width: 60px;
  padding: 0;
}
.payment-configuration .checkbox-group.icons-skin .checkbox-icon {
  max-width: 100%;
  max-height: none;
}

.payment-configuration .default-payment-configuration .default-payment-gateway-form .flex .checkbox-group.icons-skin {
  margin-right: 10px;
}

.payment-configuration .gateway-configuration-form .upi-checkbox {
  margin-left: 44px;
}

.payment-configuration .custom-payment-configuration .rate-form form {
  max-width: 100%;
}

.payment-configuration .merchant-revenue-section {
  border: 1px solid #f5f5f5;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  margin-top: 20px;
}

.payment-configuration .merchant-revenue-section .header {
  border-bottom: 1px solid #f5f5f5;
  padding: 24px 30px;
  background-color: #fbfcfc;
}

.payment-configuration .merchant-revenue-section .scroll-container {
  /* position: relative; */
  overflow-x: auto;
  padding-bottom: 10px;
}

.payment-configuration .merchant-revenue-section .fixed-table {
  width: 161px;
  position: absolute;
  background-color: white;
  z-index: 2;
}

.payment-configuration .merchant-revenue-section .fixed-table .diagonalize {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.payment-configuration .merchant-revenue-section .fixed-table .diagonalize::after {
  display: block;
  content: '';
  border-bottom: 1px solid #f5f5f5;
  transform: rotate(30deg);
  width: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(32deg); /* angle = arctan(100/160) <= 100px height, 160px width of container */
}


.payment-configuration .merchant-revenue-section .fixed-table .diagonalize .top-right,
.payment-configuration .merchant-revenue-section .fixed-table .diagonalize .bottom-left {
  width: 50%;
  padding: 12px;
  position: absolute;
  font-size: 0.75rem;
  color: #80959d;
  text-align: left;
  font-weight: 600;
}

.payment-configuration .merchant-revenue-section .fixed-table .diagonalize .top-right {
  top: 0;
  right: 0;
}

.payment-configuration .merchant-revenue-section .fixed-table .diagonalize .bottom-left {
  bottom: 0;
  left: 0;
}

.payment-configuration .merchant-revenue-section .fixed-table th,
.payment-configuration .merchant-revenue-section .fixed-table td {
  border-right: 1px solid #f5f5f5;
}

.payment-configuration .merchant-revenue-section .scrollable-table {
  width: 100%;
  margin-left: 120px;
}

.payment-configuration .merchant-revenue-section .fixed-table th {
  height: 100px;
  border-bottom: 1px solid #f5f5f5;
}

.payment-configuration .merchant-revenue-section .fixed-table td {
  font-size: 0.875rem;
  font-weight: 600;
}

.payment-configuration .merchant-revenue-section .scrollable-table th {
  height: 50px;
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
}

.payment-configuration .merchant-revenue-section .scrollable-table th {
  border-bottom: 1px solid #f5f5f5;
}

.payment-configuration .merchant-revenue-section .scrollable-table td:not(:first-child) {
  border-left: 1px solid #f5f5f5;
}

.payment-configuration .merchant-revenue-section .scrollable-table thead > tr:last-child th {
  border-bottom: 1px solid #f5f5f5;
  box-shadow: 0 1px -2px 2px rgba(0, 0, 0, 1);
  min-width: 115px;
  height: 60px;
}

.payment-configuration .merchant-revenue-section .scrollable-table th:not(:first-child) {
  border-left: 1px solid #f5f5f5;
  height: 41px;
}

.payment-configuration .merchant-revenue-section .fixed-table td,
.payment-configuration .merchant-revenue-section .scrollable-table td {
  height: 66px;
  padding: 0 20px;
  vertical-align: middle;
}

.payment-configuration .merchant-revenue-section .scrollable-table td {
  text-align: center;
  background-color: #fbfcfc;
}

.payment-configuration .merchant-revenue-section .ghost-spacer {
  width: 160px;
  border-color: transparent;
}

.payment-configuration .default-payment-configuration .linked-bank-accounts {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;
}

.payment-configuration .default-payment-configuration .linked-bank-account,
.payment-configuration .default-payment-configuration .link-bank-button {
  padding: 0 24px;
  min-width: 200px;
  height: 70px;
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #eaefef;
  margin: 10px;
}

.payment-configuration .default-payment-configuration .linked-bank-account {
  text-align: left;
  padding-left: 65px;
  background-image: url(./netbanking-icon.svg);
  background-size: 32px;
  background-position: left 18px center;
  flex: 1;
  max-width: 300px;
}

.payment-configuration .default-payment-configuration .link-bank-button {
  color: #80959d;
  background-color: #f4f7f9;
  flex: 0 1 250px;
}

.payment-configuration .default-payment-configuration .link-bank-button::before {
  content: '+';
  font-size: 1.2rem;
  line-height: 10px;
  margin-right: 10px;
}

.payment-configuration .default-payment-configuration .bank-name {
  text-transform: uppercase;
}

.payment-configuration .default-payment-configuration .bank-form .form-actions {
  display: flex;
  justify-content: space-between;
  padding: 0;
}

.payment-configuration .default-payment-configuration .bank-form .form-actions.details input[type='submit'] {
  flex: 0;
}

.payment-configuration .default-payment-configuration .bank-form input[type="submit"]+button {
  color: #eb8181;
  background-color: #ffffff;
  letter-spacing: -0.2px;
  font-weight: 600;
  padding: 0;
}

.payment-configuration .default-payment-configuration .account-number {
  color: #1557bf;
  margin-top: 2px;
  font-weight: normal;
}

.payment-configuration .default-payment-configuration .field {
  margin-bottom: 20px;
}

.payment-configuration .default-payment-configuration .default-payment-gateway-form {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f5f5f5;
  padding: 30px 40px;
  border-radius: 2px;
}

.payment-configuration .default-payment-configuration .default-info {
  height: 76px;
  border-radius: 2px;
  padding: 30px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #f5f5f5;
  border-bottom: 0;
  font-size: 0.875rem;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: space-between;
}

.payment-configuration .default-payment-gateway-form .checkboxgroup-with-label label {
  color: #2b3238;
  letter-spacing: -0.2px;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  padding: 10px 0;
  cursor: pointer;
}

.payment-configuration .default-payment-gateway-form .checkboxgroup-with-label {
  padding: 10px 0;
}

.payment-configuration .default-payment-gateway-form .checkbox-group.icons-skin {
  padding: 10px 0;
  flex-wrap: wrap;
}

.payment-configuration .custom-payment-configuration .gateway-configuration .header button {
  border-radius: 2px;
  border: solid 1px #24b058;
  font-weight: bold;
  font-size: 0.75rem;
  letter-spacing: -0.1px;
  color: #2b3238;
  margin: 8px 0;
  height: 30px;
  width: 64px;
}
.payment-configuration .custom-payment-configuration .gateway-configuration .header span {
  margin: 0 20px;
  letter-spacing: -0.2px;
  color: #2b3238;
  font-size: 0.875rem;
  flex: 1;
  padding: 15px 0;
}
.payment-configuration .custom-payment-configuration .payment-gateway-selector {
  max-width: 420px;
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px #dadee0;
}

.payment-configuration .custom-payment-configuration h6 {
  text-align: left;
  font-weight: 600;
  font-size: 0.75rem;
}

.payment-configuration .custom-payment-configuration input[type=number]::-webkit-inner-spin-button,
.payment-configuration .custom-payment-configuration input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.payment-configuration .custom-payment-configuration .scrollable-table img {
  max-width: 60px;
  max-height: 48px;
}
.payment-configuration .custom-payment-configuration .scrollable-table input[type=number] {
  width: 50px;
  padding: 0 10px;
}
.payment-configuration .custom-payment-configuration .scrollable-table .input-addon {
  padding: 0 6px;
  width: 24px;
}
.payment-configuration .custom-payment-configuration .rate-form {
  padding: 20px 0;
}
.payment-configuration .custom-payment-configuration .rate-form .form-actions {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
}
.payment-configuration .custom-payment-configuration .gateway-configuration-form .portal-selection-section .flex .field {
  margin-right: 10px;
}
.payment-configuration .default-payment-configuration .default-info button {
  margin-top: -13px;
}
.payment-configuration .custom-payment-configuration .custom-info {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  padding-top: 0px;
}
.payment-configuration .custom-payment-configuration .custom-info h4 {
  margin-bottom: 0;
  padding: 10px 0;
}

@media screen and (max-width: 480px) {
  .payment-configuration .default-payment-configuration .linked-bank-account {
    max-width: 100%;
  }

  .payment-configuration .gateway-choices {
    display: initial;
    border: 0;
  }

  .payment-configuration .gateway-choice {
    border: 1px solid #eaefef;
  }

  .payment-configuration .gateway-choice:not(:first-child) {
    border-top: 0;
  }

  .payment-configuration .gateway-choice .border-text {
    top: 0;
    left: 50%;
  }
}