.Radio {
  display: flex;
  flex-wrap: wrap;
}

.Radio input {
  display: none;
}

.Radio .radio-label {
  text-transform: none;
  font-size: 0.875rem;
  color: #2b3238;
  font-weight: normal;
  flex: 1;
  border: 1px solid var(--border-color);
  margin-left: -1px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  transition: border 0.2s ease-in-out;
  cursor: pointer;
  padding: 5px;
  min-width: 140px;
}

.Radio .radio-label:first-child {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}

.Radio .radio-label:last-child {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.Radio .radio-label.selected {
  z-index: 1;
  border-color: #1557bf;
  font-weight: 600;
}

.Radio .radio-label::before {
  display: inline-block;
  content: '';
  height: 20px;
  width: 20px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
  margin-right: 10px;
  transition: background-color 0.1s ease-in-out;
}

.campaignStatus .radio-label {
  min-width: 6.25rem;

  text-transform: none;

  font-size: 0.875rem;

  color: #2b3238;

  font-weight: normal;

  flex: 1;

  border: 1px solid var(--border-color);

  margin-left: -1px;

  height: 44px;

  display: flex;

  align-items: center;

  justify-content: center;

  background-color: white;

  transition: border 0.2s ease-in-out;

  cursor: pointer;

  padding: 5px;
}

.Radio .radio-label.selected::before {
  background-color: #1557bf;
  border-color: #1557bf;
  background-image: url(./tick-icon.svg);
  background-position: center;
  background-repeat: no-repeat;
}
.Radio .radio-label.oval.selected::before {
  background-image: url(./oval-icon.svg);
}
label[disabled] {
  opacity: 0.6;
  cursor: not-allowed !important;
}
/* Various skins */
/* Skin 1 */
.Radio.borderless-skin .radio-label {
  background-color: transparent;
  border-color: transparent;
  justify-content: left;
}

.Radio.borderless-skin .radio-label.selected::before {
  border: 4px solid white;
  box-shadow: 0 0 1px 1px #1557bf;
  background-image: none;
  height: 18px;
  width: 18px;
}

/* Skin 2 */
.Radio.block-skin .radio-label {
  border: none;
  background-color: transparent;
  flex: 1 1 100%;
  justify-content: space-between;
  margin: 0;
  height: auto;
  align-items: flex-start;
}

.Radio.block-skin .radio-label:not(:last-child) {
  margin-bottom: 14px;
}

.Radio.block-skin .radio-label::before {
  flex: 0 0 20px;
}

@media screen and (max-width: 580px) {
  .Radio .radio-label {
    padding: 0px;
  }
}

@media screen and (max-width: 360px) {
  .Radio .radio-label {
    padding: 5px;
  }
}
