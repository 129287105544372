@media screen and (max-width: 683px) {
  .scan-to-win-form {
    .form-buttons-section {
      flex-direction: column;
      width: 100%;
    }
  }
}

.scan-to-win-form {
  .grid-2-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 0 var(--s3);
  }

  h3,
  .block {
    grid-column: 1/3;
  }

  .w-100 {
    width: 100%;
  }

  .delayMs .input {
    flex-direction: row-reverse;

    .input-addon {
      border: 0.0625rem solid var(--border-color);
    }
  }

  .rc-time-picker {
    display: flex;
  }

  .validFrom {
    display: flex;
    gap: 1rem;
  }

  .validTo {
    display: flex;
    gap: 1rem;
  }

  .form-buttons-section {
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3rem;
    width: 40%;
    gap: 1rem;

    .primary {
      height: 2.5rem;
      padding: 0 1.25rem;
      font-weight: bold;
      font-size: 0.875rem;
      text-transform: capitalize;
      border: 0.0625rem solid transparent;
      border-radius: 0.125rem;
      letter-spacing: -0.0187rem;
      cursor: pointer;
      text-decoration: none;
      line-height: 2.5rem;
      display: inline-block;
      text-align: center;
    }
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: auto;
    appearance: auto;
  }
}
