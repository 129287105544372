.ConfigSetup {
  display: flex;
  justify-content: space-between;
}

.ConfigAdd {
  display: flex;
}

.ConfigNew h5 {
  margin: 0;
  font-weight: 600;
  color: white;
  background: #3680ff;
  width: 9.375rem;
  padding: 1.125rem 0.938rem;
  border-radius: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: 0.875rem;
}

.ConfigContent {
  margin-top: 2rem;
}

.ConfigContent .NoSelection {
  display: flex;
  justify-content: center;
  padding: 2.5rem;
  background: #fff7d6;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 7.625rem;
}

.ConfigDetails {
  border: 1px solid #eaecf0;
  border-radius: 0.625rem;
  margin: 2rem 0;
}

.Config-header {
  padding: 1.563rem 0.938rem;
  border-bottom: 1px solid #eaecf0;
  background: #f9fafb;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Config-Content {
  padding: 1.563rem 0.938rem;
}

.Config-Footer {
  display: flex;
  justify-content: flex-end;
}

.add-new-field-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.563rem;
  svg {
    margin-right: 0.5rem;
  }
}

.button-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
