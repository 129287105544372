.evoucher-entities .tab-details {
  display: inline-flex;
  width: 100%;
  overflow: scroll;
}
.evoucher-entities .order-details {
  margin-top: 12px;
}
.evoucher-entities .order-details .DetailsCard {
  margin-right: 20px;
  max-width: 33%;
}
.evoucher-entities .order-details .DetailsCard:last-child {
  margin-right: 0;
}
.evoucher-entities .order-details:last-child,
.evoucher-entities .tab-details:last-child {
  margin-right: 0;
}
.evoucher-entities .info-card-container {
  margin: 10px;
}

.evoucher-entities .info-card-container.total-orders,
.evoucher-entities .info-card-container.total-orders .image {
  background: #e5f6ff;
}

.evoucher-entities .info-card-container.total-amount,
.evoucher-entities .info-card-container.total-amount .image {
  background: #e9fcf1;
}

.evoucher-entities .info-card-container.joined-on,
.evoucher-entities .info-card-container.joined-on .image {
  background: #ffeeee;
}

.evoucher-entities .info-card-container.last-purchase,
.evoucher-entities .info-card-container.last-purchase .image {
  background: #f0f5f8;
}

.evoucher-entities .tabs {
  display: flex;
}
.evoucher-entities .tab-container .tab {
  flex-grow: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.evoucher-entities .tabs .tab:first-child {
  border-left: 0;
}
.evoucher-entities .tab-container {
  margin-right: 30px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaefef;
  border-top: 0;
  border-radius: 4px;
  margin-bottom: 20px;
  flex: 1 0 100%;
}
.evoucher-entities .tabWithCustomerCard .tab-row {
  width: 100%;
  display: flex;
}
.evoucher-entities .tab-details {
  flex-wrap: wrap;
  min-height: 220px;
  display: inline-flex;
  justify-content: flex-start;
}
.evoucher-entities .tabWithCustomerCard {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex: 1 0 300px;
  flex-wrap: wrap;
}
.evoucher-entities .tabWithCustomerCard .tab {
  height: 50px;
}
.evoucher-entities h3 {
  width: 100%;
  font-size: 1.125rem;
}
.evoucher-entities .AddressCard {
  width: 46%;
  padding: 20px;
  margin: 10px;
  border: 1px solid #f1f1f1;
  flex-grow: 1;
}
.evoucher-entities .tab-details .addAddress {
  width: 100%;
  max-width: 47%;
  border-radius: 4px;
  border: dashed 1px #eaefef;
  text-align: center;
  margin: 10px;
  min-height: 180px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border-image: linear-gradient(to right, 50% black, 50% transparent); */
}
.evoucher-entities .tab-details .addAddress .addTitle {
  font-size: 0.75rem;
  font-weight: 600;
  color: #1557bf;
  letter-spacing: -0.2px;
  margin: auto;
  margin-top: 10px;
}
.evoucher-entities .tabs .tab:last-child {
  border-right: 0;
  border-top-right-radius: 0;
}
.evoucher-entities .tab-details .phone-email {
  width: 100%;
  display: inline-flex;
}
.evoucher-entities .tab-details .phone,
.evoucher-entities .tab-details .email {
  display: flex;
  flex-direction: column;
}
.evoucher-entities .tab-container {
  flex: 1;
}
.evoucher-entities .tab-container .tabs .tab {
  padding: 18px;
}
.evoucher-entities .tab-details .phone {
  border-right: 1px solid #eaefef;
  width: 46%;
}
.evoucher-entities .tab-details .email {
  width: 54%;
}
.evoucher-entities .phone .ValueOptionsCard:not(:first-child),
.evoucher-entities .email .ValueOptionsCard:not(:first-child) {
  border-top: 0;
  border-radius: 0;
}
.evoucher-entities .email .ValueOptionsCard:nth-child(2),
.evoucher-entities .phone .ValueOptionsCard:nth-child(2) {
  border-top: 1px solid #eaefef;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.evoucher-entities .email .ValueOptionsCard:nth-last-child(2),
.evoucher-entities .phone .ValueOptionsCard:nth-last-child(2) {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.evoucher-entities .tab-details .phone-email .title {
  font-size: 0.75rem;
  letter-spacing: -0.1px;
  color: #80959d;
  padding: 10px;
  padding-left: 20px;
}
.evoucher-entities .tab-details .phone-email .add-button {
  font-size: 0.75rem;
  line-height: 0.9rem;
  padding: 10px;
  letter-spacing: -0.2px;
  font-weight: 600;
  color: #1557bf;
  margin-top: auto;
  width: 100%;
  border-top: 1px solid #eaefef;
}

.evoucher-entities .tab-details .phone-email .plus-sign {
  padding-right: 5px;
  vertical-align: text-top;
}
.evoucher-entities .order-details .orderslist {
  width: 100%;
  display: inline-flex;
  margin-bottom: 5px;
}
.evoucher-entities .order-details button {
  width: 30px;
  height: 30px;
  border: 1px solid #eaefef;
  margin-top: 18px;
}
.evoucher-entities .order-details .order-header {
  display: inline-flex;
  width: 100%;
}
.evoucher-entities .order-details .dropdown-menu {
  top: 17px;
}

.evoucher-entities .orders-page .header-container {
  display: none;
}
.evoucher-entities .orders-page {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .evoucher-entities .tab-container {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .evoucher-entities .order-details .orderslist {
    display: inherit;
  }
  .evoucher-entities .order-details .orderslist .DetailsCard {
    margin-bottom: 20px;
  }
  .evoucher-entities .tab-details .phone,
  .evoucher-entities .tab-details .email {
    width: 100%;
  }
  .evoucher-entities .AddressCard {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .evoucher-entities .tab-container {
    padding: 10px;
    border-top: 1px solid #eaefef;
  }
  .evoucher-entities .tabWithCustomerCard {
    flex-direction: column-reverse;
  }

  .evoucher-entities .tabWithCustomerCard .CustomerDetailsCard {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
  }
  .evoucher-entities
    .tabWithCustomerCard
    .CustomerDetailsCard
    .customer-details {
    display: flex;
    align-items: center;
  }
  .evoucher-entities
    .tabWithCustomerCard
    .CustomerDetailsCard
    .customer-actions {
    padding-bottom: 10px;
    padding-top: 0px;
    margin-left: 31%;
    margin-top: -24px;
  }

  .evoucher-entities .tabs {
    flex-wrap: wrap;
  }

  .evoucher-entities .tab-details {
    min-height: 190px;
    margin-top: 10px;
  }
  .CustomerDetailsCard .customer-details .image {
    min-width: 84px;
    margin: 0px;
  }

  .evoucher-entities .customer-details .detail {
    margin-top: -24px;
    text-align: left;
  }

  .evoucher-entities .tab-details .info-card-container {
    padding-left: 10px;
  }

  .evoucher-entities .tab-details .info-card-container .infocard-details {
    padding-left: 10px;
  }
  .evoucher-entities .tab-details .info-card-container .image {
    padding-left: 0px;
  }

  .evoucher-entities .tab-details .tab-row {
    display: flex;
    justify-content: space-between;
  }
  .evoucher-entities .tabWithCustomerCard .tab {
    padding: 0;
  }

  .evoucher-entities .info-card-container.total-orders,
  .evoucher-entities .info-card-container.joined-on {
    margin-right: 10px;
  }
  .evoucher-entities .info-card-container {
    margin: 10px 0px 0px 0px;
  }

  .evoucher-entities
    .tabWithCustomerCard
    .tab-container
    .user-tags
    .Searchable {
    padding-top: 25px;
    margin-top: 40px;
    padding-left: 0px;
  }

  .evoucher-entities .tabWithCustomerCard .Select option:first-of-type {
    display: none;
  }
  .evoucher-entities .tab-details .phone-email {
    margin-top: 10px;
  }
  .evoucher-entities .tab-container .phone,
  .evoucher-entities .tab-container .email {
    padding-right: 0;
    border: 1px solid #eaefef;
  }
  .evoucher-entities .tab-container .email {
    margin-top: 10px;
  }

  .evoucher-entities .tab-details .AddressCard,
  .evoucher-entities .tab-details .addAddress {
    margin: 10px 0px;
  }
  .evoucher-entities .tab-details .phone-email {
    display: inline-flex;
    flex-flow: row wrap;
  }
  .evoucher-entities .CustomerDetailsCard {
    flex: 1 0 100%;
  }
  .evoucher-entities .order-details .DetailsCard {
    max-width: 100%;
  }
  .evoucher-entities .tab-details .addAddress {
    max-width: 100%;
  }
  .evoucher-entities .tab-details .AddressCard .value {
    min-height: 120px;
  }
  .customer-details h1.heading {
    font-size: 18px;
  }
  .evoucher-entities
    .tabWithCustomerCard
    .CustomerDetailsCard
    .customer-details,
  .evoucher-entities .tab-details .tab-row {
    flex-direction: column;
  }
  .evoucher-entities
    .tabWithCustomerCard
    .CustomerDetailsCard
    .customer-actions {
    margin-left: 0%;
    margin-top: 20px;
  }
  .CustomerDetailsCard .info-card-container .infocard-details {
    padding-left: 3px;
  }
  .evoucher-entities .tabWithCustomerCard .CustomerDetailsCard {
    padding: 10px 5px;
  }
  .evoucher-entities .customer-details .detail {
    margin-top: 10px;
    text-align: center;
  }
  .evoucher-entities .tab-details .info-card-container .infocard-details {
    padding-left: 24px;
  }
  .CustomerDetailsCard .CustomerDetailsCard .ButtonWithOptions {
    max-width: 84px;
  }
  .CustomerDetailsCard .ButtonWithOptions .action-button {
    width: 60px;
    height: 36px;
  }
  .CustomerDetailsCard .ButtonWithOptions .DropDown {
    height: 36px;
  }
  .customer-details-orders-page
    .table-container
    .table
    .table-cell:first-child {
    padding-left: 0px;
  }
}

@media (max-width: 320px) {
  .CustomerDetailsCard .ButtonWithOptions .action-button {
    width: 30px;
    height: 30px;
  }
  .CustomerDetailsCard .ButtonWithOptions .DropDown {
    height: 30px;
  }
}
