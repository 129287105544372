@media screen and (max-width: 680px) {
  .product-filter-form .product-status {
    width: 100% !important;
  }
}
@media (max-width: 480px){
  .product-filter-form  input[type="submit"]{
    width: 48%;
  }
  .product-filter-form .button{
    width:48%;
  }
}