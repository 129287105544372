@use 'styles/colors' as *;

.color-picker-container {
  position: relative;

  .color-hex-code {
    font-size: 0.8rem;
    font-style: italic;
  }

  .color-display-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .selected-color-display {
      width: 10rem;
      height: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      border: 1px solid $fp-black;

      &:hover {
        cursor: pointer;
      }
    }

    svg {
      margin-top: 0.5rem;
      margin-left: 0.5rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .color-palette-wrapper {
    position: absolute;
    z-index: 10;
    border: 1px solid $fp-grey-2;
    padding: 0.5rem;
    background: $fp-white-0;
    border-radius: 0.25rem;

    .confirm-button {
      width: 100%;
    }
  }
}
