.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10rem;
}

.not-found .not-found-img {
  height: 2rem;
}

.not-found .not-found-filter-text {
  font-size: 1rem;
  font-weight: normal;
  color: #000000;
  text-align: center;
  margin-top: 0.5 rem;
}

.not-found .not-found-text {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #000000;
  margin-top: 1 rem;
}

.search-campaigns-page .header-container {
  display: flex;
  justify-content: space-between;
}

.search-campaigns-page .header-actions-wrapper {
  margin-bottom: 20px;
  display: flex;
}

.listing-page.search-campaigns-page
  .header-actions-wrapper
  > :last-child:only-child {
  margin-left: auto;
}

.listing-page.search-campaigns-page .pagination-container {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}

.listing-page.search-campaigns-page .pagination-count {
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  flex: 0 0 auto;
  line-height: 24px;
}

.listing-page.search-campaigns-page .search-button-wrapper {
  display: inline-block;
  vertical-align: top;
  position: relative;
}

.listing-page.search-campaigns-page
  .search-button-wrapper
  .search-button:hover {
  background-image: url('./icon-search-active.svg');
}

.listing-page.search-campaigns-page .search-button-count:empty {
  display: none;
}

.listing-page.search-campaigns-page .search-button-count {
  position: absolute;
  border: 2px solid white;
  left: 18px;
  top: 0;
  background-color: #1557bf;
  font-size: 0.6875rem;
  font-weight: 700;
  color: white;
  height: 20px;
  width: 20px;
  line-height: 16px;
  border-radius: 50%;
  display: block;
  text-align: center;
}

.listing-page.search-campaigns-page .search-button {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-image: url('./icon-search.svg');
  background-position: center;
}

.listing-page.search-campaigns-page .search-button:focus {
  outline: none;
}

.listing-page.search-campaigns-page .search-button-wrapper:not(:only-child) {
  margin-right: 25px;
}

.listing-page.search-campaigns-page .search-button.active {
  background-image: url('./icon-search-active.svg');
}

.listing-page.search-campaigns-page .filters-wrapper.hidden {
  display: none;
}

.listing-page.search-campaigns-page .filters-wrapper {
  margin-bottom: 42px;
}

.listing-page.search-campaigns-page .filters-wrapper form {
  max-width: initial;
}

.listing-page.search-campaigns-page .filters-wrapper .form-fields {
  display: flex;
  flex-flow: row wrap;
  margin: 0 -10px;
}

.listing-page.search-campaigns-page .filters-wrapper .field {
  width: 50%;
  padding: 0 10px;
  margin-bottom: 20px;
}

.jump-to-page-container {
  display: flex;
  align-items: center;
}

.jump-to-page-container .input-wrapper {
  margin-left: 0.5rem;
}

.jump-to-page-container .input-wrapper select {
  padding-right: 1.5rem;
}

@media screen and (max-width: 580px) {
  .listing-page.search-campaigns-page .page-header-wrapper {
    display: block;
  }
  .listing-page.search-campaigns-page .table-container {
    overflow-y: auto;
  }
  .listing-page.search-campaigns-page .pagination-count {
    display: none;
  }
  .listing-page.search-campaigns-page .pagination-container {
    justify-content: center;
  }
  .listing-page.search-campaigns-page .filters-wrapper .field {
    width: 100%;
  }
  .listing-page.search-campaigns-page .header-actions-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
  }
  .listing-page.search-campaigns-page .search-button-wrapper:not(:only-child) {
    margin-right: 0px;
  }
  .listing-page.search-campaigns-page .search-button {
    background-position: left center;
  }
}
@media screen and (max-width: 480px) {
  .listing-page.search-campaigns-page .search-button-wrapper:not(:only-child) {
    margin-right: 0;
  }
  .listing-page.search-campaigns-page .pagination-container .pagination {
    display: flex;
  }
  .listing-page.search-campaigns-page .pagination-container .pagination-button {
    margin: 0 4px;
  }
  .search-campaigns-page .header-container {
    margin-bottom: 1rem;
  }
}
