@use 'styles/colors' as *;

@mixin flex-container($direction, $justify-content: flex-start, $align-items: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

.sale-order-table-row {
  & > div {
    vertical-align: baseline !important;
  }
  .sale-order-num {
    @include flex-container(row);
    padding-bottom: 1.5rem;

    .reference-number {
      color: $fp-black;

      &:hover {
        color: $fp-blue;
      }
    }

    .vertical-col {
      @include flex-container(column, flex-start, flex-start);

      .text-muted {
        font-size: 0.75rem;
      }
    }
  }

  .customer {
    .name {
      color: $fp-black;
    }

    .address-icon {
      height: 1rem;
      background-image: url('../../../../../icons/icon-address-inactive.svg');
      &:hover {
        background-image: url('../../../../../icons/icon-address-active.svg');
      }
    }
    max-width: 110px;
    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .placed-time .completed-time {
    .time {
      font-size: 0.75rem;
    }
  }

  .total-amount {
    .items-count {
      font-size: 0.75rem;
    }
  }
}
