/* modal css */
.CapacityModal .modal__container {
    background-color: #fff;
    padding: 30px;
    max-width: 95vw;
    max-height: 95vh;
    border-radius: 4px;
    overflow-y: auto;
    box-sizing: border-box;
    width: 900px;
    height: 80vh;
    position: relative;
}

.CapacityModal .modal__content {
    margin-bottom: 2rem;
    line-height: 1.5;
    color: rgba(0, 0, 0, .8);
}
.CapacityModal .modal__btn-primary {
    background-color: #00449e;
    color: #fff;
}
.CapacityModal .modal__container label {
    margin-bottom: 10px;
    display: inline-block;
}
.CapacityModal .modal__container .initially-hidden {
    display: none !important;
}
.CapacityModal .error-panel {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
    padding: 7px;
    border-radius: 5px;
}
.CapacityModal .error-panel .error-msg{
    display: block;
    padding:5px;
}
