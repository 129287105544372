/* Need a better way to avoid class collision */
button[custom-button] {
  height: 40px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 26px;
  box-sizing: border-box;
  outline: none;
  padding: 7px 20px;
  cursor: pointer;
  min-width: fit-content;
}

button[custom-button][data-color='primary'] {
  background-color: #1454b8;
  color: #ffffff;
}

button[custom-button][data-filled] {
  background-color: #f2f2f2;
  color: #000000;
}
