.rewards .usedByTime{
  padding-top: 0.5rem;
}

.rewards .m-0{
  margin:0;
}

.rewards .col{
  padding:0 0.5rem;
  width:100%
}

.rewards .user-segment .single-column-field{
  border: 0.0625rem solid #dee1e2;
  border-radius: 0.125rem;
  padding: 1rem;
}

.rewards .user-segment .field-label{
  font-size: 1.125rem;
  color: #2b3238;
  font-weight: 600;
}

.editPopup.partnerOnlineCodes .details {
  padding: 1rem;
}
.editPopup.partnerOnlineCodes .details p{
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}
.editPopup.partnerOnlineCodes .details ol{
  overflow-y: auto;
  height: 14.5rem;
  padding: 0 3rem 1rem;
}
.editPopup.partnerOnlineCodes .details ol li{ 
  margin:0 0 0 0.25rem;
}
.editPopup.partnerOnlineCodes .details ol li::before{ 
  content: "";
  width: 1rem;
  display: inline-block;
}

.rewards .hideInputField{
  display: none;
}

.rewards .col-6.redemption{
  display: flex;
    justify-content: space-between;
    align-items: center;
}
.rewards .primary.viewBtn{
  margin-top: 0.5rem;
}


@media screen and (max-width: 840px) {
    .catalogue
      .table.table-dynamic
      .table-row
      .table-cell.column-partner::before {
      content: 'Partner';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      }
}

@media screen and (min-width: 840px) {
  .rewards .pr-2{
    padding-right: 1rem;
  }
  .rewards .col.time {
    max-width: 9.375rem;
  }
  .rewards .col.delete {
    max-width: 0.9375rem;
  }
}