.website-pages .table-row  .table-cell {
  height: 70px;
}

.website-pages .dropdown-items {
  width: 100px;
}

.website-pages .status {
  text-transform: capitalize;
}

.website-pages .page-name,
.website-pages .page-url {
  color: #2b3238;
}

.website-pages .page-name:hover,
.website-pages .page-url:hover {
  color: #1557bf;
}

@media screen and (max-width: 480px) {
  .website-pages .table-container {
    margin: 0px -30px;
    padding: 5px 30px 0;
    border-top: 1px solid #eaefef;
  }
  .website-pages .table .table-header {
    display: none;
  }

  .website-pages .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    border:1px solid #eaefef;
    background: #ffffff;
    margin:15px 0;
  }

  .website-pages .table .table-row .table-cell{
    padding: 15px;
    border: none;
  }
  .website-pages .table .table-row .website-page-name, .website-pages .table .table-row .website-page-url {
    font-size: 13px;
    font-weight: 600;
  }
  .website-pages .table .table-row .website-page-name .text-muted, .website-pages .table .table-row .website-page-url .text-muted{
    font-size: 11px;
    font-weight: normal;
  }
  .website-pages .table .table-row .website-page-actions {
    grid-column-start: 2;
    grid-row-start: 1;
  }
  .website-pages .table .table-row .website-page-status {
    text-align: right;
  }
  .website-pages .table .table-row .website-page-status, .website-pages .table .table-row .website-page-url {
    border-top: 1px solid #eaefef;
    background-color: #fbfcfc;
  }
}