.fp-pay-search-form form {
  max-width: initial;
}

.Payment-store-search {
  padding-top: 30px;
}

.payments-page .Payment-search {
  padding-top: 30px;
  margin-bottom: 1.25rem;
  position: relative;
}

.payments-page .Payment-search-advanced {
  padding-top: 0px;
  margin-bottom: 1.25rem;
}

.payments-page .Payment-search .Select:nth-child(2) {
  min-width: 4.6875rem;
  width: 4.6875rem;
  background-color: #f3f5f6;
  border: 1 solid #dadee0;
}

.payment-search-select-container {
  display: flex;
  align-items: center;
}

.payment-search-select-merchant {
  width: 14.75rem;
}

.payment-search-merchant-loader {
  transform: scale(0.5);
}
