.appbanner-listingpage .table-row a {
    color: #2b3238;
}

.appbanner-listingpage .table-row a:hover {
    color: #1557bf;
}

.appbanner-listingpage .table .table-row .column-img-url img {
    width: 12rem;
    height: 5rem;
    border-radius: 0.5rem;
}

.appbanner-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-Expired,.column-Unpublished {
    color: red;
    font-weight: bold;
}

.appbanner-listingpage
    .table.table-dynamic
    .table-row
    .table-cell.column-Scheduled,.column-Live {
    color: green;
    font-weight: bold;
}

.appbanner-listingpage .pagination-button {
    font-size: .875rem;
    color: #80959d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 1rem 1rem;
    gap: 0.5rem;
}

.appbanner-listingpage .pageNo {
    padding: 0.1rem 0.5rem 0.1rem 0.5rem
}

.appbanner-listingpage .pagination-button img {
    padding-top: 0.1rem;
}


@media screen and (min-width: 840px) {
    .appbanner-listingpage .table .table-cell:last-child {
        min-width: 3rem;
    }

    .appbanner-listingpage .table .table-cell:nth-last-child(2) {
        text-align: center;
        min-width: 3rem;
    }

    .appbanner-listingpage .table .table-cell:nth-last-child(3) {
        text-align: left;
    }
}

@media screen and (max-width: 840px) {
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-variation::before {
        content: 'Variation';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-img-url::before {
        content: 'Banner Image';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-campaign::before {
        content: 'Campaign Name';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-bannerType::before {
        content: 'Banner Type';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-start-date::before {
        content: 'Start Date';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-end-date::before {
        content: 'End Date';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-priority::before {
        content: 'Priority';
        display: block;
        color: #80959d;
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
    }
    .appbanner-listingpage
        .table.table-dynamic
        .table-row
        .table-cell.column-actions {
        bottom: 0;
        right: 0;
        position: absolute;
    }
}

@media screen and (max-width: 580px) {
    .appbanner-listingpage
        .table.table-dynamic
        .table-row {
        display: grid;
        grid-template-columns: auto auto;
    }
}