.store-filter-form .section-row{
    width: 100%;
}
.store-filter-form .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}

.store-filter-form .field {
    width: 50%;
    padding: 0 0.625rem;
    margin-bottom: 1.25rem;
  }
.store-filter-form .field.section-row-input-auto{
    width: auto;
    float: left;
    padding-right: 1.25rem;
}
.store-filter-form .field.section-row-input-half{
    width: 20%;
    float: left;
    padding-right: 1.25rem;
}