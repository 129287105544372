.SocialMedia .icon {
    padding-left: 12px;
}

.SocialMedia .icon:before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background: #ccc;
    border-radius: 16px;
    margin-right: 12px;
}

.SocialMedia .icon-facebook:before {
    background: #445c87 url(./icon-facebook.svg) no-repeat center;
}

.SocialMedia .icon-twitter:before {
    background: #578ccc url(./icon-twitter.svg) no-repeat center;
}

.SocialMedia .icon-gplus:before {
    background: #e3543b url(./icon-googleplus.svg) no-repeat center;
}

.SocialMedia .checkLabel label{
    text-transform: capitalize;
    font-size: 0.875rem;
    font-weight: 400
}

.SocialMedia input[type="submit"], .SocialMedia button {
    min-width: 110px;
}

.SocialMedia .form-error {
    text-align: left;
}

.SocialMedia .labelWrap {
    flex-grow: 1;
    justify-content: space-between;
}

.SocialMedia .select-social-media{
    margin-bottom: 20px;
    display: block;                                  
    position: relative;
    width: 50%;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(./dropdown-arrow.svg) no-repeat;
    background-position: calc(100% - 26px) calc(1em + 5px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    height: 44px;
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 2px;
    color: #aeb9bd;
    font-size: 14px;
}

.SocialMedia .select-social-media .select{
    width: 100%;
    height: 100%;
}
.SocialMedia .select-social-media .table{
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #dadee0;
    position: absolute;
    z-index: 99;
    top: 42px;
    left: 0px;
    background-color: #fbfcfc;
}

.SocialMedia .select-social-media .table .table-row {
    background-color: #fbfcfc;
    margin: 12px 15px 0;
    border-bottom: solid 1px #dadee0;
    display: block;
    padding-bottom: 15px;
}

.SocialMedia .select-social-media .table .table-row:last-child {
    border: none;
}

.SocialMedia .select-social-media .Select option {
    display: none
}

.SocialMedia .select-social-media .table .flex {
    justify-content: space-between;
}

.SocialMedia .select-social-media .table .flex .select-actions{
    text-align: right;
}

.SocialMedia .select-social-media .table span.icon {
    display: flex;
    align-items: center;
    padding: 0px;
}

.SocialMedia button {
    min-width: initial;
}

.SocialMedia .select-social-media-header {
    text-transform: uppercase;
    color: #2b3238;
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 12px;
    line-height: 1.41;
    display: inline-block;
}

.SocialMedia .facebook-field,
.SocialMedia .twitter-field {
    margin-top: 30px;
} 


@media(max-width: 480px) {
    .SocialMedia .form-buttons-container {
        display: flex;
    }
    .SocialMedia .form-buttons-container .primary, .SocialMedia .form-buttons-container .button{
        flex-grow: 1;
    }
    .SocialMedia .select-social-media{
        width: 100%;
    }


}