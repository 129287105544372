.blockButton {
    background: transparent;
    color: var(--red);
    border:1px solid var(--red);
}

.blockButton:hover {
    background: var(--red);
    color: white;
}

.blacklistButton {
    background: transparent;
    color: var(--fp-blue);
    border:1px solid var(--fp-blue);
}

.blacklistButton:hover {
    background: var(--fp-blue);
    color: white;
}
.customerDetails {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    text-align: center;
}
.actions {
    display: flex; 
    gap: 24px;
    margin: 12px 0;
}

.uid {
    color: rgb(128, 149, 157);
    font-weight: 400;
    font-size: 11;
}

.tabWrapper {
    border-bottom: 1px solid var(--fp-white-2);
}