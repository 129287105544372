.FileUpload {
    flex-direction: column;
    position: static;
}

.FileUpload input[type=file] {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
}

.FileUpload .upload-button-container{
    justify-content: space-between;
    font-size:0.875rem;
    line-height: 30px;
    margin-top: 10px;
    letter-spacing: -0.3px;
}

.FileUpload .file-label {
    border: 1px solid var(--border-color);
    border-radius: 2px;
    background: #fff url(./upload.svg) no-repeat center 60px;
    font-size: 0.875rem;
    color: #aeb9bd;
    letter-spacing: -0.2px;
    width: 100%;
    background-color: #f3f5f6;
    padding: 10px 20px 10px 50px;
    height: auto;
    background-position: left 20px center;
    background-size: 24px;
}

.FileUpload button {
    font-size: 0.875rem;
    font-weight: bold;
    background-color: #f3f5f6;
    margin-left: 10px;
    letter-spacing: -0.3px;
    min-width: 110px;
}

.FileUpload .upload-file-name {
    font-size: 14px;
    letter-spacing: -0.3px;
    margin-bottom: 20px;
}

.FileUpload  .form-error {
    margin-top: 10px;
    word-wrap: break-word;
}

.FileUpload .title {
    font-size: 0.875rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}

.FileUpload .file-name-container {
    display: flex;
    justify-content: center;
}

.FileUpload .file-icon img {
    margin-right: 10px;
    min-width: 18px;
}