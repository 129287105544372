$fp-white: #fbfcfc;
$fp-white-0: #ffff;
$fp-white-2: #eaefef;
$fp-white-3: #f0f4f4;

$fp-blue: #1557bf;
$fp-blue-1: #1454B8;
$fp-blue-2: #44bffe;

$fp-red: #ff0000;
$fp-red-1: #FB424B;
$fp-red-2: #eb8181;
$fp-red-3: #C10B3A;
$fp-red-4: #F89393;
$fp-red-5: #f8d7da;


$fp-green: #008000;
$fp-green-2: #3FC078;

$fp-grey: #80959d;
$fp-grey-1: #F4F7F9;
$fp-grey-2: #8B9FA6;
$fp-grey-3: #696969;
$fp-grey-4: #DADEE0;
$fp-grey-5: #999999;


$fp-teal-1: #588292;

$fp-black: #2b3238;
$fp-black-0: #0000;
$fp-black-0-40: rgba($fp-black-0, 0.04);
