.store-category-container, .store-grouping-container {
  background-color: #fbfcfc;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #e9eaeb;
  margin-top: 0.25rem;
}

.store-category-selector {
  position: relative;
  display: flex;
  flex-direction: column;
}

.store-category-btn, .store-grouping-btn {
  height: 1.875rem;
  width: auto;
  border-radius: 2px;
  padding: 0.438rem, 0.875px;
  min-width: fit-content;
  background: #f3f5f6;
  border-radius: 2px;
  cursor: pointer;
  border: none;
}

.store-category-list, .store-grouping-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.625rem;
  column-gap: 1.25rem;
  background: #fbfcfc;
  margin-bottom: 0.625rem;
}

.store-category-tag, .store-grouping-tag {
  background-color: #e5e5e5;
  font-size: 0.75rem;
  padding: 0px 0.313rem;
  margin: 0 2px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-all-btn {
  color: #1454b8;
  font-size: 0.875rem;
  cursor: pointer;
}

.selected-list {
  margin: 0;
  padding: 0.5rem 0;
  max-height: 300px;
  overflow-y: auto;
}

.selected-list__item {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  position: relative;
}

.selected-list__label {
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.selected-list__label small {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tag-icon {
  margin-right: 0.5rem;
}

.delete-icon {
  cursor: pointer;
}

.delete-icon__container {
  position: absolute;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 0.313rem;
}

.delete-icon__container:hover {
  background-color: #edf1f5;
}
