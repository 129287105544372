.mobileAppSetup-page {
.vertical {
  display: flex;
  width: '100%';
}

.optional {
  background-color: #f2f2f2;
  padding: 0.5rem;
}

.onboardingImageHover {
  margin-top: 1.3200rem;
}

.onboardingImageHover2{
  margin-top: 1.0200rem;
}

.img {
  height: 21.9375rem;
  width: 12.1875rem;
  left: 33.6875rem;
  top: 4.5625rem;
}

.inputForm {
  width: 60%;
  margin-right: 3.125rem;
}

.content {
  padding: 0 0.625rem 0 0.625rem;
}

.uploadImg-box {
  border-width: 1px solid #eaeaea;
  border-color: black;
  border-radius: 15;
  height: 200;
  width: initial;
  margin: 2rem auto;
}

.SwimlaneInfoHoverImage {
  margin-top: 1.75rem;
}
}