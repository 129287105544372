.blogs-page .status-draft {
  color: #eb8181;
}

.blogs-page .blog-title-link {
  color: #2b3238;
}

.blogs-page .blog-title-link:hover {
  color: #1557bf;
}

.blogs-page .table-row .table-cell:not(:last-child) {
  height: 70px;
}

.blogs-page .publish-date,
.blogs-page .publish-time {
  white-space: nowrap;
}