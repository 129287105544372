/* below round-corner mixin is just an example to showcase what mixin can do */
@mixin round-corner($corner, $size: 10px) {
  @if $corner == top-right {
    border-top-right-radius: $size;
  } @else if $corner == top-left {
    border-top-left-radius: $size;
  } @else if $corner == bottom-right {
    border-bottom-right-radius: $size;
  } @else if $corner == bottom-left {
    border-bottom-left-radius: $size;
  } @else {
    border-bottom-left-radius: $size;
    border-bottom-right-radius: $size;
    border-top-left-radius: $size;
    border-top-right-radius: $size;
  }
}