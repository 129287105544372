.qc-odp .table .table-row:nth-child(2n) {
  background-color: #fff;
}

.qc-odp .table .table-row .table-cell .no-row {
  border: none !important;
}
.no-row {
  border: none;
}
.border-bottom {
  border-bottom: 1px solid #eaefef;
}

.suborder-heading {
  background-color: #e8f0fd;
  font-weight: 700;
  font-size: 1rem;
}
.select-single-subOrder {
  display: flex;
  align-items: flex-end;
}
.select-single-subOrder-item {
  display: flex;
}
.select-single-subOrder .Radio {
  margin-bottom: 0.5rem;
}
.select-single-subOrder .Radio .radio-label {
  border: 0;
  min-width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  background-color: transparent;
}
.select-single-subOrder .Radio .radio-label::before {
  background-color: white;
}
.select-single-subOrder .Radio .radio-label.selected::before {
  background-color: #1557bf;
}
.select-single-subOrder-item.special-instructions {
  align-items: end;
}
.food-supplier-names {
  margin: 0;
  padding-left: 1.875rem;
}
.food-supplier-names li {
  text-align: left;
}

.sub-order-status-container {
  display: flex;
  white-space: nowrap;
  align-items: center;
}

.custom-select {
  width: 16.25rem;
  margin-left: 0.3125rem;
  background-color: white;
}

.sub-order-status {
  border: 1px solid var(--border-color);
  border-radius: 1.25rem;
  font-size: 0.75rem;
  font-weight: 400;
  padding: 1px 0.75rem;
  margin-left: 0.3125rem;
  background-color: white;
}

.edit-status {
  margin-left: 0.5rem;
  cursor: pointer;
  height: 1.25rem;
}
.edit-status.disable-suborder-status {
  opacity: 0.7;
  cursor: not-allowed;
}
.qc-order-summary-grey {
  color: #8b9fa6;
}
.suborder-special-instructions,
.suborder-special-discounts {
  padding-bottom: 0.9375rem;
  font-size: 0.875rem;
  padding-left: 0.625rem;
}
.suborder-special-instructions.special-instructions-with-checkbox,
.suborder-special-discounts.special-discounts-with-checkbox {
  padding-left: 2.125rem;
}
.special-discounts {
  border: 2px solid #eaefef;
  border-radius: 0.3125rem;
  display: inline-flex;
  padding: 0.3125rem;
}
.special-discounts img {
  margin-right: 0.3125rem;
}
.no-special-instructions {
  font-weight: 600;
}

.customisation-options-container {
  display: flex;
}
.customisation-options-container.customisation-checkbox {
  padding-left: 1.875rem;
}

.summary-listing-page .suborder-customisation-items .table-cell,
.summary-listing-page .suborder-customisation-refunded-items .table-cell {
  padding-top: 0;
  padding-bottom: 0;
  border-top-width: 0;
}
/* .summary-listing-page
  .suborder-customisation-items.last-customisation-items
  .table-cell,
.summary-listing-page
  .suborder-customisation-refunded-items.last-customisation-items
  .table-cell {
  padding-bottom: 0.9375rem;
} */
.summary-listing-page
  .suborder-customisation-items.customisation-top-space:first-child
  .table-cell,
.summary-listing-page
  .suborder-customisation-refunded-items.customisation-top-space:first-child
  .table-cell {
  padding-top: 0.9375rem;
}
.summary-listing-page
  .suborder-customisation-items.no-order-item:not(:first-child)
  .table-cell,
.summary-listing-page
  .suborder-customisation-refunded-items.no-order-item:not(:first-child)
  .table-cell {
  border-top-width: 1px;
  padding-top: 0.9375rem;
}

.summary-listing-page .suborder-items.customisation-items .table-cell,
.summary-listing-page .suborder-refunded-items.customisation-items .table-cell {
  padding-bottom: 0;
}
.qc-cutlery-option,
.qc-payment-discount {
  text-align: left;
  white-space: nowrap;
}
.summary-listing-page .table .table-cell {
  width: 100%;
}
.button.primary.button.quantity {
  border-radius: 50%;
  height: 1rem;
  width: 1rem;
  padding: 0;
  align-items: center;
  display: inline-flex;
  justify-content: center;
  margin: 0 0.5rem;
}
.button.primary.button.quantity.hide-quantity {
  visibility: hidden;
}
.suborder-refunded-items-header {
  background-color: #f4f5f7;
  padding: 0.625rem;
  font-weight: 400;
  font-size: 0.75rem;
  color: #333333;
}
.container-fee-table .container-fee-main-row .table-cell {
  padding-bottom: 0;
}
.container-fee-table .container-fee-items-row-spacer {
  padding-bottom: 0.9375rem;
}
.container-fee-table .container-fee-items-row .table-cell {
  padding-top: 0;
  border-width: 0;
}
.container-fee-table .container-fee-items-row:not(:last-child) .table-cell {
  padding-bottom: 0;
}
.card-surcharge-table .card-surcharge {
  font-weight: 700;
}
.line-through {
  text-decoration: line-through;
}

.qc-stall-discount-table .qc-stall-discount-row:last-child .table-cell {
  border-bottom: 0;
}
.qc-stall-discount-table .qc-stall-discount-row .table-cell {
  padding-bottom: 0;
}
.qc-stall-discount-table-items .qc-stall-discount-row-item .table-cell {
  border-top: 0;
  padding-top: 0;
}
.qc-stall-discount-table-items
  .qc-stall-discount-row-item:not(:last-child)
  .table-cell {
  padding-bottom: 0;
}
.refund-item-state {
  white-space: nowrap;
}
.linkpoint-details-container .table .table-header .table-cell {
  white-space: nowrap;
}
.delivery-details-container {
  display: flex;
}
.delivery-details-container .delivery-details {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1rem;
  background-color: white;
  justify-content: space-between;
}
.delivery-details-container .delivery-details > div {
  margin-bottom: 0.3125rem;
}
.other-section {
  padding-bottom: 1rem;
}
.delivery-details-iframe {
  min-width: 50%;
}
.delivery-details-iframe .content {
  overflow: hidden;
}
.no-delivery-map {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  color: #999999;
  text-align: center;
  padding: 20px;
  background-color: #f4f5f7;
}
.flex-1 {
  flex: 1;
}
.qc-payment-subheading {
  display: flex;
  padding-top: 0.5rem;
  display: none;
}
.qc-payment-subheading b {
  padding-right: 1.5rem;
  padding-left: 0.25rem;
}
@media screen and (max-width: 840px) {
  .summary-listing-page .table.table-dynamic .table-row.suborder-heading {
    margin-top: 1rem;
    background-color: #e8f0fd !important;
    width: 100%;
  }
  .payment-details-container .table.table-dynamic,
  .linkpoint-details-container .table.table-dynamic {
    width: 100% !important;
    margin: 0;
  }
  .payment-details-container .table .table-cell:last-child,
  .linkpoint-details-container .table .table-cell:last-child {
    text-align: left;
  }

  .linkpoint-details-container .table .table-header .table-cell {
    white-space: normal;
  }
  .container-fee-table .container-fee-items-row-spacer {
    padding-bottom: 0;
  }
  .summary-listing-page .suborder-items.customisation-items .table-cell,
  .summary-listing-page
    .suborder-refunded-items.customisation-items
    .table-cell {
    padding-bottom: 0.9375rem;
  }
  .suborder-refunded-items-header {
    margin: 0.625rem;
  }
}

@media screen and (max-width: 580px) {
  .suborder-refunded-items-header {
    margin: 0;
  }
}
@media screen and (max-width: 400px) {
  .sub-order-status-container {
    align-items: flex-start;
    flex-direction: column;
  }
}
