@use 'styles/colors' as *;

.segment-io-tags-section {
  padding: 0 1rem;

  .segment-tags-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
    font-size: 0.75rem;

    .segment-tag {
      background-color: $fp-grey;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      border: 1px solid $fp-grey-5;
      color: $fp-white-0;
      font-weight: bold;
    }
  }
}
