.layout-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 1rem;
}

.draggable-module {
  border: 0.063rem solid #dbdbdb;
  box-shadow:
    rgba(0, 0, 0, 0.16) 0px 3px 6px,
    rgba(0, 0, 0, 0.23) 0px 3px 6px;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
  cursor: grab;
}

.draggable-modules .opacity-0 {
  opacity: 0;
}

.draggable-modules .opacity-1 {
  opacity: 1;
}

.module-header {
  height: 3rem;
  background-color: #2b3238;
  color: #ffffff;
  padding: 1.563rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.25rem 0.25rem 0 0;
}

.module-header .up-arrow img {
  transform: rotate(180deg);
}

.module-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding: 1rem;
  overflow-x: auto;
}

.module-popup {
  max-width: 55rem;
}

.selector-buttons-section {
  display: flex;
  justify-content: space-between;
}

.selector-buttons-section > .selector-section {
  width: 10rem;
}

.selector-buttons-section > .buttons-section {
  display: flex;
  margin-left: auto;
  gap: 1rem;
}

.selector-buttons-section > .buttons-section .primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
  margin-left: var(--s3);
}

.module-type-section {
  display: flex;
  justify-content: space-evenly;
}

.id-name-section {
  display: flex;
  gap: 1rem;
}

.id-name-section :first-child {
  flex: 0.5;
}

.id-name-section :last-child {
  flex: 1;
}

.module-placeholder img {
  width: 100%;
}

.module-form-section {
  padding: 0 var(--s3);
}

.module-form-section .analytics-section {
  display: flex;
  flex-direction: column;
}

.module-form-section .buttons-section {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 3rem;
  width: 40%;
  gap: 1rem;
}

.module-form-section .buttons-section .button {
  flex: 1;
  min-width: max-content;
}

.module-form-section .deeplink-section {
  display: flex;
  gap: 1rem;
}

.module-form-section .deeplink-section :first-child {
  flex: 1;
}

.module-form-section .analytics-field label {
  display: none;
}

.applayout-hover-button {
  border: 0.0625rem solid #ccc;
  background-color: transparent;
  border-radius: 0.25rem;
  font-weight: 400;
  width: 10rem;
}

.applayout-hover-button:hover {
  background-color: #e6e6e6;
  border-color: #adadad;
}

.add-module-section {
  display: flex;
  justify-content: center;
}

/* r4u section */
.r4u-params-section {
  display: flex;
  gap: 1rem;
}

.r4u-params-section .field {
  flex: 1;
}

.r4u-params-section .delete-icon:hover {
  cursor: pointer;
}

/* applayout-module-table */
.applayout-module-table .table-header :first-child {
  text-align: left;
}

.applayout-module-table .table-header :last-child {
  text-align: left;
}

.applayout-module-table .table-row {
  background-color: white !important;
}

.applayout-module-table .table-row :first-child span:hover {
  cursor: pointer;
  color: hsl(0, 0%, 60%);
}

.applayout-module-table .table-row :last-child {
  text-align: left;
}

.applayout-module-table .table-row .module-action .dropdown-menu {
  top: -3.438rem;
  left: 1.25rem;
  width: fit-content;
}

.applayout-module-table .table-row .module-status-true {
  font-weight: bold;
  color: hsl(126, 81%, 57%);
}

.applayout-module-table .table-row .module-status-false {
  font-weight: bold;
  color: hsl(0, 100%, 50%);
}

.applayout-module-table .table-cell {
  border-bottom: none !important;
}

@media (max-width: 768px) {
  .module-section {
    grid-template-columns: 1fr;
  }

  .module-form-section .id-name-section {
    display: block;
  }

  .module-form-section .deeplink-section {
    display: block;
  }

  .module-form-section .buttons-section {
    flex-direction: column;
    width: 100%;
  }

  .module-type-section {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}

@media (min-width: 768px) {
  .module-placeholder img {
    min-width: 30rem;
  }
}
