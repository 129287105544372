.editPopup.operationHour-box .section-row:after,
.asset-detail-page .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}
.editPopup.operationHour-box .section-row-input,
.asset-detail-page .section-row-input{
    width: 50%;
    float: left;
    padding-right: 1.25rem;
}
.asset-detail-page .section-row,
.asset-detail-page .section-row-input{
    position: relative;
}
.asset-detail-page .addAssetTypeBtn,
.asset-detail-page .manageCreativeReqBtn{
    position: absolute;
    right: 1.25rem;
    top: -0.125rem;
    display: inline-block;
    padding: 0.125rem 0.375rem;
    background-color: #f3f5f6;
    border-radius: 0.125rem;
    color: #80959d;
    font-size: 0.75rem;
    line-height: 1.41;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: -0.0125rem;
    cursor: pointer;
}

.section-title.hide{
    display: none;
}


.asset-detail-page .section-title-wrap{
    display: flex;
    align-items: center;
}
.asset-detail-page .section-title-wrap button{
    margin-left: 0.625rem;
}
.asset-detail-page .operationHour-wrap{
    margin-bottom: 3.125rem;
}
.asset-detail-page .operationHour-slot-wrap{
    display: flex;
    flex-wrap: wrap;
}
.asset-detail-page .operationHour-wrap .operationHour-slot{
    width: 18%;
    padding: 0.625rem;
    margin: 1%;
    text-align: center;    
    background-color: #f0f5f8;
    border-radius: 0.375rem;
    line-height: 1.5;
    font-size: 0.75rem;
}
.asset-detail-page .operationHour-wrap .operationHour-slot .slot-1{
    font-weight: bold;
    line-height: 2;
}
.asset-detail-page .operationHour-wrap .operationHour-slot .slot-2{
    position: relative;
}
.asset-detail-page .operationHour-wrap .operationHour-slot .slot-2::before{
    content: '';
    display: block;
    position: absolute;
    left: -4rem;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 0.375rem;
    background-color: #7bd49d;
}
.asset-detail-page .operationHour-wrap .operationHour-slot .slot-2.inactive::before{
    left: -5rem;
    background-color: #eb8181;
}
.asset-detail-page .operationHour-wrap .operationHour-slot .slot-4{
    margin-top: 0.375rem;
}
.asset-detail-page .operationHour-wrap .button{
    margin: 0.625rem 1.25rem 0 0;
}

.asset-detail-page .label-tooltip {
    font-size: 0.75rem;
    margin-left: 0.5em;
    position: relative;
}

.asset-detail-page .label-tooltip .label-tooltiptext {
    visibility: hidden;
    width: 25.75rem;
    background-color: #fff;
    color: #2b3238;
    box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
    padding: 0.625rem 1.25rem 1.25rem 1.25rem;
    position: absolute;
    z-index: 1;
    bottom: 145%;
    left: -3.155rem;
    text-align: left;
}

.asset-detail-page .label-tooltip .label-tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 3.375rem;
    margin-left: -0.3125rem;
    border-width: 0.625rem;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
}

.asset-detail-page .label-tooltip:hover .label-tooltiptext {
    visibility: visible;
}

.asset-detail-page .creative-req-summary{
    border: 0.125rem solid var(--border-color);
    margin-bottom: 1.25rem;
}
.asset-detail-page .creative-req-summary .table-cell{
    min-width: 3rem !important;
}

.creativeReqList-box{
    max-width: 66rem;
}

.editPopup.operationHour-box{
    max-width: 62.5rem;
}
.editPopup.operationHour-box .section-row-input-half{
    float: left;
    padding-right: 1.25rem;
}

@media (max-width: 36rem) {
    .asset-detail-page .section-row-input{
        width: 100%;
        padding-right: 0;
    }
    .asset-detail-page .operationHour-wrap .operationHour-slot{
        width: 31%;
        padding: 1%;
        margin: 1%;
    }
    .editPopup.operationHour-box .section-row-input{
        width: 100%;
    }
    .editPopup.operationHour-box .section-row-input .Radio{
        flex-wrap: nowrap;
    }
    .editPopup.operationHour-box .section-row-input-half{
        width: 50%;
    }
    .editPopup.operationHour-box .checkbox-group{
        flex-wrap: wrap;
    }
}