.BasicInformation .form-error {
    text-align: left;
}

.BasicInformation .imageModal.form-error {
    text-align: center;
}

.BasicInformation input[type="submit"] {
    min-width: 110px;
}

.BasicInformation .Upload {
    width: 290px;
}

.basicInfo-form .map-element {
    height: 300px;
}
  
.basicInfo-form .map-element {
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    transition: border 200ms ease-in-out;
}
  
.basicInfo-form .location-search-box {
    width: 240px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 10px;
    font-weight: normal;
    height: 36px;
    text-overflow: ellipses;
    position: absolute;
    left: 50%;
    transform: translateX(-50%)
}
  
.basicInfo-form .coordinates-input input[name=coordinates] {
    display: none;
}

.basicInfo-form  .rrui__expandable {
    z-index: 2;
}

.basicInfo-form .site-url .labelWrap {
    justify-content: space-between;
}

@media(max-width: 480px) {
    .basicInfo-form .form-buttons-container {
        display: flex;
    }
    .basicInfo-form .form-buttons-container .primary, .basicInfo-form .form-buttons-container .button{
        flex-grow: 1;
    }

}

@media(max-width: 375px) {
    .basicInfo-form .input-addon:last-child {
        word-break: break-all;
    }
    .basicInfo-form .input-addon+input {
        min-width: 65px;
    }
}
