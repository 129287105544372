@use 'styles/colors' as *;

.bulk-operations-page {

  .header-container {
    margin-bottom: 1rem;
  }

  .form-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;

  }
  .button {
    margin-right: 1rem;

    &.disabled {
      background-color: $fp-grey !important;
      pointer-events: none;
    }
  }

  .order-status-checkboxes-container {
    p {
      font-size: 0.875rem;
    }
    .order-status-checkboxes-wrapper {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 0.5rem;
      margin-bottom: 1rem;

      label {
        font-size: 0.875rem;
      }
    }
  }
}

.b2b-delivery-order-notes-popup {
  .download-button-wrapper {
    margin-top: 1rem;
    width: fit-content;
    padding: 0.5rem;
    background-color: $fp-blue;
    a {
      color: $fp-white;
    }
  }
}
