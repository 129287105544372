.payment-response .payment-response-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.payment-response .success-title::before {
  content: '';
  display: block;
  height: 60px;
  width: 60px;
  background-image: url(./payment-success-icon.svg);
  background-size: contain;
  background-position: center;
  margin: 0 auto 10px auto;
}

.payment-response .fail-title {
  margin-top: 70px;
}

.payment-response .redirect-link {
  display: block;
  margin-bottom: 70px;
}