.manage-store-popup {
  max-width: 50vw !important;
  min-width: 50vw !important;
}
.manage-store-form {
  height: auto;
}

.manage-store-form .form-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10em;
}

.manage-store-form .form-control button {
  margin-left: 0.5em;
}
.manage-store-form .error-message {
  font-size: small;
}
