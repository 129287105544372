.substitution-groups-form .form-actions {
  display: flex;
  justify-content: space-between;
}

.substitution-groups-form input[type=submit] {
  width: auto;
}

.substitution-groups-form .substitutionGroup-delete-button {
  color: #eb8181;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 0 0 0 24px;
  background-image: url(./icon-dustbin.svg);
  background-size: 14px;
  background-position: left center;
  background-repeat: no-repeat;
}

.substitution-groups-form .table {
  margin-bottom: 20px;
}

.substitution-groups-form .del-button.hide {
  display: none;
}

@media (max-width: 480px){
  .substitution-groups-form .form-buttons {
    display: flex;
    flex-grow: 1;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
  .substitution-groups-form .form-buttons .primary, .substitution-groups-form .form-buttons .button {
    flex: 1
  }
  .substitution-groups-form input[type="submit"]{
    margin-left: 10px;
  }
}
