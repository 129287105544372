.Payment-search {
  display: flex;
}

.Payment-search .Select {
  width: 180px;
  min-width: 180px;
}

.Payment-search select {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.Payment-search .input-wrapper {
  width: 100%;
}

.Payment-search .DateRangePicker {
  max-width: 60%;
  width: 600px;
}

.Payment-search .date-picker .DateInput {
  width: 50%;
}

.Payment-search .date-picker .DateInput .DateInput_input {
  height: 43px;
}
