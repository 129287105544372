.content {
  button {
    display: block;
    margin: 0 auto;
    margin-top: 1rem;
  }

  .preview {
    display: flex;
    flex-direction: column;
    min-height: 325px;
    gap: 1rem;
  }
}

.trigger-wrapper {
  margin: auto;
  height: 228px;
  width: 152px;
  border: 1px solid #eaeaea;
  background-color: #ffffff;
  border-radius: 0.75rem;
  padding: 16px;

  .trigger-content {
    display: flex;
    flex-direction: column;
    position: relative;

    .trigger-text2 {
      position: absolute;
      color: #333333;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      top: 140px;
    }

    .trigger-text1 {
      position: absolute;
      color: #666666;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      top: 0;
    }

    .trigger-image {
      position: absolute;
      height: 120px;
      width: 120px;
      top: 20px;

      img {
        width: 100%;
      }
    }
  }
}
