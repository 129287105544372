.instore-config-form.logistics-config-form {
  max-width: initial;
}
.logistic-select {
  max-width: 32rem;
}
.top-space {
  display: flex;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 2rem;
  position: relative;
}

.tier-setup {
  display: flex;
  margin-top: 0.5rem;
}

.tier-setup .input-wrapper {
  padding-right: 8px;
}
.tier-setup button {
  padding: 0 10px;
  min-width: 80px;
}
.tier-group {
  margin-top: 1rem;
}
.tier {
  display: flex;
  width: 100%;
}
.button_align {
  flex: 0 0 25%;
  display: flex;
}

.button_align .button {
  margin-left: auto;
}

.tier-setup input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tier-setup .labelWrap {
  width: calc(100% / 3);
  padding-right: 8px;
}

.delete-icon {
  color: red;
  align-self: center;
  cursor: pointer;
}

.default {
  background: rgb(204, 204, 204);
  padding: 4px 10px;
}

.customized-modal {
  max-width: 600px;
}

.logistics-config-form .input-flex {
  display: flex;
}

.logistics-config-form .input-flex .field {
  width: 100%;
}

.logistics-config-form .input-flex .field:not(:last-of-type) {
  margin-right: 8px;
}

.multi-tags {
  max-height: 150px;
  overflow: auto;
}

.multi-tags .tag-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
}

.multi-tags .tag-value {
  display: inline-flex;
  align-items: center;
  padding: 4px 15px;
  border-radius: 2px;
  background-color: #80959d;
  display: inline-block;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: -0.1px;
  text-align: left;
  margin-top: 15px;
  margin-right: 10px;
  height: 30px;
}

@media screen and (max-width: 480px) {
  .instore-config-form.logistics-config-form
    .logistics-radio
    .Radio
    .radio-label {
    margin-bottom: 10px;
  }
  .instore-config-form.logistics-config-form .form-action {
    display: flex;
  }
  .instore-config-form.logistics-config-form .form-action .primary,
  .instore-config-form.logistics-config-form .form-action .button {
    flex: 1;
  }
}
