.csv-export-button {
  float: right;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.csv-button-loader {
  margin-left: 0.5em;
  padding: 0;
  display: block;
}

.csv-button-loader > svg {
  fill: transparent !important;
  stroke: white !important;
}
