.batch-upload-jobs .labelWrap {
  display: inline-block;
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 10px;
  letter-spacing: -0.2px;
}
.batch-upload .product-upload {
  width: initial;
  margin: 2rem auto;
}
.editPopup.batch-upload {
  max-width: fit-content;
}

.product-upload input {
  height: 90px;
  opacity: 0;
  cursor: pointer;
}

.product-upload .download-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 0.75rem;
}

.batch-upload .product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 1rem;
  position: relative;
}

.product-upload .border-text {
  display: inline-block;
  position: absolute;
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  padding: 6px;
  background: white;
  transform: translate(-50%, -50%);
  top: 0px;
  left: 50%;
}

.product-upload .add-product-link {
  margin-bottom: 48px;
}

.batch-upload .product-upload input.rc-time-picker-input {
  height: 45px;
}
.batch-upload .product-upload .dateTimeSelect {
  display: flex;
  justify-content: center;
}
.batch-upload .product-upload .dateTimeSelect .field {
  margin: 0 5rem;
}
.batch-upload .download-sample-text,
.batch-upload .schedule-checkbox {
  margin-bottom: 10px;
}

.batch-upload .product-upload .dateTimeSelect {
  display: flex;
  justify-content: center;
}

.product-upload .schedule-checkbox {
  margin-top: 20px;
  margin-bottom: 10px;
}
.batch-upload .product-upload input {
  height: 44px;
  opacity: 1;
  cursor: pointer;
}

.batch-upload .product-upload input[type='file'] {
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
}

@media screen and (max-width: 520px) {
  .batch-upload .product-upload {
    width: 100%;
    margin: 1rem auto 0;
  }
  .batch-upload .product-upload .dateTimeSelect {
    flex-direction: column;
  }
  .batch-upload .date-picker {
    max-width: 252px;
  }
}

.batch-upload .product-upload .upload-button-container {
  text-align: center;
  overflow-y: scroll;
}
.batch-upload .review-table {
  margin-top: 2rem;
}
.batch-upload .table-container .table .table-cell:last-child {
  text-align: left;
}
.batch-upload .table-container {
  margin-bottom: 15px;
}
.batch-upload .details .error {
  margin: 10px 0;
  color: red;
}
.batch-upload .details .success {
  margin: 10px 0;
  color: #1557bf;
  font-weight: bold;
}
.batch-upload .table-row .category-id {
  font-weight: bold;
}
.batch-upload .table-row .category-action {
  font-weight: bold;
  padding: 0 10px;
}
.batch-upload .table-row .category-action.A {
  background: green;
  color: white;
}
.batch-upload .table-row .category-action.D {
  background: red;
  color: white;
}
.batch-upload .table-row .category-action.O {
  background: blue;
  color: white;
}
.batch-upload .table-container .table-row.row-error {
  background: #ff000082;
}
.batch-upload .details button.primary {
  float: none;
}
.batch-upload .actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  background-color: white;
  text-align: right;
  margin-top: 12px;
  position: fixed;
  bottom: 0;
  right: 40px;
  padding: 10px 0;
}
.batch-upload .actions .primary.button {
  margin-left: 12px;
}
.batch-upload .error {
  color: red;
  font-size: 0.8rem;
}
.batch-upload .dropdown-wrapper.hasError select {
  border: 1px red solid;
}
.batch-upload .no-review {
  margin-top: 4rem;
  text-align: center;
}
.batch-upload .muted {
  color: #80959d;
}
.batch-upload .sample-link{
  padding-right: 30px;
}
.bubble {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  padding: 2rem;
  text-align: center;
}
.processing {
  background: url('upload-products.svg') no-repeat center top;
  animation-name: bounce-7;
  animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
  width: 100px;
  height: 100px;
  margin: 0 auto;
  background-size: 100%;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes bounce-7 {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.1) translateY(-50px);
  }
  50% {
    transform: scale(1.05, 0.95) translateY(0);
  }
  57% {
    transform: scale(1, 1) translateY(-7px);
  }
  64% {
    transform: scale(1, 1) translateY(0);
  }
  100% {
    transform: scale(1, 1) translateY(0);
  }
}
