.draggable-listing-page .table .table-header  {
    display: flex;
    justify-content: space-between;
}

.draggable-listing-page .draggable-actions {
    display: flex;
    padding-top: 20px;
    flex-direction: row-reverse;
}

.draggable-listing-page .cancel {
    margin-right: 15px;
}

.draggable-listing-page .description {
    margin-bottom: 10px;
}

.draggable-listing-page .pagination-count {
    display: none;
}

.draggable-listing-page .sequencing-disabled-info {
    margin-top: 20px;
}
  
.draggable-listing-page .sequencing-disabled-info p {
    margin: 0;
    font-size: 14px;
    line-height: 1.6;
}

.change-view {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.change-view button {
    padding: 8px 12px;
}