.tags-import-popup .product-upload {
  width: 440px;
  margin: 0 auto;
}
.tags-import-popup .product-upload input[type=file] {
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
}
.tags-import-popup .product-upload input {
  height: 44px;
  opacity: 1;
  cursor: pointer;
}
.tags-import-popup .product-upload input.rc-time-picker-input {
  height: 45px;
}
.editPopup.tags-import-popup {
  max-width: 570px;
}
.tags-import-popup .product-upload .dateTimeSelect {
  display: flex;
  justify-content: space-between;
}
.tags-import-popup .download-sample-text, .tags-import-popup .schedule-checkbox {
  margin-bottom: 10px;
}
.product-upload .download-link {
  display: inline-block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 0.75rem;
}

.product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.product-upload .border-text {
  display: inline-block;
  position: absolute;
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  padding: 6px;
  background: white;
  transform: translate(-50%, -50%);
  top: 0px;
  left: 50%;
}

.product-upload .add-product-link {
  margin-bottom: 48px;
}

.product-upload .instruction {
  font-size: 0.75rem;
  margin-top: 10px;
}

.cx-import-tooltip {
  font-size: 12px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.cx-import-tooltip .tooltip-title {
  font-weight: 600;
}
.cx-import-tooltip .tooltiptext {
  visibility: hidden;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
  padding: 10px 20px 20px 20px;
  position: absolute;
  z-index: 1;
  bottom: 200%;
  left: 1.125rem;
  text-align: left;
}

.cx-import-tooltip img {
  padding-right: 5px;
  padding-bottom: 2px;
}

.cx-import-tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 54px;
  margin-left: -5px;
  border-width: 10px;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}

.cx-import-tooltip:hover .tooltiptext {
  visibility: visible;
}
.listing-page .import-tags-button {
  margin-right: 10px;
}
@media screen and (max-width: 520px) {
  .tags-import-popup .product-upload {
    width: auto;
    margin: 0 auto;
  }
  .product-upload .bordered-box {
    padding: 0.625rem;
  }
  .editPopup.tags-import-popup {
    max-width: 500px;
  }
  .tags-import-popup .product-upload .dateTimeSelect {
    flex-direction: column;
  }
  .tags-import-popup .date-picker {
    max-width: 252px;
  }
}
