.toast .content {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  background: #111;
  border-radius: 5px;
  color: #fff;
  padding: 0.625em;
  width: 60%;
}

.toast .content .close-btn {
  color: #20c05d;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer
}

#app div.toast {
  position: fixed;
  top: 6.250em;
  width: 90%;
  z-index: 999;
}
