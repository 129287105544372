.media-advert-detail .emptyState{
  position: static !important;
  margin-top: 5rem;
  margin-bottom: 2rem;
}
.media-advert-detail .emptyState .submessage{
  margin: 0;
}
.media-advert-detail .btns-wrap{
  margin-bottom: 2rem;
}
.media-advert-detail .add-btn {
  width: 5rem;
  margin: 0 auto;
  padding: 0.125rem 1rem;
  border-radius: 0.125rem;
  border: 1px solid #1557bf;
  color: #1557bf;
  text-align: center;
  font-size: 0.875rem;
  line-height: 2;
  font-weight: 600;
  letter-spacing: -0.0125rem;
  cursor: pointer;
}

.media-advert-detail .timestamp{
  font-size: 0.75rem;
  text-align: right;
}
.media-advert-detail .section-row:after { 
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  font-size:0;
}
.media-advert-detail .section-row-input{
  width: 50%;
  float: left;
  padding-right: 1.25rem;
}
.media-advert-detail .section-row-input-full{
  width: 100%;
  padding-right: 1.25rem;
}
.media-advert-detail .section-row-input-auto{
  width: auto;
  float: left;
  padding-right: 1.25rem;
}
.media-advert-detail .section-row-input-half{
  width: 20%;
  float: left;
  padding-right: 1.25rem;
}

@media (max-width: 36rem) {
  .media-advert-detail  .section-row-input{
      width: 100%;
      padding-right: 0;
  }
  .media-advert-detail  .section-row-input-half{
      width: 50%;
  }
}

.media-advert-detail .drawer {
  max-width: 75rem;
  border: 0.125rem solid #f4f5f7;
  margin: 0 auto 2rem;
}
.media-advert-detail .drawer.active {
  border-color: #1557bf;
  box-shadow: 0 0.25rem 0.625rem 0 rgb(0 0 0 / 10%);
}

.media-advert-detail .drawer-header {
  height: 2rem;
  padding: 0.3rem 0.5rem 0.3rem 1.25rem;
  background-color: #f4f5f7;
  font-size: 0.875rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.media-advert-detail .drawer.active .drawer-header{
  background-color: #1557bf;
}

.media-advert-detail .drawer-header .title {
  display: inline-block;
  color: #2b3238;
  font-size: 0.875rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.0125rem;
}
.media-advert-detail .drawer.active .drawer-header .title {
  color: #ffffff;
}
.media-advert-detail .drawer-header .icons{
  display: flex;
}
.media-advert-detail .drawer-header .icons .icon{
  margin: 0 0.5rem;
  cursor: pointer;
}

.media-advert-detail .drawer-details {
  display: flex;
  flex-wrap: wrap;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}
.media-advert-detail .drawer .btns{
  margin: 1.25rem;
  text-align: center;
}

.media-advert-detail .drawer .cr_wrap{
  width: 100%;
  background: #f4f5f7;
  margin: 0 1.25rem 1.25rem 0;
  padding: 1rem;
  font-size: 0.75rem;
}
.media-advert-detail .drawer .cr_title{
  font-weight: bold;
  padding-bottom: 0.5rem;
}
.media-advert-detail .drawer #upload_button{
  margin-bottom: 1rem;
}

.media-advert-detail .drawer .field-no-margin-btm,
.media-advert-detail .drawer .field-no-margin-btm .radio-label{
  margin-bottom: 0;
}

.media-advert-detail .drawer #upload_button{
  margin-bottom: 1rem;
}

.media-advert-detail .drawer .img_preview img{
  max-width: 37.5rem;
  max-height: 20rem;
  margin-bottom: 1rem;
  display: block;
}

.media-advert-detail .drawer .input-hidden{
  display: none;
}

.media-advert-detail .readonly .input-wrapper,
.media-advert-detail .readonly .Radio .radio-label{
  cursor: not-allowed;
}
.media-advert-detail .readonly .Radio .radio-label.selected::before{
  background-color: #ccc;
  border-color: #ccc;
}
.media-advert-detail .readonly .Radio .radio-label.selected{
  border-color: var(--border-color)
}


.button.delete {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border: 1px solid transparent;
  margin-left: 0.625rem;
  padding: 0 1rem;
  text-align: center;
}


.creative-requirement-summary-wrap{
  margin: -1.875rem 0 1.875rem 0;
}
.creative-requirement-summary-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.creative-requirement-summary-inner {
  display: flex;
  flex-wrap: wrap;
  background-color: #f0f5f8;
  padding: 0.625rem;
}
.creative-requirement-summary-inner .creative-requirement-summary-item:not(.description){
  width: 50%;
}
.creative-requirement-summary-inner .creative-requirement-summary-item.description{
  width: 100%;
}
.creative-requirement-summary-inner .black{
  color: #000;
}
.creative-requirement-summary-inner .advertiser-name{
  background: #fff;
  margin: 0 0.375rem;
  padding: 0.125rem 0.375rem;
  display: inline-block;
  border-radius: 0.375rem;
}