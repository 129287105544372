.leave-summary-page .main-heading {
    display: grid;
    grid-template-columns: 10fr 2fr;
}

.leave-summary-page .status-selector {
  display: none;
  margin-bottom:0px;
}

.leave-summary-page .status-selector.active {
  display: block;
  margin-bottom:10px;
} 

.leave-summary-page .main-heading > button {
  margin-bottom: 38px;
  display: flex;
  justify-content: flex-end;
}

.leave-summary-page .rbc-show-more {
    margin-left:0.625rem;
    font-weight: normal;
    font-size: 0.625rem;
}

.leave-summary-page .rbc-event-continues-after:not(.rbc-event-continues-prior) {
    margin-left: 1.375rem;
}

.leave-summary-page .rbc-event .custom-event .user-image-url {
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    height: 21.2px;
    width: 21.2px;
    position: absolute;
    top: 0px;
    left: -10px;
}

.leave-summary-page .rbc-event .custom-event img {
    display: none;
    height: 21.2px;
    width: 21.2px;
    transform: scale(1.1)
}

.leave-summary-page .rbc-event-continues-after:not(.rbc-event-continues-prior)  .custom-event img {
    display: inline-block;
}


.leave-summary-page .rbc-event-content {
    padding:0 .6rem;
}

.leave-summary-page .rbc-row-segment {
  padding: 0;
}

.leave-summary-page .rbc-row:not(:first-child) {
  padding:.2rem 0;
}

.leave-summary-page input[type="submit"], .button {
    padding: 0 1rem;
}

.leave-summary-page .button {
    min-width: 24px;
}

.leave-summary-page .today {
    background:#2b3238;
    color: #fff;
    border-radius: 2px;
    padding:0 2rem;
}

.leave-summary-page .selector-wrapper ul {
    padding:0;
}
.leave-summary-page .selector-wrapper li {
    display: inline-block;
    margin: 0 .8rem;
    font-size: .75rem;
    text-transform: capitalize;
    position: relative;
}

.leave-summary-page .selector-wrapper li::before {
    content: '';
    border-radius: 50%;
    width: 7px;
    height: 7px;
    position: absolute;
    left: -11px;
    top: 6px;
    background: black;
}

.leave-summary-page li.EARNED::before {
    background: #1ec05c;
}

.leave-summary-page li.SICK::before {
    background: #eb8181;
}

.leave-summary-page li.MATERNITY::before {
  background: #80959d;
}

.leave-summary-page li.PATERNITY::before {
  background: #f8ac59;
}

.leave-summary-page li.HALFDAY::before {
  background: #45c0ff;
}

.leave-summary-page .selector{
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    margin-bottom: 25px;
}

.leave-summary-page .caption {
    font-size: 1.125rem;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: -0.5px;
    color: #2b3238;    
    margin:0 1rem;
}

  .leave-summary-page .rbc-off-range-bg {
    background:repeating-linear-gradient(45deg,#fff,#fff 4px,#e5e5e5 2px, #e5e5e5 5px)
  }

  .leave-summary-page .rbc-today {
    background: #fff;
  }

  .leave-summary-page .rbc-date-cell {
    padding:5px;
    text-align: left;
  }

  .leave-summary-page .rbc-date-cell a {
    font-weight: 600;
    font-size: 12px;

  }
  .leave-summary-page .rbc-now a {
    border-radius: 50%;
    background:black;
    font-weight: bolder;
    color:white;
    padding:.1rem .3rem .1rem .3rem;
  }

  .leave-summary-page .selector li.EARNED {
    background: #1ec05c;
  }
  .leave-summary-page .selector li.SICK {
    background: #eb8181
  }
  .leave-summary-page .selector li.PATERNITY {
    background: #f8ac59
  }
  .leave-summary-page .selector li.MATERNITY {
    background: #80959d
  }
  .leave-summary-page .selector li.HALFDAY {
    background: #45c0ff
  }

  /* Leave cell */
  .leave-summary-page .rbc-event{
    white-space: nowrap;
    padding: 2px 10px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 0px;
    position: relative;
    min-height: 21px;
  }

  /* Toolbar */

  .leave-summary-page .toolbar {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top:0;
  }

  /* Buttons */
  .leave-summary-page .button-group {
    margin: 0 -5px;
  }
  
  .leave-summary-page .button-group .button {
      height: 32px;
      line-height: 28px;
      margin: 5px;
  }

  .leave-summary-page .button-group .button img {
      width: 80%;
      margin: 0 auto;
  }

  .leave-summary-page .status-selector {
    padding-left: 1px;
    overflow:auto;
  }

  .leave-summary-page .Radio {
    width: 700px;
  }
  .leave-summary-page .field.status-selector.active .Radio {
    flex-wrap: nowrap;
  }

  @media only screen and (max-width: 640px) {
    .leave-summary-page .button-group .today {
      padding:0;
    }
  }

  @media only screen and (max-width: 780px) {
    .leave-summary-page .button-group {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .leave-summary-page .button-group .button img {
      width: unset;
    }

    .leave-summary-page .button-group .today {
      grid-column-end: span 2;
    }
    .leave-summary-page .button-group {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .leave-summary-page .button-group .previous {
      grid-column-start: 1;
      grid-row-start: 1;
    }
    .leave-summary-page .button-group .next {
      grid-column-start: 4;
      grid-row-start: 1;
    }
    .leave-summary-page .button-group .today.button {
      grid-column-start: 2;
      grid-column-end: 4;
    }
  }

  @media only screen and (max-width: 480px) {
    .leave-summary-page .main-heading {
      margin-bottom: -15px;
    }
  .leave-summary-page .toolbar {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 10px 25px 20px;
    border-top: 1px solid #efefef;
    margin: 0 -25px;
  }
  .leave-summary-page .toolbar .selector-wrapper {
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 3;

  }
  .leave-summary-page .caption {
    margin-left: 0px;
  }
  }