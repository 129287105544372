.slots-form .Radio {
  margin-bottom: 15px;
}

.slot-rules-page .slot-configure-button {
  margin-right: 20px;
  font-size: 0.875rem !important;
}

.slot-rules-page .pagination-container {
  display: none;
}

.editPopup.slot-rules-page {
  max-width: 300px;
}

.editPopup.slot-rules-page .Radio .radio-label {
  background-color: #fbfcfc;
  border-color: #e9eaeb;
}

.editPopup.slot-rules-page .Radio .radio-label.selected {
  border-color: #e9eaeb;
  background-color: #fff;
}

.slot-rules-page .table {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.slot-rules-page .table .table-row {
  display: flex;
  background-color: #fbfcfc;
  border: 1px solid #e9eaeb;
  justify-content: space-between;
}
.slot-rules-page .table .table-row .table-cell {
  border-top: none;
  border-bottom: none;
  padding: 10px 15px;
}
.slot-rules-page .table .table-row .table-cell:last-child {
  padding-left: 0;
}
.slot-rules-page .DropDown .dropdown-menu {
  width: 84px;
}
.slot-rules-page .table-cell.slot-time {
  font-weight: 600;
}
.slot-rules-page .slot-config-form {
  margin-top: 50px;
}
.slot-config-form span.input::after {
  content: 'Days';
  border: 1px solid #dadee0;;
  border-left: 0;
  background-color: #fbfcfc;
  font-size: 0.8rem;
  color: #2b3238;
  padding: 10px;
}
.slot-rules-page .slot-config-form input {
  max-width: 90px; 
}

@media screen and (max-width: 768px) {
  .slot-rules-page .table {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 480px) {
  .slots-form .form-field {
    display: inherit;
  }
  .slot-rules-page .table {
    grid-template-columns: 1fr 1fr;
  }
  .slot-rules-page .table-container {
    margin-left: 0px;
    margin-right: 0px;
  }
  .slot-config-form .primary, .slots-form .primary{
    min-width: 100%;
  }
  .slot-config-form form div:first-child .input-wrapper input{
    max-width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .slot-rules-page .table {
    grid-template-columns: 1fr;
  }
}
