.egifting-orders-page .table-responsive {
  display: block;
  overflow-y: auto;
}

.egifting-orders-page .disabled {
  pointer-events: none;
  opacity: 0.5;
}
.egifting-orders-page .order-number {
  color: #2b3238;
  cursor: pointer;
}

.egifting-orders-page .order-number:hover {
  color: #1557bf;
}
