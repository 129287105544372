.z-button {
  display: inline-block;
  color: #000000;
  background-color: rgb(225, 225, 225);
  border: none;
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  margin: 10px;
}

@media only screen and (max-width: 400px) {
  .z-button {
    padding: 0.8rem 1.5rem;
    font-size: 0.8rem;
  }
}
