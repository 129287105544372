.store-override .tabs-container {
  margin: 30px -40px -30px;
  padding: 40px 40px 30px;
  background-color: #fbfcfc;
}
.store-override .tab-contents {
  border: 1px solid #eaefef;
  margin: -1px 0px;
  padding: 0 20px 30px;
  background-color: #fff;
  position: relative;
}
.store-override .search-listing .Product-search {
  padding-top: 30px;
}
.store-override .search-listing .Category-search {
  display: flex;
  padding-top: 30px;
}
.store-override form {
  max-width: 100%;
}

.store-override .tab-contents .Searchable {
  width: 400px;
}
.store-override .tab-contents .listing-page .header-actions-wrapper {
  padding-top: 30px;
}
.store-override .config-variables {
  display: flex;
}
.store-override .config-variables .field {
  min-width: 205px;
  margin-right: 20px;
}

.store-override .listing-page .name {
  min-width: 200px;
  text-transform: capitalize;
}

.stockForm h4 {
  margin-left: 20px;
  font-weight: normal;
  text-transform: capitalize;
}

.store-override .listing-page .filters-wrapper {
  margin-top: -65px;
}

.store-override .table-header .table-cell:nth-child(3),
.store-override .table-header .table-cell:nth-child(4),
.store-override .table-header .table-cell:nth-child(5) {
  text-align: right;
}

.store-override .submit-button {
  margin-top: 25px;
}

.store-override .submit-button input[type='submit'] {
  height: 44px;
}

.store-override .listing-page .name span:hover {
  color: #1557bf;
  cursor: pointer;
}
.store-override .config-variables div {
  flex-grow: 1;
}

.stockForm .form-fields .field:not(:last-child) {
  margin-right: 20px;
}

.stockForm .form-fields {
  display: flex;
}

.store-override .entity-list .header-container {
  position: absolute;
  right: 0;
  top: -83px;
}

.store-override .entity-list .table-container {
  padding-top: 20px;
}

.store-override .emptyState {
  padding-top: 100px;
}

.stockForm .flex {
  margin-bottom: 20px;
  justify-content: center;
}

.store-override .table .store-mobile-header {
  display: none;
}

.stockForm .form-actions.add,
.stockForm .form-actions.edit {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.stockForm .form-actions.add .button {
  margin-left: 0px;
  margin-right: 10px;
}
@media screen and (max-width: 480px) {
  .store-override .table .table-header {
    display: none;
  }

  .store-override .table-container {
    margin: 0 auto;
    padding: 0;
  }

  .store-override .table .table-row .table-cell {
    border: none;
    padding: 15px;
  }
  .store-override .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    border: 1px solid #eaefef;
    background: #ffffff;
    margin: 15px 0;
  }
  .stockForm .form-fields {
    flex-direction: column;
  }
  .stockForm .form-fields .field:not(:last-child) {
    margin-right: 0px;
  }
  .stockForm .form-actions {
    display: flex;
  }
  .stockForm .form-actions .primary,
  .stockForm .form-actions .button {
    flex: 1;
  }
  .store-override .config-variables {
    margin-top: 1rem;
    flex-direction: column;
  }
  .store-override .config-variables .submit-button .primary {
    width: 48%;
  }
  .store-override .submit-button {
    margin-top: 0px;
  }
  .store-override .table-container .table {
    display: block;
  }
  .store-override .tabs-container .tabs .tab {
    padding: 18px 30px;
  }
  .store-override .table .store-mobile-header {
    font-size: 11px;
    color: #80959d;
    margin-right: 4px;
    display: inline;
    font-weight: normal;
  }
  .store-override .table .store-mobile-header::after {
    content: ':';
    margin-left: 2px;
  }
  .store-override .table .table-cell.store-actions {
    display: inline-block;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .store-override .table .table-cell.name {
    min-width: auto;
    position: absolute;
    left: 65px;
    top: 0px;
    font-weight: 600;
  }
  .store-override .table .table-cell.store-stock-buffer {
    text-align: left;
  }
  .store-override .table .table-cell.store-stock-maxpurchase {
    padding-left: 0px;
    margin-left: -10px;
  }
  .store-override .table .table-cell.store-stock-maxpurchase,
  .store-override .table .table-cell.store-stock-buffer {
    border-top: 1px solid #eaefef;
    background-color: #fbfcfc;
    font-size: 13px;
    font-weight: 600;
    grid-row-start: 2;
  }
  .store-override .table .table-cell.store-stock-buffer {
    padding-right: 0px;
    z-index: 2;
  }
  .stockForm .form-actions.edit {
    margin-top: 15px;
  }
  .stockForm .form-actions.edit .button {
    margin-left: 0px;
  }
  .store-override .tab-contents .Searchable {
    width: 250px;
  }
}
