@use 'styles/colors' as *;

form {
  max-width: 32rem;
}

.field {
  margin-bottom: 1.25rem;
  position: relative;
}
#portal .field {
  margin-bottom: 1.125rem;
}

.field label {
  display: inline-block;
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0.625rem;
  letter-spacing: -0.0125rem;
  cursor: pointer;
  user-select: none;
  transition: color 0.2s ease-in-out;
}
label.inline {
  display: inline-block;
  text-transform: capitalize;
  color: #80959d;
  line-height: 1.5;
  vertical-align: top;
  margin: 0;
}

.input {
  display: flex;
  position: relative;
}

.input {
  .secondary-action {
    position: absolute;
    right: 10px;
    top: 10px;
    color: $fp-blue-1;
    font-size: 14px;
  }
  .icon-suffix {
    position: absolute;
    right: 0.5rem;
    top: 20%;

    &:hover {
      cursor: pointer;
    }
  }
}

input:read-only {
  cursor: not-allowed;
}

input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}

select:disabled {
  cursor: not-allowed;
}

@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='tel'],
select {
  font-size: 0.875rem;
  background-color: #fff;
  padding: 0 0.9375rem;
  border: 0.0625rem solid var(--border-color);
  border-radius: 0.125rem;
  height: 2.75rem;
  width: 100%;
  vertical-align: middle;
  flex: 1;
  color: #2b3238;
}

input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
input[type='url'],
input[type='tel'] {
  font-weight: 600;
}

.field > .description {
  margin-top: 0.625rem;
  margin-bottom: 0;
  color: #80959d;
  font-size: 12px;
  letter-spacing: -0.0063rem;
}

input::placeholder,
textarea::placeholder {
  color: #aeb9bd;
  font-size: 0.875rem;
  font-weight: normal;
}

.input-addon {
  display: flex;
  align-items: center;
  height: 2.75rem;
  padding: 0 0.9375rem;
  background-color: #f3f5f6;
  border: 0.0625rem solid var(--border-color);
  font-size: 0.875rem;
  font-weight: 600;
  vertical-align: middle;
}

.input-addon:first-child {
  border-right: none;
  border-radius: 0.125rem 0 0 0.125rem;
}

.input-addon:last-child {
  border-left: none;
  border-radius: 0 0.125rem 0.125rem 0;
}

.input-addon + input {
  border-radius: 0;
  /*width: auto;*/
}

input[type='submit'],
.button {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

button {
  border: 0.0625rem solid transparent;
  display: inline-block;
  cursor: pointer;
}

button[disabled] {
  cursor: not-allowed !important;
}

.primary {
  background-color: #1557bf !important;
  color: #fff !important;
}
input:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

input[type='submit'] + button,
.button-white {
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #f3f5f6;
  color: #2b3238;
  margin-left: 0.625rem;
  letter-spacing: -0.0187rem;
}

input,
textarea,
button {
  transition: all 200ms ease-in-out;
  vertical-align: middle;
}

input:focus,
textarea:focus {
  border-color: #1557bf;
  outline: none;
}

input.focus-none:focus {
  border: 0.0625rem solid var(--border-color);
}

input:invalid {
  box-shadow: none;
}

input[type='file']:focus + .file-label {
  border-color: #1557bf;
}

textarea {
  font-size: 0.875rem;
  width: 100%;
  height: 11.25rem;
  border: 0.0625rem solid var(--border-color);
  border-radius: 0.125rem;
  padding: 0.75rem 0.9375rem;
  background-color: #fff;
}

.form-error {
  color: #ea353a;
  text-align: center;
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
}

.labelWrap {
  display: flex;
}

form label.thin {
  color: #80959d;
  font-weight: normal;
}

form .form-buttons > button:not(:first-child) {
  margin-left: 0.625rem;
}

.field.seo-category-configure {
  flex-direction: column;
  display: flex;
  margin-left: auto;
  width: 15.625rem;
}
.search-campaigns-popup .label-tooltip, .with-tooltip .label-tooltip {
  font-size: 0.75rem;
  margin-left: 1em;
  position: relative;
}

.search-campaigns-popup .label-tooltip .label-tooltiptext, .with-tooltip .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 18.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: -3.155rem;
  text-align: left;
}
.search-campaigns-popup .label-tooltip .label-tooltiptext::after, .with-tooltip .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.search-campaigns-popup .label-tooltip:hover .label-tooltiptext, .with-tooltip .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}
@media screen and (max-width: 30rem) {
  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 1rem;
  }
}
