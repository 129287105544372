.order-log-component {
  padding: 1rem;
}
.order-reference h4 {
  margin-bottom: 0;
}

.order-log-title {
  font-size: 1.125rem;
  color: #2b3238;
  font-weight: bold;
}

.qc-order-log {
  margin-top: 2.2rem;
}

.qc-order-log .log-date-time {
  min-width: 105px;
  color: #80959d;
  font-size: 0.75rem;
  border-right: 2px solid #8b9fa6;
  position: relative;
}

.qc-order-log .log-date-time .log-date {
  min-width: 12rem;
  color: #333333;
  font-size: 1rem;
  border-right: 2px solid #8b9fa6;
  position: relative;
}

.qc-order-log .log-time {
  min-width: 12rem;
  color: #333333;
  font-size: 1rem;
  position: relative;
}

.qc-order-log .log-date-time .log-date {
  font-weight: 600;
}

.qc-order-log .log-box:last-child .log-date-time {
  border-right: none;
}

.qc-order-log .placed-from,
.qc-order-log .updated-by,
.qc-order-log .COMPLETED,
.qc-order-log .DISPATCHED,
.qc-order-log .PACKED,
.qc-order-log .PICKING,
.qc-order-log .PICKED,
.qc-order-log .order-return,
.qc-order-log .CANCELLED,
.qc-order-log .CHECKED {
  position: relative;
}

.qc-order-log .placed-from:first-child::before,
.qc-order-log .updated-by:first-child::before,
.qc-order-log .COMPLETED:first-child::before,
.qc-order-log .DISPATCHED:first-child::before,
.qc-order-log .PACKED:first-child::before,
.qc-order-log .PICKING:first-child::before,
.qc-order-log .PICKED:first-child::before,
.qc-order-log .order-return:first-child::before,
.qc-order-log .CANCELLED:first-child::before,
.qc-order-log .CHECKED:first-child::before {
  content: '';
  position: absolute;
  left: -4.8rem;
  top: -1rem;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 100%;
  border: 1px solid #eaefef;
  background: #fbfcfc;
  background-repeat: no-repeat;
  background-position: 50%;
}

.qc-order-log .placed-from:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .updated-by:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .COMPLETED:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .DISPATCHED:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .PACKED:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .order-return:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .CANCELLED:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .CHECKED:first-child::before {
  background-image: url('state-icon.svg');
}

.qc-order-log .PICKED:first-child::before,
.qc-order-log .PICKING:first-child::before {
  background-image: url('state-icon.svg');
}

.order-log
  .log-box:last-child
  div.placed-from
  + div.updated-by:last-child::before {
  content: none;
}

.qc-order-log .log-details .order-date {
  font-size: 1rem;
}

.qc-order-log .log-details .order-slot {
  font-size: 1rem;
}

.qc-order-log .log-details > div:not(:first-child) {
  margin-top: 10px;
  padding-top: 10px;
}

.qc-order-log .log-box {
  display: flex;
}

.qc-order-log .log-box:first-child {
  margin-top: 10px;
}

.qc-order-log .bold {
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: -0.3px;
  color: #333333;
}

.qc-order-log table {
  width: 100%;
  min-width: 500px;
  margin-top: -12px;
}

.qc-order-log .log-details .map-with-path {
  max-width: 600px;
  overflow-x: auto;
}

.qc-order-log .log-details .map-with-path .address-map {
  min-width: 800px;
}

.qc-order-log .log-box:not(:first-child) .map-with-path {
  display: none;
}

.qc-order-log .log-details-wrapper {
  padding: 0.3rem 1.25rem 0.5rem;
  border: 1px solid #eaefef;
  display: inline-block;
  min-width: 168.1px;
  min-height: 68px;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  position: relative;
}

.qc-order-log .log-details-wrapper .tabular-data h4 {
  font-weight: 600;
  margin-top: 18px;
}

.qc-order-log .log-details-wrapper .payment-changed,
.qc-order-log .log-details-wrapper .amount-changed {
  font-weight: 600;
  margin-top: 20px;
}

.log-details-wrapper::before {
  width: 18px;
  height: 10px;
  position: absolute;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 12px solid #eaefef;
  content: '';
  left: -18px;
  top: 8px;
  z-index: 2;
}

.log-details-wrapper::after {
  width: 22px;
  height: 10px;
  position: absolute;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-right: 11px solid #fff;
  content: '';
  left: -20px;
  top: 8px;
  z-index: 10;
}

.qc-order-log .log-details-wrapper div {
  margin: 10px 0;
}

.qc-order-log .log-details {
  padding: 20px 44px;
  padding-top: 0px;
  font-size: 0.875rem;
  flex: 1;
}

.qc-order-log .table-header {
  border-collapse: collapse;
  width: 100%;
  font-size: 0.75rem;
}

.qc-order-log tbody tr {
  border-bottom: 1px solid #ececec;
}
.qc-order-log .table-body tr td {
  padding: 15px;
}

.qc-order-log .table-header tr th {
  border-bottom: 1px solid #ececec;
  padding: 8px 12px;
  text-align: left;
  color: #80959d;
  font-weight: 600;
}

.qc-order-log .table-stall-name {
  font-weight: bold;
  font-size: 1rem;
}

.qc-order-log tr th:first-child,
.qc-order-log tr td:first-child {
  padding-left: 0;
}

.qc-order-log tr th:first-child,
.qc-order-log tr td:first-child {
  padding-right: 0;
}

.qc-order-log .log-details .user-name {
  font-weight: 500;
  font-family: 'Open Sans', sans-serif;
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: -0.2px;
  color: #80959d;
  margin: 4px 0;
}

.qc-order-log .log-details .user-name .by {
  font-weight: normal;
}
.qc-order-log .log-details .status-changed {
  font-size: 1rem;
}
.qc-order-log .log-details a {
  color: #2b3238;
}

.qc-order-log .log-details a:hover {
  color: #1557bf;
}

.qc-order-log .tag-label {
  font-size: 0.8rem;
  width: 100%;
}

.qc-order-log .tag-label span {
  display: inline-block;
}

.qc-order-log .tag-label .curve-box {
  background: #8b9fa6;
  color: #ffff;
  width: 100px;
  height: 20px;
  text-align: center;
  border-radius: 10px;
}

.map-with-path .gm-svpc > div {
  margin-top: 0px;
}

@media screen and (max-width: 800px) {
  .qc-order-log .tabular-data {
    overflow-x: auto;
    max-width: 400px;
  }

  .qc-order-log .log-details .map-with-path {
    min-width: 400px;
  }
}

@media screen and (max-width: 570px) {
  .qc-order-log {
    margin-left: 20px;
    margin-top: 0px;
  }

  .qc-order-log .log-date-time {
    border-right: none;
    margin-left: -1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }

  .qc-order-log .log-box {
    flex-direction: column;
  }
  .qc-order-log .log-details {
    border-left: 1px solid #dadee0;
    border-top: 0;
  }
  .qc-order-log .table-header tr th,
  .qc-order-log .table-body tr td {
    padding: 1vw;
    font-size: 12px;
  }

  .qc-order-log .log-box:last-child .log-details {
    border-left: none;
  }

  .qc-order-log .tabular-data {
    overflow-x: auto;
    max-width: 300px;
  }
  .qc-order-log .log-details {
    padding-right: 0px;
  }
  .qc-order-log .log-details .log-details-wrapper {
    width: 100%;
  }
}
