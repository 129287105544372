.qc-orders-page .header-container {
  display: none;
}

.qc-orders-page.table-row .table-cell {
  height: 70px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.qc-orders-page.dropdown-menu {
  width: 154px;
}

.qc-orders-page.order-number {
  color: #2b3238;
  cursor: pointer;
}

.qc-orders-page.order-number:hover {
  color: #1557bf;
}

.qc-orders-page.dropdown-toggle {
  padding: 12px;
  margin: -12px;
}

.qc-orders-page.pending-amount,
.qc-orders-page.refund-amount {
  color: #80959d;
}

.qc-orders-page.table-row:hover .pending-amount,
.qc-orders-page.table-row .fill-rate {
  color: #eb8181;
}

.qc-orders-page.table-row:hover .refund-amount {
  color: #1557bf;
}
.qc-orders-page.column-customer-name .cx-name-text {
  max-width: 110px;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}
.qc-orders-page.column-order-fillrate {
  min-width: 87px;
}
.qc-orders-page.column-creation-time {
  min-width: 97px;
}
.qc-orders-page.column-completion-time {
  min-width: 89px;
}
.qc-orders-page.table .table-cell:last-child {
  max-width: 55px;
}
.qc-orders-page.table .table-cell:first-child {
  padding-left: 10px;
}
.qc-orders-page.table .table-cell {
  vertical-align: baseline;
}
.qc-orders-page.customer-actions {
  margin-top: 2px;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  max-width: 112px;
}

.qc-orders-page.customer-actions .CustomerAddressWidget {
  grid-column-start: 1;
}
.qc-orders-page.customer-actions .CustomerEmailWidget {
  grid-column-start: 2;
}
.qc-orders-page.customer-actions .CustomerCallWidget {
  grid-column-start: 3;
}

.qc-orders-page.customer-actions .CustomerAddressWidget .address-icon {
  margin-right: 0px;
}

.qc-orders-page.customer-name {
  cursor: pointer;
  color: #2b3238;
  text-transform: capitalize;
}

.qc-orders-page.customer-name:hover {
  color: #1557bf;
}
.qc-orders-page.pre-order {
  display: flex;
}
.qc-orders-page.pre-order span {
  order: 1;
}

.qc-orders-page.address-icon {
  display: inline-block;
  width: 14px;
  height: 24px;
  background-position: center;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  margin-right: 30px;
}

.qc-orders-page.CustomerEmailWidget,
.qc-orders-page.CustomerCallWidget {
  margin-right: 4px;
  height: 24px;
  width: 24px;
}

.qc-orders-page.order-status {
  text-transform: uppercase;
}

.qc-orders-page.DetailsCard {
  max-width: initial;
}
.qc-orders-page.editPopup .address-map {
  height: 300px;
}

.editPopup.order-log {
  max-width: 1000px;
}

.qc-orders-page.prefix {
  margin-right: -4px;
}

.qc-orders-page.container-order-number .order-type-icons .image {
  background: transparent;
}

.form-fields.date-fields .date-picker .DateRangePicker {
  width: 100%;
}

.form-fields.date-fields .date-picker .DateRangePicker .DateInput {
  width: 9.375rem;
}

.form-fields.date-fields .date-picker .DateRangePicker .CalendarDay__selected,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected_start.CalendarDay__selected_span,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected_end.CalendarDay__selected_span,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__default:hover,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected__start,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected__end,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected:active,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected:hover {
  background: #1557bf;
  border-color: #1557bf;
  color: #fff;
}

.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__selected_span,
.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .CalendarDay__hovered_span {
  background: #e8f0fd;
  border-color: #e8f0fd;
  color: #333;
}

.form-fields.date-fields
  .date-picker
  .DateRangePicker
  .DayPickerKeyboardShortcuts_show__bottomRight {
  border-right-color: #1557bf;
}

@media screen and (max-width: 840px) {
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-order-number
    a {
    color: #1557bf;
    font-weight: 900;
  }
  .qc-orders-page.table.table-dynamic
    .table-row
    .table-cell.column-customer-name,
  .qc-orders-page.table.table-dynamic .table-row .table-cell.column-brand-name,
  .qc-orders-page.table.table-dynamic
    .table-row
    .table-cell.column-order-amount {
    display: none;
  }
  .qc-orders-page.table.table-dynamic
    .table-row
    .table-cell.column-order-status {
    position: absolute;
    right: 0px;
    top: 0px;
    text-align: right;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-order-status
    > div {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-order-status::before {
    content: 'Order Status';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-completion-time {
    position: absolute;
    right: 0;
    top: 52px;
    color: #80959d;
    font-size: 0.75rem;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-completion-time
    div {
    display: inline;
    padding-right: 5px;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-creation-time {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 12px 20px;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-creation-time
    div {
    display: inline;
    color: #80959d;
    padding-right: 5px;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-order-actions {
    position: absolute;
    bottom: 14px;
    right: 20px;
    padding: 0;
    height: auto;
  }
}

@media only screen and (max-width: 650px) {
  .qc-orders-page.table .table-row .table-cell small,
  .qc-orders-page.table .table-row .table-cell span,
  .qc-orders-page.table .table-row .table-cell div {
    font-size: 0.75rem;
  }
  .qc-orders-page.order-type-icon-delivery {
    margin-top: 3px;
  }

  .qc-orders-page.table .table-row .table-cell .dropdown-item {
    font-size: 13px;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-creation-time {
    padding: 10px 15px;
  }
  .qc-orders-page.table .table-row .order-type-icon-pickup,
  .qc-orders-page.table .table-row .order-type-icon-delivery {
    padding: 10px 15px;
  }
  .qc-orders-page.filters-wrapper .primary,
  .qc-orders-page.filters-wrapper .button {
    min-width: 48%;
  }
}

@media (max-width: 580px) {
  .qc-orders-page.table.table-dynamic .table-row {
    margin: 10px 20px !important;
    width: auto;
  }
  .qc-orders-page .table-container {
    margin: 10px -15px 0 -15px;
  }
  .qc-orders-page .header-container .header-actions-wrapper {
    margin-top: -4px;
    margin-right: -5px;
  }
  .qc-orders-page .table-container .table .table-row .order-number {
    font-size: 14px;
    font-weight: 600;
  }
  .qc-orders-page .filters-wrapper .button {
    margin-top: 0px;
  }
  .qc-orders-page.table-container .table-cell .order-number span {
    font-size: 14px;
    font-weight: 600;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-creation-time
    div,
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-creation-time
    small {
    font-size: 13px;
  }
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-order-status::before,
  .orders-page
    .table.table-dynamic
    .table-row
    .table-cell.column-order-status::before {
    font-size: 11px;
  }
  .qc-orders-page.table.table-dynamic .order-number,
  .qc-orders-page.table.table-dynamic .store-name {
    margin-left: 0px;
  }
  .qc-orders-page.dropdown-toggle {
    margin-right: -15px;
  }
  .qc-orders-page.table .table-cell .order-type-icons {
    margin-left: -8px;
  }
  .qc-orders-page.table .table-cell .order-type-icons .image {
    margin-right: 5px;
  }
  .search_n_filter {
    margin-top: 0.5rem;
    width: 100%;
  }
}

.qc-orders-page .fullWidth {
  width: 100%;
}
.qc-orders-page .search_n_filter {
  margin-top: 0.5rem;
}

.search-fields .search_n_filter .selected {
  border: 0.0625rem solid #1557bf;
  background-color: #e8f0fd !important;
  color: #1557bf !important;
  margin-left: 0.625rem;
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.search_n_filter .filter-button {
  border: 0.0625rem solid #1557bf;
  background-color: #fff !important;
  color: #1557bf !important;
  margin-left: 0.625rem;
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.qc-orders-page .filters-wrapper {
  margin-bottom: 0 !important;
}

.qc-orders-page .search-position {
  padding-left: 0px !important;
}
