.store-group-box {
  transition: height 0.3s ease-in-out;
}
.store-group-box.contracted {
  height: 0;
  overflow: hidden;
}
.store-group-box.expanded {
  margin-top: 0.625rem;
  height: max-content;
}

.toggle-expand {
  display: flex;
  align-items: center;
  margin-top: 0.625rem;
}
.toggle-expand .store-type.open {
  font-weight: 700;
}
.toggle-expand .toggle-store-visibility {
  cursor: pointer;
  margin-right: 0.5rem;
}

.flex {
  display: flex;
  align-items: center;
}

.margin-top__small {
  margin-top: 0.75rem;
}

.categorized-selector-container {
  max-width: 28.125rem;
}
