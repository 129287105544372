.permissions-form input[type="checkbox"] + label {
  cursor: pointer;
}

.permissions-form input[name="toggle-all"] + label {
  font-weight: 600;
}

.permissions-form .checkboxes-container input[type="checkbox"] + label {
  padding-left: 18px;
  margin-top: 2px;
}

.permissions-form .outer-container {
  border: 1px solid #eaefef;
  display: flex;
  width: 100%;
  margin-bottom: 40px;
  flex-direction: row-reverse;
}

.permissions-form .fields-container {
  flex: 0 0 auto;
}

.permissions-form .checkboxes-container {
  padding: 24px;
  overflow: auto;
  height: calc(100% - 56px);
}

.permissions-form .user-list-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fbfcfc;
  flex: 1 1 auto;
  padding: 80px;
}

.permissions-form .multi-checkbox-group[data-depth="1"] > div > .Checkbox {
  font-weight: bold;
}

.permissions-form .user-list-container h4 {
  text-transform: uppercase;
  font-size: 0.75rem;
  margin-top: 0;
}

.permissions-form .permissions-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.permissions-form .permissions-row:not(:last-child) {
  margin-bottom: 12px;
}

.permissions-form .permissions-row:not(:first-child) {
  margin-top: 12px;
}

.permissions-form .section-heading {
  font-weight: 600;
  margin: 0 0 14px 0;
  padding-bottom: 14px;
  border-bottom: 1px solid #f5f5f5;
}

.permissions-form .permissions-section:not(:first-child) .section-heading {
  border-top: 1px solid #f5f5f5;
  margin-top: 30px;
  padding-top: 14px;
}

.permissions-form .permission-label {
  flex: 1 0 auto;
}

.permissions-form .read-toggle-container,
.permissions-form .write-toggle-container {
  width: 60px;
  text-align: right;
  font-weight: 600;
  font-size: 0.75rem;
  color: #80959d;
}

.permissions-form .toggle-all-container {
  border-bottom: 1px solid #eaefef;
  padding: 0 24px;
  display: flex;
  align-items: center;
  height: 56px;
}

.permissions-form .disabled-section {
  opacity: 0.5;
  cursor: not-allowed;
}

.permissions-form .loading-section {
  opacity: 0.5;
  cursor: wait;
}

.permissions-form .disabled-section > *,
.permissions-form .loading-section > * {
  pointer-events: none;
}

@media screen and (max-width: 550px) {
  .permissions-form .user-list-container {
    border-bottom: 1px solid #eaefef;
    padding: 24px;
  }
  .permissions-form .outer-container {
    flex-direction: column-reverse;
  }
}

@media screen and (min-width: 551px) {
  .permissions-form .toggle-all-container.overflown {
    padding-right: 40px;
  }
  .permissions-form .user-list-container {
    border-right: 1px solid #eaefef;
  }
  .permissions-form .outer-container {
    /* TODO: Find a better way to manage this widget's height */
    height: calc(100vh - 220px);
   }
}