.staff-connection-wrapper {
  .export-button {
    button {
      color: white;
      background-color: #1557bf;
      font-size: 0.875rem;
      border: 1px solid #eaefef;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
    }
  }
}
