@use 'styles/colors' as *;

.offer-form-confirmation-modal {
  .segment-io-audience-container {
    display: flex;
    flex-wrap: wrap;
  }

  .segment-io-audience {
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
    padding: 0.25rem;
    background-color: $fp-grey-4;
    border-radius: 0.5rem;
    font-size: 0.75rem;
  }
}
