.StyledSelect {
  position: relative;
  width: 100%;
}

.StyledSelect .container {
  border: 1px solid var(--border-color);
  background-color: white;
  padding: 10px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.StyledSelect .dropdown {
  position: absolute;
  top: 100%;
  border: 1px solid var(--border-color);
  border-top: none;
  background-color: white;
  width: 100%;
  padding: 5px 0;
}

.StyledSelect .container::after {
  width: 20px;
  height: 10px;
  background-image: url(./arrow.svg);
  content: '';
  align-self: center;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  transition: transform ease-in-out 0.1s;
  transform: rotate(0deg);
}

.StyledSelect.expanded .container::after {
  transform: rotate(180deg);
}

.StyledSelect .dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
  position: relative;
}

.StyledSelect .dropdown-item:hover::before {
  background-color: #1557bf;
  display: block;
  content: '';
  width: 2px;
  height: 100%;
  position: absolute;
  left: -2px;
  top: 0;
}