.notify {
  display: flex;
  justify-content: center;
}
.notify .migration {
  width: 66%;
  border: 1px solid red;
  color: red;
  font-size: 1rem;
  font-weight: bold;
  padding: 8px 32px;
}
