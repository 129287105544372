.products-page .brand-name {
  font-size: 0.875rem;
  font-weight: bold;
}

.products-page .product-name {
  color: #2b3238;
  font-size: 0.875rem;
  cursor: pointer;
  text-decoration: none;
}

.products-page .product-status {
  text-transform: capitalize;
}

.products-page .product-name:hover {
  color: #1557bf;
}

.products-page .dropdown-menu {
  width: 120px;
}

.products-page .bulk-upload-dropdown .dropdown-menu {
  width: 150px;
}

.products-page .table-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.products-page .add-product-dropdown-button {
  width: 150px;
}
.products-page .download-button {
  height: 40px;
  line-height: 40px;
  margin-right: 20px;
}
.products-page .only-mobile {
  display: none;
}
.products-page .not-mobile {
  display: block;
}

@media screen and (max-width: 580px) {
  .products-page .header-actions-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .products-page .header-actions-wrapper .button {
    min-width: 0;
    width: auto;
    font-size: 20px;
    padding: 0 15px;
  }
  .products-page .header-actions-wrapper .button span.text {
    display: none;
  }
}

@media (max-width: 480px) {
  .products-page .only-mobile {
    display: block;
  }
  .products-page .not-mobile {
    display: none;
  }
  .products-page .table-container {
    border-top: 1px solid #eaefef;
    margin: 0px -25px;
    padding: 15px 20px;
  }
  .products-page .table .table-header {
    display: none;
  }
  .products-page .table .table-row {
    display: block;
    margin-bottom: 15px;
    border: 1px solid#eaefef;
    min-height: 129px;
    position: relative;
  }
  .products-page .table .table-row .table-cell div {
    font-size: 0.75rem;
  }
  .products-page .table .table-row:nth-child(2n) {
    background-color: #fff;
  }
  .products-page .table .table-row .table-cell {
    border: none;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .products-page .table-row .table-cell .brand-name {
    color: #2b3238;
    font-weight: 600;
  }
  .products-page .table-row .product-status,
  .products-page .table-row .product-actions,
  .products-page .table-row .primary-category {
    position: absolute;
  }
  .products-page .product-name {
    font-weight: 600;
  }
  .products-page .table-row .primary-category {
    padding-left: 15px;
  }
  .products-page .table-row .product-status {
    right: 6px;
  }
  .products-page .table .table-cell:first-child {
    padding-left: 15px;
  }
  .products-page .table-row .product-actions {
    right: -3px;
    top: 10px;
  }
  .products-page
    .table-row
    .product-actions
    .DropDown
    .dropdown-menu
    .dropdown-items
    .dropdown-item {
    font-size: 13px;
  }
  .products-page .table-row .table-cell.primary-category {
    font-size: 11px;
    background: #fbfcfc;
    width: 100%;
    border-top: 1px solid #eaefef;
    border-bottom: 1px solid #eaefef;
  }
  .products-page .table-row .product-text-muted {
    color: #000;
    font-size: 12px;
  }
  .products-page .table-row .table-cell small.product-text-muted {
    font-size: 12px;
  }
  .products-page .product-name div:first-child {
    max-width: 195px;
  }
}
