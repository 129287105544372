.tablePopup {
  max-width: 68.75rem;
}

.tablePopup .header {
  display: none;
}
.tablePopup h3 {
  margin: 0;
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  align-items: baseline;
}
.tablePopup h4 {
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
}
.tablePopup .details {
  text-align: left;
  border-radius: 0.3125rem;
  padding: 1.5625rem 0;
}

.tablePopup.editPopup .details > * {
  padding-bottom: 0;
}
.tablePopup.editPopup .button-container {
  display: flex;
  justify-content: flex-end;
  padding-right: 1.25rem;
}
.tablePopup.editPopup button.primary {
  margin-right: 1.25rem;
}
.tablePopup.editPopup button.primary,
.tablePopup.editPopup button {
  float: none;
  margin-top: 1.25rem;
}
.tablePopup.editPopup .details {
  padding-bottom: 2.8125rem;
}
.tablePopup .custom-tooltip-container {
  display: flex;
  flex-direction: column;
  width: 14.375rem;
}
.tablePopup .custom-tooltop-desc {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
}
.tablePopup .custom-tooltop-desc button {
  margin-top: 0;
}
.tablePopup.editPopup button.discount-info-btn {
  margin-top: 0;
}
.tablePopup.editPopup .details:last-child {
  padding-bottom: 1.5625rem;
}
.tablePopup.editPopup .scroll-table {
  max-height: 18.75rem;
  overflow: auto;
}
.tablePopup.editPopup .amount-calculation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.625rem;
  padding-right: 1.25rem;
  width: 100%;
  margin-top: 0.5rem;
}

.tablePopup.editPopup .amount-calculation.sub-item h3 {
  font-weight: 400;
  font-size: 0.875rem;
  padding-right: 1.875rem;
  margin-top: 0;
}
.tablePopup.editPopup .prepared-order {
  display: flex;
  align-items: center;
  margin-left: 0.625rem;
  margin-bottom: 0.625rem;
  margin-right: 1.25rem;
}
.tablePopup.editPopup .prepared-order img {
  margin-right: 0.625rem;
}
.tablePopup.editPopup
  .summary-listing-page
  .table
  .table-cell:nth-last-child(2) {
  min-width: 4.5rem;
}
