.package-filter-form .section-row{
    width: 100%;
}
.package-filter-form .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}
.package-filter-form .field {
    width: 50%;
    padding: 0 0.625rem;
    margin-bottom: 1.25rem;
}
.package-filter-form .field.section-row-input{
    float: left;
}
.package-filter-form .field.section-row-input-auto{
    width: auto;
    float: left;
    padding-right: 1.25rem;
}
.package-filter-form .field.section-row-input-half{
    width: 20%;
    float: left;
    padding-right: 1.25rem;
}

.package-list .campaign-view-icon{
    cursor: pointer;
    width: 1.3rem;
    height: 1.3rem;
    background: url(./eye.svg) no-repeat;
    background-size: 100%;
}
.package-list .link-btn{
    cursor: pointer;
}
.package-list .link-btn:hover{
    color: #1557bf;
}


.package-list .inventory-btn-box{
    margin-right: 1rem;
}
.package-list .inventory-btn-box .button{
    padding: 0.125rem 1rem;
    border-radius: 0.125rem;
    border: 1px solid #1557bf;
    color: #1557bf;
    background: #fff;
    font-size: 0.875rem;
    line-height: 2;
    font-weight: 600;
    letter-spacing: -0.0125rem;
    cursor: pointer;
}
.package-list .inventory-btn-box .left-btn{
    border-radius: 0.5rem 0 0 0.5rem;
    padding: 0.125rem 0.5rem;
    border-right: 0;
}
.package-list .inventory-btn-box .mid-btn{
    border-radius: 0;
    padding: 0.125rem 0.5rem;
}
.package-list .inventory-btn-box .right-btn{
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 0.125rem 0.5rem;
    border-left: 0;
    vertical-align: bottom;
    line-height: 2.4;
}
.package-list .button-checked {
    background-image: url(../commonMedia/images/checkbox-checked.svg);
    background-size: 100%;
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    border: none;
}
.package-list .button-unchecked {
    background-image: url(../commonMedia/images/checkbox-unchecked.svg);
    background-size: 100%;
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    border: none;
}