.confimation-dialog.backMask {
  background: #33333380;
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.confimation-dialog .header {
  height: 2.75rem;
  width: 100%;
  background: #2c3238;
  color: #ffffff;
  font-size: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 1.1875rem;
}

.confimation-dialog .dialog {
  width: 28.75rem;
  min-height: 12.1875rem;
  position: relative;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.confimation-dialog .content {
  padding: 1.3125rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.confimation-dialog .actions {
  display: flex;
  justify-content: flex-end;
  padding: 1.3125rem;
  padding-top: 0;
  column-gap: 0.5rem;
}

.radio-buttons-container {
  padding: 0rem 1.3125rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 0.3125rem;
  margin-bottom: 1.25rem;
}

.radio-buttons-container input[type='radio'] {
  border: 0px;
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.875rem;
}
