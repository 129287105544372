@media screen and (max-width: 840px) {
    .categories
      .table.table-dynamic
      .table-row
      .table-cell.column-name::before {
      content: 'Title';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .categories
      .table.table-dynamic
      .table-row
      .table-cell.column-date::before {
      content: 'Publish Date';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .categories
    .table.table-dynamic
    .table-row
    .table-cell.column-rank.homeDeck::before {
      content: 'Home Deck Rank';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .categories
    .table.table-dynamic
    .table-row
    .table-cell.column-rank.homeSwimlane::before {
      content: 'Home Swimlane Rank';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .categories
    .table.table-dynamic
    .table-row
    .table-cell.column-rank.catalogueTab::before {
      content: 'Category Tab Rank';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
  }
  

  