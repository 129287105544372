.customers-page .customer-table-image {
  width: 92px;
  vertical-align: middle;
  padding-top: 0;
  padding-bottom: 0;
}

.customers-page .customer-table-image .image {
  padding: 0;
  overflow: hidden;
  border-radius: 50%;
}

.customers-page .customer-name {
  cursor: pointer;
  color: #2b3238;
  text-transform: capitalize;
}

.customers-page .customer-name:hover {
  color: #1557bf;
}

.customers-page .customer-phone {
  color: #2b3238;
  font-size: 0.875rem;
}
.customers-page .dropdown-menu {
  min-width: 150px; /* TODO: Check all such occurences and refactor */
}

.customers-page .table-cell.customer-table-contact a{
  color: #80959d;
  cursor: default;
}

@media(max-width:840px) {
  .customers-page .table-container {
    margin: 20px -30px 0 -30px;
  }
  .customers-page .table.table-dynamic{
    flex-flow: column wrap;
  }
  .customers-page .table.table-dynamic .table-row {
    flex-grow: 1;
    flex-flow: column wrap;
    width: 100%;
    margin: -1px 0 0 0 !important;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-image{
    margin-left: 25px;
    margin-top: 5px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-name span{
    color: #1557bf !important;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-name,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-contact {
    position: absolute;    
    left: 90px;
    width: calc(100% - 115px);
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-contact{
    color: #80959d;
    font-size: .75rem;
    top: 40px;
    padding-top: 0px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-actions{
    padding: 0;
    position: absolute;
    top: 25px;
    right: 30px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining{
    padding-right: 10px;
    position: relative;
    margin-left: 90px;
    margin-top: 10px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining div,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase div{
    display: inline;
    padding-right: 5px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining::before,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase::before,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders::before{
    display: block;
    font-size: 10px;
    color: #80959d;
    position: absolute;
    top: 0px;
    left: 20px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining::before{
    content: 'Joined';
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase::before{
    content: 'Last Purchase';
    right: 0;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders::before{
    content: 'Orders';
    right:0;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining div,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase div{
    font-size: 10px;
    color: #2b3238;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders small,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining small,
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase small{
    color:#4a4a4a;
    font-size: 10px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase{
    padding-left: 10px;
    position: absolute;
    right: 30px;
    top: 99px;
    padding-right: 0;
    text-align: right;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders{
    position: relative;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders{
    text-align: right;
    width: 100px;
    position: absolute;
    left: -15px;
    top: 80px;
    padding-right: 0;
    padding-left: 0;
  }
}

@media(max-width:480px) {
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining{
    margin-top: 2px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase{
    top: 94px;
    right: 36px;
    font-size: 11px;
    width: 100px;
    margin-top: -2px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-status {
    margin-left: 36px;
    font-size: 13px;
    display: none;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-contact, .customers-page .table.table-dynamic .table-row .table-cell.customer-table-contact .text-nowrap.text-muted {
    font-size: 11px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders small,.customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining .text-nowrap, .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase .text-nowrap{
    font-size: 13px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining::before, .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase::before, .customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders::before {
    font-size: 11px;
  }
  .customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining {
    margin-top: 10px;
    margin-bottom: 15px;
}
.customers-page .table.table-dynamic .table-row .table-cell.customer-table-orders {
  margin-top: 10px;
}
.customers-page .table.table-dynamic .table-row .DropDown .dropdown-menu .dropdown-items .dropdown-item {
  font-size: .8125rem;
}
.customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining div, .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase div{
  display: block;
  padding-right: 0px;
}
.customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining small, .customers-page .table.table-dynamic .table-row .table-cell.customer-table-lastpurchase small{
  font-size: 13px;
  color: #2b3238;
}

.customers-page .filters-wrapper .primary,.customers-page .filters-wrapper .button {
  min-width: 48%;
}
.customers-page .table.table-dynamic .table-row .table-cell.customer-table-joining::before{
margin-left: -4px;
}

}