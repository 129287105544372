@use 'styles/colors' as *;

.delivery-order-summary-wrapper .delivery-order-placement-details {
  width: 380px;
}

.delivery-order-summary-wrapper .delivery-order-placement-details .section-pre-order {
  display: flex;
  justify-content: space-between;
}

.delivery-order-summary-wrapper .delivery-order-placement-details .pre-order {
  margin-top: 5px;
}

.delivery-order-summary-wrapper .delivery-order-placement-details .pre-order img {
  margin-right: 8px;
  margin-top: -5px;
}

.user-details .flex {
  display: flex;
  align-items: center;
}
.user-details .flex .button-container {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}
.user-details .error {
  font-size: 11px;
  color: red;
}

.order-placement-info-item {

  &.verification-status-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .verification-status-icon {
    width: 1rem;
    height: auto;
    color: $fp-grey-3;
    margin: 0 0.75rem 0 0.25rem;
  }

  .noti-container {
    height: 1rem;
    width: auto;
    background-color: $fp-red-5;
    margin-left: 1rem;
    margin-top: 0.5rem;
    .error-txt {
      color: $fp-red-1;
      font-size: 0.75rem;
      margin-left: 0.75rem;
    }
  }

  .text-muted {
    font-size: 0.8rem;
  }

  .note {
    color: $fp-red-1;
    font-size: 1rem;
  }

  .verify-button {
    margin-left: 1rem;
  }
}

.order-placement-details {
  width: fit-content;
}

.order-placement-details .order-placement-info-item {
  min-width: 17.5rem;
  padding: 0.75rem 0;
  background-image: linear-gradient(to right, $fp-white-2 80%, rgba(255,255,255,0) 0%);
  background-position: top;
  background-size: 0.5rem 1px;
  background-repeat: repeat-x;
}

.order-placement-details .order-placement-info-icon {
  margin-right: 0.5rem;
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.25rem 1.25rem;
}

.icon-shield {
  background-image: url('../shield-icon.svg');
}
.icon-clock {
  background-image: url('../clock-icon.svg');
}
.icon-mobile {
  background-image: url('../mobile-icon.svg');
}
.icon-sales-order {
  background-image: url('../sales-order-icon.png');
}
.icon-store {
  background-image: url('../../../../../icons/shop.svg');
}
