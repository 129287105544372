@use 'styles/colors' as *;

.bulk-upload-error-message-box-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .message-box-error {
   border: 1px solid $fp-grey-4;
   margin-top: 1rem;
   padding: 0.5rem 1rem;
   text-align: center;
   color: $fp-red-2;
   width: 100%;
  }
}
