.seo-form form {
  max-width: 100%;
}
.seo-form .tab-details {
  padding: 20px;
  border: 1px solid #eaefef;
  border-top: 0;
}
.seo-form .tab-container {
  margin-bottom: 20px;
}
.seo-form .tabs a {
  text-transform: capitalize;
}
.seo-form .tabs {
  display: flex;
}
.seo-form .tabs .tab {
  flex: 1;
}
.seo-form textarea {
  height: 100px;
}