.optional-header {
  display: flex;
  position: relative;
}

.rule-date {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.rule-date .col{
  padding: 0;
  margin: 0;
  width:100%;
}

.chance-page .label-tooltip {
  font-size: 0.75rem;
  margin-left: 0.5em;
  position: relative;
}

.chance-page .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 25.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: -3.155rem;
  text-align: left;
}
.chance-page .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.chance-page .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}

.tooltip-two {
  bottom: 132% !important;
  left: 15.4rem !important;
}

.tooltip-three {
  bottom: 132% !important;
  left: 6.8rem !important;
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.mb-0 {
  margin-bottom: 0.4rem;
}

.ml-0 {
  margin-left: -0.4rem;
}

.mt-1 {
  margin-top: 1rem;
}

.mt-2 {
  margin-top: 2.25rem;
}

/* Add Rule Box */
.rule-popup {
  border: 0.063rem solid #dbdbdb;
  margin-bottom: 1.25rem;
}

.rule-header {
  height: 2.188rem;
  background-color: #f4f5f7;
  color: #ffffff;
  padding-left: 1.563rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rule-details {
  display: grid;
  grid-template-columns: 0.8fr 1.2fr 0.8fr 0.3fr;
  grid-gap: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}

/* Add Condition Box */
.condition-popup {
  border: 0.063rem solid #dbdbdb;
  margin-bottom: 1.25rem;
}

.condition-header {
  height: 2.188rem;
  background-color: #f4f5f7;
  color: #ffffff;
  padding-left: 1.563rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.condition-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  grid-gap: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}

@media screen and (max-width: 415px) {
  .icon-2 {
    position: absolute;
    bottom: 0.188rem;
    left: 4.813rem;
  }

  .icon-3 {
    position: absolute;
    bottom: 0.188rem;
    left: 9.813rem;
  }

  .tooltip-two {
    bottom: 76% !important;
    left: 1.75rem !important;
    width: 14.875rem !important;
  }
}

@media screen and (max-width: 590px) {
  .chance-page .label-tooltip .label-tooltiptext {
    width: 10.875rem;
  }
}

@media screen and (max-width: 768px) {
  .rule-details .field {
    grid-column: 1 / span 4;
  }
  .condition-details .field {
    grid-column: 1 / span 3;
  }
}

@media screen and (min-width: 768px) {
  .chance-page .tooltip-two {
    width: 25.75rem !important;
  }
  .rule-date .col{
    width:auto;
  }
}
