@use 'styles/colors' as *;

// styles for header
.header-container-time-slot-blocking {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  h2 {
    margin-top: 0;
  }

  .add-rule-button-wrapper {

    .add-rule-dropdown {
      .button {
        padding: 0 3.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
      }
      .dropdown-item {
        &.disabled {
          color: $fp-grey;
          pointer-events: none;
          &:hover {
            box-shadow: none;
            font-weight: normal;
            cursor: default;
          }
        }
      }
    }
  }
}

.timeslot-blocking {
  .server-error-message {
    text-align: center;
    margin-top: 5rem;
  }
}

// styles for map and rules list container
.delivery-area-content {
  .map-area {
    width: calc(100% - 380px) !important;
  }
  .rules-container {
    flex-grow: 1;
    padding: 0 1rem;
    .title {
      font-weight: 700;
      margin: 1rem 0;
      .rules-count {
        font-size: 0.8rem;
        color: $fp-grey-2;
        font-weight: 400;
      }
    }
    .no-rules {
      font-size: 0.75rem;
      color: $fp-grey-2;
    }

    .rules-list {
      overflow-x: hidden;
      overflow-y: auto;
      height: 30rem;
      padding: 0;

      .load-more  {
        position: relative;
        text-align: center;
        font-size: 0.875rem;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          width: 32%;
          right: 0;
          height: 1px;
          background-color: $fp-grey-2;
          margin-right: 1rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          width: 32%;
          left: 0;
          height: 1px;
          background-color: $fp-grey-2;
          margin-left: 1rem;
        }
      }

      .loader-bg {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem;
        padding-right: 0.75rem;
        width: 6rem;
        height: auto;
        list-style-type: none;
      }

      .single-rule {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem;
        padding-right: 0.75rem;

        .dropdown-menu {
          width: 9.5rem;

          .divider {
            border-top: 1px solid #eaeaea;
            width: 90%;
            margin-left: 0.5rem;
            margin-bottom: 1rem;
          }

          .dropdown-item {
            &.disabled {
              color: $fp-grey;
              pointer-events: none;
              &:hover {
                box-shadow: none;
                font-weight: normal;
                cursor: default;
              }
            }
          }
        }

        &:hover {
          background-color: $fp-white-2;
          cursor: pointer;
        }

        .rule-name {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-size: 14px;

          &.selected {
            color: $fp-blue-1;
            font-weight: bold;
          }

          span {
            display: inline-flex;
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }
  }
}

// styles for add rule popup
.add-rule-popup {
  width: 58rem;
  margin: auto;
  letter-spacing: -0.3px;
  text-align: center;
  background-color: $fp-white-0;
  border-radius: 2px;

  .header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem;
    background-color: $fp-black;
  }

  .add-rule-title {
    color: $fp-white;
    font-size: 18px;
    font-weight: 600;
  }

  .cancel-button {
    color: $fp-blue-1;
    font-weight: 600;
  }

  .add-rule-popup-form {
    height: 41rem;
  }
}

// styles confirmation to leave popup
.confirmation-popup {
  background-color: $fp-white;
  width: 26rem;
  height: 12.75rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 1rem;

  .body-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 100%;
    p {
      line-height: 1.5rem;
      margin: 0.5rem;
    }

    .title {
      font-weight: 700;
      font-size: 18px;
    }
    .body {
      font-size: 16px;
    }
    .buttons-wrapper {
      margin-top: auto;
      margin-left: auto;
      button {
        margin: 0 0.5rem;
        border-radius: 5px;
        &.primary {
          background-color: $fp-red-3 !important;
        }
        &.primary-blue {
          background-color: $fp-blue-1;
          color: $fp-white-0;
        }
      }
    }
  }
}
