.successPopup .header {
  display: none;
}
.successPopup.editPopup {
  max-width: 34.375rem;
}
.successPopup .details {
  text-align: center;
  border-radius: 0.3125rem;
}
.successPopup.editPopup .details > * {
  padding-bottom: 0;
}
.successPopup.editPopup button.primary {
  float: none;
  margin-top: 1.25rem;
}
.successPopup.editPopup .button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.successPopup.editPopup .button-container button {
  width: 250px;
}
.successPopup.editPopup .button-container button.primary {
  margin-bottom: 1rem;
}
.successPopup.editPopup .details {
  padding-bottom: 2.8125rem;
}
.successPopup.editPopup .details:last-child {
  padding-bottom: 2.5rem;
}
