.dialogPopup {
  min-width: 280px;
  max-width: 480px;
  padding: 20px;
  margin: auto;
  letter-spacing: -0.3px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 2px;
}
.dialogPopup .image {
  display: none;
}
.dialogPopup .warn {
  margin: auto;
  font-size: 1.125rem;
  font-weight: 600;
  color: #eb8181;
}
.dialogPopup .info {
  margin: auto;
  font-size: 1.125rem;
  font-weight: 600;
  background-color: #ffc499;
}
.dialogPopup .error {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  font-size: 1.125rem;
  font-weight: 600;
  background-color: #ffffff;
}
.dialogPopup .image {
  display: inline-block;
  background-image: url(./caution.svg);
  height: 55px;
  width: 55px;
  border: none;
}
.dialogPopup .success .image {
  background-image: url(./success.svg);
}
.dialogPopup .description {
  max-width: 180px;
  margin: auto;
  line-height: 1.38;
  font-size: 1rem;
  letter-spacing: -0.2px;
  padding-top: 5px;
}
.dialogPopup p {
  font-size: 12px;
  color: #80959d;
  letter-spacing: -0.1px;
  padding-top: 0.75rem;
  padding-bottom: 20px;
}
.dialogPopup button {
  min-width: 100px;
  border-radius: 2px;
  background-color: #f3f5f6;
  margin-top: 0.5rem;
}

.dialogPopup .primary {
  margin-left: 10px;
}
