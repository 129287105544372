.eazy-page .common-header > div {
  background-color: #000000;
}
.eazy-page .common-header {
  position: fixed;
}
.eazy-page .common-header .site-nav {
  background-color: #000000;
}
.eazy-page .common-header .site-nav a {
  font-size: 0.75rem;
  color: #ffffff;
  letter-spacing: 1px;
  font-weight: 500;
  height: 100%;
}
.eazy-page .common-header .site-nav a:hover {
  color: #00b6a4;
}
.eazy-page .site-nav .submenu {
  background-color: #000000;
  z-index: 2;
}
.eazy-page .common-header,
.eazy-page .common-header .wrap {
  height: 40px;
}
.eazy-page .common-header {
  z-index: 23;
}
.eazy-header {
  position: fixed;
  z-index: 2;
}
.eazy-page .eazy-header .site-nav a {
  color: #333333;
  letter-spacing: 0.1px;
  font-weight: 500;
  font-size: 1rem;
  font-weight: 500;
}
.eazy-page .hero {
  padding-top: 235px;
}
.eazy-page .hero h1,
.eazy-page .hero p {
  color: #ffffff;
}
.eazy-page .hero p {
  margin-bottom: 20px;
}
.eazy-page .eazy-header .wrap {
  justify-content: flex-start;
}
.eazy-page .eazy-header .wrap div:first-of-type {
  flex: 1;
}
.eazy-page .eazy-header .wrap div:first-of-type a {
  max-width: 110px;
}
.eazy-page .eazy-header .site-nav {
  margin-right: 35px;
}
.relative {
  position: relative;
}
.fixed {
  position: fixed;
  top: 0;
}
.hidden-header {
  margin-top: 0px !important;
}

@media screen and (min-width: 640px) {
  .eazy-page .common-header .site-nav > ul {
    height: 40px;
  }
  .eazy-page .submenu a:hover {
    color: #00b6a4;
    background-color: #ffffff;
  }
  .eazy-page .eazy-header .site-nav li a:hover {
    color: #1557bf;
  }
  .eazy-page .submenu-link:hover > a {
    color: #00b6a4;
  }
  .eazy-page .common-header .submenu-link:hover {
    background-position-x: calc(100% - -4px);
    background-position-y: -17px;
  }
  .eazy-page .site-nav li a:hover {
    color: #00b6a4;
  }
  .eazy-page .eazy-header a {
    font-size: 0.875rem;
    font-weight: 500;
  }
  .eazy-page .eazy-header .header-links a {
    font-size: 0.875rem;
    padding: 0 20px;
    line-height: 36px;
  }
  .eazy-header .header-links {
    padding: 20px 0;
  }
  .eazy-header .menu-button-container {
    display: flex;
  }
}

@media screen and (min-width: 1440px) {
  .eazy-page .hero {
    padding-top: 243px;
  }
}
@media screen and (min-width: 1600px) {
  .eazy-page .hero {
    padding-top: 350px;
  }
}
@media screen and (max-width: 640px) {
  .site-nav ul,
  .site-nav > ul li {
    background-color: inherit;
  }
  .eazy-page .eazy-header .site-nav li a:hover {
    color: #1557bf;
  }
  .eazy-page .common-header .site-nav a {
    font-size: 0.875rem;
  }
  .eazy-page .site-nav .submenu a {
    color: #000000;
  }
  .eazy-page .common-header .site-nav.expanded {
    margin-top: -16px;
  }
  .eazy-page .site-nav.expanded {
    margin-top: 40px;
    text-align: right;
    width: 100%;
    height: auto;
  }
  .eazy-page .hero {
    padding-top: 150px;
  }

  .eazy-page #show-nav-eazy:checked + .site-nav {
    margin-top: 20px;
    margin-right: 0;
  }
}
@media screen and (min-width: 641px) and (max-width: 720px) {
  .eazy-header > div {
    padding: 0 25px;
  }
}
@media screen and (max-width: 960px) {
  .eazy-page .eazy-header .site-nav a {
    font-size: 14px;
  }
  .eazy-page .common-header .site-nav a {
    font-size: 10.5px;
  }
  .eazy-page .eazy-header .header-links a {
    font-size: 12.25px;
  }
}
