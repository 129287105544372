@use 'styles/colors' as *;

/* POSTAL CODE INPUT */
.postal-code-input-step {
  padding: 1rem;
  .label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .loader-wrapper {
    width: 5rem;
  }
  .field {
    margin-bottom: 0 !important;
  }
  .input-error-message {
    text-align: left;
    color: $fp-red-3;
  }
  .input-label-message {
    text-align: left;
    color: $fp-grey-2;
    font-size: 12px;
    margin-top: 0.25rem;
  }
  .postal-code-chips-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
    max-height: 22rem;
    overflow-y: auto;
    margin-top: 1rem;
    .chip {
      background-color: $fp-white-2;
      padding: 0.5rem 0.75rem;
      border-radius: 50px;
      border: 1px solid $fp-grey-2;
      font-size: 14px;
      font-weight: 600;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      transition: 0.5s;
      svg {
        margin-bottom: 2px;
        g {
          stroke: $fp-grey-2;
        }
      }
    }
    .error-chip {
      color: $fp-red-3;
    }
  }
}

/* KML INPUT */
.kml-input-step, .map-input-step {
  padding: 0 1rem;
  height: 65%;

  .error-text {
    text-align: left;
    font-size: 0.75rem;
    color: $fp-red-3;
  }

  .delivery-area-content {
    flex-direction: column;
    .map-area {
      width: 100% !important;

      .map-element {
        height: 23rem;
      }
    }
  }

  p {
    text-align: left;
    font-size: 14px;
  }
  .file-upload-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $fp-grey-1;
    border: 1px dashed $fp-grey-4;
    height: 100%;
    position: relative;

    svg {
      margin-bottom: 2rem;
      fill: none;
    }

    span {
      color: $fp-grey-2;
      font-weight: 600;
    }

    // style changes to common FileUpload component
    .secondary-title {
      margin-bottom: 0.75rem;
    }
    input {
      opacity: 0;
      cursor: pointer;
    }
  }
}

/* TIME SLOT INPUT */
.time-slot-input-step {
  padding: 0 1rem;

  .title-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    p {
      text-align: left;
    }
    .select-all-text {
      svg {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }


  .styled-table {
    border-collapse: collapse;
    margin: 0.5rem 0;
    font-size: 0.9em;
    min-width: 400px;
    width: 100%;
    height: 100%;

    thead tr {
      background-color: $fp-grey-2;
      color: $fp-white-0;
      th {
        padding: 0.75rem 0;
      }
    }

    thead th, tbody td {
      border: 1px solid $fp-white-2;
    }

    tbody tr:nth-of-type(even) {
        background-color: $fp-white;
    }
  }
}


/* MAP INPUT */
.map-input-step {
  .map-element {
    height: 28rem !important;
  }
}


/* RULE NAME INPUT */
.rule-name-input-step {
  padding: 1rem;

  #ruleName:disabled {
    color: $fp-grey-5;
  }

  .field {
    margin-bottom: 0 !important;
  }

  .hint-text-container {
    text-align: left;
    color: $fp-grey-2;
    font-size: 12px;
    margin-top: 0.25rem;
    margin-left: -1rem;
  }
}

