.top-labels-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 0.8rem;
}
.top-labels-container > p {
    color: #333333;
}

.labels-display-container {
    width: 30rem;
    border: 1px dotted #DADEE0;
    border-radius: 0.3rem;
    padding: 1rem;
    width: 100%;
    margin-bottom: 1rem;
}

.labels-display-container .tag-label {
    font-weight: 700;
    font-size: 1rem;
    line-height: 26px;
    color: #333333;
}
.labels-display-container .package-label {
    font-weight: 700;
    font-size: 14px;
    color: #8B9FA6;
}
.labels-display-container .tag-label span {
    color: #8B9FA6;
    font-size: 0.8rem;
    font-weight: 500;
}
.number-of-selected-labels {
    color: #8B9FA6;
    font-size: 0.8rem;
}
.checkboxes-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 0.5rem;
    border-bottom: 1px solid #EAEFEF;
}

.last-item {
    border-bottom: none;
}