.delivery-area-content .pincode-form .pincode-group {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 15px;
}
.delivery-area-content .pincode-form .pincode-group-list .pincode {
    width: 56px;
    margin-right: 10px;
    padding: 5px;
    border: 1px solid #eaefef;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #2b3238;
    margin-bottom: 10px;
}
.delivery-area-content .pincode-form .pincode-group-list .pincode:nth-child(3n) {
    margin-right: 0px;
}
.delivery-area-content .pincode-form .pincode-group-list .delete-group {
    position: absolute;
    right: 0px;
    top: -30px;
}

.delivery-area-content .pincode-form .pincode-group-list .horizontal-line {
    margin-top: -10px;
}
.delivery-area-content .pincode-form .pincode-group-list.edit .pincode {
    width: 89px;
    padding: 5px 10px;
    border: 1px solid #eaefef;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #2b3238;
    margin-bottom: 10px;
    margin-right: 0px;
}
.delivery-area-content .pincode-form .pincode-group-list.saved .pincode.selected {
    color: #fff;
    background-color: #45c0ff;
}

.delivery-area-content .pincode-form .pincode-group-list.edit .pincode:nth-child(odd) {
    margin-right: 10px;
}
.delivery-area-content .pincode-form .pincode-group-list .horizontal-line:last-child {
    display: none;
}
.delivery-area-content .pincode-form .pincode-group-list .pincode-group-name {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 7px;
    margin-top: 15px;
} 
.delivery-area-content .pincode-form .multitext-tags .tag-value:nth-child(2n) {
    margin-right: 0px;
}
.delivery-area-content .pincode-form .zones-content>button {
    margin-bottom: 10px;
}