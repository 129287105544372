.participants-tab .contain {
  max-width: 80rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.participants-tab .date{
  color: #2b3238
}
.row {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0 var(--s3);
  display: flex;
}
.row.flex-direction {
  flex-direction: column;
}
.participants-tab .primary {
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}
.participants-tab .error {
  margin: 0.5rem 0;
}
.participants-tab .loading-msg-2 {
  text-align: center;
  color: #80959d;
  font-size: 0.75rem;
  font-weight: 600;
  padding: 0.5rem 0;
}
.participants-tab .loading-msg {
  margin: 0.5rem 0 1.5rem;
  font-size: 0.75rem;
  border: 1px solid transparent;
  padding: 0.5rem 0;
  text-align: center;
  background-color: rgb(232, 240, 253);
  color: rgb(51, 51, 51);
  border: 1px solid rgb(226 231 240);
  font-weight: 400;
}
.participants-tab .primary.preview-draw-btn{
  margin-top: 3rem;
}
.participants-tab .normal-height-field{
  padding: inherit 0.5rem;
}

.participants-tab .actual-draw-section{
  padding: 0 1.5rem;
   display: block;
}
.participants-tab .actual-draw-section .col-6{
  margin-bottom: 0.625rem;
}

.participants-tab .email .field label, 
.participants-tab .actual-draw-section label{
  font-weight: 600;
}
.participants-tab .DropDown .dropdown-menu {
  min-width: 9.375rem;
}
.participants-tab .status {
  font-weight: 700;
}
.participants-tab .download-winner-list, .participants-tab .header-actions-wrapper{
  align-items: center;
  width: 100%;
  margin-left: 0 !important;
  
}
.participants-tab .download-winner-list .primary{
  margin-top:2rem;
}
.successPopup ul.draw-winners-success-popup{
  text-align: left;
}
.participants-tab .download-winner-list .email{
  margin-right:1.5rem;
}
.participants-tab .status.winner {
  color: green;
}
.participants-tab .status.reserve {
  color: #1557bf;
}
.participants-tab .status.forfeit {
  color: #eb8181;
}
.participants-tab .col-12 {
  width: 100%;
}
.participants-tab .col-6 {
  width: 100%;
}
.drawWinners-popup .styledBox {
  background-color: var(--bg-color);
  border: 0.0625rem solid var(--border-color);
  padding: 0 0.5rem;
  margin-bottom: 0.5rem;
}
.drawWinners-popup .form-actions {
  float: right;
}

.participants-tab .col-4 {
  width: 50%;
  margin: var(--s2) 0;
}
.participants-tab hr {
  margin: var(--s3) 0;
}
.participants-tab .m0 {
  margin: var(--s0);
}
.participants-tab .ml {
  margin-left: var(--s2);
}
.participants-tab .mt-0 {
  margin-top: 0;
}
.participants-tab .my {
  margin: var(--s2) 0;
}
.participants-tab .col-3 {
  width: 100%;
}
.participants-tab .mt-025 {
  margin-top: var(--s1) !important;
}
.participants-tab .mb-1 {
  margin-bottom: var(--s3);
}
.participants-tab label.thin {
  height: 1.875rem;
}
.participants-tab .col-12 {
  width: 100%;
}
.participants-tab select {
  text-transform: capitalize;
  min-width: 18rem;
}
.participants-tab .table.table-dynamic {
  margin: 0;
}
.participants-tab .table .table-row:last-child {
  width: 100%;
  border-bottom: 1px solid #eaefef;
}
.participants-tab .table-row .table-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;

}
.participants-tab .table .table-row:last-child .table-cell{
  border-bottom:none;

}
.participants-tab .table .table-cell:last-child {
  width: 100%;
  text-align: center;
  border-bottom:none;
}
/* mobile */
@media screen and (max-width: 39.9375em) {
  .participants-tab .table-row .table-cell {
    height: 4rem;
  }
  .participants-tab .table-row .table-cell.id {
    display: none;
  }
  .participants-tab .table.table-dynamic .table-row {
    margin: 0.625rem 0 !important;
}

  .participants-tab .table.table-dynamic .table-row .table-cell.name {
    width: 100%;
  }
  .participants-tab .table.table-dynamic .table-row .table-cell.row {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding-left: 1.875rem;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.channel::before {
    content: 'Channel: ';
    width: 50%;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.cardNumber::before {
    content: 'Card number: ';
    width: 50%;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.total {
    width: 100%;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.order::before {
    content: 'Order Reference no.: ';
    width: 50%;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.total::before {
    content: 'Total Chances Earned: ';
    width: 50%;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.date::before {
    content: 'Drawn Date & Time: ';
    width: 50%;
    color: #2b3238;
    font-size: 0.875rem;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.status {
    bottom: 0;
    width: 100%;
    text-align: right;
    background: #fbfcfc;
    border-top: 0.0625rem solid #eaefef !important;
    height: auto;
    padding: 0.75rem 0.9375rem;
  }

  .participants-tab .table.table-dynamic .table-row .table-cell.column-actions {
    bottom: 0;
    width: 50%;
    background: #fbfcfc;
    border-top: 0.0625rem solid #eaefef !important;
    height: auto;
    padding: 0.75rem 0.9375rem;
  }
}
/* small */
@media screen and (min-width: 40em) {
  .participants-tab .contain {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .participants-tab .col-6 {
    width: 50%;
  }
  .participants-tab .col-4 {
    width: 25%;
  }
  .participants-tab .col-3 {
    width: 30%;
  }
  .participants-tab .row.flex-direction {
    flex-direction: row;
  }
  .participants-tab .ml-sm {
    margin-left: var(--s2);
  }
  .participants-tab .ml-med {
    margin-left: var(--s3);
  }
  .participants-tab .primary {
    margin-top: 0;
  }
}
/* medium */
@media screen and (min-width: 52em) {
  .participants-tab .table.table-dynamic {
    margin: 0;
    width: 100%;
  }

  .participants-tab .table .table-header {
    display: flex;
    justify-content: center;
    min-width: 0;
    flex-direction: row;
  }
  .participants-tab .table .table-row {
    display: flex;
    justify-content: center;
    min-width: 0;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    border-top: 0.0625rem solid #eaefef;
  }
  .participants-tab .table .table-cell {
    display: table-cell;
    vertical-align: middle;
    padding: 0;
    margin:0;
    border:none;
}
  .participants-tab .table .table-cell {
    min-width: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
