.main-container-invoice {
  padding: 0 5rem;
}
.tax-invoice {
  font-weight: 700;
  font-size: 23;
}
.body-container {
  color: #333333;
}
.no-spacing {
  padding: 0;
  margin: 0;
}

.styled-text {
  padding: 2rem 0 0 0;
  span {
    color: #666666;
  }
}

.margin-top-32 {
  margin-top: 2rem !important;
}

.container-padding {
  margin: 3rem 0;
}

.font-size-label {
  font-size: 0.75rem;
}

.margin-top-small {
  margin-top: 0.5rem;
}

.margin-top-12 {
  margin-top: 0.75rem !important;
}

.margin-top-16 {
  margin-top: 1rem !important;
}

.margin-top-medium {
  margin-top: 3rem;
}
.margin-top-32 {
  margin-top: 2rem !important;
}

.dark-color {
  color: #0c3677;
}
.payment-logo {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
}
.img-invoice {
  width: 10.75rem;
  height: 2.5rem;
  cursor: pointer;
}
.header-section-right {
  float: right;
  margin-left: auto;
  span {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.875rem;
    color: #333333;
  }
}
.header-right-text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  color: #333333;
}
.header-right-text-link {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 0.875rem;
  color: #0c3677;
}
.header-subtitle {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
}
.titleM {
  font-weight: 700;
  font-size: 20;
}
.horizontal-line {
  border: 1px solid #333333;
  margin: 1rem 0 1rem;
}
.bodyM {
  font-weight: 400;
  font-size: 16;
}
.total-label {
  float: right;
  image {
    vertical-align: middle;
    margin-right: 0.25rem;
    width: 2rem;
    height: 1.375rem;
  }
}
.titleM {
  font-weight: 700;
  font-size: 20;
}
.dashed-hr {
  border: 0.25rem solid #333333;
  margin-top: 2rem;
}
.body-invoice-content {
  margin-top: 2rem;
}
.titleS {
  font-weight: 700;
  font-size: 18;
}
.titleXs {
  font-weight: 700;
  font-size: 16;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
