.today .selection-container {
    margin-bottom: 1.25rem;
}


.today .selection-container .input-wrapper {
    width: 9.375rem;
}

.today {
    .selection-container {
        display: flex;
        gap: 1rem;
    }
}

.today header.today-capacity-plan button.SingleDatePickerInput_calendarIcon.SingleDatePickerInput_calendarIcon_1 {
    margin: -2px 0;
}

.today header.today-capacity-plan {
    display: grid;
    grid-template-columns:  calc(100% - 20.5rem) 20.5rem;
}

.today header.today-capacity-plan .header-icons {
    position: relative;
    display: block;
    text-align: right;
}

.today header.today-capacity-plan .today-heading {
  font-size: 1.8rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.93;
  letter-spacing: -0.7px;
  color: #2b3238;
}

.today header.today-capacity-plan  a {
    display: inline-block;
}

.today header.today-capacity-plan span.today-button.green {
    height: 2.75rem;
    padding:  .5rem 1.7rem;
    text-align: center;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid #1557bf;
    margin: 0 .5rem;
}

.today header.today-capacity-plan span.today-button.black {
    line-height: 2.5rem;
    height: 2.75rem;
    padding:  .5rem 1.7rem;
    text-align: center;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
    border: solid 1px #dadee0;
    margin: 0 .5rem;
}

.today header.today-capacity-plan .date-picker .DateInput {
    display: none;
}

.today header.today-capacity-plan .date-picker .DateRangePickerInput_arrow {
    display: none;
}

.today header.today-capacity-plan .input-wrapper {
    display: inline-block;
}

.today .table-wrapper {
    position: relative;
}

.today .table-wrapper .outer-table {
    vertical-align: top;
    display: grid;
    grid-template-columns: 15.3rem calc(100% - 15.3rem);
    position: relative;
}

.today .table-wrapper .outer-table > div:last-child {
   max-width: max-content;
   position: relative;
}

.today .table-wrapper .slots {
    display: inline-block;
}

.today .table {
    display: inline-block;
    overflow: auto;
    position: relative;
    border: 1px solid #eaefef;
}

.today .table::-webkit-scrollbar {
    display: none;
}
.today .slot-table {
     border: 1px solid #eaefef;
     border-right: none;
}

.today .slot-table .slot-table-heading {
    padding: 10px 10px;
    font-size: .75rem;
    font-weight: 600;
    letter-spacing: -0.2px;
    color: #80959d;
    border: 1px solid #eaefef;
    border-bottom: none;
    border-right: none;
}

.today .slot-table .table-cell {
    display: grid;
    grid-template-columns: 10fr 2fr;
    justify-content: center;
    align-items: center;
    height: 9rem;
    border: 1px solid #eaefef;
    border-right: none;
    text-align: center;
    vertical-align: middle;
    background-color: #fbfcfc;
}

.today .slot-table .table-cell span.slot-duration {
    padding: 0 1rem;
}

.today .slot-table .table-cell .slot-icons {
    border-left: 1px solid #eaefef;
    height: 100%;
    background:#ffffff;
}

.today .slot-table .table-cell .slot-icons > span {
    display: grid;
    height: 50%;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: relative;
}

.today .slot-table .table-cell .slot-icons.DELIVERY > span {
    height: 100%;
}

.today .slot-table .table-cell .slot-icons.PICKUP > span {
    height: 100%;
}

.today .slot-table .table-cell .slot-icons.RB_PREORDER > span {
    height: 100%;
}

.today .slot-table .table-cell .slot-icons .pickup-icon img {
    position: relative;
    top:.8rem;
}

.today .slot-table .table-cell .slot-icons .preorder-icon img {
    position: relative;
    top:.8rem;
}

.today .slot-table .table-cell .slot-icons .delivery-icon img {
    position: relative;
    bottom: .8rem;
}

.today .slot-table .table-cell .slot-icons.DELIVERY .delivery-icon img {
    position: relative;
    bottom:unset;
}

.today .slot-table .table-cell .slot-icons.PICKUP .pickup-icon img {
    position: relative;
    top: unset;
}

.today .slot-table .table-cell .slot-icons.RB_PREORDER .preorder-icon img {
    position: relative;
    top: unset;
}





.today .table .table-row {
    display: block;
}

.today .table .table-header {
    display: block;
}


.today .table .table-header .table-cell, .today .table .table-cell {
    min-width: 15rem;
    border: 1px solid #eaefef;
    background: #ffffff;
    position: relative;
    padding: 15px 10px;
    text-align: center;
    max-width: 15rem;
}

.today .table :not(.table-header) .table-cell {
    height: 9rem;
}

.today .table .table-cell img {
    position: absolute;
    top: 10px;
    right: 10px;
}

.today .table .table-header .table-cell {
    border-bottom: none;
}

.today .table .table-cell:last-child {
    text-align: center;
}

.today .table .pickup, .today .table .delivery {
    margin: 1.5rem 0;
    display: grid;
    grid-template-columns: 2fr 8fr 2fr;
}

.today .table .pickup.edit {
    margin-top: 0.7rem !important;
    margin-bottom: 0 !important;
    grid-template-columns: 2fr 4fr 6fr;
}

.today .table .delivery.edit {
    margin-top: 1.31rem !important;
    margin-bottom: 0 !important;
    grid-template-columns: 2fr 4fr 6fr;
}

.today .zone-progress-bar {
    display: inline-block;
}

.today .zone-progress-bar .progress-line {
    width: 5.1875rem;
    height: 0.375rem;
    border: solid 1px #eaefef;
    position: relative;
    display: inline-block;
    margin:0 .5rem;
}

/* .today .zone-progress-bar .curr {
    font-size: .75rem;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #2b3238;
}

.today .zone-progress-bar .total {
    font-size: .75rem;
    letter-spacing: -0.1px;
    color: #80959d;
} */

.today .table .table-cell input {
    width: 5rem;
    height: 2rem;
}

.today .table .table-cell span.pickup-count,  .today .table .table-cell span.delivery-count {
    font-size: .85rem;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #2b3238;
}

.today .table .table-cell span.pickup-capacity,  .today .table .table-cell span.delivery-capacity {
    font-size: .85rem;
    font-weight: 600;
    letter-spacing: -0.1px;
    color: #80959d;
}


.today .table .table-cell input[type="submit"] {
    display: none;
}

.today .zone-progress-bar .progress-line .completed-progress-line {
    background:  #80959d;
    height: 100%;
}

.today .arrow-right {
    position: absolute;
    top: 0.5625rem;
    right: 0.625rem;
    background-color: #f3f5f6;
    border: 1px solid #eaefef;
    border-radius: 2px;
    height: 1.5625rem;
    width: 1.5625rem;
    text-align: center;
}

.today .arrow-left {
    position: absolute;
    top: 0.5625rem;
    left: 0.625rem;
    background-color: #f3f5f6;
    border: 1px solid #eaefef;
    border-radius: 2px;
    height: 1.5625rem;
    width: 1.5625rem;
    text-align: center;
}


.today .bottom-info p {
    text-align: right;
}

.today .bottom-info p > span {
    margin: 0 1rem;
}

.today .bottom-info p > span img{
    margin: 0 1rem;
}


@media screen and (max-width: 48rem) {
    .today header {
        margin-bottom: 2.5rem;
    }

}


@media screen and (max-width: 42rem) {
    .today header {
        margin-bottom: 2.5rem;
    }

}

@media screen and (max-width: 36.25rem) {
    .today .table .table-header .table-cell, .today .table .table-cell {
        min-width: 11rem;
    }
    .today .table-wrapper .outer-table {
        display: grid;
        grid-template-columns: calc(100% - 15rem) 15rem;
        min-width: 26rem;
    }

    .today .table-wrapper .outer-table > div:last-child {
        width: 15rem;
    }

    .today header.today-capacity-plan .today-heading {
        margin-top: 5px;
        font-size: 1.125rem;
    }

    .today .slot-table .table-cell span.slot-duration {
        font-size: 14px;
        padding: 0 5px;
    }

    .today .slot-table .table-cell {
        grid-template-columns: 3fr 1fr;
    }
    .today header.today-capacity-plan {
        grid-template-columns: 1fr;
        height: max-content;
        margin-bottom: -0.625rem;
        margin-bottom: 20px
    }

    .today header.today-capacity-plan .header-icons {
        display: grid;
        grid-template-columns: auto auto auto;
        margin-left: -42px;
        margin-top: 30px;
    }
    .today header.today-capacity-plan .header-icons {
        text-align: center;
    }
    .capacity-planning .header-actions-wrapper.SingleDatePicker {
        width: auto;
      }
    .today header.today-capacity-plan button.SingleDatePickerInput_calendarIcon.SingleDatePickerInput_calendarIcon_1 {
        margin: 0px;
        padding: 8px;
        height: 41px;
        padding-left: 12px;
    }

    .today header.today-capacity-plan .SingleDatePickerInput__withBorder {
        text-align: left;
    }
    .today header.today-capacity-plan span.today-button.green {
        height: 42px;
    }

    .today header.today-capacity-plan .primary {
        height: 42px;
    }
    .today .table .delivery.edit, .today .table .pickup.edit {
        grid-template-columns: 1fr;
    }
    .today .table .delivery.edit input, .today .table .pickup.edit input {
        width: 70%;
        margin: 0 auto;
    }

    .today .table .delivery.edit span.delivery-count, .today .table .pickup.edit span.pickup-count {
        display: none;
    }

    .today .table .delivery.edit .zone-progress-bar, .today .table .pickup.edit .zone-progress-bar {
        display: none;
    }
    .today .bottom-info p {
        text-align: center;
    }
    .today .selection-container .input-wrapper {
        width: 49%;
    }
}

@media screen and (max-width: 360px) {
    .today .today-capacity-plan input[type="submit"], .button {
        padding: 0 13px;
    }
}

