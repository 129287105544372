@use 'styles/colors' as *;

.offer-sku-limit-page {
  .header-container {
    h2 {
      margin: 0;
    }
  }

  .scheduled-date-time-container {
    margin-top: 2rem;
    width: 30rem;

    .Date-time {
      .input-wrapper:nth-child(2) {
        width: 10rem;
      }
    }
  }

  .file-upload-wrapper {
    display: flex;
    border: 1px dashed $fp-grey-4;
    background-color: $fp-grey-1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20rem;
    position: relative;
    margin-top: 2rem;

    svg {
      margin-bottom: 2rem;
      fill: none;
    }

    span {
      color: $fp-grey-2;
      font-weight: 600;
    }

    // style changes to common FileUpload component
    .secondary-title {
      margin-bottom: 0.75rem;
    }
    input {
      opacity: 0; // to disable default file input element
      cursor: pointer;
    }
  }

}
