.scan-to-win-listing {
  .mr-1 {
    margin-right: 1rem;
  }

  .table .table-row .table-cell {
    min-width: 9rem;
  }

  .table .table-cell:last-child {
    text-align: center;
  }

  .table-row a {
    color: #2b3238;
  }

  .table-row a:hover {
    color: #1557bf;
  }

  .pagination-button {
    font-size: 0.875rem;
    color: #80959d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 1rem 1rem;
    gap: 0.5rem;
  }

  .pageNo {
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  }

  .pagination-button img {
    padding-top: 0.1rem;
  }

  .column-Expired,
  .column-Unpublished {
    color: red;
    font-weight: bold;
  }

  .column-Scheduled,
  .column-Live {
    color: green;
    font-weight: bold;
  }

  .filters-section {
    display: flex;
    gap: 1rem;

    .field {
      width: 100%;
      padding: 0;
    }
  }
}

.select-trigger-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .trigger-button {
    border: 0.0625rem solid #ccc;
    background-color: transparent;
    min-width: 8rem;
    max-width: fit-content;
    border-radius: 0.25rem;
    font-weight: 400;
  }

  .trigger-button:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

@media screen and (max-width: 840px) {
  .scan-to-win-listing .table.table-dynamic .table-row {
    .table-cell.column-campaign::before {
      content: 'ID';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .table-cell.column-start-date::before {
      content: 'Start Date';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .table-cell.column-end-date::before {
      content: 'End Date';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .table-cell.column-actions {
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
}
