.media-asset-list .title .light{
    font-weight: 300;
    color: #80959d;
}

.media-asset-list .store-info {
    display: flex;
    flex-wrap: wrap;
    background-color: #f0f5f8;
    padding: 0.625rem;
}
.media-asset-list .store-info .store-info-item{
    width: 50%;
}
.media-asset-list .store-info .black{
    color: #000;
}


.media-asset-list .assetSearch{
    max-width: 100%;
}

.media-asset-list .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}
.media-asset-list .assetSearch .section-row-input{
    width: 50%;
    float: left;
    padding-right: 1.25rem;
}
.media-asset-list .assetSearch .section-row-input-auto{
    width: auto;
    float: left;
    padding-right: 1.25rem;
}
.media-asset-list .assetSearch .section-row-input-half{
    width: 15%;
    float: left;
    padding-right: 1.25rem;
}
.media-asset-list .section-row-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.media-asset-list .section-row-flex .section-row{
    width: 80%;
}

.media-asset-list .link-btn{
    cursor: pointer;
}
.media-asset-list .link-btn:hover{
    color: #1557bf;
}

.media-asset-list .pagination-container {
    margin-top: 2rem;
    display: flex;
    justify-content: space-between;
}


@media (max-width: 36rem) {
    .media-asset-list .store-info .store-info-item{
        width: 100%;
    }
    .media-asset-list .assetSearch .section-row{
        width: 100%;
    }
    .media-asset-list .assetSearch .section-row-input{
        width: 100%;
    }
    .media-asset-list .assetSearch .section-row-input-half{
        width: 50%;
    }
    .media-asset-list .section-row-flex{
        flex-wrap: wrap;
    }
    .media-asset-list .assetSearch .form-buttons{
        width: 100%;
        margin-bottom: 1.25rem;
    }
    .media-asset-list .website-page-name,
    .media-asset-list .website-page-actions{
        background-color: #fbfcfc;
        width: auto !important;
    }
}