/* Stats Table Styles */
.operations-dashboard .table-row:hover,
.operations-dashboard .table-row:hover + .table-row {
  box-shadow: unset !important;
}
.operations-dashboard .stats-table {
  display: flex;
}
.operations-dashboard .stats-table .table-header  {
  color: black;
}
.operations-dashboard .stats-table .table-cell  {
  height: 70px;
  font-weight: 600;
}
.operations-dashboard .stats-table .table-row .table-cell:not(:first-child) {
  text-align: center;
  border-right: 1px solid #eaefef;
}

.operations-dashboard .stats-table .table:not(:first-child) .first-column {
  display: none;
}
.operations-dashboard .stats-table img {
  margin-right: 10px;
}

/* Products Table Styles */
.operations-dashboard .products-table .table-cell {
  height: 70px;
}
.operations-dashboard .products-table {
  flex-grow: 1;
  margin-right: 40px;
}
.operations-dashboard .products-table .table-row {
  font-size: 0.75rem;
}
.operations-dashboard .products-table .table-cell:not(:first-child) {  
  color: #80959d;
}

/* Categories Stats Styles */
.operations-dashboard .categories-stats .donut-chart {
  width : 300px;
  height : 400px;
  border-radius: 2px;
  border: solid 1px #eaefef;
}
.operations-dashboard .recharts-wrapper {
  margin : 0 auto;
}
.operations-dashboard .legend-list {
  position: relative;
  margin-bottom: 13px;
  list-style: none;
  font-weight: 600;
}
.operations-dashboard .legend-value {
  position: absolute;
  right:32px;
}
.operations-dashboard ul {
  padding-left: 30px;
}

/* Headings Styles */
.operations-dashboard .products-table .heading,
.operations-dashboard .categories-stats .heading
{
  font-weight: bold;
  letter-spacing: -0.3px;
  margin: 60px 0 25px 0;
}
.operations-dashboard .table-chart-container {
  display: flex;
}

.operations-dashboard .order-stats .table .table-header {
  display: table-row;
}

.operations-dashboard .order-stats .table .table-cell:not(:first-child) {
  text-align: right;
}

.operations-dashboard .order-stats .heading {
  margin-top: 40px;
  font-weight: bold;
  letter-spacing: -0.3px;
  margin: 60px 0 25px 0;
}
.operations-dashboard .order-stats .heading .orders-count{
  margin-left: 10px;
  font-weight: normal;
}
.operations-dashboard .order-stats .header-container .middle {
  align-self: center;
  margin-top: 27px;
}
.operations-dashboard .order-stats .header-container .right {
  align-self: center;
  margin-top: 27px;
  margin-right: -15px;
}
.operations-dashboard .order-stats .header-container .SingleDatePickerInput__withBorder {
  border: none;
}
.operations-dashboard .order-stats .table-cell a {
  color: #2b3238;
}
.operations-dashboard .order-stats .table-cell {
  font-size: 0.75rem;
}
.operations-dashboard .order-stats .table-cell a:hover {
  color: #1557bf;
}

.operations-dashboard .order-stats .table-header a {
  color: #80959d;
}

.operations-dashboard .order-stats .order-table-container {
  overflow-x: auto
}

.operations-dashboard .order-stats .order-table-container .table-cell {
  min-width: 100px;
}

.operations-dashboard .order-stats .order-table-container .table-cell:first-child {
  min-width: 150px;
  text-align: left;
}

.operations-dashboard .order-stats .order-table-container .first-column, .operations-dashboard .order-stats .order-table-container .first-column-header {
  position: absolute;
  left: -2px;
  min-width: 150px;
  background-color: #fbfcfc;
}

.operations-dashboard .order-stats .order-table-container .first-column-header { 
  background: white;
}

@media (min-width : 1301px) {
  .operations-dashboard .stats-table {
    display: grid;
    grid-template-columns:  calc(25% + 65px) calc(25% - 25px) calc(25% - 25px) calc(25% - 25px);
  }
  .operations-dashboard .stats-table .table:first-child .first-column {
    width : 100px;
  }
  .operations-dashboard .table-caption {
    display: none;
  }
  .operations-dashboard .stats-table .table:last-child .table-row .table-cell {
    border-right: none;
  }
}

@media (min-width: 701px)  and (max-width: 1300px) {
  .operations-dashboard .stats-table {
    flex-wrap: wrap;
  }
  .operations-dashboard .stats-table .table {
    width : 48%;
  }
  .operations-dashboard .stats-table .table:nth-child(even) {
    margin-left: 20px;
  }
}

@media (max-width: 700px) {
  .operations-dashboard .stats-table {
    flex-direction: column;
  }
}



/* Media Queries*/
@media (max-width: 1300px) {
  /* Categories Chart Styles*/
  .operations-dashboard .table-chart-container {
    flex-direction: column;
    flex-grow: 1;
  }

  /* Stats Table Styles*/
  .operations-dashboard .table-caption:not(:first-child) {
    margin-top : 20px;
  }
  .operations-dashboard .table-header {
    display: none;
  }
  .operations-dashboard .products-table {
    margin-right: 0
  }
  .operations-dashboard .hidden-cell {
    display : none;
  }
  .operations-dashboard .stats-table .table:not(:first-child) .first-column {
    display: table-cell;
  }
  .operations-dashboard .stats-table .table-cell {
    height: 50px;
  }
  .operations-dashboard .stats-table .table-row .table-cell:nth-child(odd) {
    border-left: 1px solid #eaefef;
  }
  .operations-dashboard .first-row .table-cell {
    border-top : 0;
  }
  .operations-dashboard .table-caption .nameValueCard .name {
     height: 42px;
     border-radius: 4px 0 0 0;
     text-align: left;
     padding : 0 20px;
     line-height: 42px;
     min-width: 160px;
  }
  .operations-dashboard .table-caption .nameValueCard .value {
     height: 100%;
     text-align: right;
     border-radius: 0 4px 0 0;
     padding: 8px 20px 0 0;
  }
  .operations-dashboard .table-caption .nameValueCard {
    height: 42px;
    display: flex;
    flex-direction: row-reverse;
  }

}

@media screen and (max-width: 480px) {
.operations-dashboard .order-stats .order-table-container .table-row:last-child .table-cell:first-child{
  position: absolute;
  background-color:#fbfcfc;
}
.operations-dashboard .order-stats .header-container .left {
  align-self: center;
}
.operations-dashboard .order-stats .header-container .SingleDatePickerInput__withBorder {
  border: 1px solid #dbdbdb;
}
.operations-dashboard .order-stats .header-container {
  flex-direction: column;
}
}
