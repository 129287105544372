.analytics-field .errorField textarea {
  background-color: #fbf5f5;
  border: 1px solid #ea353a;
}

.analytics-field .errorField-msg {
  color: #ea353a;
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
}

.preview-section {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
  padding: 2rem;
  padding-top: 2.5rem;
  overflow: auto;
  gap: 2rem;
}

.column-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.actionButton-section {
  display: flex;
  flex-direction: column;
}

.actionButton-section button {
  min-width: max-content;
}

.actionButton-section .input-error-message {
  display: block;
  margin: 0.5rem auto;
}
