@use 'styles/colors' as *;

.voucher-center-campaign-form-page {
  .offer-campaign-status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 0.75rem;
    height: 2rem;
    margin-right: 1rem;

    span {
      margin-top: 0.25rem;
      margin-right: 0.25rem;
    }

    .status-text {
      color: $fp-white;
      border-radius: 5px;
      padding: 0 0.5rem;
      &.enabled {
        background-color: $fp-green;
      }

      &.disabled {
        background-color: $fp-red-1;
      }

      &.expired {
        background-color: $fp-grey;
        opacity: 0.6;
      }
    }

  }
 .datetime-picker-container, .primary-cta-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;

  // set the width of the time input
  .Date-time .input-wrapper:nth-child(2) {
    width: 7rem;
  }

  .primary-cta {
    width: 25%;
  }
  .link {
    width: 75%;
  }
 }

  .offer-slots-container {
    padding: 1rem;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

    .offer-slots-title {
      margin-top: 0;
      font-size: 1.25rem;
      font-weight: bold;
      margin-bottom: 0;
    }

    .single-slot-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      border-bottom: 1px solid $fp-grey-4;
      padding: 1rem 0;

      .offer-schedule-subtext {
        font-size: 0.8rem;
        color: $fp-grey-3;
        font-style: italic;
      }
    }

    .single-slot-container:last-child {
      border-bottom: none !important;
    }

    .single-offer-slot-inputs {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;

          // set the width of the time input
        .Date-time .input-wrapper:nth-child(2) {
          width: 7rem;
        }

        .delete-icon {
          width: 2rem;
        }

        .offer-ids {
          width: 10rem;
        }
      }

    .add-offer-slot-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
        margin-right: 0.5rem;
      }
    }

  }

  .form-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
  }

  .deep-links-container {
    border: 1px solid $fp-grey-4;
    padding: 1rem 2rem;
    color: $fp-grey-3;

    span {
      font-size: 1.25rem;
    }
  }
}
