.opsParentWrapper {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;

  .views {
    div {
      display: flex;
      align-items: center;
    }
    .operationsButton {
      color: white;
      background-color: #1557bf;
      font-size: 0.875rem;
      border: 1px solid #eaefef;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
      margin-left: 1rem;
    }
  }
  .views,
  .operations {
    padding: 0.5rem;
  }
  .operations {
    input {
      width: 100%;
    }
    .operationsButton {
      color: white;
      background-color: #1557bf;
      font-size: 0.875rem;
      border: 1px solid #eaefef;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .full-link-acc {
      text-overflow: ellipsis;
      overflow: hidden;
      .json-key {
        color: brown;
      }
      .json-value {
        color: navy;
      }
      .json-string {
        color: olive;
      }
    }
    .full-link-acc-error {
      white-space: normal;
      .json-string {
        color: red;
      }
    }
  }
  .views {
    .user-view,
    .user-view-child {
      display: flex;
      flex-direction: column;
      align-items: start;
      margin-bottom: 1rem;
      tr {
        td {
          border: 1px solid black;
          padding: 0.3rem;
        }
      }
      .table-field-name {
        min-width: 9.375rem;
      }
      .table-field-content {
        min-width: 18.75rem;
      }
    }
    .link-acc,
    .card-list {
      text-overflow: ellipsis;
      overflow: hidden;
      .json-key {
        color: brown;
      }
      .json-value {
        color: navy;
      }
      .json-string {
        color: olive;
      }
    }
    .card-list-error {
      white-space: normal;
      .json-string {
        color: red;
      }
    }
  }
  .dev-message {
    color: red;
    margin: 0.5rem 0;
  }
}

.successCheckmark {
  font-size: 2rem;
}
