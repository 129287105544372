.product-form {
  max-width: initial;
}

.product-form .product-description {
  padding-bottom: 40px;
  margin-bottom: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.product-form .product-description .input-wrapper,
.product-form .product-description .input,
.product-form .product-description textarea {
  height: 100%;
}
.product-form .input-wrapper .Checkbox {
  display: inline-flex;
}
.product-form textarea {
  margin-top: 20px;
}
.product-form .store-name-container .store-toggle {
  margin-left: 94%;
}
.product-form .barcodes-field {
  margin-top: 10px;
}
.product-form .product-description textarea {
  resize: none;
  min-height: 168px;
}

.product-form .variant-section .description-tabs {
  margin-bottom: -1px;
  z-index: 1;
}

.product-form .variant-details .field > .description {
  margin-top: 5px;
}

.product-form .variant-section .tab {
  position: relative;
}

.product-form .add-variant-link {
  font-weight: 600;
  font-size: 0.875rem;
  white-space: nowrap;
  margin-left: 10px;
  border-radius: 2px;
  background-color: var(--green);
  width: 143px;
  height: 44px;
  color: #ffffff;
}

.product-form .variant-delete-icon {
  color: #80959d;
  position: absolute;
  top: 11px;
  right: -20px;
  height: 14px;
  width: 14px;
  line-height: 14px;
  cursor: pointer;
  border-radius: 50%;
}

.product-form .variant-delete-icon:hover {
  background-color: #eb8181;
  color: white;
}

.product-form .inline-label {
  height: 44px;
  margin: 0;
  display: flex;
  align-items: center;
  text-transform: none;
  font-size: 0.875rem;
  color: #2b3238;
  font-weight: 600;
}

.product-form .basic-section.form-row .flex-around {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0px 15px;
  grid-column-gap: 20px;
}

.product-form .basic-section.form-row .flex-around .field {
  padding: 0px;
}

.product-form .form-actions {
  padding: 20px 35px;
  margin: 30px 0px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
}

.product-form .add-variant-link:only-child {
  margin-left: auto;
}

.product-form .store-related-details .field {
  flex: 1;
}

.product-form .stores-error-text {
  color: #80959d;
  margin-bottom: 40px;
  font-size: 0.875rem;
  text-align: center;
  cursor: pointer;
}

.product-form .product-delete-button {
  margin-right: 20px;
  display: flex;
}

.product-form .product-delete-button-text {
  color: #eb8181;
  line-height: 35px;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.875rem;
  margin-top: 3px;
}

.product-form .product-delete-icon {
  color: #eb8181;
  margin-right: 6px;
  vertical-align: middle;
}

.product-form .product-info-icon {
  cursor: 'pointer';
  padding-left: 4px;
}

.product-form .inventory-info {
  margin: -4px;
}

.product-form .form-buttons:only-child {
  margin-left: auto;
}

.product-form .product-location-details {
  width: 100%;
  display: flex;
}

.product-form .metadata-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  margin-top: 20px;
  margin-bottom: -15px;
}

.product-form .metadata-section .string,
.product-form .metadata-section .text {
  grid-column-start: 1;
  grid-column-end: 3;
}
.product-form .image-upload-container .preview-area-container {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 20px;
  overflow: unset;
  transition: all 0.6s ease;
  white-space: nowrap;
}

.product-form .image-upload-container .preview-slider-container {
  overflow: hidden;
}
.product-form .image-upload-container .preview-slider-container .image-preview {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-form .image-updload-section .field.variant-image {
  margin-bottom: -15px;
}

.product-form .ImageUpload .image-upload-container.with-items .input-area {
  flex: 1;
}

.product-form .form-row {
  margin-left: -15px;
  margin-right: -15px;
}

.product-form .form-row .field {
  padding-left: 15px;
  padding-right: 15px;
  flex-basis: 33.33%;
}

.product-form input[name='brand'] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
  max-width: 180px;
  background: #fbfbfc;
}
.product-form input[name='brand']:focus {
  z-index: 1;
}

.product-form input[name='name'] {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.product-form .description-tabs {
  color: #80959d;
  font-size: 14px;
  line-height: 2;
  overflow: unset;
  border-bottom: 1px solid #eaefef;
  padding-bottom: 10px;
  margin-bottom: 20px;
  letter-spacing: -0.3px;
  margin-top: 10px;
}

.product-form .description-tabs span {
  padding-bottom: 14px;
  margin: 0px 14px;
  cursor: pointer;
}

.product-form .description-tabs span:first-of-type {
  margin-left: 0px;
}

.product-form .description-tabs .selected {
  color: #2b3238;
  font-weight: 600;
  border-bottom: 2px solid #1557bf;
}

.product-form .basic-section {
  display: flex;
  flex-direction: column;
}

.product-form .basic-section .product-details-header,
.product-form .store-related-details .product-details-header {
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.6;
  margin: 0px 0px 22px 15px;
  letter-spacing: -0.3px;
}
.product-form .store-related-details .product-details-header {
  margin: 20px 0px;
}

.product-form .flex-around {
  flex-wrap: wrap;
}

.product-form .flex-around.variants-tab {
  margin-top: -15px;
  margin-bottom: -2px;
}

.product-form .flex-around.variants-tab .tabs {
  overflow: initial;
}

.product-form .flex-around.variants-tab .tabs .tab {
  padding: 10px 0px;
  margin: 0px 25px;
  box-shadow: none;
  border: 0px;
}

.product-form .flex-around.variants-tab .tabs a:first-of-type {
  margin-left: 0px;
}

.product-form .flex-around.variants-tab .tabs .tab.active {
  border-bottom: 2px solid #1557bf;
  background: transparent;
  position: relative;
}

.product-form .flex-around .field {
  flex: 50%;
}

.product-form .variant-section {
  position: relative;
  padding: 15px 40px 20px 40px;
  margin: 20px -40px;
}

.product-form .variant-section .toggle-section,
.product-form .variant-section .variant-add-section {
  display: block;
  background-color: #fbfbfc;
  border-radius: 2px;
  width: auto;
  padding: 15px 20px;
  border-top: 1px solid #eaefef;
  border-bottom: 1px solid #eaefef;
  margin: 0px -20px;
  min-height: 60px;
}
.product-form .variant-section .variant-add-section {
  padding: 0px 20px;
  border-top: none;
  margin-bottom: 20px;
  margin-top: -2px;
}
.product-form .field.client-item-id-field {
  margin-right: 2%;
  margin-top: 10px;
}
.product-form .variant-section .toggle-section .Toggle {
  display: inline;
}
.product-form .variant-section .toggle-section span:first-of-type {
  font-size: 1.125rem;
  font-weight: 600;
}
.product-form .variant-section .toggle-section div:first-of-type {
  float: right;
}

.product-form .flex-around.variants-tab .tabs span {
  font-size: 0.875rem;
}
.product-form .variant-details {
  padding: 0px 20px 20px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid #eaefef;
  border-top: none;
  margin-top: 0px;
}

.product-form .variant-name {
  width: 50%;
}

.product-form .edit-variant-container .edit-variant {
  display: grid;
  grid-template-columns: 10fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 15px;
}
.product-form .edit-variant-container .edit-variant input[type='text'] {
  width: 80px;
  padding: 0px 5px;
}
.product-form .edit-variant-container .variant-save {
  border-radius: 2px;
  height: 40px;
}
.product-form .edit-variant-container .variant-edit,
.product-form .edit-variant-container .variant-cancel {
  min-width: 80px;
  min-height: 36px;
  font-size: 0.875rem;
  font-weight: 600;
}
.product-form .edit-variant-container .edit-variant div {
  display: flex;
  flex-wrap: wrap;
}
.product-form .variant-details .variant-name .labelWrap {
  display: none;
}

.variant-details .store-specific-details .field .labelWrap label {
  font-size: 0.8rem;
}

.product-form .no-variants {
  padding: 10px 0px 20px 0px;
  border-top: 1px dashed #dadee0;
  margin-top: 15px;
}
.product-form .image-updload-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
}
.product-form .image-updload-section .field.variant-image {
  grid-column-start: 1;
  grid-column-end: 3;
}
.product-form .variant-name .input-addon:last-child {
  padding: 0px;
  background: #1557bf;
}

.product-form .store-related-details {
  margin-top: 70px;
}

.product-form .store-details-row {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
  padding-top: 28px;
  margin: auto;
  grid-gap: 15px;
  margin-top: 40px;
}

.product-form .store-name-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  padding: 0px 0px 0px 15px;
  width: 100%;
}
.product-form .store-name-container::after {
  width: 99%;
  content: '';
  display: block;
}

.product-form .store-name {
  position: absolute;
  top: 3px;
  font-weight: 600;
  font-size: 0.875rem;
  background-color: white;
  transform: translateY(-50%);
  padding: 5px 2px 0 12px;
  z-index: 1;
  line-height: 1.21;
  letter-spacing: -0.26px;
  text-align: center;
  color: #2b3238;
}

.product-form .form-actions {
  padding: 20px 40px;
}

.product-form .input-area {
  display: none;
}

.product-form .product-image-container {
  display: grid;
  grid-template-columns: 20px 1fr 20px;
  flex: 1;
}
.product-form .product-image-container button[disabled] {
  cursor: auto;
}

.product-form .form-actions .form-buttons {
  display: flex;
  flex-direction: row-reverse;
}
.product-form .form-actions .form-buttons .primary {
  margin-left: 10px;
  width: 110px;
}

.product-form .store-specific-details {
  display: flex;
  flex: 32%;
  padding: 8px 16px 16px 16px;
  flex-direction: column;
  flex-wrap: wrap;
  border: 1px solid #eaefef;
  background-color: #fbfcfc;
  margin-top: 20px;
}

.product-form .store-specific-details .heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 2;
  letter-spacing: -0.3px;
  color: #2b3238;
  margin-bottom: 15px;
  border-bottom: 1px solid #eaefef;
}

.product-form .store-specific-details .details {
  display: flex;
}

.product-form .store-specific-details .location-details {
  display: flex;
  flex-direction: column;
}

.product-form .store-specific-details .location-details .field {
  width: 12rem;
}

.product-form .store-specific-details .location-details {
  > .field:first-child {
    width: 100%;
    margin-bottom: 1rem;
  }
}

.product-form .store-specific-details .details .field {
  margin-bottom: 0px;
}

.product-form .store-specific-details .details .input-wrapper .input {
  border: 1px solid var(--border-color);
}

.product-form
  .store-specific-details
  .details
  .fresh-stock
  .input-wrapper
  .input {
  border: 1px solid var(--border-color);
}
.product-form .input-wrapper .Checkbox {
  display: inline-flex;
}
.product-form
  .store-specific-details
  .details
  div:last-of-type
  .input-wrapper
  .input {
  border-right: 1px solid var(--border-color);
}

.product-form .store-specific-details .details .input-wrapper .input-addon {
  padding: 0px;
  background-color: transparent;
  border: none;
  font-weight: 400;
  z-index: 2;
  margin-left: 10px;
}

.product-form
  .store-specific-details
  .details
  .fresh-stock
  .input-wrapper
  .input-addon {
  margin-right: 10px;
}

.product-form
  .store-specific-details
  .details
  .input-wrapper
  input[type='number'],
.product-form
  .store-specific-details
  .details
  .input-wrapper
  input[type='text'] {
  border: none;
  padding: 0px 8px;
}
.product-form
  .price-related-details
  .details
  .input-wrapper
  input[type='number'] {
  padding-left: 25px;
  margin-left: -18px;
}

.product-form
  .tax-related-details
  .details
  .input-wrapper
  input[type='number'] {
  padding-right: 5px;
}
.product-form .tax-related-details .details .input-wrapper .input-addon {
  position: absolute;
  right: 25px;
}

.product-form
  .tax-related-details
  .details
  .input-wrapper
  input[type='number'] {
  padding: 0px 5px;
}

.product-form .store-specific-details .details .field {
  padding: 0px;
}

.product-form .ImageUpload .image-preview-container {
  width: 234px;
  height: 206px;
}

.product-form .store-name-container img {
  z-index: 2;
  margin-top: -9px;
  margin-left: -15px;
}

.product-form .store-specific-details.stock-related-details .details {
  position: relative;
}
.product-form
  .store-specific-details.stock-related-details
  .details
  .field.unlimited-stock {
  position: absolute;
  right: -10px;
  top: -4px;
  display: flex;
  align-items: center;
}

.product-form
  .store-specific-details.stock-related-details
  .details
  .field.unlimited-stock
  .input-wrapper {
  margin-top: -14px;
  margin-left: 10px;
}
.product-form
  .store-specific-details.stock-related-details
  div:nth-child(2)
  .input-wrapper
  input {
  border-right: 1px solid var(--border-color);
}
.product-form
  .store-specific-details.stock-related-details
  div:first-child
  .Toggle {
  position: absolute;
  top: -45px;
  right: 0;
}
.product-form .store-related-details .toggle-expand {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.product-form .store-related-details .toggle-expand .store-type.open {
  font-weight: 700;
}
.product-form .store-related-details .toggle-expand .toggle-button {
  cursor: pointer;
  margin-right: 8px;
}

@media screen and (max-width: 768px) {
  .product-form .inline-label {
    margin-top: 5px;
  }
  .product-form .store-details-row {
    grid-template-columns: 1fr 1fr;
  }
  .product-form .image-upload-container .product-image-container {
    grid-template-columns: 48px 1fr 38px;
    max-width: 350px;
  }
}

/* Responsive stuff */
@media screen and (max-width: 767px) {
  .product-form .form-row .field {
    padding-left: 9px;
    padding-right: 9px;
  }

  .product-form input[name='brand'] {
    flex-basis: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: -1px;
    max-width: 100%;
  }
  .product-form input[name='brand']:focus {
    z-index: 1;
  }

  .product-form input[name='name'] {
    flex-basis: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .product-form .variant-section {
    position: relative;
    padding: 0;
    margin: 15px 0px;
  }

  .product-form .variant-details {
    background-color: #ffffff;
  }

  .product-form .description-tabs {
    margin-left: -1px;
  }

  .product-form .add-variant-link {
    padding-right: 25px;
  }

  .product-form .variant-name .input {
    display: block;
  }

  .product-form .variant-name .input-addon {
    border-right: 1px solid var(--border-color);
    border-bottom-width: 0;
  }

  .product-form .variant-image input,
  .product-form .variant-image .file-label {
    width: 100%;
  }

  .product-form .store-related-details {
    margin: 45px -25px 0px;
  }

  .product-form .store-details-row {
    padding: 30px 25px 0;
    position: relative;
  }

  .product-form .store-details-row .field {
    display: inline-block;
    width: 50%;
    margin-bottom: 30px;
    vertical-align: top;
  }
  .product-form
    .store-specific-details.stock-related-details
    .field.unlimited-stock {
    width: auto;
  }
  .product-form .store-name-container {
    text-align: center;
    left: 23px;
    top: 0;
    position: absolute;
    width: 92%;
  }

  .product-form .form-buttons {
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
  }

  .product-form .form-buttons:only-child {
    margin-left: -10px;
  }

  .product-form .metadata-section {
    grid-template-columns: 1fr;
  }
  .product-form .variant-name .input-addon:last-child {
    width: 44px;
    overflow: hidden;
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  .product-form .variant-name .add-variant-link span {
    padding: 10px;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 640px) {
  .product-form .basic-section.form-row .flex-around {
    display: flex;
    flex-direction: column;
  }
  .product-form .basic-section .product-name .input {
    flex-flow: nowrap;
  }
  .product-form .ImageUpload {
    height: auto;
    border: 0px;
  }
  .product-form .image-upload-container .product-image-container {
    grid-template-columns: 30px 1fr 30px;
    margin: auto;
  }
  .product-form .ImageUpload .image-upload-container .input-area {
    width: 100%;
    height: 200px;
    margin: auto;
    border: 1px dashed #dadee0;
  }
  .product-form .store-details-row {
    grid-template-columns: 1fr;
  }
  .product-form .metadata-section {
    display: flex;
    flex-direction: column;
  }
  .product-form .variant-name {
    width: 100%;
  }
  .product-form .ImageUpload .image-upload-container.with-items {
    display: grid;
    grid-gap: 30px;
    padding: 20px;
  }
  .product-form .tax-related-details .details .input-wrapper .input-addon {
    right: 10px;
  }
  .product-form .store-name-container .store-toggle {
    margin-left: 90%;
  }
}

@media screen and (max-width: 480px) {
  .product-form {
    border-top: 1px solid #efefef;
    margin: 0px -25px;
    padding: 15px 25px;
  }

  .product-form .basic-section .product-name .input-wrapper {
    width: 100%;
  }
  .product-form .basic-section .product-details-header,
  .product-form .store-related-details .product-details-header {
    margin-left: 14px;
  }
  .product-form .variant-section {
    margin: auto;
  }
  .product-form .variant-name .input > span {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .product-form .store-details-row {
    grid-template-columns: 1fr;
  }
  .product-form .no-variants {
    padding-bottom: 0px;
  }
  .product-form .variant-section .toggle-section span:first-of-type {
    font-size: 1rem;
  }
  .product-form .add-variant-link {
    margin: auto;
    padding: 0px;
  }
  .product-form .form-buttons button {
    margin-left: 0;
    flex: 1;
  }

  .product-form .form-buttons input[type='submit'] {
    margin-left: 10px;
    flex: 1;
  }
  .product-form .variant-details {
    padding: 0px 18px 0 18px;
  }
  .product-form .image-updload-section {
    display: flex;
    flex-direction: column;
  }
  .product-form .field.client-item-id-field,
  .product-form .field.barcodes-field {
    width: 100%;
  }

  .product-form .store-name-container {
    width: 86%;
  }
  .product-form .variant-section .flex-around.variants-tab div:first-of-type {
    overflow-x: auto;
    padding-top: 10px;
  }
  .product-form .product-image-container {
    margin-left: -20px;
  }
  .product-form .store-details-row.form-row {
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .product-form .form-actions {
    margin-top: 20px;
    flex-direction: column-reverse;
  }
  .product-form .form-actions .button {
    margin-left: 0px;
  }
  .product-form .form-actions .product-delete-button {
    justify-content: center;
    margin-top: 15px;
  }

  .product-form .ImageUpload .image-upload-container.with-items {
    padding: 10px 0px;
  }
  .product-form .ImageUpload {
    border: 0px;
    margin-bottom: 20px;
  }
  .product-form .variant-section .toggle-section {
    margin-top: 15px;
    border: 0px;
  }

  .product-form .image-upload-container .product-image-container {
    grid-template-columns: 0px 1fr 0px;
  }
  .product-form .variant-name .add-variant-link span {
    padding: 14px;
  }
  .product-form .edit-variant-container .edit-variant {
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 10px;
    margin-bottom: 10px;
  }
  .product-form .edit-variant-container .edit-variant div {
    grid-column-start: 1;
    grid-column-end: 3;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .product-form .variant-add-section .variant-edit {
    margin: 10px 0px;
  }
  .product-form .image-upload-container .product-image-container button img {
    display: none;
  }
  .product-form .store-details-row {
    grid-gap: 0px;
  }
  .product-form .flex-item textarea {
    height: auto;
    min-height: 90px;
  }
  .product-form .store-name-container .store-toggle {
    margin-left: 84%;
  }
}

@media screen and (max-width: 360px) {
  .product-form .image-upload-container .preview-slider-container {
    margin-left: 10px;
  }
  .product-form .image-updload-container .delete-icon {
    right: 10px;
  }
  .product-form .store-name-container .store-toggle {
    margin-left: 80%;
  }
}

@media screen and (max-width: 320px) {
  .product-form .image-upload-container .preview-slider-container {
    margin-left: 6px;
  }
  .product-form .store-name-container .store-toggle {
    margin-left: 80%;
  }
}

.stock-tooltip-button img {
  z-index: 100;
  display: inline-block;
  float: right;
  cursor: pointer;
}
.stock-tooltip-content {
  position: absolute;
  background: #333;
  padding: 10px;
  border-radius: 3px;
  right: 1px;
  top: 40px;
  width: 320px;
  z-index: 100;
  color: #fff;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 1);
}
.stock-tooltip-content span {
  display: inline-block;
  float: right;
  color: #fff;
  cursor: pointer;
  margin-top: -12px;
  -webkit-transform: scaleY(0.9);
  -ms-transform: scaleY(0.9);
  transform: scaleY(0.9);
  font-size: 1.3em;
}
.stock-tooltip-content p {
  font-weight: 900;
  display: inline-block;
}
.stock-tooltip-content table {
  width: 100%;
}
.stock-tooltip-content td {
  width: 50%;
}
.stock-tooltip-content li {
  list-style: none;
}
.product-form .btnSaveLocation {
  margin-top: 27px;
  line-height: 1em;
  height: 45px;
  min-width: 60px;
  margin-left: 10px;
}
.product-form .sap-product-name-field {
  width: 48%;
}
.product-form .store-related-details .store-type {
  display: inline-block;
}
.product-form .store-related-details .flex {
  display: flex;
  align-items: center;
}
.product-location-details .secondary-input-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
}
