.DetailsCard {
    width: 100%;
    height: 120px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    border: solid 1px #eaefef;
    font-size: 0.75rem;
}
.DetailsCard .top {
    padding: 20px;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
}
.DetailsCard .left .title {
    font-size: 0.875rem;
    font-weight: bold;
    color: #1557bf;
    letter-spacing: -0.3px;
}
.DetailsCard .top .value {
    font-weight: 600;
    letter-spacing: 0.1px;
    color: #2b3238;
}
.DetailsCard .top .right .title {
    color: #80959d;
}
.DetailsCard .bottom {
    height: 42px;
    background-color: #fbfcfc;
    padding: 12px 20px;
    color: #80959d;
    border-top: 1px solid #eaefef;
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    letter-spacing: -0.2px;
}
.DetailsCard .top .right .value::before {
    content: '';
    display: inline-block;
    height: 7px;
    width: 7px;
    border-radius: 7px;
    margin: 0 5px;
}
.DetailsCard .top .right .value.green::before {
    background-color: #1557bf;
}
.DetailsCard .top .right {
    text-align: right;
}