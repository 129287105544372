.creative-add-creative-page .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}
.creative-add-creative-page .section-row-input{
    width: 50%;
    float: left;
    padding-right: 1.25rem;
}

.creative-add-creative-page .timestamp{
    font-size: 0.75rem;
    text-align: right;
}

.creative-add-creative-page .form-buttons{
    margin-bottom: 2rem;
}

.creative-add-creative-page .section-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.creative-add-creative-page .section-title .section-btn{
    padding: 0.125rem 1rem;
    border-radius: 0.125rem;
    border: 1px solid #1557bf;
    color: #1557bf;
    font-size: 0.875rem;
    line-height: 2;
    font-weight: 600;
    letter-spacing: -0.0125rem;
    cursor: pointer;
}

.creative-add-creative-page .center {
    margin: auto;
    height: 50%;
    width: 50%;
    padding-top: 20%;
    text-align: center;
  }

.creative-add-creative-page .table .table-cell:last-child {
    width: 50%;
    text-align: left;
}

.creative-add-creative-page .button-checked {
    background-image: url(../../commonMedia/images/checkbox-checked.svg);
    background-size: 100%;
    height: 1.125rem;
    width: 1.125rem;
    padding: 0;
    border: none;
}

.creative-add-creative-page .button-unchecked {
    background-image: url(../../commonMedia/images/checkbox-unchecked.svg);
    background-size: 100%;
    height: 1.124rem;
    width: 1.125rem;
    padding: 0;
    border: none;
}
  
@media (max-width: 36rem) {
    .creative-add-creative-page .section-row-input{
        width: 100%;
        padding-right: 0;
    }
}


