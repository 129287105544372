.delivery-order-details h1.heading {
  font-weight: 300;
  color: #80959d;
}

.delivery-order-details h1.heading .reference-number {
  color: #2b3238;
}

.delivery-order-details .invoice-button {
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  background-image: url('./invoice-icon.svg');
  background-position: left 10px center;
  padding: 0 0 0 40px;
  background-repeat: no-repeat;
  background-size: 18px;
  height: 28px;
  border-width: 0;
  cursor: pointer;
}

.delivery-order-details .invoice-button a {
  color: #80959d;
}

.delivery-order-details .batch-icon {
  background-image: url('./sales-order-icon.png');
  margin-right: 0.625rem;
}

.delivery-order-details .print-icon {
  background-image: url('./print.png');
  margin-right: 10px;
}

.delivery-order-details .DropDown {
  display: inline-block;
}

.delivery-order-details .dropdown-menu {
  top: 34px;
}

.delivery-order-details .order-actions-dropdown {
  padding-left: 0.8rem;
}

.delivery-order-details .order-actions-dropdown .dropdown-items {
  width: 160px;
}

.delivery-order-details .actions-link {
  color: #80959d;
  font-weight: 700;
}

.delivery-order-details .edit-icon {
  cursor: pointer;
}

.delivery-order-details .ButtonWithOptions {
  margin-right: 10px;
}

.delivery-order-details .section-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin: 1rem 0 20px 0;
  line-height: 28px;
}
.delivery-order-details .slot-title {
  margin-bottom: 0;
  margin-top: 5px;
}
.delivery-order-details .section-title h3,
.delivery-order-details .section-title h5 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.delivery-order-details .section-title.title {
  margin: 10px 0;
}
.delivery-order-details .section-title h5 {
  font-size: 0.875rem;
  margin-top: 10px;
}

.delivery-order-details .order-status-pending {
  color: #f88c8c;
}

.delivery-order-details .order-status-completed {
  color: #1557bf;
}

.delivery-order-details .user-details {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
}

.delivery-order-details .address-details {
  width: 260px;
}

.delivery-order-details .customer-name {
  color: #1557bf;
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  font-weight: 600;
  text-decoration: none;
  display: inline-block;
}

.delivery-order-details .order-placement-info-item {
  min-width: 280px;
  padding: 12px 0;
  background-image: linear-gradient(
    to right,
    #eaefef 80%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: top;
  background-size: 10px 1px;
  background-repeat: repeat-x;
}

.delivery-order-details .order-placement-info-icon {
  margin-right: 10px;
  display: inline-block;
  height: 24px;
  width: 24px;
  vertical-align: middle;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.delivery-order-details .icon-shield {
  background-image: url('./shield-icon.svg');
}

.delivery-order-details .icon-clock {
  background-image: url('./clock-icon.svg');
}

.delivery-order-details .icon-mobile {
  background-image: url('./mobile-icon.svg');
}

.delivery-order-details .icon-sales-order {
  background-image: url('./sales-order-icon.png');
}
.delivery-order-details .creation-time,
.delivery-order-details .completion-time,
.delivery-order-details .device-name,
.delivery-order-details .batch-info,
.delivery-order-details .verification-status,
.delivery-order-details .delivery-preference {
  color: #2b3238;
  font-weight: 600;
}

.delivery-order-details .batch-info-reassigned {
  color: #aeb9bd;
  font-weight: 600;
}

.delivery-order-details .customer-actions {
  display: flex;
  margin-top: 10px;
}

.delivery-order-details .customer-address,
.delivery-order-details .customer-addresses,
.delivery-order-details .store-address,
.delivery-order-details .order-delivery-date,
.delivery-order-details .order-delivery-slot {
  font-size: 0.875rem;
  color: #80959d;
}

.delivery-order-details .customer-address.customer-meta {
  font-weight: 600;
}

.delivery-order-details .customer-addresses {
  margin-top: 24px;
  margin-bottom: 20px;
}

.delivery-order-details .order-items-view {
  margin-top: 48px;
}

.delivery-order-details .retry-message {
  text-align: center;
  cursor: pointer;
}

.delivery-order-details .button {
  min-width: 80px;
  padding: 0 16px;
}

.delivery-order-details .location-options .radio-label {
  height: 28px;
}

.delivery-order-details .location-options .radio-label-text {
  font-size: 1.125rem;
  line-height: 28px;
  font-weight: 600;
  letter-spacing: -0.3px;
}

.delivery-order-details .customer-addresses .Radio {
  flex-flow: row wrap;
}

.delivery-order-details .customer-addresses .radio-label {
  flex-direction: row-reverse;
}

.delivery-order-details .customer-addresses .radio-label::before {
  margin: 0 0 0 10px;
}

.delivery-order-details .customer-addresses .radio-label-text {
  font-size: 0.875rem;
  color: #80959d;
  font-weight: normal;
}

.delivery-order-details .delivery-option-buttons:not(:empty) {
  margin-top: 20px;
}

.delivery-order-details .address-details .customer-heading {
  margin-bottom: 10px;
}

.orders-page .order-type-icon-pickup,
.orders-page .order-type-icon-delivery {
  border-radius: 50%;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
}
.orders-page .order-type-icon-pickup {
  background-color: #ffeeee;
}
.orders-page .order-type-icon-delivery {
  background-color: #e9fcf1;
}
.orders-page .container-order-number {
  display: flex;
}
.orders-page .order-number,
.orders-page .store-name {
  margin-left: 10px;
}
.delivery-order-details .payment-details .payment-card {
  margin-right: 10px;
}
.delivery-order-details .payment-details .payment-card-number .image-sm img {
  width: 30px;
  height: 30px;
}
.delivery-order-details .order-actions .order-actions-web-view {
  display: inline;
}

.delivery-order-details .order-actions .order-actions-mobile-view {
  display: none;
}

.delivery-order-details .edit-address-and-timeslot-text {
  color: #80959d;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eaefef;
}

@media screen and (max-width: 550px) {
  .delivery-order-details .order-placement-details {
    margin-bottom: 48px;
  }
  .delivery-order-details .order-placement-details,
  .delivery-order-details .address-details {
    width: 100%;
  }
  .delivery-order-details .order-actions .order-actions-mobile-view {
    display: inline;
  }
  .delivery-order-details .order-actions .order-actions-web-view {
    display: none;
  }
  .delivery-order-details .order-metadata-details .form-action,
  .delivery-order-details .delivery-option-buttons {
    display: flex;
  }
  .delivery-order-details .order-metadata-details .form-action .primary,
  .delivery-order-details .order-metadata-details .form-action .button,
  .delivery-order-details .delivery-option-buttons .button {
    flex: 1;
  }
}

@media screen and (max-width: 480px) {
  .delivery-order-details .print-icon {
    margin-right: 0;
  }
  .delivery-order-details .order-actions-dropdown {
    padding-left: 0.8rem;
  }
  .order-actions {
    text-align: right;
    margin-bottom: 10px;
  }
  .delivery-order-details .order-placement-details,
  .delivery-order-details .address-details {
    border: solid 1px #eaefef;
    background-color: #fbfcfc;
    padding: 8px 15px 12px;
  }
  .delivery-order-details .order-placement-details .section-pre-order h3 {
    font-size: 14px;
    letter-spacing: -0.26px;
    margin-bottom: 10px;
  }
  .delivery-order-summary-wrapper .order-placement-details .section-pre-order {
    border-bottom: solid 1px #eaefef;
  }
  .delivery-order-details .order-placement-details {
    margin-bottom: 20px;
  }
  .delivery-order-details .address-details .customer-heading,
  .delivery-order-details .address-details .flex-around.section-title.title {
    font-size: 14px;
    letter-spacing: -0.26px;
    margin: 10px 0px;
  }
  .delivery-order-details .address-details .flex-around.section-title.title {
    margin-top: 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #eaefef;
  }
  .delivery-order-details .address-details .slot-details {
    margin-top: 15px;
    border-top: 1px dashed #eaefef;
  }
  .delivery-order-details .address-details .slot-details .section-title h5 {
    margin-top: 8px;
  }
  .delivery-order-details .customer-addresses {
    margin-top: 0px;
  }
  .delivery-order-details .section-title {
    margin-bottom: 10px;
  }
  .delivery-order-details .location-options .radio-label-text {
    font-size: 14px;
  }
  .delivery-order-details .collapse-container-order,
  .delivery-order-details .collapse-container-metadata,
  .delivery-order-details .collapse-container-payment,
  .delivery-order-details .collapse-container-refund {
    border-top: 1px solid #eaefef;
    /* padding-top: 20px; */
    position: relative;
    display: flex;
    align-items: center;
    margin: auto -30px;
    padding: 0px 30px;
  }
  .delivery-order-details .collapse-container-order {
    margin-top: 20px;
  }

  .delivery-order-details .order-items-view .flex-around.section-title h3,
  .delivery-order-details .order-metadata-details .flex-around.section-title h3,
  .delivery-order-details .payment-details .section-title h3 {
    margin-left: 30px;
    font-size: 0.875rem;
  }
  .delivery-order-details .collapse-container-order button,
  .delivery-order-details .collapse-container-metadata button,
  .delivery-order-details .collapse-container-payment button,
  .delivery-order-details .collapse-container-refund button {
    position: absolute;
    font-size: 18px;
    font-weight: 600;
    color: #80959d;
  }
  .delivery-order-details .collapse-container-order.false button {
    top: 20px;
    z-index: 10;
  }
  .delivery-order-details .collapse-container-metadata.false button,
  .delivery-order-details .collapse-container-payment.false button,
  .delivery-order-details .collapse-container-refund.false button {
    top: 15px;
    z-index: 10;
  }
  .delivery-order-details .collapse-container-order h3,
  .delivery-order-details .collapse-container-metadata h3,
  .delivery-order-details .collapse-container-payment h3,
  .delivery-order-details .collapse-container-refund h3 {
    margin-left: 30px;
    font-size: 0.875rem;
  }
  .delivery-order-details .order-items-view .table {
    margin-top: 15px;
  }
  .delivery-order-details .section-title {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .orders-page .table .table-cell .order-type-icon-delivery,
  .orders-page .table .table-cell .order-type-icon-pickup {
    margin-right: 10px;
  }
}
