.customer-metadata-form h3{
  margin: 1rem 0rem;
}

.customer-metadata-heading-and-edit-button{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  position: absolute;
  right:0px;
}

.customer-metadata-edit-link-style {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--smart-store);
  letter-spacing: -0.3px;
}

.customer-metadata-edit-link-style > img {
  padding-left: 5px;
}
.customer-metadata-form {
  margin: 1rem;
  position: relative;
}

.customer-metadata-form form {
  max-width: 100%;
}

.customer-metadata-form .form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.customer-metadata-form input{
  font-weight: 500;
}

.customer-metadata-form input:focus{
  font-weight: bold;
}
.customer-metadata-form textarea:focus{
  font-weight: bold;
}

.customer-metadata-show-view {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  font-size: 14px;
  letter-spacing: -0.3px;
}

.customer-metadata-key {
  font-weight: bold;
  margin-bottom: 14px;
}

.customer-metadata-value {
  color: var(--secondary-text-color);
}

.customerDetails .tab-container .horizontal-line {
  padding: 0 20px;
  border: none !important;
}

.customer-metadta-show-view-container{
  padding-top:10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.customer-metadata-form .boolean-field {
  padding-top: 35px;
}
@media screen and (max-width: 768px) {
  .customer-metadata-form .form-fields {
    grid-template-columns: repeat(1, 1fr);
  }
}

