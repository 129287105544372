.filter-main {
  width: 100%;
}

.date-time-section {
  width: 100%;
  display: flex;
}

.date-time-section .date-time-filter {
  width: 50%;
  display: flex;
}

.filter-main .field {
  width: 50%;
  padding: 0 0.625rem;
  margin-bottom: 1.25rem;
}

.filter-main .actions {
  display: flex;
  justify-content: end;
  margin-bottom: 2.5rem;
  margin-top: 1.25rem;
}

@media (max-width: 996px) {
  .date-time-section {
    flex-direction: column;
  }

  .date-time-section .date-time-filter {
    width: 100%;
  }
}
