.team-leaves-page .last-one {
  margin-top: 40px;
  margin-bottom: 20px;
}

.team-leaves-page .team-leaves-approve-page h1 {
  margin-bottom: 20px;
}

.team-leaves-page .tabsLeaves {
  padding-bottom: 30px;
  margin-top: 40px
}

@media screen and (max-width: 768px) {
  .team-leaves-page .table {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -10px;
    width: auto;
  }

  .team-leaves-page .spacer {
    padding: 10px;
    width: 50%;
  }
}

@media screen and (max-width: 480px) {
  .team-leaves-page .table {
    display: block;
    padding: 0 30px;
  }

  .team-leaves-page .spacer {
    padding: 0 0 20px 0;
    width: 100%;
  }
}
