.game-form-fields {
  .field-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1.25rem;
    margin-bottom: 1.5rem;
    .deeplink-row {
      display: grid;
      grid-template-columns: 7fr 1fr;
      button {
        font-size: 2rem;
        font-weight: 600;
      }
    }
  }
  .field-row {
    .start,
    .end {
      display: flex;
      justify-content: space-between;
      .time-wrapper {
        display: flex;
        flex-direction: column;
        .time-input {
          width: 15rem;
          padding: 0.6rem;
          border: 0.0625rem solid #dadee0;
          border-radius: 0.125rem;
        }
      }
    }
  }
  .single-input-field {
    margin-bottom: 1.5rem;
  }
  .game-buttons {
    text-align: right;
    align-items: flex-end;
    button {
      font-size: 0.875rem;
      border: 1px solid #eaefef;
      border-radius: 0.25rem;
      padding: 0.5rem 1rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    .cancel-button {
      color: black;
      background-color: #f3f5f6;
      margin-right: 0.5rem;
    }
    .submit-button {
      color: white;
      background-color: #1557bf;
    }
    .cancel-button:hover,
    .submit-button:hover {
      cursor: pointer;
    }
  }
}
