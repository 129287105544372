.dfc{
.preorder-page .tab-details {
  padding: 2.5rem 0;
}

.preorder-page .delete-button {
  margin-top: -2.1875rem;
  float: right;
  font-size: 0;
}

.preorder-page .delete-button {
  background-image: url('../../../icons/delete.svg');
  height: 2.5rem;
  background-position: center;
  margin-top: 1.875rem;
  margin-left: 0.3125rem;
  padding: 0.625rem;
}

.preorder-page .vector-button {
  background-image: url('./Vector.svg');
  height: 2.5rem;
  background-position: center;
  margin-top: 1.75rem;
  margin-left: 0.3125rem;
  padding: 0.625rem;
}

.preorder-page .form-actions {
  justify-content: flex-start;
  margin-top: 2.5rem;
}

.preorder-page .key-container {
  display: flex;
  justify-content: flex-start;
  margin-right: 0;
}
.preorder-page .key-container .field.checkbox {
  margin: 0 1.875rem;
}

.preorder-page .key-container .field.checkbox .input-wrapper {
  margin-top: 1.0625rem;
}

.preorder-page .key-container .field:first-child {
  margin-right: 1.25rem;
}

.preorder-page form {
  max-width: 100%;
}
.preorder-page .enum-fields {
  margin-left: 1.25rem;
}
.preorder-page form .form-actions input[type='submit'],
.preorder-page form .form-actions .button {
  min-width: 6.25rem;
}
.preorder-page .enum-fields.checkbox .input-wrapper {
  margin-top: 1.0625rem;
}

.tab-row {
  display: flex;
  align-items: center;
}

.tab-text {
  color: grey;
  font-size: 0.875rem;
  margin-left: 1.25rem;
  margin-right: 0.625rem;
  font-weight: 400;
}

.row {
  display: flex;
  align-items: center;
}

.box-container {
  border: 1px solid #F2F2F2;
  padding: 1.25rem;
}

.form-actions.edit {
  justify-content: space-between;
  margin-top: 1.875rem;
  bottom: 0;
}

.togglebtn {
  width: 2.25rem;
  height: 1.25rem;
}

.preorderCampaign{
  padding: 1.25rem;
  border: 1px solid #eaefef;
  min-height: 80vh;
}

.tab-align{
  justify-content: space-between;
  margin-right: 0.625rem;
}

.toggleButtonAlign{
  display: flex;
  justify-content: end;
}
.center{
  text-align: center
}
.publishScreenViewButtons{
  margin-left: 262px;
  width: 470px;
}
.publishPopup{
  max-width: 525px !important;
}
.publishMsg{
    width: 41.438rem;
    font-family: 'Lato';
    font-style: normal;
    display: flex;
    justify-content: right;
    flex:1;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.25rem;
    margin-right: 0.938rem;
}

.AlignCampaign{
  display: flex;
  align-items: center;
  justify-content: center;
}

.AlignCampaignSaved{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10vh;
  max-width: 100vw;
}

.flexProperty{
  display: flex;
}

.marginSet{
  margin-right: 40px;
}


.publishBtn{
  margin-bottom: 2rem;
  padding-bottom: 0rem;
}
.campaignSave{
  display: flex  ;
  justify-content: right  ;
  margin-top: 2rem  ;
}

.p-0{
  padding: 0rem !important;
}
.mr-8{
   margin-right: 0.5rem !important;
}
.T-none{
  text-transform: none ;
}
.mt-32{
margin-top: 2rem;
}

.publish-preorder{
  font-size: 15px;
}

}