
.date-picker .DateRangePickerInput_arrow_svg {
    margin-top : 10px;
 }
 .date-picker .DateRangePicker .DateRangePickerInput{
     display: flex;
 }
 .date-picker .DateRangePicker .DateRangePickerInput > .DateInput:first-child input{
     text-align: center;
     padding-left: 0;
     padding-right: 5px;
 }
 .date-picker .DateRangePicker .DateRangePickerInput > .DateInput:last-child input{
     text-align: center;
     padding-right: 0;
     padding-left: 5px;
 }
 .date-picker .DateInput input {
     border-width: 0;
 }
 .date-picker .DateRangePickerInput__withBorder {
     border: 1px solid var(--border-color);
     border-radius: 2px;    
 }
 .date-picker .DateRangePickerInput__withBorder:focus {
     border-color: #1557bf;
     outline: none;
 }

 .date-picker .DateRangePickerInput_calendarIcon {
     margin : 0;
     background-color: #f3f5f6;
     border-right: solid 1px var(--border-color);
 }