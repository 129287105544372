.tab-wrapper {
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 11rem;
}

.payment-wrapper,
.jwc-operations-wrapper {
  padding: 1rem;
}

.membership-information,
.link-details {
  border-right: 1px solid #dadee0;
}

.membership-information,
.date-information,
.link-details,
.operations {
  padding: 0.5rem;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
}

.info-details {
  text-align: right;
}

.cancel-membership-button-divider {
  width: 95%;
  margin: auto;
}

.cancel-membership-button {
  color: #ffffff;
  background-color: #c10b3a;
  padding: 0.5rem;
  float: right;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#pulao-id-master,
#pulao-id-child,
#vc-master,
#vc-child {
  margin: 0.5rem 0;
}

.merge-button {
  margin-top: 1rem;
  width: 100%;
}

h5 {
  margin: 0;
}

.operations-button {
  color: white;
  background-color: #1557bf;
  font-size: 0.875rem;
  border: 1px solid #eaefef;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
