.PackageReq-box{
  max-width: 50rem;
}
.PackageReq-box .details{
  height: 36rem;
}
.PackageReq-box .errMsg{
  text-align: center;
}
.PackageReq-box .form-buttons{
  margin-top: 1rem;
}

.PackageReq-box .detils{
  height: 36rem;
}
.PackageReq-box .PackageReq-set-scroll{
  height: 30rem;
  overflow-y: auto;
}
.PackageReq-box .PackageReq-set{
  margin-bottom: 1rem;
}
.PackageReq-box .PackageReq-set-index{
  margin-bottom: 1rem;
  border-bottom: 1px solid #eaefef;
  color: #80959d;
  font-size: 0.875rem;
  line-height: 2;
}

.PackageReq-box .PackageReq-set-assets{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.PackageReq-box .PackageReq-set-assets-item{
  width: 50%;
  margin: 0.5rem 0;
  color: #2b3238;
  font-size: 0.875rem;
  line-height: 1.41;
  letter-spacing: -0.0125rem;
}
.PackageReq-box .PackageReq-set-assets-item.disabled{
  cursor: not-allowed;
}
.PackageReq-box .PackageReq-set-assets-item.disabled .checkbox_label{
  opacity: 0.6;
}
.PackageReq-box .checkbox_btn{
  background-image: url(../../../commonMedia/images/checkbox-unchecked.svg);
  margin-right: 0.6rem;
  background-size: 100%;
  height: 1.125rem;
  width: 1.125rem;
  padding: 0;
  border: none;
}

.PackageReq-box .checkbox_btn.button-checked{
  background-image: url(../../../commonMedia/images/checkbox-checked.svg);
}