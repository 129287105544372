.rewards-applicable-container {
  background-color: #fbfcfc;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid #e9eaeb;
  margin-top: 0.25rem;
  margin-bottom: 1.5rem;
}

.stores.rewards-applicable-container {
  margin: 0.25rem var(--s2) 1.5rem var(--s2);
  width: 100%;
}

.rewards-applicable-selector {
  position: relative;
  display: flex;
  flex-direction: column;
}

.rewards-applicable-btn {
  height: 1.875rem;
  width: auto;
  border-radius: 2px;
  padding: 0.438rem, 0.875px;
  min-width: fit-content;
  background: #f3f5f6;
  border-radius: 2px;
  cursor: pointer;
  border: none;
}

.rewards-applicable-list {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.625rem;
  column-gap: 1.25rem;
  background: #fbfcfc;
  margin-bottom: 0.625rem;
}

.rewards-applicable-tag {
  background-color: #e5e5e5;
  font-size: 0.75rem;
  padding: 0 0.313rem;
  margin: 0 0.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rewards-applicable-container .selected-list {
  margin: 0;
  padding: 0.5rem 0;
  max-height: 18.75rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.rewards-applicable-container .selected-list__item {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
  position: relative;
}

.rewards-applicable-container .selected-list__label {
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 1;
}

.rewards-applicable-container .selected-list__label small {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.rewards-applicable-container .tag-icon {
  margin-right: 0.5rem;
}

.rewards-applicable-container .delete-icon {
  cursor: pointer;
}

.rewards-applicable-container .delete-icon__container {
  position: absolute;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 0.313rem;
}

.rewards-applicable-container .delete-icon__container:hover {
  background-color: #edf1f5;
}
