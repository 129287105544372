.invoiceEvochers{
  display: flex;
  justify-content: right;
}

.invoice-summary {
  width: 310px !important;
  padding: 0 15px;

}

.invoice-summary .total-amount {
  font-size: 1.125rem;
}

.invoice-summary .total-amount {
  margin-bottom: 12px;
  margin-top: 12px;
}

.invoice-summary .payment-amount {
  margin-top: 24px;
}
