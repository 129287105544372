/* space: [0, 4, 8, 16, 32] */
:root {
  --s0: 0;
  --s1: 0.25rem;
  --s2: 0.5rem;
  --s3: 1rem;
  --s4: 2rem;
}
.search-container {
  margin-left: 8px !important;
}
.success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border: 1px solid transparent;
  margin: 0.9375rem 0 0.5rem;
  padding: 0.5rem 0;
  text-align: center;
}
.search-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  border: 1px solid transparent;
  margin: 1rem 2.5rem !important;
  padding: 0.5rem 0;
  text-align: center;
}
.strong-btn {
  float: right;
  margin-right: 8px;
  cursor: pointer;
}
.contain {
  max-width: 80rem;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.row {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0 var(--s3);
  display: flex;
}
.row.flex-direction {
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}
.col {
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 0;
}

.evoucher-wrapper.contain {
  margin-left: 0;
}
.error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 0.75rem;
  border: 1px solid transparent;
  margin: 0.9375rem 0 0.5rem;
  padding: 0.5rem 0;
  text-align: center;
}
.non-breaking {
  white-space: nowrap;
}

form {
  margin: auto;
  max-width: 100%;
}
label,
.editPopup label {
  color: #2b3238;
  font-size: 0.875rem;
  line-height: 1.41;
  margin-bottom: 0.625rem;
  letter-spacing: -0.0125rem;
  font-weight: normal;
}
.field label {
  font-size: 0.875rem;
  text-transform: none;
  font-weight: normal;
}
.field label.thin {
  color: inherit;
}
.confirmPopup textarea {
  color: #80959d;
}
.confirmPopup.voucher-values {
  color: #80959d;
  margin-bottom: var(--s3);
}
.add-more button {
  background: url('./plus.svg') no-repeat center;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  border-radius: 20px;
  border: 1px solid #1557bf;
  font-weight: bold;
}
.add-more {
  margin-top: var(--s3);
  font-weight: 600;
}
.denomination {
  padding: 0 var(--s3);
}
.evoucher-wrapper .labelWrap:not(:first-child) {
  display: none;
}
#voucher-values .labelWrap {
  visibility: hidden;
}
.col-12 {
  width: 100%;
}
.col-6 {
  width: 100%;
  margin: 0 0.5rem;
}
.col-4 {
  width: 50%;
  margin: var(--s2) 0;
}
hr {
  margin: var(--s3) 0;
}
.ml {
  margin-left: var(--s2);
}
.mt-0 {
  margin-top: 0;
}
.my {
  margin: var(--s2) 0;
}
.col-3 {
  width: 100%;
}
.mt {
  margin-top: var(--s3);
}
.mb {
  margin-bottom: var(--s3);
}
label.thin {
  height: 1.875rem;
}
.form-actions .primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}
.confirmPopup {
  max-width: 100%;
}
textarea {
  resize: none;
  min-height: 50px;
  max-height: 100px;
}
.col-12 {
  width: 100%;
}
input[type='checkbox'] + label {
  text-transform: none;
}
select {
  text-transform: capitalize;
}
input[type='text'] {
  font-size: 0.875rem;
  font-weight: normal;
}
/* Resend Details start*/
.resend-details-wrapper {
  border: 1px solid #eaefef;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: var(--s4);
}
.word-wrap-resend {
  word-wrap: break-word;
  padding-left: 1rem;
}
/* Resend Details end*/
/* Search Result start*/
.search-result-wrapper {
  border: 1px solid #eaefef;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: var(--s4);
}
.search-result-wrapper label {
  margin-right: 0.1875rem;
}
.row.status {
  border-bottom: 1px solid #eaefef;
  background: #fbfcfc;
  text-transform: capitalize;
}
.status .active {
  color: green;
}
.status .used {
  color: #1557bf;
}
.status .expired {
  color: #eb8181;
}
.transaction-item {
  display: flex;
  border-bottom: 0.0625rem solid rgb(234, 234, 234);
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-direction: column;
  padding: 1rem 0px;
  line-height: 1.5rem;
}
.refund-pointer {
  cursor: pointer;
}
.date {
  font-size: 0.75rem;
  color: rgb(153, 153, 153);
  line-height: 1rem;
  font-weight: 400;
}
.transaction-item .row {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  justify-content: space-between;
  font-weight: 400;
  line-height: 1.5rem;
  color: rgb(77, 77, 77);
}
.amount {
  font-weight: 700;
  line-height: 1rem;
  width: 6rem;
  text-align: right;
}
.empty-transactions {
  color: #979797;
  line-height: 1.5rem;
}

/* Search Result end*/

/* mobile */
@media screen and (max-width: 39.9375em) {
}
/* small */
@media screen and (min-width: 40em) {
  .contain {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .col-6 {
    width: 50%;
  }
  .col-4 {
    width: 25%;
  }
  .col-3 {
    width: 30%;
  }
  .confirmPopup {
    max-width: 50%;
  }
  label.thin {
    height: inherit;
  }
  .row.flex-direction {
    flex-direction: row;
  }
  .ml-sm {
    margin-left: var(--s2);
  }
}
/* medium */
@media screen and (min-width: 52em) {
}
/* large */
@media screen and (min-width: 64em) {
}
