.search-wrapper {
  width: 100%;
  padding-left: 15px;
  padding-right: 10px;
  border: 0.0625rem solid var(--border-color);
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 2px;
}

.search-input {
  position: relative;
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  font-size: 1rem;
  border: none;
  color: #2b3238;
  background-color: transparent;
  outline: none;
  font-weight: 600;
}

.has-error {
  border: 1px solid red;
  background-color: #fbf5f5;
}

.search-input::placeholder {
  color: #aeb9bd;
  font-size: 0.875rem;
  font-weight: normal;
}

.search-results {
  display: block;
  position: absolute;
  width: 100%;
  overflow-x: auto;
  border-radius: 2px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  border-top: 0;
  z-index: 2;
  max-height: 200px;
  top: 45px;
  left: 0;
}

.search-results__wrapper {
  padding-left: 0;
  margin: 0;
  padding: 4px;
}

.search-results__option {
  color: #645f5f;
  font-size: 12px;
  list-style: none;
  padding: 4px 12px;
  cursor: pointer;
}
