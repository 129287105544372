form.luckydraw-form {
  max-width: 100%;
  padding: 1.875rem 0;
  margin-top: -0.063rem;
}
.luckydraw.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.luckydraw .container .tabs {
  width: 100%;
}
.luckydraw-form .form-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;
}

.luckydraw-form .form-fields .row{
  grid-column: 1 / span 2;
}

.luckydraw-form .normal-text {
  color: #000;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.41;
  letter-spacing: -0.013rem;
}

.luckydraw-form .grid {
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.25rem;
}

.single-column-field {
  grid-column: 1 / span 2;
}

.luckydraw-form textarea {
  height: 5.25rem;
}

.luckydraw .field-label {
  font-size: 0.875rem;
  text-transform: none;
  font-weight: normal;
  display: inline-block;
  color: #2b3238;
  margin-bottom: 0.5rem;
  line-height: 1.41;
  letter-spacing: -0.0125rem;
  cursor: pointer;
  user-select: none;
}

.luckydraw .field .input{
  margin: 0;
}
/* Add Draw Box */
.add-content-block-lucky {
  margin-top: 0.938rem;
}

.header-1 {
  display: inline-block;
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.0125rem;
}

.header-1-lowercase {
  display: inline-block;
  color: #2b3238;
  font-size: 0.875rem;
  line-height: 1.41;
  /* font-weight: 600; */
  letter-spacing: -0.0125rem;
}

.draw-popup {
  border: 0.063rem solid #dbdbdb;
}

.draw-header {
  height: 2.188rem;
  background-color: #f4f5f7;
  color: #ffffff;
  padding-left: 1.563rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mr10-luckydraw {
  padding-bottom: 0.5rem;
}

.draw-details {
  display: flex;
  gap: 1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 1.25rem;
}

.single-column-field .draw-popup .input-wrapper {
  width: 100%;
}

.single-column-field .form-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.single-column-field .submit-form-actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.submit-form-actions .input-wrapper {
  padding-top: 0.625rem;
}

.submit-draw-buttons {
  display: flex;
  gap: 0.938rem;
}

.draw-details .label-tooltip {
  font-size: 0.75rem;
  margin-left: 0.5em;
  position: relative;
}

.draw-details .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 25.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  left: -3.155rem;
  text-align: left;
}
.draw-details .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.draw-details .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}

/* Verify and confirm */
.verify-box {
  display: flex;
  align-items: center;
}

.verify-box-error {
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 425px) {
  .draw-popup {
    width: 100% !important;
  }

  .campaign-assets .file-label {
    text-align: center;
    padding: 1.875rem 3.75rem;
    background-color: #fff;
    font-size: 0.875rem;
    color: #aeb9bd;
    letter-spacing: -0.013rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .submit-form-actions .input-wrapper {
    margin-bottom: 1rem;
  }

  .verify-box-error {
    margin-bottom: 1.3rem;
  }
}

@media screen and (min-width: 495px) {
  .normal-height-field textarea {
    height: 1.875rem;
    padding: 0.875rem;
  }
}

@media screen and (max-width: 590px) {
  .draw-popup .details {
    display: flex;
    flex-direction: column;
  }
  .campaign-details-1 {
    grid-column: 1 / span 2;
  }
  .draw-details .label-tooltip .label-tooltiptext {
    width: 16.875rem;
  }
  .full-width-mobile {
    width: 100%;
  }
  .luckydraw.container .tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .fiftypercent-field .input-wrapper {
    width: 50%;
  }
  .twentyfivepercent-field .input-wrapper {
    width: 25%;
  }
  form.luckydraw-form {
    padding: 1.875rem;
    border: 0.063rem solid #efefef;
  }
}

@media screen and (max-width: 768px) {
  .draw-details {
    flex-flow: row wrap;
  }
  .mobile-full-width {
    grid-column: 1 / span 2;
  }
  .mobile-full-width .field {
    grid-column: 1 / span 2;
  }
  .mobile-full-width .field .rc-time-picker {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .draw-popup {
    width: 80%;
  }
}

@media screen and (min-width: 1025px) {
  .draw-popup {
    width: 35.625rem;
  }
}
