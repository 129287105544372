form.omnichallenge-form {
    max-width: 100%;
    padding: 1.875rem 0;
    margin-top: -0.063rem;
}

.omnichallenge .container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.omnichallenge .task-stores-applicable{
  flex-grow: 1;
}

.omnichallenge .Searchable.customer-tags .tag-value{
  margin-top: 0;
}

.omnichallenge .cardbins select{
  text-transform: none;
}

.omnichallenge .container .tabs {
  width: 100%;
}

.omnichallenge-form .normal-text, .catelogue-form .normal-text  {
  color: #000;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.41;
  letter-spacing: -0.013rem;
}

.omnichallenge-form .single-column-field {
  grid-column: 1 / span 2;
}

.omnichallenge-form .flex {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
}

.omnichallenge-form .mb-1 {
  margin-bottom: 1rem;
}

.omnichallenge-form .mt-1 {
  margin-top: 1rem;
}

.omnichallenge-form .mr-1 {
  margin-right: 1rem;
}

.omnichallenge-form .ml-1 {
  margin-left: 1rem;
}

.omnichallenge-form .mr-2 {
  margin-right: 2rem;
}

.omnichallenge-form .mb-2 {
  margin-bottom: 2rem;
}

.omnichallenge-form .ml-2 {
  margin-left: 2rem;
}

.omnichallenge-form .mr-3 {
  margin-right: 3rem;
}

.omnichallenge-form .mr-05 {
  margin-right: 0.5rem;
}

.omnichallenge-form .ml-05 {
  margin-left: 0.5rem;
}

.omnichallenge-form .mt-05 {
  margin-top: 0.5rem;
}

.omnichallenge-form .pb05 {
  padding-bottom: 0.5rem;
}

.omnichallenge-form .pt01 {
  padding-top: 0.1rem;
}

.omnichallenge .separator {
  border-bottom: 0.063rem dashed #dbdbdb;
}

.omnichallenge .field-label, .catelogue-form .field-label{
  font-size: 0.875rem;
  text-transform: none;
  font-weight: normal;
  display: inline-block;
  color: #2b3238;
  margin-bottom: 0.5rem;
  line-height: 1.41;
  letter-spacing: -0.0125rem;
  cursor: pointer;
  user-select: none;
}

.omnichallenge .header-1 {
  display: inline-block;
  color: #2b3238;
  font-size: 0.75rem;
  line-height: 1.41;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: -0.0125rem;
}

.omnichallenge .header-1-lowercase {
  display: inline-block;
  color: #ffffff;
  font-size: 1.125rem;
  line-height: 1.41;
  font-weight: bold;
  letter-spacing: -0.0125rem;
}

.omnichallenge .header-rewards{
  font-size: 1rem;
}

.omnichallenge #reward-type{
  min-width: 12.5rem;
}

.omnichallenge .header-2 {
  font-size: 1rem;
  text-transform: none;
  font-weight: bold;
  display: inline-block;
  color: #2b3238;
  line-height: 1.41;
  letter-spacing: -0.0125rem;
}

.campaign-duration .field .rc-time-picker {
  width: 100%;
}

.reward-popup {
  border: 0.063rem dashed #dbdbdb;
  border-radius: 0.5rem;
}

.popup-header {
  height: 2.188rem;
  color: #ffffff;
  margin-bottom: -1rem;
  display: flex;
  justify-content: end;
  align-items: center;
}

.popup-details{
  padding: 0 1.25rem;
}

.popup-details textarea {
  height: 1.875rem;
  padding: 0.875rem;
  border-radius: 0.5rem;
  margin-bottom: 0;
}

.single-column-field .reward-popup .input-wrapper {
  width: 100%;
}

.omnichallenge .form-actions {
  display: flex;
  align-items: center;
  gap: 0.938rem;
  margin-top: 1rem;
  justify-content: flex-end;
}

.omnichallenge .clone-action {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
}

.clone-action .primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.single-column-field .submit-form-actions {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
}

.submit-form-actions .input-wrapper {
  padding-top: 0.625rem;
}

/* Verify and confirm */
.verify-box {
  display: flex;
  align-items: center;
}

.verify-box-error {
  margin-bottom: 0.2rem;
  margin-left: 0.5rem;
}

@media screen and (max-width: 425px) {
  .add-popup {
    width: 100% !important;
  }

  .submit-form-actions .input-wrapper {
    margin-bottom: 1rem;
  }

  .verify-box-error {
    margin-bottom: 1.3rem;
  }
}

.challenge-details textarea {
  height: 20rem;
  max-height: 20rem !important;
}

@media screen and (max-width: 495px) {
  .popup-details textarea {
    height: 5.25rem;
  }
}

@media screen and (max-width: 590px) {
  .add-popup .details {
    display: flex;
    flex-direction: column;
  }
  .campaign-details-1 {
    grid-column: 1 / span 2;
  }
  .full-width-mobile {
    width: 100%;
  }
  .omnichallenge .container .tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (min-width: 768px) {
  .omnichallenge-form .grid {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.25rem;
  }
  .omnichallenge .fiftypercent-field .input-wrapper {
    width: 48.8%;
  }
  .twentyfivepercent-field .input-wrapper {
    width: 25%;
  }
  form.omnichallenge-form {
    padding: 1.875rem;
    border: 0.063rem solid #efefef;
  }
}

@media screen and (max-width: 768px) {
  .grid-col-1-span-2 {
      grid-column: 1 / span 2;
    }
  .campaign-duration {
    grid-column: 1 / span 2;
  }
  .campaign-duration .field {
    grid-column: 1 / span 2;
  }
}
