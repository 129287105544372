.ChangePassword .field {
    margin-bottom: 20px;
}
.ChangePassword .form-error {
    text-align: left
}

.ChangePassword .password-validation-textbox {
    width: 315px;
    height: 215px;
    border-radius: 4px;
    background-color: #fbfcfc;
    margin-left: 40px;
    margin-top: 30px;
}
.ChangePassword .form-container {
    display: flex
}
.ChangePassword form {
    flex-grow: 1
}

.ChangePassword .password-validation-lists {
    display: flex;
    height: 19px;
    letter-spacing: -0.2px;
    color: #80959d;
    margin-bottom: 10px;
    margin-left: 30px;
    font-size: 0.875rem;
}

.ChangePassword .has-six-characters:after,
.ChangePassword .has-uppercase:after,
.ChangePassword .has-lowercase:after,
.ChangePassword .has-number:after
{
    content: "";
    background-image: url(../../../components/Menu/tick-icon.svg);
    width : 20px;
    height: 20px;
    display: block;
    margin-top: 6px;
    margin-left: 15px;
}

.ChangePassword .password-validation-heading {
    margin: 30px 0 18px 30px;
    height: 19px;
    font-weight: bold;
    letter-spacing: -0.2px;
}

@media (max-width: 500px) {
    .ChangePassword .form-container {
        flex-direction:column-reverse;
    }
    .ChangePassword .form-container .form-buttons-container {
        display: flex;
    }
    .ChangePassword .form-container .form-buttons-container .primary, .ChangePassword .form-container .form-buttons-container .button{
        flex: 1;
    }
    .ChangePassword .password-validation-textbox {
        margin: 0px 0px 20px auto;
    }

  }