.apphome-popup-form .w-100 {
  width: 100%;
}

.apphome-popup-form .two-col {
  grid-column: 1/3;
}

.apphome-popup-form .daily-schedule .table .table-cell:not(:first-child) {
  width: revert;
  text-align: center;
}

.apphome-popup-form .appFormButtons .primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.apphome-popup-form .validFrom .rc-time-picker {
  width: 100%;
}

.apphome-popup-form .validTo .rc-time-picker {
  width: 100%;
}

.apphome-popup-form .interval .input {
  flex-direction: row-reverse;
}

.apphome-popup-form .interval .input-addon:first-child {
  border-left: none;
  border-right: 0.0625rem solid var(--border-color);
}

@media screen and (max-width: 683px) {
  .apphome-popup-form .appFormButtons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
}

@media screen and (min-width: 683px) {
  .apphome-popup-form .grid-2-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 0 var(--s3);
  }

  .apphome-popup-form .validFrom {
    grid-column: 1/2;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .apphome-popup-form .validTo {
    grid-column: 2/3;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .apphome-popup-form .appFormButtons {
    grid-column: 2/3;
    justify-self: flex-end;
    margin-top: 2rem;
  }

  .apphome-popup-form .appFormButtons .primary {
    margin-left: var(--s3);
  }
}
