.parentTab {
  width: 100%;
  display: flex;

  .detailsTab {
    border: solid 1px #eaefef;
    width: 75%;

    .buttonRow {
      display: flex;

      .button {
        color: #80959d;
        background-color: #fbfcfc;
        font-size: 0.875rem;
        border: 1px solid #eaefef;
        width: 100%;
      }
      .button:focus {
        color: #2b3238;
        background-color: white;
      }
    }
  }
}

.tab-display-info {
  padding: 1rem;
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  .json-key {
    color: brown;
  }
  .json-value {
    color: navy;
  }
  .json-string {
    color: olive;
  }
}

.developer-tab-wrapper {
  max-width: 50.625rem;
}
