.creative-detail-page .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}
.creative-detail-page .section-row-input{
    width: 50%;
    float: left;
    padding-right: 1.25rem;
}

.creative-detail-page .timestamp{
    font-size: 0.75rem;
    text-align: right;
}

.creative-detail-page .form-buttons{
    margin-top: 2rem;
}

.creative-detail-page .section-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 36rem) {
    .creative-detail-page .section-row-input{
        width: 100%;
        padding-right: 0;
    }
}
