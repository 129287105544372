.AddressCard {
    width: 100%;
    font-size: 0.75rem;
    padding: 10px;
}
.AddressCard .title {
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: -0.3px;
    color: #2b3238;
    padding-bottom: 10px;
}
.AddressCard .value {
    font-size: 0.875rem;
    letter-spacing: -0.3px;
    padding-bottom: 15px;
    min-height: 114px;
    color: #2b3238;
}
.AddressCard .view-address-icon {
    width: 15px;
    height: 15px;
}
.AddressCard .view-map-text {
    padding-left: 8px;
    letter-spacing: -0.1px;
    color: #1557bf;
}
.AddressCard .map-link {
    padding-left: 0;
    border : none;
}
.AddressCard .card-actions {
    color: #80959d;
    font-size: 0.875rem;
    border-top: 1px solid #f1f1f1;
    margin: 0px -20px -20px;
    display: flex;
    justify-content: space-around;
    flex: 1 1;
}
.AddressCard .card-actions .action1 {
    padding-left: 0;
}
.AddressCard .card-actions .action2 {
    color: #fd8f8f;
}
.AddressCard .card-actions button {
    min-width: 50px;
    font-weight: normal;
    padding-left: 0;
    flex: 1;
    padding: 5px;
}

.AddressCard .card-actions button:last-of-type {
    border-left: 1px solid #f1f1f1;
}

.AddressCard .card-actions button:last-of-type img{
    margin-top: -5px;
    margin-right: 4px;
}

.address-map {
    height : 300px;
}
.AddressCard .address-map .places-search {
    display: flex;
}
.AddressCard .address-map .places-search img {
    margin-left: -16px;
    height: 20px;
    width: 14px;
    margin-top: 12px;
}
.AddressCard .form-error {
    margin-bottom: 0;
}
.address-heading {
    font-size : 0.875rem;
    text-align: center;
    font-weight: 500;
}
.editPopup.map-address {
    max-width: 80%;
}
.editPopup.map-address form {
    max-width: 100%;
}