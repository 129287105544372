.polygon-form .hidden-location input, .polygon-form .hidden-location .labelWrap {
    display: none;
}

.polygon-form .hidden-location {
    margin-bottom: 0;
}

.polygon-form .polygon-locations-list .list-item {
    font-size: 12px;
    position: relative;
    margin-bottom: 5px;
}
.polygon-form .polygon-locations-list {
    margin-top: 10px;
}
.polygon-form .polygon-locations-list .list-item span{
    height: 10px;
    width: 10px;
    margin-right: 9px;
    margin-bottom: -1px;
    display: inline-block;
}
.polygon-form .polygon-locations-list .list-item .delete-polygon{
    position: absolute;
    right: -6px;
    top: -1px;
    display: none;
}
.polygon-form .polygon-locations-list .list-item:hover .delete-polygon {
    display: block;
}

.polygon-form .polygon-locations-list .list-item input{
    padding: 0px;
    height: 20px;
    width: 167px;
    font-size: 12px;
    border: none;
}

.polygon-form .polygon-locations-list .polygon-names {
    display: inline-block;
}