.scratch-card-listing {
  .mr-1 {
    margin-right: 1rem;
  }

  .table .table-row .table-cell {
    min-width: 9rem;
  }

  .table .table-cell:last-child {
    text-align: center;
  }

  .table-row a {
    color: #2b3238;
  }

  .table-row a:hover {
    color: #1557bf;
  }

  .pagination-button {
    font-size: 0.875rem;
    color: #80959d;
    font-weight: 600;
    display: flex;
    justify-content: center;
    margin: 1rem 1rem;
    gap: 0.5rem;
  }

  .pageNo {
    padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  }

  .pagination-button img {
    padding-top: 0.1rem;
  }

  .column-Expired,
  .column-Unpublished {
    color: red;
    font-weight: bold;
  }

  .column-Scheduled,
  .column-Live {
    color: green;
    font-weight: bold;
  }
}

@media screen and (max-width: 840px) {
  .scratch-card-listing .table.table-dynamic .table-row {
    .table-cell.column-campaign::before {
      content: 'ID';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .table-cell.column-start-date::before {
      content: 'Start Date';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .table-cell.column-end-date::before {
      content: 'End Date';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }

    .table-cell.column-actions {
      bottom: 0;
      right: 0;
      position: absolute;
    }
  }
}
