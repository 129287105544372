/* User Segment */
.tab-header {
  font-size: 1rem;
  text-transform: none;
  font-weight: bold;
  display: inline-block;
  color: #2b3238;
  margin-bottom: 0.5rem;
  line-height: 1.41;
  letter-spacing: -0.0125rem;
  cursor: pointer;
  user-select: none;
}

.mb-1 {
  margin-bottom: 1rem;
}

.channel-field {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1rem;
}

.channel-checkbox {
  padding-top: 0.5rem;
  margin-right: 2.5rem;
  width: 20%;
}

.channel-field .input-wrapper input:disabled {
  background: #f7f7f7;
}
