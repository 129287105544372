.editPopup.packagePreview-box{
    max-width: 50rem;
}
.package-summary-wrap .package-summary-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.package-summary-wrap .package-summary-inner {
    display: flex;
    flex-wrap: wrap;
    font-size: 0.75rem;
    line-height: 1.8;
    padding: 0.625rem;
}
.package-summary-wrap .package-summary-inner .package-summary-item:not(.description){
    width: 50%;
    margin-bottom: 0.625rem;
}
.package-summary-wrap .package-summary-inner .package-summary-item.description{
    width: 100%;
    margin-bottom: 0.625rem;
}
.package-summary-wrap .package-summary-inner .black{
    color: #000;
}
.package-summary-wrap .package-table-title{
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: -3.125rem;
}

.package-summary-wrap .assetTable{
    max-height: 12.75rem;
    overflow-y: scroll;
}
.package-summary-wrap .assetRow{
    display: flex;
    border-top: 0.0625rem solid #ccc;
    border-left: 0.0625rem solid #ccc;
    border-right: 0.0625rem solid #ccc;
    color: #000;
}
.package-summary-wrap .assetRow:last-child{
    border-bottom: 0.0625rem solid #ccc;
}
.package-summary-wrap .assetRow .id{
    box-sizing: border-box;
    width: 20%;
    padding-left: 0.625rem;
}
.package-summary-wrap .assetRow .name{
    width: 80%;
}