.slot-summary-form{
  max-width: 100%;
}
.slot-summary-form .section-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.slot-summary-form .section-row{
  width: 50%;
}
.slot-summary-form .section-row:after { 
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  font-size:0;
}
.slot-summary-form .section-row-input{
  width: 30%;
  float: left;
  padding-right: 1.25rem;
}
.slot-summary-form .section-row-input-auto{
  width: auto;
  float: left;
  padding-right: 1.25rem;
}
.slot-summary-form .section-row-input-half{
  width: 15%;
  float: left;
  padding-right: 1.25rem;
}


@media (max-width: 36rem) {
  .slot-summary-form .section-row{
      width: 100%;
  }
  .slot-summary-form .section-row-input-half{
      width: 50%;
  }
}