.users-page .dropdown-menu {
  width: 164px;
}

.users-page .table-row .table-cell {
  height: 70px;
}

.users-page .user-name {
  color: #2b3238;
}

.users-page .user-name:hover {
  color: #1557bf;
}

.users-page .table-cell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-actions {
  display: flex;
  justify-content: space-between;
}

.editPopup input[type=submit] {
  width: auto;
}

.users-page .editPopup .error-message {
  font-size: 0.875rem;
  color: #ea353a;
}