.dr .view-role-btn {
  color: #1557bf;
}
.dr .view-role-btn:hover {
  text-decoration: underline;
}
.roles-table-popup {
  max-width: 93.75rem !important;
  min-width: 31.25rem !important;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: auto;
  margin-right: 5rem;
  margin-left: 5rem;
  padding: 0px;
  transform: translate3d(0, -50%, 0);
  max-height: 100vh;
  background: purple;
}
.roles-table-popup .row .wrap-cell {
  width: 50%;
}
