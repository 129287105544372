@media screen and (max-width: 840px) {
    .rewards.store-form .row{
      display:block

    }
    .stores
      .table.table-dynamic
      .table-row
      .table-cell.column-partner-name::before {
      content: 'Partner Name';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .stores
      .table.table-dynamic
      .table-row
      .table-cell.column-street::before {
      content: 'Street';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .stores
      .table.table-dynamic
      .table-row
      .table-cell.column-postalcode::before {
      content: 'Postal Code';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .stores
      .table.table-dynamic
      .table-row
      .table-cell.column-lat::before {
      content: 'Lat';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .stores
      .table.table-dynamic
      .table-row
      .table-cell.column-lng::before {
      content: 'Lng';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }

  }
  

  