.rewards-page .header-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
}
.rewards .rdw-link-modal-target-option input[type=checkbox] {
  display: inline-block;
}

.rewards .rdw-editor-wrapper{
  margin-top:0.625rem;
}
.rewards .col-12.webDescription{
  margin-bottom: 1.25rem;
}

.omnichallenge .Searchable .tag-value {
  cursor: pointer;
  padding: 0.5rem;
  
}
.omnichallenge .Searchable .tag-value-icon {
  width: 0.75rem;
  height: 0.75rem;
}

.rewards .rc-time-picker{
  display: flex;
}

.rewards .col-12.status{
  padding-top: 0.625rem;
}

.rewards .error{
  align-self: center;
}
.rewards .summary .heading{
  text-align: center;
  padding: 0.3125rem;
  background: #d0d0d8;
  font-weight: bold;
}
.rewards .summary .succesCount{
  color: green;
}

.rewards .summary .failure{
  color: red;
}

.rewards .summary .errors{
  color: maroon;
}

.rewards .summary .error{
  margin: 0.25rem 0;
  padding: 0.125rem 0;
}

.rewards-page .header-container > a {
  margin-right: 0.625rem;
  border: 1px solid #eaefef;
  background-color: #f3f5f6;
  padding: 0.375rem 0.5rem 0.625rem 0.5rem;
  border-left: none;
}
.rewards-page .header-container .dropdown-menu {
  width: 12rem;
  text-align: left;
}
.rewards-page .header-container .dropdown-menu span {
  white-space: nowrap;
}
.rewards-page .header-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.rewards-page .header-container .header-actions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.rewards .row.flex-wrap{
  flex-wrap: wrap;
}
.rewards .row.flex-wrap .col-12,.rewards .row.flex-wrap .col-6  {
  padding: 0 var(--s2);
  margin: 0;
  align-self: center;
}
.rewards .row.flex-wrap .error{
  margin: -1rem var(--s2) var(--s3);
  padding: 0.5rem 0;
}
.rewards .col-12,.rewards .col-6 ,.rewards .col-4,.rewards .col-3   {
  margin: 0 var(--s2);
  align-self: center;
}

.rewards .flex {
  display: flex;
  flex-flow: row wrap;
  gap: 1rem;
}

.rewards .flex-wrap {
  flex-wrap: wrap;
}

.rewards .flex-grow {
  flex-grow: 1;
}

.rewards .box {
  margin: 0.625rem 0;
  padding: 1rem;
  border: 0.0625rem solid var(--border-color);
}

.rewards .mt-05 {
  margin-top: 0.55rem;
}

.rewards .urlInfo {
  flex: 40%;
  padding: var(--s2);
}

.rewards .select-categories .select-field-dropdown {
  position: absolute;
  z-index: 2;
  width: 100%;
}

.rewards .form-btns{
  float: right;
}

.rewards .form-btns .primary{
  margin-left:var(--s3);
}

.rewards-page .table .table-cell {
  min-width: 8rem;
}
.rewards-page .listing-page .search-button-wrapper:not(:only-child) {
  margin-left: auto;
}
.rewards-page .header-container .header-actions-wrapper {
  margin-left: -3.25rem;
}
.rewards-page .table-row .rewards-page-actions a {
  color: #2b3238;
}
.rewards-page .table-row .rewards-page-actions a:hover {
  color: #1557bf;
}
.rewards-page .table.table-dynamic .table-row .table-cell.column-name {
  width: 55%;
}
.rewards-page .table-row .table-cell {
  height: 4.375rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.rewards-page .table .table-cell {
  vertical-align: baseline;
}
.rewards-page .table .table-cell:last-child {
  width: 8rem;
}
.rewards-page h1.title {
  min-width: 9.875rem;
}
.rewards-page .table .table-cell:last-child {
  width: 8rem;
}

.rewards .SingleDatePicker_picker{
  z-index: 999;
}

.rewards .small{
  font-size: 0.875rem;
}

.rewards-page .label-tooltip {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.rewards-page .label-tooltip .label-tooltiptext {
  visibility: hidden;
  width: 25.75rem;
  background-color: #fff;
  color: #2b3238;
  box-shadow: 0 0.25rem 0.625rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.625rem 1.25rem 1.25rem 1.25rem;
  position: absolute;
  z-index: 1;
  bottom: 134%;
  left: 4.625rem;
  text-align: left;
}
.rewards-page .label-tooltip .label-tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 3.375rem;
  margin-left: -0.3125rem;
  border-width: 0.625rem;
  border-style: solid;
  border-color: #fff transparent transparent transparent;
}
.rewards-page .label-tooltip:hover .label-tooltiptext {
  visibility: visible;
}

@media (max-width: 580px) {
  .rewards-page .label-tooltip .icon {
    position: absolute;
    bottom:-0.3125rem;
    left: 6rem;
  }
  .rewards-page .label-tooltip .label-tooltiptext{
    width: 19.25rem;

  }

  .rewards-page .table.table-dynamic .table-row {
    margin: 0.625rem 1.25rem !important;
    width: auto;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  .rewards-page .table.table-dynamic .table-cell {
    width: 100%;
  }
  .rewards-page .table-container {
    margin: 0.625rem -0.938rem 0 -0.938rem;
  }
  .rewards-page .header-container .header-actions-wrapper {
    margin-top: -0.25rem;
    margin-right: -0.313rem;
  }
  .rewards .slugify-button {
    width: 100%;
    margin-top: -1rem;
    margin-bottom: 0.5rem;
  }
}

@media only screen and (max-width: 650px) {
  .rewards-page .table .table-row .table-cell small,
  .rewards-page .table .table-row .table-cell span,
  .rewards-page .table .table-row .table-cell div {
    font-size: 0.75rem;
  }
  .rewards-page .header-container {
    flex-direction: column;
  }

}
@media screen and (max-width: 840px) {
  .rewards .row{
    flex-direction: column;
  }
  .rewards .row .col-6 {
    width: 100%;
  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-id::before {
    content: 'ID';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }

  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-type::before {
    content: 'Type';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .rewards-page
  .table.table-dynamic
  .table-row
  .table-cell.column-name::before {
  content: 'Name';
  display: block;
  color: #80959d;
  font-size: 0.75rem;
  }
  .rewards-page
  .table.table-dynamic
  .table-row
  .table-cell.column-title::before {
  content: 'Title';
  display: block;
  color: #80959d;
  font-size: 0.75rem;

  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-rank::before {
    content: 'Rank';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-startdate::before {
    content: 'Start Date';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }
  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-enddate::before {
    content: 'End Date';
    display: block;
    color: #80959d;
    font-size: 0.75rem;
  }

  .rewards-page
    .table.table-dynamic
    .table-row
    .table-cell.column-status {
    bottom: 0;
    position: relative;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 0.75rem 1.2rem;
    }
  .rewards-page .table.table-dynamic .table-row .table-cell.column-actions {
    bottom: 0;
    right: 0;
    position: absolute;
    width: 50%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 0.75rem 0.9375rem;
  }
}

@media screen and (min-width: 840px) {
  .col-6.pr{
    padding-right:1rem;
  }
  .rewards .w-50{
    display: flex;
    width: 100%;
    max-width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  .rewards-page .header-container {
    margin-left: auto;
    justify-content: space-between;
  }
  .rewards-page .header-container .header-actions-wrapper {
    flex-direction: row;
  }

}
