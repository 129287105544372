@use 'styles/colors' as *;
@use 'styles/mixins';

.tabs {
  overflow: auto;
  white-space: nowrap;
  display: flex;
  width: 100%;

  &.right {
    text-align: right;
  }
  &.center {
    text-align: center;
  }

  .tab {
    color: $fp-grey;
    background-color: $fp-white;
    font-size: 0.875rem;
    padding: 18px 48px;
    box-shadow: inset 0 -5px 3px -3px $fp-black-0-40;
    border: 1px solid $fp-white-2;
    border-width: 1px 1px 0 0;
    text-align: center;
    display: inline-block;
    position: relative;
    text-transform: capitalize;
    flex-grow: 1;

    &.previous {
      @include mixins.round-corner(bottom-right, 5px);
      border-right-width: 0;
      box-shadow: inset -5px -5px 3px -3px $fp-black-0-40;
    }
    &.active {
      font-weight: 600;
      color: $fp-black;
      background-color: $fp-white-0;
      box-shadow: 0 -2px 0 0 $fp-blue, inset 0 1px 0 0 $fp-blue;
      border-width: 0;

      &+ .tab {
        @include mixins.round-corner(bottom-left, 5px);
        box-shadow: inset 5px -5px 3px -3px $fp-black-0-40;
      }
    }
    &:first-child {
      border-left: 1px solid $fp-white-2;
    }
    &:last-child {
      @include mixins.round-corner(top-right, 5px);
      border-right: 1px solid $fp-white-2;
    }
  }
}

.add-tab {
  line-height: 54px;
  font-size: 0.875rem;
  font-weight: 600;
  white-space: nowrap;
  margin-left: 10px;
}
.delete-tab {
  display: none;
  color: $fp-grey;
  position: absolute;
  top: 6px;
  right: 6px;
  height: 1.5rem;
  width: 1.5rem;
  line-height: 1.5rem;
  cursor: pointer;
  border-radius: 100%;
  font-size: 1.25rem;
}
.tab.active .delete-tab {
  display: block;
}
.delete-tab:hover {
  background-color: $fp-red-2;
  color: $fp-white-0;
}