/* General */
.refund-popup .styledBox, .goodwill-popup .styledBox {
  background-color: var(--bg-color);
  border: 0.0625rem solid var(--border-color);
  padding: 0 0.5rem;
  margin-bottom: 1rem !important;
}
.refund-popup .content, .goodwill-popup .content{
  display: block;
  margin-top: 0.5rem;
}
.refund-popup .Checkbox label, .rewards-tab .rewards-tab-catalogue, .goodwill-popup .Checkbox label {
  margin-bottom: 1rem;
}
.rewards-tab.container {
  max-width: 100%;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.rewards-tab .row {
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0 var(--s3);
  display: flex;
}
.rewards-tab .row.flex-direction {
  flex-direction: column;
}
.rewards-tab .primary {
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}
.rewards-tab .error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  font-size: 0.75rem;
  border: 1px solid transparent;
  margin: 0.9375rem 0 0.5rem;
  padding: 0.5rem 0;
  text-align: center;
}
.rewards-tab .DropDown .dropdown-menu {
  min-width: 9.375rem;
}
.rewards-tab .status {
  font-weight: 700;
}
.rewards-tab .status.winner {
  color: green;
}
.rewards-tab .status.reserve {
  color: #1557bf;
}
.rewards-tab .status.forfeit {
  color: #eb8181;
}
.rewards-tab .header-container {
  display: none;
}
.rewards-tab .table .table-cell:last-child {
  width: 100%;
}
.rewards-tab .tab-list .title {
  font-size: 1.25rem;
  margin-bottom: 1.25rem;
}
.rewards-tab .tab-list span {
  color: var(--secondary-text-color);
  padding-left: 0.25rem;
}
.rewards-tab .col-12 {
  width: 100%;
}
.rewards-tab .col-6 {
  width: 100%;
}
.rewards-tab .col-4 {
  width: 50%;
  margin: var(--s2) 0;
}
.rewards-tab hr {
  margin: var(--s3) 0;
}
.rewards-tab .ml {
  margin-left: var(--s2);
}
.rewards-tab .m-lr-05 {
  margin: 0 0.5rem;
}
.rewards-tab .mt-0 {
  margin-top: 0;
}
.rewards-tab .my {
  margin: var(--s2) 0;
}
.rewards-tab .auto-width {
  width: auto;
}
.rewards-tab .col-3 {
  width: 100%;
}
.rewards-tab .mt {
  margin-top: var(--s3);
}
.rewards-tab .mb {
  margin-bottom: var(--s3);
}
.rewards-tab .mt-1 {
  margin-top: 1.25rem;
}
.rewards-tab label.thin {
  height: 1.875rem;
}
.rewards-tab .col-12 {
  width: 100%;
}
.rewards-tab select {
  text-transform: none;
  min-width: 19.5rem;
}
.rewards-tab .table.table-dynamic {
  margin: 0;
}
.rewards-tab .rewards-page .table .table-row {
  width: 100%;
}
.rewards-tab .table-row .table-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rewards-tab .table-cell {
  align-items: flex-start;
  justify-content: flex-start;
}
.refund-popup .details > *{
  padding-bottom: 0!important;
}
.rewards-tab .rewards-tab-catalogue .table-cell {
  min-width: 6rem !important;
}
.rewards-tab-catalogue .catalogue-name{
  overflow-y: scroll;
  display: block !important;
}
.refund-popup .form-btns {
  float: unset !important;
  margin: 1rem 0;
  padding: 0;
}
.refund-popup .form-btns .col-12{
  display: flex;
  align-items: center;
  justify-content: end;
}

.refund-popup .error, .refund-popup .success,
.goodwill-popup .error, .goodwill-popup .success {
  padding-bottom: 0.5rem !important;
  width: 100%;
  margin-top:0;
}
.rewards-tab .reward-code {
  word-break: break-all;
}
.rewards-tab .btn-primary {
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #1557bf !important;
  color: #fff !important;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
  text-decoration: none;
  line-height: 2.5rem;
  display: inline-block;
  text-align: center;
}

.goodwill-popup.rewards .form-btns{
  float: none;
  text-align: right;
}
.goodwill-confirm-popup .input-addon, .goodwill-confirm-popup input[type='text']:read-only,
.goodwill-confirm-popup input[type='number']:read-only, .goodwill-confirm-popup .Select select {
  background:none !important;
  border: white !important;
  padding-right: 0 !important;
}

.goodwill-confirm-popup .orderNewAmount input[type='number']{
  padding-left: 0.125rem !important;
}
.goodwill-popup .details > * {
  padding-bottom: 0.5rem!important;
}
.goodwill-popup .details:last-child {
  padding-bottom: 1.5rem !important;
}
.goodwill-confirm-popup .error{
  margin-top :0 !important;
  margin-bottom: var(--s3) !important;
}

.goodwill-confirm-popup .field label,.goodwill-confirm-popup .field{
  margin: 0 !important;
}
/* Draw Opt In Feature */
.rewards-tab .optOut label {
  margin-bottom: 0;
}

/* Challenges Feature */
.rewards-tab .challenge-dates textarea {
  height: 1.875rem;
  padding: 0.875rem;
}
.rewards-tab .challenge-dates div {
  flex: 0.5;
}
.rewards-tab .challenge-date textarea {
  color: var(--border-color);
}
.rewards-tab .view-details-button, .order-popup .apply-goodwill{
  height: 2.5rem;
  width: 7rem;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  background-color: #f3f5f6;
  color: #2b3238;
  border: 0.0625rem solid transparent;
  border-radius: 0.125rem;
  letter-spacing: -0.0187rem;
  cursor: pointer;
}

.order-popup .apply-goodwill{
  width: 9.125rem;
  padding: 0.5rem;
  height: auto;
}
.order-popup .form-btns{
  padding-bottom: 0;
}
.challenge-table .header-container {
  display: none !important;
}
.challenge-table .table.table-dynamic .table-row .table-cell.taskTitle {
  min-width: 15rem;
}
.challenge-table .table.table-dynamic .table-row .table-cell {
  height: auto;
}
.challenge-table .table.table-dynamic .table-header :nth-child(2){
  min-width: 15rem;
}
.order-popup {
  max-width: 43.75rem;
}
.order-popup .header-container {
  display: none;
}
.order-popup .pagination-container {
  display: none;
}
.order-popup .emptyState {
  margin-bottom: 0;
}

.goodwill-popup p{
  padding: 0.25rem 0!important;
  margin: 0.25rem 0!important;
}
/* mobile */
@media screen and (max-width: 39.9375em) {
  .rewards-tab .table-row .table-cell {
    height: 4rem;
  }
  .rewards-tab .table-row .table-cell.id {
    display: none;
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.name {
    width: 100%;
  }

  .rewards-tab .table.table-dynamic .table-row .table-cell.order {
    width: 100%;
  }

  .rewards-tab .table.table-dynamic .table-row .table-cell.total {
    width: 100%;
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.taskIndex::before {
    content: 'No.: ';
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.taskTitle::before {
    content: 'Task Title: ';
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.taskProgress::before {
    content: 'Overall Progress: ';
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.taskCompletedAt::before {
    content: 'Completed At: ';
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.taskAward::before {
    content: 'Awarded: '
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.order::before {
    content: 'Reference no.: ';
    width: 100%;
  }

  .rewards-tab .table.table-dynamic .table-row .table-cell.channel::before {
    content: 'Channel: ';
    width: 50%;
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.ordertype::before {
    content: 'Order Type: ';
    width: 100%;
  }
  .rewards-tab .table.table-dynamic .table-row .table-cell.chances::before {
    content: 'Chances: ';
    width: 50%;
  }

  .rewards-tab .table.table-dynamic .table-row .table-cell.date {
    bottom: 0;
    width: 100%;
    height: auto;
    padding: 0.75rem 0.9375rem;
  }

  .rewards-tab .table.table-dynamic .table-row .table-cell.status {
    bottom: 0;
    width: 50%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 0.75rem 0.9375rem;
  }

  .rewards-tab .table.table-dynamic .table-row .table-cell.column-actions {
    bottom: 0;
    width: 50%;
    background: #fbfcfc;
    border-top: 1px solid #eaefef !important;
    height: auto;
    padding: 0.75rem 0.9375rem;
    align-items: flex-end;
  }
}
/* small */
@media screen and (min-width: 40em) {
  .rewards-tab.container {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .rewards-tab .col-6 {
    width: 50%;
  }
  .rewards-tab .col-4 {
    width: 25%;
  }
  .rewards-tab .col-3 {
    width: 30%;
  }
  .rewards-tab .row.flex-direction {
    flex-direction: row;
  }
  .rewards-tab .ml-sm {
    margin-left: var(--s2);
  }
  .rewards-tab .primary {
    margin-top: 0;
  }
  .rewards-tab .challenge-dates {
    gap: 3rem;
  }
}
/* medium */
@media screen and (min-width: 52em) {
  .rewards-tab .table.table-dynamic {
    margin: 0;
    width: 100%;
  }

  .rewards-tab .table .table-header {
    display: flex;
    justify-content: center;
    min-width: 0;
    flex-direction: row;
  }
  .rewards-tab .table .table-row {
    display: flex;
    justify-content: center;
    min-width: 0;
    flex-direction: row;
    width: 100%;
  }
  .rewards-tab .table .table-cell {
    min-width: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
