
.creativeReqList-box .creative-req-filter-form{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.creativeReqList-box .creative-req-filter-form .form-fields{
  width: 50%;
}
.creativeReqList-box .creative-req-filter-form .section-row-input{
  width: 100%;
}
.creativeReqList-box .add-creativereq{
  margin-left: 0.625rem;
}


.creativeReqList-box .table .table-cell{
  min-width: 3rem !important;
}

.creativeReqList-box .pagination-container {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}


.creativeReqList-box .creative-req-detail-form .section-row:after { 
  content: "";
  display: block;
  clear: both;
  visibility: hidden;
  height: 0;
  font-size:0;
}
.creativeReqList-box .creative-req-detail-form .section-row-input{
  width: 50%;;
  float: left;
  padding-right: 1.25rem;
}
.creativeReqList-box .creative-req-detail-form .form-buttons{
  margin-top: 4rem;
}

.creativeReqList-box .checkbox_btn{
  display: block;
  background-image: url(../../commonMedia/images/checkbox-unchecked.svg);
  margin-right: 0.6rem;
  background-size: 100%;
  height: 1.125rem;
  width: 1.125rem;
  padding: 0;
  border: none;
}

.creativeReqList-box .checkbox_btn.button-checked{
  background-image: url(../../commonMedia/images/checkbox-checked.svg);
}

.creativeReq-box .creative-req-detail-form .editip{
  font-size: 0.875rem;
  color: #80959d;
}