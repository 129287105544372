@use 'styles/colors' as *;

.delivery-area-content .places-search {
    margin-top: 10px;
}
.delivery-area-content .places-search img {
    position: absolute;
    top: 10px;
    right: 10px;
}
.delivery-area-content .map-area div[title='Stop drawing'], .delivery-area-content .map-area div[title='Draw a shape'] {
    padding: 7px !important;
}

.info-box {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    z-index: 1;
    padding: 0.5rem;
    min-width: 15rem;
    img {
        width: 1rem;
        height: 1rem;
        float: right;
        margin: 0.25rem;
        &:hover {
            cursor: pointer;
        }
    }
    .title {
        font-size: 14px;
        color: $fp-grey-2;
        font-weight: 700;
        margin-top: 0.5rem;
    }
    .content {
        font-size: 14px;
        color: $fp-black;
        margin-bottom: 0.5rem;
    }
}

@media screen and (max-width: 480px) {
    .delivery-areas .delivery-area-content {
        flex-direction: column;
    }
    .delivery-areas .add-delivery-area {
        margin-top: -7px;
    }
    .delivery-areas .delivery-area-content .map-controls .delivering-zones.title {
        font-size: 14px;
    }
    .delivery-areas .delivery-area-content .map-area, .delivery-areas .delivery-area-content .map-controls {
        width: 100%;
    }
    .delivery-areas .delivery-area-content .action-buttons {
        width: 88%;
    }
    .delivery-areas .delivery-area-content .action-buttons .button{
        width: 48%;
    }
    .delivery-areas .delivery-area-content .map-element {
        height: 300px;
    }
    .delivery-areas .delivery-area-content .zones-content .flex {
        flex-direction: row-reverse;
    }
    .delivery-area-content .zones-content button.update-group, .delivery-area-content .zones-content button.cancel-update {
        width: 48%;
    }
}

@media screen and (max-width: 375px) {
    .delivery-areas .add-delivery-area .primary {
        min-width: 100%;
    }
    .delivery-areas .add-delivery-area .primary span {
        display: none;
    }
}
