.emptyState {
    top: 108px;
    width: 100%;
    text-align: center;
    margin-bottom: 70px;
}

.emptyState .message {
    margin: 25px auto 5px;
    max-width: 30em;
}
.emptyState img {
    max-width: 100%;
}
.emptyState .submessage {
    font-size: 12px;
    color: #80959d;
    margin-bottom: 40px;
}

.emptyState + a {
    width: fit-content;
    display: inherit;
    text-decoration: none;
    line-height: 40px;
    text-align: center;
    margin: auto;
    margin-top: -35px;
    font-size: 14px;
    font-weight: bold;
}
