.payments-page .Payment-search {
  margin-bottom: 1.25rem;
  position: relative;
}

.payments-page .tab-contents {
  border: 1px solid #eaefef;
  margin: -1px 0px;
  padding: 0 20px 30px;
  background-color: #fff;
  position: relative;
}

.payments-page .field.payment-status {
  max-width: 680px;
}

.payments-page .table .cell-mobile-header {
  display: none;
}

@media screen and (max-width: 480px) {
  .payments-page .table .cell-mobile-header {
    font-size: 11px;
    color: #80959d;
    margin-right: 4px;
    display: inline;
    font-weight: normal;
  }
  .payments-page .table .cell-mobile-header::after {
    content: ':';
    margin-left: 2px;
  }
}
