.bulk-upload .product-upload {
  width: 440px;
  margin: 0 auto;
}
.editPopup.bulk-upload {
  max-width: fit-content;
}

.product-upload input {
  height: 90px;
  opacity: 0;
  cursor: pointer;
}

.product-upload .download-link {
  display: inline-block;
  margin-top: 10px;
  font-size: 0.75rem;
}

.product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.product-upload .border-text {
  display: inline-block;
  position: absolute;
  font-size: 0.75rem;
  color: #80959d;
  font-weight: 600;
  padding: 6px;
  background: white;
  transform: translate(-50%, -50%);
  top: 0px;
  left: 50%;
}

.product-upload .add-product-link {
  margin-bottom: 48px;
}

.bulk-upload .product-upload input.rc-time-picker-input {
  height: 45px;
}
.bulk-upload .product-upload .dateTimeSelect {
  display: flex;
  justify-content: space-between;
}
.bulk-upload .download-sample-text,
.bulk-upload .schedule-checkbox {
  margin-bottom: 10px;
}

.bulk-upload .product-upload .dateTimeSelect {
  display: flex;
  justify-content: space-between;
}

.product-upload .schedule-checkbox {
  margin-top: 20px;
  margin-bottom: 10px;
}
.product-upload .bordered-box {
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 20px;
  position: relative;
}

.bulk-upload .product-upload input {
  height: 44px;
  opacity: 1;
  cursor: pointer;
}

.bulk-upload .product-upload input[type='file'] {
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
}

@media screen and (max-width: 520px) {
  .bulk-upload .product-upload {
    width: 300px;
    margin: 0 auto;
  }
  .editPopup.bulk-upload {
    max-width: 500px;
  }
  .bulk-upload .product-upload .dateTimeSelect {
    flex-direction: column;
  }
  .bulk-upload .date-picker {
    max-width: 252px;
  }
}

.bulk-upload .product-upload .upload-button-container {
  text-align: center;
}
.bulk-upload .details .table-container,
.bulk-upload .loader-box {
  height: 70vh;
  width: 90vw;
  overflow-y: scroll;
}
.bulk-upload .table-container .table .table-cell:last-child {
  text-align: left;
}
.bulk-upload .details .error {
  margin: 10px 0;
  color: red;
}
.bulk-upload .details .success {
  margin: 10px 0;
  color: #1557bf;
  font-weight: bold;
}
.bulk-upload .table-row .category-id {
  font-weight: bold;
}
.bulk-upload .table-row .category-action {
  font-weight: bold;
  padding: 0 10px;
  color: white;
}
.bulk-upload .table-row .category-action.A {
  background: green;
}
.bulk-upload .table-row .category-action.D {
  background: red;
}
.bulk-upload .table-row .category-action.O {
  background: blue;
}
.bulk-upload .table-container .table-row.row-error {
  background: #ff000024;
}
.bulk-upload .details button.primary {
  float: none;
}
.bulk-upload .actions {
  text-align: right;
  margin-top: 12px;
}
.bulk-upload .muted {
  color: #80959d;
}
