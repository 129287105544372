.MapForm .form-buttons-container {
  margin-top: 10px;
}
.MapForm .form-buttons-container:after {
  content: "";
  display: block;
  clear: right;
}
.MapForm .form-buttons-container .button {
  float: none;
}
.MapForm .places-search {
  margin-top: 6px;
  right: 48px;
}
.MapForm .address-map {
  margin-top: 20px;
}