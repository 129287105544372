@media screen and (max-width: 840px) {
    .maintenance
      .table.table-dynamic
      .table-row
      .table-cell.column-period::before {
      content: 'Period';
      display: block;
      color: #80959d;
      font-size: 0.75rem;
    }
    .maintenance
      .table.table-dynamic
      .table-row
      .table-cell.column-period {
        margin-bottom: 2.5rem;
        height: auto;
    }
    .maintenance .table.table-dynamic .table-row .table-cell.column-actions {
      width: 100%;
 
    }
  }
  

  