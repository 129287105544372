.form-fields-entities,
.form-actions-entities {
  width: 50%;
}
#entityName {
  width: 100% !important;
}
.no-margin {
  margin: 0;
}
.error-msg {
  align-self: center;
  padding-left: 1.5rem;
}
.margin-top {
  margin-top: 3rem;
}
.uen {
  margin-top: 2rem;
}
#floor {
  margin-top: 1rem;
}
#buildingName {
  margin-top: 1rem;
}
#postalCode {
  margin-top: 1rem;
}

.entity-button {
  margin-right: auto;
}
