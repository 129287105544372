@use 'styles/colors' as *;

.amendment-item-text-order {
    font-weight: 600;
    background-color: $fp-teal-1;
    opacity: 0.8;
    color: $fp-white;
    border-radius: 5px;
    width: 7.4rem;
    text-align: center;
}

.invoice-summary-wrapper {
    .pending-amount-text {
        font-size: 0.8rem;
        margin-top: 0 !important;
        text-align: right;
    }
}

.delivery-order-list-items-container {
    position: relative;
    margin-top: 1rem;

    .sub-heading {
        background: $fp-white-3;
        padding: 1.3rem;
        width: 100%;
        position: absolute;
        box-shadow: none;
        z-index: 1;
    }

    .item-quantity {
      width: 5.625rem;
    }

    .item-amount {
      font-weight: 600;
      color: $fp-grey;
      font-size: 0.75rem;
    }

    .table-footer {
      margin-top: 1.25rem;
    }

    .product-name {
      color: $fp-teal-1;
      .brand-name {
        color: $fp-black;
      }
    }
    .table {
      .table-cell {
        min-width: 7rem;

        &.item-name {
          min-width: 8rem;
        }
        &.item-name-org {
          display: flex;
          min-width: 8rem;
        }
      }
      .row-without-styling {
        background-color: none !important;
      }
      .table-row {
        &:last-child {
          font-weight: bold;
        }
        &.child-row {
          opacity: 0.5;
        }
        &.sub-item-gray-out {
          opacity: 0.5;
        }

        .substitution-icon-img {
          right: 0.5625rem;
          bottom: 0.3rem;
        }
        &.black-text{
          small {
            color: $fp-black;
          }
        }
        &.without-border {
          .table-cell {
            border-top: none;
          }
        }
      }

      .table-cell {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;

        &.item-image {
          position: relative;
          .sub-icon-img {
            position: absolute;
            right: 0.5625rem;
            bottom: 0.3rem;
          }
        }

        .item-link {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }
      }

      .offset-top {
        .table-cell {
          padding-top: 5rem;
        }
      }

    }


    .invoice-summary-wrapper {
      display: flex;
      justify-content: flex-end;

      .invoice-summary {
        .pending-amount {
          color: $fp-red-1;
        }
        & > div {
          margin-top: 0.6rem;
        }
        .small-size {
          font-size: 0.8rem;
        }
      }
    }
  }
