.delivery-note-form .form-action {
    margin-bottom:  1.25rem;
  }
  
  .delivery-note-form {
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
  }
  
  .delivery-note-form label {
    font-weight: 600;
  }