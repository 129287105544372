.media-spot-allocation .title .light{
    font-weight: 300;
    color: #80959d;
    font-size: 1.4rem;
}

.media-spot-allocation .asset-summary-wrap{
    margin: -0.625rem 0 1.25rem;
}

.media-spot-allocation .summary-info {
    display: flex;
    justify-content: center;
}

.media-spot-allocation .nameValueCard {
    max-width: 26.4rem;
}

/* slot table */
.media-spot-allocation .slotWrap{
    margin-top: 1.25rem;
}
.media-spot-allocation .slotWrap .slot-headercolumn{
    display: inline-block;
    width: 8rem;
    vertical-align: top;
}
.media-spot-allocation .slotWrap .slot-innerbox{
    display: inline-block;
    width: calc(100% - 8rem);
    white-space: nowrap;
    overflow-x: scroll;
    scroll-behavior: smooth;
}
.media-spot-allocation .slotWrap .slot-innerbox .table{
    width: 8rem;
    display: inline-block;
}
.media-spot-allocation .slotWrap .table .table-cell:last-child{
    text-align: center;
}

.media-spot-allocation .slotWrap .table .table-cell.green{
    background-color: #e9fbf1;
}
.media-spot-allocation .slotWrap .table .table-cell.red{
    background-color: #feeeed;
}

.media-spot-allocation .slotWrap .table .headerColumn .table-cell.doubleline{
    padding-top: 0.3125rem;
    padding-bottom: 0.375rem;
}
.media-spot-allocation .slotWrap .headerColumn .table-cell:last-child{
    width: 4rem;
    min-width: 4rem;
}
.media-spot-allocation .slotWrap .headerColumn .navigation{
    display: flex;
    justify-content: space-between;
  }

.media-spot-allocation .navigation-button {
    display: inline-block;
    margin: 0 0.125rem;
    height: 1.125rem;
    min-width: 1.5rem;
    padding: 0 0.25rem;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    color: #80959d;
    border: 0 solid transparent;
    transition: background 0.2s ease-in-out;
    vertical-align: bottom;
}

.media-spot-allocation .navigation-icon {
    display: inline-block;
    vertical-align: middle;
}


@media (max-width: 36rem) {
    .media-spot-allocation .section-wrap {
        flex-wrap: wrap;
    }
    .media-spot-allocation .summary-info {
        flex-wrap: wrap;
    }
    .media-spot-allocation .nameValueCard {
        margin : 1.25rem auto 0;
    }
}