@use 'styles/colors' as *;

.CustomerDetailsCard {
  background-color: $fp-white;
  text-align: center;
  font-size: 0.875rem;
  border-radius: 4px;
  width: 260px;

  .customer-details {
    align-items: center;
  }

  .image {
    height: 84px;
    width: 84px;
    margin-top: 30px;
    margin-bottom: 18px;
    border-radius: 84px;
    background-color: transparent;

    img {
      height: 100%;
      width: 100%;
      border: 1px solid $fp-blue;
      border-radius: 84px;
    }
  }

  .detail div {
    font-weight: 600;
    word-break: break-all;
    padding: 0 10px 5px 10px;
  }

  .detail {
    .delete-linkpass-button {
      background-color: red;
      color: white;
      padding: 0.5rem;
      border-radius: 0.25rem;
      width: 13rem;
      margin-bottom: 0.3rem;
    }
    .delete-linkpass-button:active {
      background-color: #a70000;
      cursor: pointer;
    }
    .password-reset-button,
    .force-logout-button {
      color: white;
      background-color: #1557bf;
      padding: 0.5rem;
      border-radius: 0.25rem;
      width: 13rem;
      margin-bottom: 0.3rem;
    }
    .update-email-button {
      color: green;
    }
    .close-update-email-button {
      color: red;
    }
    .password-reset-button:active,
    .force-logout-button:active {
      background-color: #0e3c85;
      cursor: pointer;
    }
    .show-linkpass-email-input-button:active {
      color: #d3d3d3;
    }
    .update-email-button:active {
      color: #80ff80;
    }
    .update-email-input-group {
      display: flex;
    }
    .input-wrapper {
      padding: 0;
    }
  }

  .button-container {
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }

  .error {
    font-size: 11px;
    color: $fp-red;
  }

  .success {
    font-size: 11px;
    color: $fp-green;
    font-weight: bold;
    padding: 0.5rem;
  }

  .customer-actions {
    padding-bottom: 40px;
    padding-top: 20px;
    display: flex;
    justify-content: center;

    .DropDown .dropdown-menu {
      top: 29px;
      right: -2px;
    }

    .call {
      background-image: url(./call-inactive.svg);
      &:hover {
        background-color: $fp-blue;
        background-image: url(./call.svg);
      }
    }
    .mail {
      background-image: url(./mail.svg);
      &:hover {
        background-color: $fp-blue-2;
        background-image: url(./mail-active.svg);
      }
    }
    .sms {
      background-image: url(./sms.svg);
      &:hover {
        background-color: $fp-red-2;
        background-image: url(./smsactive.svg);
      }
    }
  }
}

.edit-btn {
  width: 100% !important;
}

.delete-linkpass-popup {
  button {
    background-color: red;
    color: white;
    display: block;
    padding: 0.5rem;
    margin-left: auto;
  }
  button:active {
    background-color: #a70000;
    cursor: pointer;
  }
}
