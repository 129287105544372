.campaign-detail-page .section-row:after { 
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
    height: 0;
    font-size:0;
}
.campaign-detail-page .section-row-input{
    width: 50%;
    float: left;
    padding-right: 1.25rem;
}

.campaign-detail-page .timestamp{
    font-size: 0.75rem;
    text-align: right;
}

.campaign-detail-page textarea {
    width: 100%;
    height: 6.25rem;
  }

@media (max-width: 36rem) {
    .campaign-detail-page .section-row-input{
        width: 100%;
        padding-right: 0;
    }
}