.stores-page .table-cell {
  height: 70px;
  max-width: 280px;
}

.stores-page .dropdown-menu {
  width: 100px;
}

.stores-page .store-name {
  color: #2b3238;
}

.stores-page .store-name:hover {
  color: #1557bf;
}

.stores-page .table .table-cell .store-mobile-view-header {
  display: none;
}

.stores-page .actions-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.stores-page .actions-box button {
  margin-right: 0.6em;
}

@media screen and (max-width: 480px) {
  .stores-page .table-container {
    margin: 0px;
  }
  .stores-page .table .table-header {
    display: none;
  }
  .stores-page .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    border: 1px solid #eaefef;
    background: #ffffff;
    margin: 15px 0;
  }
  .stores-page .table .table-row .table-cell {
    border: none;
    padding: 10px 15px;
    height: auto;
    min-height: 30px;
  }
  .stores-page .table .table-row div.table-cell .store-mobile-view-header {
    display: block;
    font-size: 11px;
    color: #80959d;
    font-weight: normal;
  }
  .stores-page .table .store-has-delivery-hub {
    grid-column-start: 1;
    grid-row-start: 2;
  }
  .stores-page .table .store-actions {
    grid-column-start: 4;
    grid-row-start: 1;
  }
  .stores-page .table .store-name-container {
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .stores-page .table .store-client-id {
    grid-column-start: 3;
    grid-row-start: 2;
  }
  .stores-page .table .store-has-picking {
    grid-column-start: 2;
    grid-row-start: 2;
  }
  .stores-page .table .store-status {
    grid-column-start: 4;
    grid-row-start: 2;
  }
  .stores-page .table .table-cell.store-address {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    margin-top: 30px;
  }

  .store-form .business-time-row .table-cell:nth-child(2) {
    min-width: 120px;
  }
  .store-form .business-time-row .table-cell:nth-child(1) {
    min-width: 150px;
  }
  .stores-page .table .table-cell.store-has-delivery-hub,
  .stores-page .table .table-cell.store-client-id,
  .stores-page .table .table-cell.store-has-picking,
  .stores-page .table .table-cell.store-status {
    background-color: #fbfcfc;
    border-top: 1px solid #eaefef;
    font-size: 13px;
  }
  .stores-page .table .store-name-container .store-name {
    font-size: 14px;
    font-weight: 600;
  }
  .stores-page .table .table-cell.store-address {
    font-size: 12px;
    color: #80959d;
    font-weight: normal;
    padding-bottom: 10px;
    padding-top: 0px;
  }
  .stores-page .stores-filter .primary,
  .stores-page .stores-filter .button {
    min-width: 48%;
  }
  .stores-page .table .table-cell.store-has-delivery-hub span,
  .stores-page .table .table-cell.store-client-id span,
  .stores-page .table .table-cell.store-has-picking span,
  .stores-page .table .table-cell.store-status span {
    font-weight: 600;
  }
}

.store-configuration-form {
  max-width: unset;
  row-gap: 2rem;
}

.store-configuration-form .map-element {
  height: 300px;
}

.store-configuration-form .map-element {
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  transition: border 200ms ease-in-out;
}

.store-configuration-form .location-search-box {
  width: 240px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  font-weight: normal;
  height: 36px;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.store-configuration-form .coordinates-input input[name='coordinates'] {
  display: none;
}

.store-configuration-form .row {
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
  column-gap: 2rem;
}

.store-configuration-form .field.store-name {
  /* background-color: aqua; */
  width: 50%;
}

.store-configuration-form .field.store-name::after {
  content: '45 Characters Max.';
  display: block;
  text-align: right;
  color: #80959d;
  font-size: 0.75rem;
}
.store-configuration-form .field.store-type {
  /* background-color: aqua; */
  width: 50%;
}

/** Store Map Locator / Google Map components **/
.store-configuration-form .store-map-locator {
  /* background-color: blue; */
  width: auto;
  height: 300px;
}

.store-configuration-form .field.store-address {
  width: 100%;
}

.store-configuration-form .field.store-address::after {
  content: '(Readonly)';
  display: block;
  text-align: right;
  color: #80959d;
  font-size: 0.75rem;
}

.store-configuration-form .field.store-address .labelWrap label {
  margin: 0;
}

.store-configuration-form .field.store-address .address-label {
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: start;
  padding-bottom: 0.5rem;
  column-gap: 1rem;
}

.store-configuration-form .store-map-locator {
  width: 100%;
  height: 400px;
  /* background-color: #1557bf; */
}
.store-configuration-form .store-map-locator .map-element {
  width: 100%;
  height: 100%;
  border-radius: 2px;
  overflow: hidden;
  width: 100%;
  transition: border 200ms ease-in-out;
}

.store-configuration-form .store-map-locator .location-search-box {
  width: 240px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  font-weight: normal;
  height: 40px;
  text-overflow: ellipses;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.store-configuration-form .row.coord-button {
  margin-bottom: 1rem;
}

.store-configuration-form .row.upload {
  margin-top: 2rem;
  margin-bottom: 2rch;
}

.store-configuration-form .row .field {
  width: 100%;
}

.store-configuration-form .hours-form {
  margin-bottom: 30px;
}
.store-configuration-form .hours-form h2 {
  padding-bottom: 20px;
}
.store-configuration-form .hours-form .table-cell:nth-child(1) {
  min-width: 200px;
}
.store-configuration-form .hours-form .table-cell:nth-child(1) {
  min-width: 180px;
}

.form-error {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #ea353a;
  padding-left: 1rem;
  border-radius: 0.2rem;
}
.form-error .form-error-text {
  color: #ea353a;
}

.store-configuration-form .row .field .sng-toggle {
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.store-configuration-form .metadata-section .metadata-section-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1rem;
}

.store-configuration-form .metadata-section .metadata-section-wrapper .text,
.store-configuration-form .metadata-section .metadata-section-wrapper .string {
  grid-column: span 1;
}

.store-configuration-form
  .metadata-section
  .metadata-section-wrapper
  .field-checkbox {
  margin-bottom: 40px;
}
.store-configuration-form
  .metadata-section
  .metadata-section-wrapper
  span.Checkbox {
  position: relative;
  top: 30px;
}

.form-footer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.preview__popup {
  max-width: 1000px;
  max-height: 70vh;
}
.changes__submit {
  margin-top: 1rem;
}
.changes__wrapper {
  max-height: 500px;
  overflow-y: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.changes__wrapper .changes_empty {
  width: 100%;
  grid-column: span 2;
}
.changes__wrapper * {
  text-align: start;
  padding: 0.5rem;
}
.changes__header {
  font-weight: bold;
  text-align: left;
}

.changes__field {
  border-bottom: 1px solid lightgray;
}

.line-through {
  text-decoration: line-through;
}
.block {
  display: block;
}

.changes__wrapper .form-footer {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  align-items: center;
}
