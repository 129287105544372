@media screen and (max-width: 683px) {
  .appcard-form {
    .form-buttons-section {
      flex-direction: column;
      width: 100%;
    }
  }
}

.appcard-form {
  .grid-2-col {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 1rem;
    padding: 0 var(--s3);
  }

  h3 {
    grid-column: 1/3;
  }

  .w-100 {
    width: 100%;
  }

  .card-content-section {
    display: flex;
    grid-column: 1/3;
    flex-direction: column;
    gap: 1rem;

    .card-content {
      margin: 0 auto;
    }
  }

  .preview-button {
    margin: 0 auto;
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;
    grid-column: 1/3;
    border-radius: 0.25rem;
  }

  .preview-button:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  .rc-time-picker {
    display: flex;
  }

  .form-buttons-section {
    display: flex;
    grid-column: 1/3;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 3rem;
    width: 40%;
    gap: 1rem;

    .primary {
      height: 2.5rem;
      padding: 0 1.25rem;
      font-weight: bold;
      font-size: 0.875rem;
      text-transform: capitalize;
      border: 0.0625rem solid transparent;
      border-radius: 0.125rem;
      letter-spacing: -0.0187rem;
      cursor: pointer;
      text-decoration: none;
      line-height: 2.5rem;
      display: inline-block;
      text-align: center;
    }
  }
}
