.bottom-actions-wrapper {
	justify-content: flex-end !important;
	margin-top: 2rem;
	border-top: 1px solid #EAEFEF;
	padding-top: 1rem;
}

.error-msg {
	color: #a94442;
	font-size: 0.8rem;
	p {
		font-weight: bold;
		margin-top: 0;
	}
}