.account-recharge-page .header-container {
  flex-flow: row wrap;
}

.account-recharge-page .recharge-widget {
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fbfcfc;
  border-radius: 2px;
  margin-bottom: 30px;
}

.account-recharge-page .recharge-form-container {
  display: flex;
  max-width: initial;
  flex-basis: 420px;
}

.account-recharge-page h3 {
  margin: 0;
}

.account-recharge-page .account-recharge-input {
  margin-right: 10px;
  height: 40px;
}

.account-recharge-page .balance-indication {
  height: 74px;
  padding-left: 136px;
  background-image: url(./balance-info-icon.svg);
  background-repeat: no-repeat;
  background-position: center left;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 20px;
}

.account-recharge-page .available-balance-label {
  color: #80959d;
  font-size: 0.75rem;
  text-transform: capitalize;
}

.account-recharge-page .table-row .table-cell {
  height: 70px;
}

.account-recharge-page .transaction-name {
  text-transform: capitalize;
}

.account-recharge-page .payment-amount {
  font-weight: 600;
}

.account-recharge-page .payment-gateway-redirect-form {
  display: none;
}

.account-recharge-page .payment-status-completed {
  color: #1557bf;
}

.account-recharge-page .payment-status-failed {
  color: #ea353a;
}

.account-recharge-page .payment-type{
  text-transform: capitalize;
}

.account-recharge-page .empty-state {
  display: flex;
  flex-direction: column;
  margin: 25px auto 5px;
}

.account-recharge-page .account-mobile-view {
  display: none;
}

@media screen and (max-width: 820px) {
  .account-recharge-page .recharge-widget {
    flex-flow: row wrap;
  }
  .account-recharge-page .recharge-form-container {
    flex-basis: 100%;
  }
  .account-recharge-page .balance-indication {
    margin-bottom: 25px;
  }
}

@media screen and (max-width: 480px) {
  .account-recharge-page .account-mobile-view {
    display: block;
    font-size: 11px;
    color: #80959d;
    font-weight: normal;
  }
  .account-recharge-page .table-container {
    margin: 0px;
}
  .account-recharge-page .table .table-header {
    display: none;
  }
  .account-recharge-page .table .table-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    border:1px solid #eaefef;
    background: #ffffff;
    margin:15px 0;
  }
  .account-recharge-page .table .table-row .table-cell{
    border: none;
    padding: 10px 15px;
  }
  .account-recharge-page .table-cell.account-billing-info {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
  }
  .account-recharge-page .table-row .table-cell.account-payment-status, .account-recharge-page .table-row .table-cell.account-card-type, .account-recharge-page .table-row .table-cell.account-payment-amount {
    background-color: #fbfcfc;
    border-top: solid 1px #eaefef;
    font-size: 13px;
    font-weight: 600;
  }
  .account-recharge-page .transaction-name {
    font-weight: 600;
    display: inline-block;
  }
  .account-recharge-page .transaction-time {
    display: inline-block;
  }
  .account-recharge-page .table-row .table-cell {
    height: auto;
    min-height: 58px;
}
.account-recharge-page .transaction-time::before {
  content: '(';
  margin-left: 2px;
}
.account-recharge-page .transaction-time::after {
  content: ')';
}
.account-recharge-page .transaction-time::after, .account-recharge-page .transaction-time::before {
  font-size: 12px;
  color: #80959d;
}
.account-recharge-page .recharge-widget {
  margin-bottom: 15px;
}
.account-recharge-page .table-row .table-cell.account-card-type {
  text-align: center;
}
}