.verify img {
    max-width: 345px;
    max-height: 204px;
    max-width: 100%;
    object-fit: contain;
}
.verify {
    text-align: center;
}
.verify header {
    margin-top: 30px;
}
.verify .heading {
    text-transform: none;
    color: #2b3238;
}
.verify .header::after {
    display: none;
}
.verify .subheading {
    color: #80959d;
    font-size: 16px;
    letter-spacing: -0.2px;
}
.verify .button {
    width: 150px;
    height: 44px;
    display: inline-block;
    text-decoration: none;
}
.verify .span {
    margin-bottom: 10px;
    color: #2b3238;
}
