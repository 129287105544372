@use 'styles/colors' as *;

.info-card-container {
    width: 100%;
    border-radius: 4px;
    background-color: $fp-white;
    height: 84px;
    display: inline-flex;

    .image {
        line-height: 75px;
        padding-left: 30px;
        background-color: $fp-white;
    }

    img {
        height: 30px;
        width: 30px;
    }

    .info-card-details {
        padding-left: 24px;
        .title {
            padding-top: 1rem;
            letter-spacing: -0.1px;
            font-size: 0.75rem;
            color: $fp-grey;
        }

        .description {
            font-weight: 600;
            font-size: 0.875rem;
            letter-spacing: -0.3px;
        }
    }

}
