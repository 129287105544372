.print-order-details {
  select {
    text-transform: none;
  }
  background-color: white;
  .quantity {
    input {
      font-weight: 400;
    }
  }
}

.delete-code-qty {
  cursor: pointer;
  padding: 0 0.5rem;
}

.vendor-email {
  padding-right: 0.9375rem;
}

.print-order-detail-items {
  border: 1px solid #dadee0;
  border-radius: 0.3125rem;
  padding: 0.625rem 0.625rem 0 0.625rem;
  margin: 0 0.5rem 1.25rem 0.5rem;
  display: flex;
  flex-direction: column;
}

.other-details {
  padding-left: 0.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  margin-top: 1.25rem;
}

.print-order-actions {
  .DropDown {
    &.disabled-dropdown {
      cursor: not-allowed;
      opacity: 0.3;
    }
    .disabled-dropdown {
      pointer-events: none;
    }
  }
}

.print-order-detail-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
  button {
    margin-right: 0.5rem;
  }
}

.print-order-cancel-button {
  margin-left: 0.625rem;
}

.search-print-order {
  margin-right: 0.9375rem;
}

.dialogPopup .error {
  padding: 0;
}

.dialogPopup {
  .success,
  .error {
    display: block;
    .description {
      display: flex;
      margin-bottom: 1rem;
      font-size: 1rem;
      justify-content: center;
    }
  }

  .success .image {
    background-color: #d4edda;
  }
}
