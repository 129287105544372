.fullWidthPage.error-page main {
  box-shadow: none !important;
}
.error-page {
  position: relative;
}
.error-page h1 {
  position: absolute;
  top: 0;
  left: 0;
}