.communication-config .actions {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  padding: 0 30px;
  border: 1px solid #f5f5f5;
}

.communication-config .actions .DropDown {
  margin: auto;
  margin-right: -6px;
}

.communication-config .form-fields {
  border-radius: 2px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);
  background-color: #fbfcfc;
  padding: 20px 30px;
  margin-bottom: 20px;
}

.communication-config .actions img {
  max-width: 88px;
}

.communication-config form {
  max-width: 100%;
}

.communication-config .field {
  max-width: 320px;
}

.communication-config .selection {
  border: 1px solid #f5f5f5;
  padding: 30px;
  margin-bottom: 20px;
  margin-top: -1px;
}

.communication-config .selection .field {
  margin-bottom: 0;
  max-width: 420px;
}

.communication-config h5:first-of-type {
  margin-top: 0;
}

.communication-config .actions .Toggle {
  padding: 20px 0;
}

.communication-config .actions.alfa img {
  margin-left: -25px;
}

@media screen and (max-width: 768px) {
  .communication-config .form-fields {
    padding: 20px;
  }

  .communication-config .actions {
    padding: 0 20px;
  }
}
@media(max-width: 480px){
  h1.title{
    font-size: 1.125rem;
    line-height: 1.625rem;
    margin: 0 0 1rem;
  }
  .communication-config form .primary, .communication-config form .button {
    width: 48%;
  } 
}