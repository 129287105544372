@use 'styles/colors' as *;

.sale-order-details .sale-order-summary-wrapper {
    padding: 3rem 1.875rem;
    border-width: 0 1px;
    border-style: solid;
    border-color: $fp-white-2;
    background-color: $fp-white-0;
    position: relative;
  }

  .sale-order-details .sale-order-summary-wrapper::before {
    position: absolute;
    height: 1.125rem;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    content: '';
    background-image: url('../../../../../icons/sawtooth-pattern.svg');
    background-repeat: repeat-x;
    background-position: top left 1rem;
  }

  .sale-order-details .sale-order-summary-wrapper::after {
    position: absolute;
    height: 1.125rem;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    content: '';
    background-image: url('../../../../../icons/sawtooth-pattern.svg');
    background-repeat: repeat-x;
    background-position: bottom left;
  }

  .sale-order-details .user-details {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
  }