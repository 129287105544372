.customer-details {
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  padding: 1rem;
  background-color: white;
  justify-content: space-between;
  height: 20rem;
}

.customer-name {
  color: #4787eb;
}

.address-info {
  margin-top: 1rem;
  max-width: 18.75rem;
}

@media screen and (max-width: 580px) {
  .customer-details {
    height: auto;
  }
}
