@use 'styles/colors' as *;

.elapsed-time-container {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  line-height: 0.4rem;
  max-width: 10rem;
  border: solid 1px $fp-grey;
  .sub-heading {
    background-color: $fp-grey-2;
    color: $fp-white-2;
    padding: 0.5rem;
  }

  .duration-text {
    background-color: $fp-white-0;
    padding: 0.5rem;
    span {
      font-weight: 600;
    }
  }
}

.start-time-text {
  border-top: solid 1px $fp-black;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  font-size: 0.8rem;
  span {
    font-weight: 600;
  }
}