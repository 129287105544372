.ImageUpload {
  width: 100%;
  background-color: white;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  height: 250px;
}

.ImageUpload .image-upload-container {
  padding: 0;
  height: 100%;
}

.ImageUpload .image-upload-container.with-items {
  display: flex;
  padding: 20px 10px;
}

.ImageUpload input[type=file] {
  background-color: #fff;
  padding: 2px;
  border: 1px solid #e2e2e2;
  border-radius: 2px;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.ImageUpload .input-area {
  border: 2px solid transparent;
  position: relative;
  height: 100%;
}

.ImageUpload .image-upload-container.with-items .input-area {
  margin: 0 10px;
  width: 240px;
  flex: 0 0 240px;
}

.ImageUpload .image-upload-container.with-items .input-area:hover {
  border: 2px dashed #e2e2e2;
}

.ImageUpload .preview-area-container {
  flex: 1;
  margin: 0 10px;
  height: 100%;
  white-space: nowrap;
  overflow-x: auto;
}

.ImageUpload .image-preview-container {
  height: 180px;
  width: 180px;
  padding: 15px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.ImageUpload .image-preview-container img {
  max-width: 100%;
  max-height: 100%;
}

.ImageUpload .loading-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}

.ImageUpload .delete-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 16px;
  height: 16px;
  background-color: #80959d;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  text-align: center;
  line-height: 16px;
  z-index: 2;
}

.ImageUpload .image-preview-container:hover .delete-icon {
  display: block;
}

.ImageUpload .image-upload-placeholder-icon {
  width: 50px;
}

.ImageUpload input + .image-upload-placeholder {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
  color: #9e9e9e;
  font-size: .875rem;
  z-index: -1;
  transition: border-color 0.2s ease-in-out;
}

.ImageUpload .image-upload-placeholder-content {
  text-align: center;
  margin: auto;
}

.ImageUpload .image-upload-placeholder-text {
  max-width: 240px;
  margin: 16px auto 0 auto;
  color: #aeb9bd;
}

.image-selection-preview {
  text-align: center;
  padding-bottom: 0;
}

.image-selection-preview > img {
  max-width: 100%;
}

.image-selection-buttons {
  text-align: right;
}

.image-selection-button {
  background-color: #1557bf !important;
  color: #fff !important;
}

.image-confirmation-popup {
  max-width: initial;
  margin: auto 20px;
}
