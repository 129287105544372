.BrandForm .field {
  margin-bottom: 20px;
}

.BrandForm .form-actions.add, 
.BrandForm .form-actions.edit{
  display: flex;
  justify-content: flex-end;
  margin-top : 25px;
  position: relative;
}

.BrandForm input[type='submit'],
.BrandForm .button {
  min-width: 110px;
  width: auto;
}
.BrandForm .delete-button img{
  margin-right: 5px;
  color: #eb8181;
}

.BrandForm .delete-button span{
  margin-top: 2px;
}

.BrandForm .edit input[type='submit'] {
  margin-left: 10px;
}

.BrandForm .button:not(:last-child) {
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
  .BrandForm .hide-delete-buttons.flex {
    margin-bottom: 10px;
  }
  .BrandForm .form-actions.add .button{
    width:48%;
  }
  .BrandForm .form-actions.add input[type='submit']{
    width: 48%;
  }
  .BrandForm .form-actions.edit {
    height:82px;
  }
  .BrandForm .form-actions.edit .delete-button{
    align-self: flex-end;
    left:42%;
    bottom:-7%;
  }
  .BrandForm .form-actions.edit {
    margin-top: -6px;
  }
  .BrandForm .form-actions.edit .hide-delete-buttons .button{
    width:38vw;
  }
  .BrandForm .form-actions.edit input[type='submit']{
    min-width:48%;
  }
}
@media screen and (max-width: 400px){
  .BrandForm .form-actions.edit .hide-delete-buttons .button {
    width: 37vw;
}
.BrandForm .form-actions.edit input[type='submit'] {
  min-width: 33vw;
}
.BrandForm .form-actions.edit .delete-button{
  align-self: flex-end;
  left:35%;
}
.BrandForm .form-actions.add .button{
  width:46%;
}
}