.rack-management .store-selector{
  width: auto;
  max-width: 200px;
}

.rack-management .rack-add-store{
  display: flex;
  justify-content: space-between;
}

.rack-management .grid-box{
   margin-left: 3px;
   margin-top: 25px;
}

.rack-management .grid-box .grid-styling {
  display: flex;
  flex-wrap: wrap;
}
.rack-management .grid-styling-item{
  background-color: #f0f5f8;
  color: #2b3238;
  cursor: -webkit-grab;
}

.grid-styling-item {
  min-width: 64px;
  text-align: center;
  padding: 6px 10px;
  background-color: #80959d;
  color: #fff;
  margin: 5px;
  font-size: 0.875rem;
  position: relative;
  border: 2px solid #fff;
  border-radius: 4px;
  cursor: -webkit-grabbing;
}
.grid-styling-item .remove-button{
  display: none;
}

.rack-management .grid-styling-item:hover .remove-button{
  position: absolute;
  top: -7px;
  right: -7px;
  border-radius: 50%;
  background-color: #f88c8c;
  display: inline-block;
  height: 14px;
  width: 14px;
  line-height: 12px;
  text-align: center;
  font-size: 12px;
  color: white;
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.2s ease-in-out;
  padding: 0;
  vertical-align: middle;
}

.rack-management .add-more-rack{
  background-color: #1557bf;
  color: #fff;
  height: 40px;
  padding: 0 20px;
  font-weight: bold;
  font-size: 0.875rem;
  text-transform: capitalize;
  order: 1px solid transparent;
  border-radius: 2px;
  letter-spacing: -0.3px;
  cursor: pointer;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  margin-left: 6px;
}
.rack-management .form-buttons{
  margin-top: 30px;
}

.rack-management .button{
  margin-left: 10px;
}

.rack-management textarea{
  margin-bottom: 20px;
  resize: none;
}


@media screen and (max-width: 580px) {
  .rack-management .page-header-wrapper {
    display: block;
  }
  .rack-management .table-container {
    margin: 20px -30px 0 -30px;
  }
  .rack-management .pagination-count {
    display: none;
  }
  .rack-management .pagination-container {
    justify-content: center;
  }
  .rack-management .filters-wrapper .field {
    width: 100%;
  }
  .rack-management .header-actions-wrapper {
    display: flex;
    justify-content: flex-end;
  }
  .rack-management .search-button-wrapper:not(:only-child) {
    margin-right: 0px;
  }
  .rack-management .search-button {
    background-position: left center;
  }
  .rack-management .header-actions-wrapper .button{
    min-width: 0;
    font-size: 20px;
    padding: 0 15px;
  }
  .rack-management .header-actions-wrapper .button span.text{
    display: none;
  }
}