img[role='checkbox'],
img[role='button'] {
  cursor: pointer;
}

.selected-action {
  color: #1557bf;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.0256rem;
  border-left: 1px solid #cccccc;
  padding-left: 0.75rem;
}

.selected-label {
  color: #000000;
  font-style: normal;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;
}

.mr-14 {
  margin-right: 0.875rem;
}

.mr-12 {
  margin-right: 0.75rem;
}

.actions-toolbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #eaeaea;
  margin-top: 0.5rem;
}

.swimlane-actions {
  display: flex;
  align-items: center;
  border: 1px solid #eaeaea;
  padding-right: 0px;
  border-radius: 0.25rem;
  min-height: 3.9375rem;
  margin-bottom: 0.4375rem;
  margin-left: -1rem;
  padding-left: 1rem;
}

.view-changer {
  display: flex;
  column-gap: 0.5rem;
}
