.search-config {
  position: relative;
}

.search-config .search-config-listingpage {
  position: unset !important;
}

.search-config .header-container {
  position: absolute;
  width: 100%;
  top: 30px;
  right: 40px;
}

.search-config .search-config-listingpage {
  border: 1px solid #eaefef;
  padding: 15px;
  margin-top: -1px;
}

.search-config .table-header .table-cell:nth-child(4) {
  text-align: right;
}

.search-config .table .name span:hover {
  color: #1557bf;
  cursor: pointer;
}

.search-config-form .searchable-product-details .bold {
  font-weight: bold;
}

.search-config .table .table-cell.name,
.search-config .table-cell:last-child {
  padding-top: 25px;
  vertical-align: top;
}

.search-config .table .del-button {
  font-size: 0;
}

.search-config .table .product-details {
  display: flex;
  justify-content: flex-start;
  height: 40px;
}

.search-config .product-details small {
  padding: 8px 0;
}

.search-config .product-details .image {
  margin-right: 20px;
}

.search-config .table .delete-button img {
  height: 20px;
  width: 20px;
}

.search-config-popup .form-action {
  margin-top: 25px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.search-config .product-name {
  padding: 10px 0;
}

.search-config-popup {
  max-width: 1000px;
}

.search-config-popup form {
  max-width: 100%;
}

.search-config-popup .pdt-row:hover {
  cursor: grab;
}

.search-config-popup .search-product {
  max-width: 500px;
}

.search-config-popup .flex {
  flex-wrap: wrap;
}
.search-config-popup .flex div {
  flex: 1;
}

.search-config-popup .flex > div:not(:last-of-type) {
  margin-right: 20px;
}

.search-config-popup .flex.no-margin > div:not(:last-of-type) {
  margin-right: 5px;
}

.search-config .table-row .product-details:not(:last-of-type) {
  padding-bottom: 5px;
}

.sortableSearchPageHelper {
  z-index: 1000000;
  visibility: visible;
}

.sortableSearchPageHelper.pdt-row {
  display: flex;
  color: #2b3238;
  font-size: 0.875rem;
  -webkit-transition: -webkit-box-shadow 0.2s ease-in-out;
  transition: -webkit-box-shadow 0.2s ease-in-out;
  -o-transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out;
  transition: box-shadow 0.2s ease-in-out, -webkit-box-shadow 0.2s ease-in-out;
  width: 100%;
  background-color: #f4f7f9;
  padding: 20px;
  width: 100%;
}

.sortableSearchPageHelper.pdt-row .table-row {
  display: table-row;
}
.sortableSearchPageHelper.pdt-row .table-cell {
  display: table-cell;
  flex: 1;
  vertical-align: middle;
  padding: 5px 40px;
}

.sortableSearchPageHelper .table-cell:last-child {
  text-align: right;
  padding: 0;
  padding-right: 5px;
}

.sortableSearchPageHelper .table-cell:first-child {
  padding: 0;
  padding-left: 5px;
  text-align: left;
}

@media screen and (min-width: 890px) {
  .sortableSearchPageHelper.pdt-row .table-cell:nth-child(2) {
    min-width: 540px;
  }
}

@media (max-width: 480px) {
  .search-config .search-config-listingpage .table .table-header {
    display: none;
  }
  .search-config .table-container {
    border-top: 1px solid #eaefef;
    margin: 0px -25px;
    padding: 15px 20px;
  }
  .search-config .table .table-row {
    display: block;
    margin-bottom: 15px;
    border: 1px solid#eaefef;
    min-height: 110px;
    position: relative;
  }
  .search-config .table .table-row .table-cell div {
    font-size: 0.75rem;
  }
  .search-config .table .table-row .table-cell {
    border: none;
  }
  .search-config .table .table-cell.name {
    width: 90%;
  }
  .search-config .table .table-cell {
    display: block;
  }
  .search-config .table .search-config-product-name {
    display: inline-block;
    width: 100%;
  }
  .search-config .table .search-config-product-number {
    display: inline-block;
    position: absolute;
    right: 7px;
  }
  .search-config
    .table
    .search-config-product-name
    .product-details:nth-child(2n) {
    width: 100%;
    margin-top: 6px;
    background: #fbfcfc;
    border-top: 1px solid #eaefef;
    border-bottom: 1px solid #eaefef;
  }
  .search-config .table .product-details {
    height: 50px;
  }
  .search-config .search-config-actions {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .search-config .table .table-cell:last-child {
    padding-right: 15px;
  }
  .search-config .table .table-row:nth-child(2n) {
    background-color: #fff;
  }
  .search-config .table .table-row .table-cell div .search-config-name {
    font-size: 14px;
    padding-bottom: 5px;
  }
  .search-config
    .table
    .table-row
    .table-cell.search-config-product-name
    .header-product,
  .header-position {
    color: #80959d;
    font-size: 11px;
    font-weight: 600;
    height: 38px;
    padding-bottom: 5px;
  }
  .search-config .table .product-details,
  .search-config .table .product-name {
    height: 62px;
  }
  .search-config .table .product-name {
    margin-top: 3px;
    max-width: 165px;
  }
  .search-config
    .table
    .table-row
    .table-cell.search-config-product-name
    .search-config-image {
    margin: 7px;
  }
  .search-config .table .table-cell:first-child {
    padding-left: 15px;
    padding-bottom: 0px;
  }
  .search-config h1 {
    font-size: 18px;
  }
  .search-config input[type='submit'],
  .search-config .button {
    margin-top: -9px;
  }
  .search-config .header-actions-wrapper .button {
    width: 50px;
    overflow: hidden;
  }
  .search-config .tabs .tab {
    padding: 20px 20px;
  }
  .search-config .table-container {
    border-top: none;
  }
  .search-config .table .table-cell.name {
    padding-top: 15px;
  }
  .search-config .search-config-listingpage .header-actions-wrapper {
    margin-top: 0px;
    margin-right: -12px;
  }
  .search-config-popup .search-config-form .flex {
    display: grid;
  }
  .search-config-popup .search-config-form .flex > div:not(:last-of-type) {
    margin-right: 0px;
  }
  .search-config-popup .form-action .primary,
  .search-config-popup .form-action .button {
    flex: 1;
  }
  .search-config-popup .SingleDatePicker.SingleDatePicker_1 button {
    min-width: 0px;
  }
  .search-config-popup .DateInput.DateInput_1 input[type='text'] {
    padding-left: 0px;
  }
  .search-config-popup .form-action {
    margin-top: 10px;
  }
}

.search-config .search-notify {
  margin: 1.8rem 0;
  display: flex;
  font-size: 1.2rem;
  background: #f3f3f3;
  padding: 0.5rem;
}

.search-config .search-config-listingpage .disable-btn {
  pointer-events: none;
  color: #ccc;
}