
.carousels .table.table-dynamic .table-row .table-cell.column-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:15.625rem;
}

@media screen and (max-width: 840px) {
    .carousels
      .table.table-dynamic
      .table-row
      .table-cell.column-link::before {
      content: 'Page Url';
      display: block;
      color: #80959d;
      font-size: 0.75rem;

      }
      .carousels
      .table.table-dynamic
      .table-row
      .table-cell.column-web-img::before {
      content: 'Web Img';
      display: block;
      color: #80959d;
      font-size: 0.75rem;

      }
      .carousels
      .table.table-dynamic
      .table-row
      .table-cell.column-app-img::before {
      content: 'App Img';
      display: block;
      color: #80959d;
      font-size: 0.75rem;

      }
      .carousels
      .table.table-dynamic
      .table-row
      .table-cell.column-channel::before {
      content: 'Device Type';
      display: block;
      color: #80959d;
      font-size: 0.75rem;

      }
}
  

  