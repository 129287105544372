.seller-page .pagination-container {
  display: none;
}

.seller-page .table-header .table-cell {
  text-align: left;
}

.seller-page .seller-name {
  color: #2b3238;
  cursor: pointer;
}

.seller-page .seller-name:hover {
  color: #1557bf;
}
.seller-page .header-actions-wrapper a {
  color: white;
}

.seller-page .table .mobile-view-header {
  display: none;
}

@media screen and (max-width: 480px) {
  .seller-page .table .mobile-view-header {
    display: block;
    font-size: 11px;
    color: #80959d;
    font-weight: normal;
  }

  .seller-page .table .table-header {
    display: none;
  }

  .seller-page .table .table-row {
    display: grid;
    border: 0.0625rem solid #eaefef;
    background: #ffffff;
    margin: 0.9375rem 0;
  }

  .seller-page .table .table-cell span {
    font-size: 0.8125rem;
    color: #2b3238;
    font-weight: 600;
  }

  .seller-page .table .table-row .table-cell {
    border: none;
    padding: 0.625rem 0.9375rem;
    height: auto;
    min-height: 1.875rem;
    min-width: 8rem;
  }
}