.substitution-groups-page .table-row .table-cell {
  height: 70px;
}

.substitution-groups-page .dropdown-menu {
  width: 100px;
}

.substitution-groups-page .table-cell:nth-child(2) {
  text-align: right;
  padding-right: 10px;
}

.substitution-groups-page .group-name {
  color: #2b3238;
}

.substitution-groups-page .group-name:hover {
  color: #1557bf;
}

.substitution-groups-page .substitution-header-mobileview{
  display: none;
}
.substitution-groups-page .only-mobile{
  display: none;
}
.substitution-groups-page .not-mobile{
  display: block;
}
@media (max-width: 480px) {
  .substitution-groups-page .table-container{
    border-top: 1px solid #eaefef;
    margin: 0px -25px;
    padding: 15px 20px;
  }
  .substitution-groups-page .table .table-header{
    display: none;
  }
  .substitution-groups-page .table .table-row{
    display: block;
    margin-bottom: 15px;
    border: 1px solid#eaefef;
    min-height: 100px; 
    position: relative;
  }
  .substitution-groups-page .table .table-row .table-cell div {
    font-size: 0.75rem;
 }
 .substitution-groups-page .table-row .table-cell{
  height: auto;
}
.substitution-groups-page .table .table-row:nth-child(2n) {
  background-color: #fff;
}
.substitution-groups-page .table .table-row .table-cell{
  border: none;
  padding-left: 15px;
}
.substitution-groups-page .group-name{
  color: #2b3238;
  font-weight: 600;
  padding-right: 24px;
  word-break: break-all;
}
.substitution-groups-page .substitutionGroup-status,
.substitution-groups-page .product-count,
.substitution-groups-page .substitutionGroup-actions{
  position: absolute;
}
.substitution-groups-page .substitutionGroup-status{
  right:6px;
}
.substitution-groups-page .table .table-cell:first-child {
  padding-left: 15px;
}
.substitution-groups-page .table-row .product-count{
  bottom: 0px;
  width:100%;
  background:#fbfcfc;
  text-align: left;
  bottom:0px;
}
.substitution-groups-page .table-row .product-count{
  font-size: 0.75rem;
}

.substitution-groups-page .table-row .product-count .substitution-text-muted{
  color:#000;
  font-weight: 600;
}
.substitution-groups-page .substitutionGroup-actions{
  right:-8px;
  top:1px;
}
.substitution-groups-page .substitutionGroup-actions .DropDown .dropdown-menu .dropdown-items .dropdown-item{
  font-size: 13px;
}
.substitution-groups-page .table-row .table-cell.product-count {
  border-top: 1px solid #eaefef; 
}
.substitution-groups-page .filters-wrapper .primary, .substitution-groups-page .filters-wrapper .button{
  width: 48%;
}
.substitution-groups-page .substitution-header-mobileview{
  display: inline;
}
.substitution-groups-page .only-mobile{
  display: block;
}
.substitution-groups-page .not-mobile{
  display: none;
}
}