.slot-charges .form-actions {
  justify-content: flex-end;
}

.slot-charges .form-actions button {
  margin-right: 10px;
}

.slot-charges .table-row  span:hover {
  /* color: #1557bf; */
  cursor: pointer;
}
.slot-charges .table .table-cell {
  min-width: 16rem;
  border: 1px solid #eaefef;
  border-left: 0px;
  background: #ffffff;
  position: relative;
  padding: 0;
  text-align: center;
  max-width: 15rem;
}

.slot-charges .table :not(.table-header) .table-cell {
  height: 7rem;
  border-top: none;
}

.slot-charges .table-cell.slot-name {
  align-items: center;
  height: 9rem;
  border: 1px solid #eaefef;
  border-right: none;
  text-align: center;
  vertical-align: middle;
  background-color: #fbfcfc;
 
}
.slot-charges .tabs {
  margin-bottom: 30px;
}
.slot-charges .default-data, .slot-charges .date-data {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}
.slot-charges .default-data .delivery, .slot-charges .date-data .delivery {
  border-right: 1px solid #eaefef;
}
.slot-charges .delivery, .slot-charges .pickup {
  position: relative;
}
.slot-charges .delivery span.cell-action, .slot-charges .pickup span.cell-action {
  position: absolute;
  top: 5px;
  right: 5px;
  display: none;
}
.slot-charges .delivery:hover span.cell-action {
  display: inline-block;
}
.slot-charges .pickup:hover span.cell-action {
  display: inline-block;
}
.slot-charges .table .table-header {
  height: 65px;
}
.slot-charges .table .subcolumn-header {
  padding: 6px;
}
.slot-charges .SingleDatePickerInput__withBorder {
  border: none;
}
.slot-charges .cell-action.add {
  font-size: 25px;
  top: 10px;
  line-height: 13px;
  color: #80959D;
}
.slot-charges .table-row .charge-value {
  margin-top: 33%;
}
.slot-charges .table-row .charge-value input {
  max-width: 94px;
  margin-top: -10%;
}
.slot-charges .table-row:nth-child(2) .charge-value input {
  margin-top: -5%;
}
.slot-charges .table-row:nth-child(2) .charge-value {
  margin-top: 10%;
}
.slot-charges .table-wrapper{
  display: flex;
}
.slot-charges .table-wrapper .scrollable-table{
  overflow-x:auto;
}
.slot-charges .fixed-table {
  border-right: 1px solid #eaefef;
}
.slot-charges .fixed-table .table-header .table-cell:first-child { 
  border-left: 1px solid #eaefef;
  border-right: none;
}
.slot-charges .fixed-table .table .table-cell {
  min-width: 12rem;
}
@media(max-width:480px){
  .slot-charges .table :not(.table-header) .table-cell{
    height: 6rem;
  }
  .slot-charges .table .table-cell {
    min-width: 250px;
  }
  .slot-charges .fixed-table .table .table-cell {
    min-width: 100px;
    padding: 15px;
    text-align: left;
  }
  .slot-charges .table .charge-value input[type='number'] {
    max-height: 10px;
    max-height: 24px;
    max-width: 60px;
  }
  .slot-charges .delivery span.cell-action {
    top: 4px;
    right: 8px;
  }
  .slot-charges .delivery span.cell-action.add {
    top: 8px;
  }
  .slot-charges .table-row:nth-child(2) .charge-value {
    margin-top: 10%;
  }
  .slot-charges .table .cell-action img{
    width: 12px;
  }
  .slot-charges .fixed-table .table div:nth-child(2) .table-cell {
    padding-top: 27px;
  }
  .slot-charges .table-row .charge-value {
    margin-top: 30%;
}
.slot-charges .table-row .charge-value input{
  margin-top: 0px;
}
}