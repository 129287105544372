.UserActions .dropdown-item a {
    color: #2b3238;
}

.UserActions .DropDown {
    position: inherit
}

.UserActions .dropdown-item {    
    line-height: 20px;
    font-weight: normal;
    cursor: pointer;
}

.UserActions .dropdown-toggle {
    opacity: 0;
    padding: 10px;
}

.UserActions .dropdown-menu {
    margin-top: -17px !important;
    width: 160px;
    margin-right: 15px;
}

.UserActions .dropdown-menu::before {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 81.5%;
    margin-left: -6px;
    border-width: 10.5px;
    border-style: solid;
    border-color: transparent transparent #eaefef transparent;
}

.UserActions .dropdown-menu::after {
    content: " ";
    position: absolute;
    bottom: 100%;
    left: 82.5%;
    margin-left: -6px;
    border-width: 9px;
    border-style: solid;
    border-color: transparent transparent #fff transparent;
}

.UserActions .DropDown .dropdown-item:hover {
    font-weight: normal;
}
.UserActions .DropDown .dropdown-item a:hover {
    color: #2b3238;
}
.UserActions {
    position: relative;
}
.UserActions:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 78px;
    height: 78px;
    border: 27px solid #fff;
    border-radius: 50%;
}
.UserActions.active:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 6px solid #1557bf;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}

#menu ul.menu-items:hover ~.accountInfo .UserActions.active::after {
    display: none;
} 
.userinfo .userinfo-image {
    display: inline-block;
    margin-bottom: 10px;
    position: relative;
}
.userinfo .userinfo-image img {
    border-radius: 50%;
}
.userinfo .userinfo-image-edit {
    position: absolute;
    font-size: 0;
    padding: 0;
    width: 24px;
    height: 24px;
    bottom: 0;
    right: 10px;
    border: 2px solid #fff;
    border-radius: 50%;
    background: #f4f7f9 url('./edit.svg') no-repeat center;
    cursor: pointer;
}

.UserActions.icon-profile-image.menu-toggle-container:hover {
    background-image: url('./active-user.svg');
    background-size: 24px;
} 

.userinfo .userinfo-name {
    font-size: 1.125rem;
    line-height: 1.6;
    letter-spacing: -0.3px;
    font-weight: bold;
}
.userinfo .userinfo-email {
    font-size: 0.75rem;
    line-height: 1.5;
    letter-spacing: -0.2px;
    color: #80959d;
}

.userinfo {
    text-align: center;
    font-weight: 600;
}

.userinfo .editPopup {
    text-align: left;
    width: 320px;
}

.userinfo .userinfo-image-edit + .input-wrapper {
    height: 0;
    width: 0;
    overflow: hidden;
    position: relative;
}

.userinfo .image-preview-popup {
    margin-bottom: 20px;
    padding: 0;
}
