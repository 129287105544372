@media screen and (max-width: 840px) {
    .rewards.partner-form .row{
      display:block

    }
    .partners
      .table.table-dynamic
      .table-row
      .table-cell.column-linkpointGain::before {
      content: 'Issuance Rate Linkpoint';
      display: block;
      color: #80959d;
      font-size: 0.75rem;

      }
      .partners
      .table.table-dynamic
      .table-row
      .table-cell.column-linkpointDollarSpend::before {
      content: 'Issuance Rate Dollar Value';
      display: block;
      color: #80959d;
      font-size: 0.75rem;

      }
}
  

  