.extensions-page button {
  min-width: 100px;
  min-height: 30px;
  padding: 0 15px;
  border-radius: 2px;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: -0.1px;
  background-color: #f3f5f6;
  color: var(--primary-text-color);
}
.extensions-page button.primary {
  margin-left: auto;
}
.extensions-page .editPopup {
  max-width: 800px;
  border-radius: 2px;
  text-align: left;
}
.extensions-page .search-button-wrapper button {
  background-color: transparent;
}
.extensions-page .table {
  display: grid;
  grid-template-columns: 2fr 2fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-auto-rows: minmax(216px, auto);
}
.extensions-page .extension-box {
  border-radius: 2px;
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #eaefef;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.extensions-page .extension-details {
  padding: 20px;
  position: relative;
  min-height: 160px;
}
.extensions-page .extension-table-name {
  display: flex;
  justify-content: flex-start;
  flex: 1 0;
}

.extensions-page .extension-table-name span {
  letter-spacing: -0.3px;
  font-weight: 600;
}
.extensions-page .extension-table-name span:hover {
  cursor: pointer;
  color: #1557bf;
}
.extensions-page .extension-table-name .description {
  color: #80959d;
  letter-spacing: -0.2px;
}
.extensions-page .extension-table-image {
  margin-right: 20px;
}
.extensions-page .extension-table-image img {
  height: 60px;
  width: 60px;
}
.extensions-page .extension-box .description .name {
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: -0.3px;
  padding: 2px 0;
}
.extensions-page .extension-table-name .explanation {
  font-size: 0.875rem;
  letter-spacing: -0.2px;
  color: #80959d;
  padding-top: 5px;
}
.extensions-page .extension-box .pricing {
  padding-top: 10px;
  position: absolute;
  bottom: 20px;
}
.extensions-page .pricing span {
  color: #1557bf;
  font-size: 0.875rem;
  font-weight: 600;
}
.extensions-page .extension-box .pricing .pricing-heading {
  font-size: 0.75rem;
  letter-spacing: -0.2px;
}
.extensions-page .extension-box .pricing .pricing-description {
  color: #80959d;
  letter-spacing: -0.3px;
  font-size: 0.75rem;
  padding-top: 2px;
}
.extensions-page .extension-actions {
  height: 70px;
  border-radius: 2px;
  background-color: #fbfcfc;
  padding: 20px;
  border-top: 1px solid #f5f5f5;
}
.extensions-page .extension-buttons {
  display: flex;
  justify-content: space-between;
}
.extensions-page .extension-configure {
  border-radius: 2px;
  background-color: #87d5fa;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  height: 30px;
  font-weight: bold;
  font-size: 0.75rem;
  padding-top: 4px;
  background-image: url('./setting.svg');
  background-position: left;
  padding-left: 33px;
  background-position-x: 11px;
  padding-right: 15px;
  color: #ffffff;
  line-height: 1.8;
}
.extensions-page .image {
  padding: 0;
}
.extensions-page .image > img {
  background-color: #fbfcfc;
  box-shadow: inset 0 0 0 1px #f5f5f5;
  border-radius: 2px;
}
.extensions-page .bordered.image {
  padding: 4px;
  background-color: #fbfcfc;
  border-color: #f5f5f5;
}
.extensions-page .bordered.image img {
  width: 50px;
  height: 50px;
}
.extensions-page .pagination-container {
  display: none;
}
.extensions-page .extension-filter-buttons {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--separator-color);
}
.extensions-page .extension-filter-buttons button {
  background-color: transparent;
  text-align: left;
  min-width: auto;
  color: var(--secondary-text-color);
  font-weight: normal;
  font-size: 0.875rem;
  margin-left: -2px;
  padding-bottom: 16px;
}

.extensions-page .extension-filter-buttons button:first-child {
  padding-left: 0px;
}

.extensions-page .extension-filter-buttons .selected span {
  padding: 16px 0;
  border-bottom: 2px solid var(--primary-color);
}
.extensions-page .extension-filter-buttons button.selected {
  color: #2b3238;
  font-weight: 600;
}

@media screen and (min-width: 1440px) {
  .extensions-page .table {
    grid-template-columns: 3fr 3fr 3fr;
  }
}

@media screen and (max-width: 768px) {
  .extensions-page .table {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 480px) {
  .extensions-page .header-actions-wrapper {
    width: initial;
  }
  .extensions-page .table-container {
    margin: 0;
  }
  .extensions-page .extension-filter-buttons {
    display: flex;
    overflow-x: auto;
  }
  .extensions-page .extension-filter-buttons button {
    min-width: fit-content;
  }
}
